import React from "react";
import footerlogo from "../../assets/images/footerlogo.png";
import facebook from "../../assets/images/facebook-icon.png";
import insta from "../../assets/images/instagrame-icon.png";
import tiktok from "../../assets/images/tiktok-icon.png";
import footerlogoresp from "../../assets/images/headerlogo.png";
import { Link } from "react-router-dom";
function Footer() {
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "instant" });
  };

  return (
    <footer className="footer-mean">
      <div className="container-1640">
        <div className="row d-lg-flex d-none">
          <div className="col-lg-4 col-md-12 m-auto">
            <div className="footer-contact px-4">
              <span className="foot-bg"></span>
              <h4>
                Get in <span>touch</span>
              </h4>
              <div class="ul-fo">
                <ul>
                  <li class="footer-flex">
                    <span>
                      <i class="fa-solid fa-envelope fa-shake"></i>
                    </span>

                    <a href="mailto:hello@tobaccovapesmart.com">
                      hello@tobaccovapesmart.com
                    </a>
                    <a></a>
                  </li>

                  <li>
                    <span>
                      <i class="fa-solid fa-phone fa-bounce"></i>
                    </span>
                    <a
                      href="tel:0426 597 982
"
                    >
                      0426 597 982
                    </a>
                  </li>
                  <li>
                    <span>
                      <i class="fa-solid fa-location-dot fa-bounce"></i>
                    </span>

                    <a
                      href="https://maps.app.goo.gl/qh81YpPL2z3j2L7YA"
                      target="_blank"
                      rel="noreferrer"
                    >
                      17 George Street, Parramatta, NSW 2150, Australia
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-12">
            <div className="footer-log position-relative z-50">
              <span>
                <Link to="/">
                  <img src={footerlogo} alt="" />
                </Link>
              </span>
            </div>
          </div>
          <div className="col-lg-4 col-md-12 m-auto">
            <div className="footer-social">
              <span className="foot-bg"></span>
              <h4 className="mb-4">
                Quick <span>Links</span>
              </h4>
              <ul>
                <li class="">
                  <Link onClick={scrollToTop} to="/Brand">
                    {" "}
                    Brands
                  </Link>
                </li>
                <li class="">
                  <Link onClick={scrollToTop} to="/sales">
                    Shop
                  </Link>
                </li>
                <li class="">
                  <Link onClick={scrollToTop} to="/Promotions">
                    This Week's Promotion
                  </Link>
                </li>
                {/* <li class="">
                    <Link to="">Order Status</Link>
                  </li> */}
                <li class="">
                  <Link onClick={scrollToTop} to="/about">
                    About Us
                  </Link>
                </li>
                <li class="">
                  <Link onClick={scrollToTop} to="/contact">
                    Contact
                  </Link>
                </li>
              </ul>
              <h4 className="mb-4 mt-4">
                Follow <span>us</span>
              </h4>
              <ul className="socialicons">
                <li>
                  <Link to="https://www.facebook.com/profile.php?id=61554943433228">
                    <img src={facebook} alt="" />
                  </Link>
                </li>
                <li>
                  <Link to="https://www.instagram.com/tobaccovapesmart/">
                    <img src={insta} alt="" />
                  </Link>
                </li>
                {/* <li>
                  <Link>
                    <img src={tiktok} alt="" />
                  </Link>
                </li> */}
              </ul>
            </div>
          </div>
        </div>
        <div className="row d-lg-none d-md-flex d-none">
          <div className="col-lg-4 col-md-4 m-auto">
            <div className="footer-social">
              <span className="foot-bg"></span>
              <h4 className="mb-4">
                Quick <span>Links</span>
              </h4>
              <ul>
                <li class="">
                  <Link onClick={scrollToTop} to="/Brand">
                    {" "}
                    Brands
                  </Link>
                </li>
                <li class="">
                  <Link onClick={scrollToTop} to="/sales">
                    Shop
                  </Link>
                </li>
                <li class="">
                  <Link onClick={scrollToTop} to="/Promotions">
                    This Week's Promotion
                  </Link>
                </li>
                {/* <li class="">
                    <Link to="">Order Status</Link>
                  </li> */}
                <li class="">
                  <Link onClick={scrollToTop} to="/about">
                    About Us
                  </Link>
                </li>
                <li class="">
                  <Link onClick={scrollToTop} to="/contact">
                    Contact
                  </Link>
                </li>
              </ul>
              <h4 className="mb-4 mt-4">
                Follow <span>us</span>
              </h4>
              <ul className="socialicons">
                <li>
                  <Link to="https://www.facebook.com/profile.php?id=61554943433228">
                    <img src={facebook} alt="" />
                  </Link>
                </li>
                <li>
                  <Link to="https://www.instagram.com/tobaccovapesmart/">
                    <img src={insta} alt="" />
                  </Link>
                </li>
                {/* <li>
                  <Link>
                    <img src={tiktok} alt="" />
                  </Link>
                </li> */}
              </ul>
            </div>
          </div>
          <div className="col-lg-4 col-md-4 px-0">
            <div className="footer-log position-relative z-50">
              <span>
                <Link to="/">
                  <img src={footerlogo} alt="" />
                </Link>
              </span>
            </div>
            <div className="footer-contact px-4 p-md-3">
              <span className="foot-bg"></span>
              <h4>
                Get in <span>touch</span>
              </h4>
              <div class="ul-fo">
                <ul>
                  <li class="footer-flex">
                    <span>
                      <i class="fa-solid fa-envelope fa-shake"></i>
                    </span>

                    <a href="mailto:hello@tobaccovapesmart.com">
                      hello@tobaccovapesmart.com
                    </a>
                    <a></a>
                  </li>

                  <li>
                    <span>
                      <i class="fa-solid fa-phone fa-bounce"></i>
                    </span>
                    <a
                      href="tel:0426 597 982
"
                    >
                      0426 597 982
                    </a>
                  </li>
                  <li>
                    <span>
                      <i class="fa-solid fa-location-dot fa-bounce"></i>
                    </span>

                    <a
                      href="https://maps.app.goo.gl/qh81YpPL2z3j2L7YA"
                      target="_blank"
                      rel="noreferrer"
                    >
                      17 George Street, Parramatta, NSW 2150, Australia
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-4 h-100">
            <div className="footer-social h-100">
              <span className="foot-bg"></span>
              <ul>
                <li class="">
                  <Link onClick={scrollToTop} to="/refund">
                    Refund Policy
                  </Link>
                </li>
                <li class="">
                  <Link onClick={scrollToTop} to="/privacy">
                    Security and Cookie Policy
                  </Link>
                </li>
                <li class="">
                  <Link onClick={scrollToTop} to="/Privacy-Policy">
                    Privacy Policy
                  </Link>
                </li>
                {/* <li class="">
                    <Link to="">Order Status</Link>
                  </li> */}
                <li class="">
                  <Link onClick={scrollToTop} to="/Delivery">
                    Same Day Delivery
                  </Link>
                </li>
                <li class="">
                  <Link onClick={scrollToTop} to="/How-Order">
                    How To Order
                  </Link>
                </li>
                <li class="">
                  <Link onClick={scrollToTop} to="/Delivery">
                    Delivery Times
                  </Link>
                </li>
                <li class="">
                  <Link onClick={scrollToTop} to="/Track-Order">
                    Track Your Order
                  </Link>
                </li>
                <li class="">
                  <Link onClick={scrollToTop} to="/Product-Availability">
                    Product Availability
                  </Link>
                </li>
                <li class="">
                  <Link onClick={scrollToTop} to="/TermsCondition">
                    Terms And Conditions Of Use
                  </Link>
                </li>
                <li class="">
                  <Link onClick={scrollToTop} to="/FAQ">
                    FAQ
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="row d-md-none d-flex">
          <div className="col-6 m-auto">
            <div className="footer-log position-relative z-50">
              <span>
                <Link to="/">
                  <img src={footerlogoresp} alt="" />
                </Link>
              </span>
            </div>
            <div className="footer-social">
              <span className="foot-bg"></span>
              <h4 className="mb-4">
                Quick <span>Links</span>
              </h4>
              <ul>
                <li class="">
                  <Link onClick={scrollToTop} to="/Brand">
                    {" "}
                    Brands
                  </Link>
                </li>
                <li class="">
                  <Link onClick={scrollToTop} to="/sales">
                    Shop
                  </Link>
                </li>
                <li class="">
                  <Link onClick={scrollToTop} to="/Promotions">
                    This Week's Promotion
                  </Link>
                </li>
                {/* <li class="">
                    <Link to="">Order Status</Link>
                  </li> */}
                <li class="">
                  <Link onClick={scrollToTop} to="/about">
                    About Us
                  </Link>
                </li>
                <li class="">
                  <Link onClick={scrollToTop} to="/contact">
                    Contact
                  </Link>
                </li>
              </ul>
              <h4 className="mb-4 mt-4">
                Follow <span>us</span>
              </h4>
              <ul className="socialicons">
                <li>
                  <Link to="https://www.facebook.com/profile.php?id=61554943433228">
                    {" "}
                    <img src={facebook} alt="" />
                  </Link>
                </li>
                <li>
                  <Link to="https://www.instagram.com/tobaccovapesmart/">
                    {" "}
                    <img src={insta} alt="" />
                  </Link>
                </li>
                {/* <li>
                  <Link>
                    {" "}
                    <img src={tiktok} alt="" />
                  </Link>
                </li> */}
              </ul>
            </div>
          </div>
          <div className="col-6 px-0">
            <div className="footer-contact px-3 pt-3 p-md-3 mb-4">
              <span className="foot-bg"></span>
              <h4>
                Get in <span>touch</span>
              </h4>
              <div class="ul-fo">
                <ul>
                  <li class="footer-flex">
                    <span>
                      <i class="fa-solid fa-envelope fa-shake"></i>
                    </span>

                    <a href="mailto:hello@tobaccovapesmart.com">
                      hello@tobaccovapesmart.com
                    </a>
                    <a></a>
                  </li>

                  <li>
                    <span>
                      <i class="fa-solid fa-phone fa-bounce"></i>
                    </span>
                    <a
                      href="tel:0426 597 982
"
                    >
                      0426 597 982
                    </a>
                  </li>
                  <li>
                    <span>
                      <i class="fa-solid fa-location-dot fa-bounce"></i>
                    </span>

                    <a
                      href="https://maps.app.goo.gl/qh81YpPL2z3j2L7YA"
                      target="_blank"
                      rel="noreferrer"
                    >
                      17 George Street, Parramatta, NSW 2150, Australia
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="footer-social h-100">
              <span className="foot-bg"></span>
              <ul>
                <li class="">
                  <Link onClick={scrollToTop} to="/refund">
                    Refund Policy
                  </Link>
                </li>
                <li class="">
                  <Link onClick={scrollToTop} to="/privacy">
                    Security and Cookie Policy
                  </Link>
                </li>
                <li class="">
                  <Link onClick={scrollToTop} to="/Privacy-Policy">
                    Privacy Policy
                  </Link>
                </li>
                {/* <li class="">
                    <Link to="">Order Status</Link>
                  </li> */}
                <li class="">
                  <Link onClick={scrollToTop} to="/Delivery">
                    Same Day Delivery
                  </Link>
                </li>
                <li class="">
                  <Link onClick={scrollToTop} to="/How-Order">
                    How To Order
                  </Link>
                </li>
                <li class="">
                  <Link onClick={scrollToTop} to="/Delivery">
                    Delivery Times
                  </Link>
                </li>
                <li class="">
                  <Link onClick={scrollToTop} to="/Track-Order">
                    Track Your Order
                  </Link>
                </li>
                <li class="">
                  <Link onClick={scrollToTop} to="/Product-Availability">
                    Product Availability
                  </Link>
                </li>
                <li class="">
                  <Link onClick={scrollToTop} to="/TermsCondition">
                    Terms And Conditions Of Use
                  </Link>
                </li>
                <li class="">
                  <Link onClick={scrollToTop} to="/FAQ">
                    FAQ
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div class="warning copyright" style={{ justifyContent: "center" }}>
        <p>
          © 2023 Tobacco Store | All Rights Reserved |
          <Link className="text-dark" to="https://techciaga.com.au" _blank>
            {" "}
            Who Designed This Website?{" "}
          </Link>
        </p>
      </div>
    </footer>
  );
}

export default Footer;
