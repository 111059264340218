import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import SideBar from "../../../Components/Admin/SideBar";
import ProductImg from "../../../assets/images/product1.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faChevronUp,
    faCircleChevronLeft,
    faListUl,
    faPencil,
    faPlus,
    faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { AuthorizationToken, baseUrl } from "../../../assets/utils/IP";
import axios from "axios";
import Swal from "sweetalert2";
export default function AdminProduct() {
    const [allProducts, setAllProducts] = useState()
    const [restoreProductId, setRestoreProductId] = useState()
    const [visibleProducts, setVisibleProducts] = useState(10);
    const [selectedProducts, setSelectedProducts] = useState([]);
    const [selectedValue, setSelectedValue] = useState('');
    const [productType, setProductType] = useState('');

    useEffect(() => {
        const fetchData = async () => {
            try {
                const config = {
                    method: 'get',
                    maxBodyLength: Infinity,
                    url: `${baseUrl}/get-all-deleted-product`,

                };

                const response = await axios.request(config);
                setAllProducts(response.data.results)
            } catch (error) {
            }
        };

        fetchData();
    }, []);
    const handleDropdownChange = (e) => {
        setSelectedValue(e.target.value);
    };
    const handleProductDropdownChange = (e) => {
        setProductType(e.target.value);
    };
    const RestoreByID = (pid) => {
        setRestoreProductId(pid)
    }

    const handleRestore = (id) => {
        const config = {
            method: 'put',
            maxBodyLength: Infinity,
            url: `${baseUrl}/restore-product/${id}`,
            headers: {
                'Authorization':
                    `Bearer ${AuthorizationToken}`,
            },
        };

        axios
            .request(config)
            .then((response) => {
                setAllProducts((prevProd) =>
                    prevProd.filter((product) => product.product_id !== id)
                );
            })
            .catch((error) => {
            });
    };

    const loadMoreProducts = () => {
        setVisibleProducts((prevVisibleProducts) => prevVisibleProducts + 10);
    };

    const displayedProducts = allProducts?.slice(0, visibleProducts);





    const handleSelectAll = () => {
        setSelectedProducts(allProducts?.map((products) => products?.product_id));
    };

    const handleDeselectAll = () => {
        setSelectedProducts([]);
    };

    const handleBrandSelect = (productID) => {
        // Step 4
        if (selectedProducts.includes(productID)) {
            setSelectedProducts(selectedProducts.filter((id) => id !== productID));
        } else {
            setSelectedProducts([...selectedProducts, productID]);
        }
    };



    return (
        <section className="bg-main">
            <SideBar />
            <div className="second-main-side">
                <div className="sec-main-side-parent p-md-4 p-lg-5">
                    <div className="blur-pages px-5">
                        <div className="border-text">
                            <div className="d-flex justify-content-between">
                                <h1>DELETED PRODUCTS</h1>
                                <div className="boxs-flex">
                                    <Link
                                        className="p-3 justify-content-center bg-light w-25 h-25 d-flex align-items-center text-dark rounded-2"
                                        to="/AdminProduct"
                                    >
                                        <FontAwesomeIcon icon={faCircleChevronLeft} />
                                    </Link>
                                </div>
                            </div>
                            <hr className="text-light opacity-100" />
                        </div>
                        <div className="d-flex justify-content-end mt-4">
                            <div className="dropdown drop-cust">
                                <select className="border-btn bg-dark text-light p-2 rounded mx-2 " value={selectedValue} onChange={handleDropdownChange}>
                                    <option value="">Select</option>
                                    <option value="In Stock">In Stock</option>
                                    <option value="Out Stock">Out Stock</option>
                                </select>
                            </div>
                            <div className="dropdown drop-cust">
                                <select className="border-btn bg-dark text-light p-2 rounded mx-2 " value={productType} onChange={handleProductDropdownChange}>
                                    <option value="">Select</option>
                                    <option value="Promotion">Promotion Products</option>
                                    <option value="Non-Promotion">Non promotion Products</option>
                                </select>
                            </div>
                        </div>
                        <div className="add-categorie-list">
                            <FontAwesomeIcon color="#fff" icon={faListUl} />
                            <span className="mx-2">Product List</span>
                        </div>
                        <div className="main-fi-order-box-master">
              <div className="main-fi-order-box">
                        <div className="product-table-parent">
                            <div className="product-grid-divide w-100 align-items-center rounded-3 my-3">
                                <div className="categori-name p-3">
                                    <input
                                        type="checkbox"
                                        onChange={() =>
                                            selectedProducts?.length < allProducts?.length
                                                ? handleSelectAll()
                                                : handleDeselectAll()
                                        }
                                        checked={selectedProducts.length === allProducts?.length}
                                    />                     </div>
                                <div className="categori-name p-3">
                                    <p className="mx-3">Images</p>
                                </div>
                                <div className="categori-name d-flex align-items-center p-3">
                                    <p className="mx-3">Product Name</p>
                                    <FontAwesomeIcon icon={faChevronUp} />
                                </div>
                                <div className="categori-name border-end p-3 text-start">
                                    <p>Category</p>
                                </div>
                                <div className="categori-name border-end p-3 text-center">
                                    <p>Price</p>
                                </div>
                                <div className="categori-name border-end p-3 text-center">
                                    <p>Quantity</p>
                                </div>
                                <div className="categori-name p-3 text-end border-end-0">
                                    <p>Action</p>
                                </div>
                            </div>

                            {displayedProducts
                                ?.filter((product) => {
                                    if (selectedValue === 'In Stock') {
                                        return product.status === 1; // Filter In Stock products
                                    } else if (selectedValue === 'Out Stock') {
                                        return product.status === 0; // Filter Out Stock products
                                    }
                                    else if (productType === "Promotion") {
                                        return product?.promotion_status === 1;
                                    }
                                    else if (productType === "Non-Promotion") {
                                        return product?.promotion_status === 0;
                                    }
                                    return true; // No filter (Select)
                                })
                                .map((product) => (<div key={product.id} className="product-grid-divide-box w-100 align-items-center rounded-3 my-3 text-light">
                                    <div className="h-100 d-flex align-items-center categori-name p-2">
                                    </div>
                                    <div className="h-100 categori-name text-center p-2">
                                        <img className="" style={{ borderRadius: "50px", height: "50px", width: "50px" }} src={`${baseUrl}/${product.main_image}`} alt="" />
                                    </div>
                                    <div className="h-100 categori-name justify-content-between d-flex align-items-center p-2">
                                        <p className="mx-3">{product.product_name}</p>
                                        <p className={`fw-bold ${product.quantity > 0 ? 'text-success' : 'text-danger'}`}>
                                            {product.quantity > 0 ? 'IN STOCK' : 'OUT OF STOCK'}
                                        </p>
                                    </div>
                                    <div className="h-100 d-flex align-items-center categori-name border-end p-2 text-start">
                                        <p>{product.category_name}</p>
                                    </div>
                                    <div className="h-100 categori-name border-end p-2 text-end">
                                        <p className="text-decoration-line-through">${product.regular_price}</p>
                                        <p className="text-warning">${product.sale_price}</p>
                                    </div>
                                    <div className="h-100 d-flex align-items-center categori-name border-end p-2 justify-content-end">
                                        <p>{product.quantity}</p>
                                    </div>
                                    <div className="h-100 d-flex align-items-center justify-content-end categori-name p-2 px-3 text-end">
                                        {/* Button trigger modal */}
                                        <button type="button" className="btn bg-yellow resp-font-restore-btn" data-bs-toggle="modal" data-bs-target="#exampleModal" onClick={() => RestoreByID(product.product_id)}>
                                            Restore Product
                                        </button>

                                    </div>
                                </div>
                                ))}
                        </div>
                        </div>
                        </div>
                        <div className="text-center mt-3">
                                {visibleProducts < allProducts?.length && (
                                    <button className="btn-gradient px-4 py-1 rounded border-0" onClick={loadMoreProducts}>Load More</button>
                                )}
                            </div>
                    </div>
                </div>
            </div>
            {/* Modal */}
            <div
                className="modal fade"
                id="exampleModal"
                tabIndex={-1}
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog delet-popup d-flex align-items-center justify-content-center h-100">
                    <div className="modal-content p-3 rounded-5">
                        <div className="modal-header border-0">
                            <button
                                type="button"
                                className="btn-close text-light position-relative"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                            />
                        </div>
                        <div className="modal-body text-center delete-modal-responsive">
                            <FontAwesomeIcon color="red" fontSize="80px" icon={faTrash} />{" "}
                            <h1 className="text-light">
                                You are about to Restore this product
                            </h1>
                        </div>
                        <div className="modal-footer justify-content-center border-0">
                            <button
                                type="button"
                                className="btn btn-gradient"
                                data-bs-dismiss="modal"
                            >
                                Go Back
                            </button>
                            <button
                                type="button"
                                data-bs-dismiss="modal"
                                className="btn btn-danger"
                                onClick={() => handleRestore(restoreProductId)}
                            >
                                Restore Product
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            {/* Modal */}
            <div
                className="modal fade"
                id="exampleModal1"
                tabIndex={-1}
                aria-labelledby="exampleModalLabel1"
                aria-hidden="true"
            >
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h1 className="modal-title fs-5" id="exampleModalLabel">
                                Modal title
                            </h1>
                            <button
                                type="button"
                                className="btn-close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                            />
                        </div>
                        <div className="modal-body">..awdawdawdawd.</div>
                        <div className="modal-footer">
                            <button
                                type="button"
                                className="btn btn-secondary"
                                data-bs-dismiss="modal"
                            >
                                Close
                            </button>
                            <button type="button" className="btn btn-primary">
                                Save changes
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}
