import React, { useEffect, useState } from "react";
import SideBar from "../../../Components/Admin/SideBar";
import Graph from "../../../Components/Admin/Graph";
import GraphY from "../../../Components/Admin/GraphYearly";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCartShopping,
  faChartSimple,
  faEye,
} from "@fortawesome/free-solid-svg-icons";
import { faCircleDot } from "@fortawesome/free-regular-svg-icons";
import { baseUrl } from "../../../assets/utils/IP";
import { AuthorizationToken } from "../../../assets/utils/IP";
import axios from "axios";

export default function DashboardAdmin() {
  const [cancelOrder, setCancelOrder] = useState()
  const [pendingOrder, setPendingOrder] = useState()
  const [totalOrder, setTotalOrder] = useState()
  const [totalSales, setTotalSales] = useState()
  const [orders, setOrders] = useState()

  useEffect(() => {
    const fetchData = async () => {
      try {
        const config = {
          method: 'get',
          maxBodyLength: Infinity,
          url: `${baseUrl}/cancelled-order`,
          headers: {
            'Authorization':
              `Bearer ${AuthorizationToken}`,
          },
        };

        const response = await axios.request(config);
        setCancelOrder(response.data)
      } catch (error) {
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    // Define the Axios configuration
    let config = {
      method: 'get',
      maxBodyLength: Infinity,
      url: `${baseUrl}/pending-order`,
      headers: {
        'Authorization':
          `Bearer ${AuthorizationToken}`,
      }
    };

    // Make the Axios request within the useEffect
    axios.request(config)
      .then((response) => {
        setPendingOrder(response.data)
        // You can handle the response data here.
      })
      .catch((error) => {
        // Handle errors here.
      });

    // If you want to use this as a one-time request, you can omit the dependency array ([]) in useEffect.
  }, []);

  useEffect(() => {
    // Define the function to make the Axios request
    const fetchTotalOrders = async () => {
      try {
        let config = {
          method: 'get',
          maxBodyLength: Infinity,
          url: `${baseUrl}/total-order`,
          headers: {
            'Authorization':
              `Bearer ${AuthorizationToken}`,
          }
        };

        const response = await axios.request(config);
        setTotalOrder(response.data)
      } catch (error) {
      }
    };
    fetchTotalOrders();
  }, []);

  console.log(AuthorizationToken, "tokeeennn")


  useEffect(() => {
    const fetchData = async () => {
      try {
        const config = {
          method: 'get',
          maxBodyLength: Infinity,
          url: `${baseUrl}/get-all-orders`,
          headers: {
            'Authorization':
              `Bearer ${AuthorizationToken}`,
          },
        };

        const response = await axios.request(config);
        setOrders(response.data.orders)
      } catch (error) {
      }
    };
    fetchData();
  }, []);



  useEffect(() => {
    const fetchTotalSales = async () => {
      try {
        let config = {
          method: 'get',
          maxBodyLength: Infinity,
          url: `${baseUrl}/total-sales`,
          headers: {
            'Authorization':
              `Bearer ${AuthorizationToken}`,
          }
        };

        const response = await axios.request(config);
        setTotalSales(response.data)
      } catch (error) {
        // Handle errors here.
      }
    };
    fetchTotalSales();
  }, []);

  return (
    <section className="bg-main">
      <SideBar />
      <div className="second-main-side">
        <div className="sec-main-side-parent ">
          <div className="sec-side-fi-text justify-content-center border-text">
            <h1 className="text-light text-center display-2">Dashboard</h1>
            <hr className="text-light opacity-100" />
          </div>
          <div className="main-without-heading p-md-4 p-lg-5">
            <div className="parent-boxes-flex row">
              <div className="col-lg-3 p-3">
                <div className="parent-box-img">
                  <div className="text-center pt-2">
                    <FontAwesomeIcon
                      color="#fff"
                      fontSize="100px"
                      icon={faChartSimple}
                    />
                  </div>
                  <p className="text-center text-light fs-4 pt-3 m-0">
                    Total Sales
                  </p>
                  <h1 className="text-light fs-70 text-center">{totalSales?.total_sales != null ? `$${totalSales?.total_sales}` : "0"}</h1>
                  <div class="text-center veiw-more mt-4 ">
                    <Link
                      className="py-2 btn-gradient d-table w-75 mx-auto rounded-pill text-dark fw-bold font-bebas"
                      to="/AdminSales"
                    >
                      View More
                    </Link>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 p-3">
                <div className="parent-box-img">
                  <div className="text-center pt-2">
                    <FontAwesomeIcon
                      color="#fff"
                      fontSize="100px"
                      icon={faCartShopping}
                    />
                  </div>
                  <p className="text-center text-light fs-4 pt-3 m-0">
                    Cancelled Order
                  </p>
                  <h1 className="text-light fs-70 text-center">{cancelOrder?.cancelled_order_count}</h1>
                  <div class="text-center veiw-more mt-4 ">
                    <Link
                      className="py-2 btn-gradient d-table w-75 mx-auto rounded-pill text-dark fw-bold font-bebas"
                      to="/Reports"
                    >
                      View More
                    </Link>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 p-3">
                <div className="parent-box-img">
                  <div className="text-center pt-2">
                    <FontAwesomeIcon
                      color="#fff"
                      fontSize="100px"
                      icon={faCartShopping}
                    />
                  </div>
                  <p className="text-center text-light fs-4 pt-3 m-0">
                    Pending Orders
                  </p>
                  <h1 className="text-light fs-70 text-center">{pendingOrder?.pending_order_count}</h1>
                  <div class="text-center veiw-more mt-4 ">
                    <Link
                      className="py-2 btn-gradient d-table w-75 mx-auto rounded-pill text-dark fw-bold font-bebas"
                      to="/Reports"
                    >
                      View More
                    </Link>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 p-3">
                <div className="parent-box-img">
                  <div className="text-center pt-2">
                    <FontAwesomeIcon
                      color="#fff"
                      fontSize="100px"
                      icon={faCartShopping}
                    />
                  </div>
                  <p className="text-center text-light fs-4 pt-3 m-0">
                    Total Order
                  </p>
                  <h1 className="text-light fs-70 text-center">{totalOrder?.total_order_count}</h1>
                  <div class="text-center veiw-more mt-4 ">
                    <Link
                      className="py-2 btn-gradient d-table w-75 mx-auto rounded-pill text-dark fw-bold font-bebas"
                      to="/Reports"
                    >
                      View More
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="graph-parent-flex row">
              <div className="col-lg-6">
                <div className="graph my-4">
                  <Graph />
                </div>
              </div>
              <div className="col-lg-6">
                <div className="graph my-4">
                  <GraphY />
                </div>
              </div>
            </div>
            <div className="latest-order-box-parent">
              <div className="latest-order-flex">
                <img src="assets/imgs/Group-22.png" alt="" />
                <span>Latest Orders</span>
              </div>
              <div className="main-fi-order-box-master">
              <div className="main-fi-order-box">
              <div className="fi-order-box">
                <ul className="order-list">
                  <li>Order ID</li>
                  <li>Customer</li>
                  <li>Status</li>
                  <li>Date Added</li>
                  <li>Total</li>
                  <li>Action</li>
                </ul>
              </div>
              {orders?.slice(0, 5).map((order, index) => (
                <div key={index} className="sec-order-box py-4 border my-4 rounded rounded-3">
                  <ul className="sec-order-list text-light">
                    <li>{order.order_id}</li>
                    <li>{order.users}</li>
                    <li className="set-color-icn">
                      {order.order_status == "pending" ?
                        <li>< FontAwesomeIcon className="me-1 text-warning" icon={faCircleDot} />Pending</li> :
                        order.order_status == "canceled" ? (<li>< FontAwesomeIcon className="me-1 text-danger" icon={faCircleDot} />Canceled</li>) :
                          (<li>< FontAwesomeIcon className="me-1 text-success" icon={faCircleDot} />Completed</li>)}
                    </li>

                    <li>{order.created_at.substring(0, 10)}</li>
                    <li>{order.total_amount}</li>
                    <li>
                      <Link to={`/SalesView/${order.order_id}`}>
                        <FontAwesomeIcon icon={faEye} />
                      </Link>
                    </li>
                  </ul>
                </div>
              ))}
</div>
</div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
