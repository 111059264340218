import React, { useEffect, useState } from "react";
import Footer from "../Components/Footer/Footer";
import Header from "../Components/Header/Header";
import Warning from "../Components/Warning";
import { Link, useLocation } from "react-router-dom";
import header from "../assets/images/headerlogo.png";
import thnnkssmoke from "../assets/images/thnnkssmoke.svg";
import axios from "axios";
import { baseUrl } from "../assets/utils/IP";
import { useContext } from "react";
import { CartContext } from "../Components/Cart";

function OrderComplete() {
  const [billingAdd, setBillingAdd] = useState();
  const [streetAddress, setStreetAddress] = useState();
  const [streetAddress2, setStreetAddress2] = useState();
  // const [currentDateTime, setCurrentDateTime] = useState(null);

  const location = useLocation();

  // Access data from state
  const receivedData = location.state;

  const { getCartTotalCopy, cartItemsCopy } = useContext(CartContext);
  const userid = localStorage.getItem("userId");

  // console.log("receivedData>>>", receivedData)
  // console.log("billingAdd>>>", billingAdd)
  // console.log("userid>>>", userid)

  useEffect(() => {
    const fetchData = async () => {
      if (userid) {
        try {
          let config = {
            method: "get",
            maxBodyLength: Infinity,
            url: `${baseUrl}/get-billing-address/${userid}`,
            headers: {},
          };

          const response = await axios.request(config);
          setBillingAdd(response.data.user);
        } catch (error) {}
      } else {
        setBillingAdd({ ...receivedData });
      }
    };

    fetchData();
  }, [userid]);

  //street add
  useEffect(() => {
    const fetchData = async () => {
      if (userid) {
        try {
          const response = await axios.get(
            `${baseUrl}/get-shipping-address/${receivedData?.order_id}`
          );
          setStreetAddress(response?.data?.order);
        } catch (error) {}
      }
    };

    fetchData();
  }, [receivedData?.order_id]);

  useEffect(() => {
    const fetchData = async () => {
      if (userid) {
        try {
          const response = await axios.get(
            `${baseUrl}/get-street-address/${userid}`
          );
          setStreetAddress2(response?.data);
        } catch (error) {}
      }
    };

    fetchData();
  }, [userid]);

  //date time genrate
  // useEffect(() => {
  //   // Function to get the current date and time
  //   const getCurrentDateTime = () => {
  //     const now = new Date();
  //     const formattedDateTime = now.toLocaleString(); // You can customize the format as needed
  //     setCurrentDateTime(formattedDateTime);
  //   };

  //   getCurrentDateTime(); // Call the function when the component mounts

  //   const intervalId = setInterval(getCurrentDateTime, 1000);
  //   return () => clearInterval(intervalId);
  // }, []);

  return (
    <div className="font-bebas">
      <Warning />
      <Header />
      <div className="bef-aft">
        <div className="container-1640 pt-5">
          <div className="thanksmoke">
            <span className="buyleftspan">
              <img src={thnnkssmoke} />
            </span>
            <span className="buyrightspan">
              <img src={thnnkssmoke} />
            </span>
          </div>
          <section className="completeordersec border-top">
            <div className="thankyou">
              <img src={header} alt="" />
              <h1 class="carttitle mt-3">Thank you for your purchase!</h1>
              <p>Please find your order confirmation below</p>

              <h1 class="carttitle" style={{ marginTop: "100px" }}>
                Hello,{" "}
                {billingAdd?.full_name
                  ? billingAdd?.full_name
                  : billingAdd?.user_first_name +
                    " " +
                    billingAdd?.user_last_name}
                {/* Hello, {billingAdd?.user_first_name} {billingAdd?.user_last_name} */}
              </h1>
              <p>
                Ordering with Tobacco Store leaves you safe in the knowledge,
                <br />
                that not only are you dealing with the best cigar merchant in
                Australia, but that you
                <br />
                are dealing with a company that prides itself on ensuring each
                and every
                <br />
                customer receives the best customer service.
              </p>

              <div className="row">
                <h1
                  class="carttitle"
                  style={{ marginBottom: "0px", marginTop: "50px" }}
                >
                  Order Id: {receivedData?.order_id}
                </h1>
                <p className="mb-5">
                  Placed on {receivedData?.update_at.slice(0, 10)}
                </p>
                <div className="col-lg-6">
                  <div>
                    <h1 class="carttitle">Billing Information</h1>
                    <p>
                      {billingAdd?.full_name
                        ? billingAdd?.full_name
                        : billingAdd?.user_first_name +
                          " " +
                          billingAdd?.user_last_name}{" "}
                      <br /> {billingAdd?.billing_address} <br />
                      {billingAdd?.state} <br />
                      {billingAdd?.city},{billingAdd?.country} <br />
                    </p>
                    <h1 class="carttitle" style={{ marginTop: "50px" }}>
                      Payment Method
                    </h1>
                    <p>
                      {receivedData?.shipping_status === 1 ||
                      receivedData?.shipping_status === 2
                        ? "Via Bank transfer"
                        : "Pay at store"}
                    </p>
                  </div>
                </div>

                <div className="col-lg-6">
                  <div>
                    <h1 class="carttitle">Shipping Information</h1>
                    <p>
                      {/* {billingAdd?.user_first_name} {billingAdd?.user_last_name}  <br /> {streetAddress?.shipping_address ? streetAddress?.shipping_address : streetAddress2?.streetAddress} <br /> */}
                      {billingAdd?.full_name
                        ? billingAdd?.full_name
                        : billingAdd?.user_first_name +
                          " " +
                          billingAdd?.user_last_name}
                      <br />
                      {userid
                        ? streetAddress?.shipping_address
                          ? streetAddress?.shipping_address
                          : streetAddress2?.streetAddress
                        : billingAdd?.shipping_address}
                      <br />
                      {billingAdd?.state} <br /> {billingAdd?.city},
                      {billingAdd?.country} <br />
                      {billingAdd?.phone_number}
                    </p>
                    <h1 class="carttitle">Shipping Method</h1>
                    <p>
                      {receivedData?.shipping_status === 1
                        ? " Worldwide Shipping ( Standard Post estimated delivery and dispatch 7 - 10 working days"
                        : receivedData?.shipping_status === 2
                        ? "NATION WIDE"
                        : "Pay at store"}
                    </p>
                  </div>
                </div>
              </div>
              {/* <div className="passnum">
                <h1 class="carttitle">
                  Passport Number or Identity Number {billingAdd?.passport_number}
                </h1>
              </div> */}
            </div>
            <div>
              {cartItemsCopy?.map((item, index) => {
                return (
                  <div key={item.product_id} className="cartdiscription mb-3">
                    <div className="cartProd">
                      <img
                        src={`${baseUrl}/${item?.main_image}`}
                        alt={`Product ${index}`}
                      />
                    </div>

                    <div className="prodetailcart">
                      <h4>{item.product_name}</h4>
                      <p>
                        $
                        {item.sale_price == 0
                          ? item.regular_price
                          : item.sale_price}{" "}
                        x {item.p_quantity}
                      </p>
                    </div>
                    {/* <img src={CartCancelImg} style={{ position: "absolute", right: "20px" }} onClick={() => handleClick(item)} /> */}
                  </div>
                );
              })}
            </div>
            <div className="row" style={{ marginTop: "40px" }}>
              <div className="col-lg-6">
                <div class="subtotal my-1">
                  {/* <h6>Sub Total:</h6> */}
                  {/* <p>${item.sale_price * item.p_quantity}</p> */}
                </div>
                {/* <div class="subtotal pb-3 mb-3 border-bottom">
                  <h6>Shipping:</h6>
                  <p>$0.00</p>
                </div> */}
                <div class="subtotal">
                  <p className="fs-1">Shipping Price</p>
                  <p className="fs-1 text-color-blue">
                    $
                    {receivedData?.shipping_status === 1
                      ? 20
                      : receivedData?.shipping_status === 2
                      ? 10
                      : 0}
                  </p>
                </div>
                {/* <br></br> */}
                <div class="subtotal">
                  <p className="fs-1">Total</p>
                  <p className="fs-1 text-color-blue">
                    $
                    {receivedData?.shipping_status === 1
                      ? getCartTotalCopy() + 20
                      : receivedData?.shipping_status === 2
                      ? getCartTotalCopy() + 10
                      : getCartTotalCopy() + 0}
                  </p>
                </div>
                <div class="subtotal">
                  <p className="fs-1">Order Status</p>
                  <p className="fs-1 text-color-blue">
                    {receivedData?.order_status}
                  </p>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="note">
                  <h1 class="carttitle">Please note:</h1>
                  <p>
                    our daily cut off time for all orders requested for next day
                    delivery is 2PM. Any order received after the cut off time
                    off 2PM on Friday will not be dispatched until the following
                    Monday, which is the next available Postal and Courier
                    Service day.
                  </p>
                </div>
              </div>
            </div>
            <div className="wishes">
              <h1 class="carttitle">Warmest wishes</h1>
              <p>
                The Sales Team at <br />
                Tobacco Store
              </p>
              <p style={{ margin: "0" }}>
                <Link to="/">https://www.tobaccovapesmart.com</Link>
              </p>
            </div>
          </section>
        </div>
        <Footer />{" "}
      </div>
    </div>
  );
}

export default OrderComplete;
