import React, { useEffect } from "react";
import Footer from "../Components/Footer/Footer";
import Header from "../Components/Header/Header";
import Warning from "../Components/Warning";
import { Link, useNavigate } from "react-router-dom";
import buyleftspan from "../assets/images/buyleftspan.png";
import buyrightspan from "../assets/images/buyrightspan.png";
import flower from "../assets/images/flowercontact.png";
import axios from "axios";
import { useState } from "react";
import { baseUrl } from "../assets/utils/IP";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";


function Login() {

  const [singinloader, setsinginloader] = useState(false)
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [validation, setValidation] = useState()
  const [user, setUser] = useState({
    email: "",
    password: "",
  });

  const Swal = require("sweetalert2");
  const navigate = useNavigate();


  const togglePasswordVisibility2 = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  let handleSubmit = async (e) => {
    e.preventDefault();

    setsinginloader(true)

    try {
      const email = user.email;
      const password = user.password;

      if (!email || !password) {
        return; // Stop execution if fields are missing
      }

      const res = await axios.post(`${baseUrl}/login`, {
        email,
        password,
      });

      if (res && res.status === 200) {
        setValidation()
        const accessToken = res.data.token;
        localStorage.setItem("root", accessToken);
        const token = accessToken.split(".");
        const details = atob(token[1]);
        const isAdmin = JSON.parse(details);

        localStorage.setItem(
          "current",
          JSON.stringify({
            value: isAdmin.userId,
            value2: isAdmin.isAdmin,
          })
        );
        localStorage.setItem("userId", isAdmin.userId);
        Swal.fire({
          icon: "success",
          title: "Login Successfull",
          customClass: {
            container: "customstyle",
          }
        });
        if (isAdmin.isAdmin) {
          navigate("/DashboardAdmin");
        } else {
          navigate("/UserDashboard");
        }
      }
    } catch (err) {
      if (err?.response?.data.message == "Invalid credentials") {
        setValidation("Incorrect email / password")
        
      }
      else if (err?.response?.data.message == "Your account has been deactivated") {
        Swal.fire({
          icon: "error",
          title: "Account Deactivated!",
          text: "Your Account has been deactivated",
        });
      }
      else {
        setTimeout(() => {
          Swal.fire({
            icon: "error",
            title: "User not found!",
            text: "Please try again with valid email Address",
          });
        }, 3000);
      }
    }
  };


  return (
    <div className="font-bebas">
      <Warning />
      <Header />
      <section className="loginsection loginsectionsec position-relative">
        <span className="buyleftspan">
          <img src={buyleftspan} alt="" />
        </span>
        <span className="buyrightspan">
          <img src={buyrightspan} alt="" />
        </span>
        <div className="container-1640 z-10 position-relative">
          <div className="row justify-content-center">
            <div className="col-lg-6">
              <form
                className="form signupform loginform rounded-5 position-relative"
                onSubmit={handleSubmit}
              >
                <h1 class="login-title text-white text-center">Welcome</h1>
                <p className="text-center mb-3">Please login below</p>
                <img
                  src={flower}
                  alt="Flower"
                  className="position-absolute flower-login"
                />
                <div className="row">
                  <div className="col-lg-12">
                    <input
                      type="email"
                      className="border poppins"
                      name="email"
                      placeholder="Email Address"
                      value={user.email}
                      onChange={(e) => {
                        setUser({
                          ...user,
                          email: e.target.value.toLowerCase(),
                        });
                      }}
                      required
                    />
                  </div>
                  <div className="col-lg-12" style={{ position: "relative" }}>
                    <input
                      type={showConfirmPassword ? "text" : "password"}
                      name="password"
                      className="border mb-2 poppins"
                      placeholder="Password"
                      value={user.password}
                      onChange={(e) => {
                        setUser({ ...user, password: e.target.value });
                      }}
                      required
                    />
                    <FontAwesomeIcon className="eyeiconLogin" onClick={togglePasswordVisibility2} icon={showConfirmPassword ? faEyeSlash : faEye} />

                    <span style={{ color: "red" }}>{validation}</span>
                    <br></br>
                    <Link
                      to="/forgot-password"
                      className="text-white poppins text-decoration-none"
                    >
                      Password forgotten?
                    </Link>

                    <div className="cont">
                      <div class="signinbtn d-flex flex-wrap justify-content-center">
                        <button className="text-uppercase mx-4 border-0 w-auto" type="submit">
                          Sign In
                        </button>


                        <Link to="/signup" className="text-uppercase mx-4">
                          Register
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
}

export default Login;
