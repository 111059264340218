
import Modal from "react-bootstrap/Modal";
import { baseUrl } from "../../assets/utils/IP";
import Button from "react-bootstrap/Button";
import { Link } from "react-router-dom";

export default function AddToCartModal({show, handleClose, products, cartvalue} ) {
    console.log("products>>>", products)
    return <Modal
    className="accountcreated continuemodl resp-modal side-padd-mdal-add"
    show={show}
    onHide={handleClose}
  >
    <Modal.Header className="pb-3">
      <h2 className="mb-0">{products?.product_name}</h2>
      <Button onClick={handleClose} className="p-0">
        <i class="fa-solid fa-circle-xmark"></i>
      </Button>
    </Modal.Header>
    <Modal.Body>
      <div className="row">
        <div className="col-md-5 p-0">
          <div className="ProductModalImg">
            <img src={`${baseUrl}/${products?.main_image}`} alt="Smoke" />
          </div>
        </div>
        <div className="col-md-7 position-relative">
          <div className="mx-2 my-4">
            <h2>
              <i class="fa-solid fa-check"></i> {products?.product_name} was added to{" "}
              <br />
              your shopping cart.
            </h2>

            <p class="pricesku">
              ${cartvalue * products?.sale_price == 0 ? products?.regular_price : products?.sale_price} <span>incl. GST</span>
            </p>
          </div>
          <div class="signinbtn addcart-btn-pos d-flex">
            <Link className="me-4 px-4 py-2" onClick={handleClose}>
              <i class="fa-solid fa-chevron-left"></i> Continue Shopping
            </Link>
            <Link to={'/cart'} className="px-4 py-2">
              Proceed to checkout
            </Link>
          </div>
        </div>
      </div>
    </Modal.Body>
  </Modal>
}