import React from "react";
import SideBar from "../../../Components/User/SideBar";
import Logo from "../../../assets/images/logo.png";
import tobaccoText from "../../../assets/images/text-tobacco.png";
import graphImg from "../../../assets/images/garphOrder.png";
import Graph2 from "./Graph2";

import NotificationUser from "../../../assets/images/notification-user.png";
import { Link } from "react-router-dom";
import { useEffect } from "react";
import axios from "axios";
import { baseUrl } from "../../../assets/utils/IP";
import { useState } from "react";
import { faCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function UserDashboard() {

  const [notifications, setNotifications] = useState();
  const [flag, setFlag] = useState();
  const [user, setUser] = useState();


  const userId = localStorage.getItem("userId")
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${baseUrl}/get-notification-by-user/${userId}`);
        setNotifications(response.data.history)
      } catch (error) {
      }
    };

    fetchData();
  }, [userId]);



  useEffect(() => {
    const fetchData = async () => {
      const config = {
        method: 'get',
        maxBodyLength: Infinity,
        url: `${baseUrl}/users/${userId}`,

      };

      try {
        const response = await axios.request(config);
        setUser(response.data)
      } catch (error) {
      }
    };

    fetchData();
  }, [userId]);



  function getTimeAgo(targetTime) {
    console.log(targetTime, "time -----")
    const originalTime = new Date(targetTime);
    const newTimeCopy = new Date(originalTime.getTime() + 10 * 60 * 60 * 1000);
    const newTime = newTimeCopy.toISOString()
    const targetDate = new Date(newTime); // Parse the target time string to a Date object
    const currentDate = new Date(); // Get the current time as a Date object
    const timeDifferenceMs = currentDate - targetDate; // Calculate the time difference in milliseconds
    const secondsAgo = Math.floor(timeDifferenceMs / 1000); // Convert milliseconds to seconds
    const minutesAgo = Math.floor(secondsAgo / 60); // Convert seconds to minutes
    const hoursAgo = Math.floor(minutesAgo / 60) + 5; // Convert minutes to hours
    const daysAgo = Math.floor(hoursAgo / 24); // Convert hours to days

    if (daysAgo > 0) {
      return daysAgo + (daysAgo === 1 ? ' day ago' : ' days ago');
    } else if (hoursAgo > 0) {
      return hoursAgo + (hoursAgo === 1 ? ' hour ago' : ' hours ago');
    } else if (minutesAgo > 0) {
      return minutesAgo + (minutesAgo === 1 ? ' minute ago' : ' minutes ago');
    } else {
      return 'Few minute ago';
    }
  }

  const handleNotificationSeen = () => {
    setFlag(true)
  }

  return (
    <section className="bg-main">
      <SideBar userName={user?.user_first_name} />
      <div className="second-main-side">
        <div className="sec-main-side-parent ">
          <div className="sec-side-fi-text justify-content-center border-text">
            <h1 className="text-light text-center display-2">Dashboard</h1>
            <hr className="text-light opacity-100" />
          </div>
          <div className="main-without-heading p-5">
            <div className="w-100">
              <div className="parent-box-img user-prnt-box px-5">
                <div className="row usercolum">
                  <div className="col-lg-6 responsive-user-dashboard">
                    <h1 className="text-light m-0">
                      Hi <span className="fw-bold text-theme">{user?.user_first_name} !</span>
                    </h1>
                    <p className="text-light fs-1 my-3 responsive-welcome">
                      Welcome to
                    </p>
                    <img className="text-Img" src={tobaccoText} alt="" />
                    <h1 className="fw-bold text-light py-lg-2 py-md-2 py-sm-1">
                      User Dashboard
                    </h1>
                    <Link
                      to="/MyProfile"
                      className="btn-gradient edt-profile-responsive p-2 px-3 rounded font-bebas text-dark"
                    >
                      Edit Your Profile
                    </Link>
                  </div>
                  <div className="col-lg-6 text-center responsive-user-dashboard">
                    <img className="w-75" src={Logo} alt="" />
                  </div>
                </div>
              </div>
            </div>


            <div className="graph-parent-flex row align-items-center">
              <div className="col-lg-6 ">
                <h1 className="text-light-yellow fw-bold text-center my-4 ">
                  NOTIFICATIONS
                </h1>
                {notifications?.slice(0, 2).map((notification) => (
                  <div className="parent-status-box pt-3">
                    <div key={notification.id} className="flex-img-txt d-flex border border-2 rounded-4">
                      <div className="status-box-img" style={{
                        width: "50px", height: "50px"
                      }}>
                        <img src={`${baseUrl}/${notification?.user_image}`} alt="" style={{
                          width: "50px", height: "50px"
                        }} />
                      </div>
                      <div className="status-box-txt">
                        <h3>Your Order is {notification?.status}</h3>
                        <p>
                          {notification.comment}
                          <br />
                        </p>

                        <span>{getTimeAgo(notification.created_at) == 0 ? "Few mintues ago" : getTimeAgo(notification.created_at)}</span>
                      </div>
                      {notification.mark_status == 0 ? <></> : <div style={{
                        position: "absolute",
                        right: "10px",
                      }}><FontAwesomeIcon color="green" icon={faCircle} /></div>}
                    </div>
                  </div>
                ))}
                <Link
                  to="/Notification"
                  className="btn-gradient p-2 px-5 rounded font-bebas text-dark mx-auto d-table"
                  style={{
                    marginTop: "10px"
                  }}
                >
                  Veiw All
                </Link>
              </div>
              <div className="col-lg-6">
                <div className="graph my-4">
                  <Graph2 />
                </div>
              </div>

              {/* <div className="col-lg-6">
                <img width="100%" src={graphImg} alt="" />
              </div> */}


            </div>



          </div>
        </div>
      </div>
    </section>
  );
}

