import * as Yup from "yup";

const validationSchema = Yup.object().shape({
  firstName: Yup.string()
    .matches(/^[a-zA-Z]*$/, "Only alphabets are allowed")
    .required("First Name is required"),
  lastName: Yup.string()
    .matches(/^[a-zA-Z]*$/, "Only alphabets characters are allowed")
    .required("Last Name is required"),
  fullName: Yup.string()
    .matches(/^[a-zA-Z ]*$/, "Only alphabets characters are allowed")
    .required("Last Name is required"),
  email: Yup.string()
    .matches(
      /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
      "Invalid email format"
    )
    .email("Invalid email format")
    .required("Email is required"),
  streetAddress: Yup.string().required("Street Address is required"),
  passportNumber: Yup.string()
    .required("Passport Number is required")
    .test("valid-passport", "Invalid Passport/ID Number", (value) => {
      return (
        /^[NEDFACUX]\d{7}$/.test(value) ||
        /^(PA|PB|PC|PD|PE|PF|PU|PW|PX|PZ)\d{7}$/.test(value)
      );
    }),
  country: Yup.string().required("Country is required"),
  state: Yup.string().required("State is required"),
  city: Yup.string().required("City is required"),
  phone: Yup.string()
    .required("Contact is required")
    .matches(/^\d{10,10}$/, "Invalid phone format. Use XXXX-XXX-XXX"),
  password: Yup.string()
    .required("Password is Required")
    .min(8, "Password is too short")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$+!%*?&])[A-Za-z\d@$+!%*?&]{8,}$/,
      "Invalid password format"
    ),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref("password"), null], "Passwords must match")
    .required("Confirm Password is required"),
  newsLetter: Yup.boolean(),
});
export default validationSchema;
