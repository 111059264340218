import React from "react";
import flower from "../assets/images/flower.png";
import bannersmoke from "../assets/video/bannersmoke.mp4";
import Header from "../Components/Header/Header";
import Warning from "../Components/Warning";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import buyleftspan from "../assets/images/buyleftspan.png";
import buyrightspan from "../assets/images/buyrightspan.png";
import Partner from "../Components/Partner";
import headerlogo from "../assets/images/headerlogo.png";
import Footer from "../Components/Footer/Footer";
import mission from "../assets/images/mission.png";

function About() {
  return (
    <div className="main-overlayabout">
      <div className="font-bebas">
        <Warning />
        <div className="overlayabout">
          <div className="about-hover">
            <Header />
          </div>
          <section className="bannersec bannersec22 aboutus">
            <div class="bg-video-wrap">
              <video src={bannersmoke} loop muted autoPlay playsInline></video>
              <div class="overlay"></div>
              <div className="row">
                <div className="col-lg-6 m-auto">
                  <h1>
                    About Us
                    <span>
                      <img alt="" src={flower} />
                    </span>
                  </h1>
                  <p>
                    A specialty outlet aims to provide <br />
                    a premium, next-generation experience
                    <br />
                    at Parramatta place.
                  </p>
                </div>
                <div className="col-lg-6"></div>
              </div>
            </div>
          </section>
          <section className="miss-goal">
            <span class="seccoverline-about"></span>
            <span className="buyleftspan">
              <img src={buyleftspan} alt="" />
            </span>
            <span className="buyrightspan">
              <img src={buyrightspan} alt="" />
            </span>
            <div className="container-1640">
              <div className="mission">
                <img src={headerlogo} alt="" />
                <h2>
                  Mission
                  <span>
                    <img src={mission} alt="" />
                  </span>
                </h2>
                <p>
                  A specialty outlet aims to provide a premium, next -
                  generation
                  <br className="d-lg-none d-block" /> experience at Parramatta.
             
                  <br className="d-lg-block d-none" />
                  This acquisition will be employed
                  <br className="d-lg-none d-block" /> by industry experience
                  and the transfer of knowledge with a concept
                  <br className="d-lg-none d-block" /> store
                  <br className="d-lg-block d-none" /> inside a specialty
                  focused shopping precint. This model is
                  <br className="d-lg-none d-block" /> based off international
                  markets that have developed
                  <br className="d-lg-block d-none" /> ( Australia )
                  <br className="d-lg-none d-block" /> along with concept stores
                  that are endorsed and developed by
                  <br className="d-lg-none d-block" /> industry leaders. This
                  will be a<br className="d-lg-block d-none" />
                  launch pad for Vape Partners and
                  <br className="d-lg-none d-block" /> also see the continuation
                  of concept stores being rolled
                  <br className="d-lg-none d-block" /> out across the state.
                </p>
              </div>
              <div className="row">
                <div className="mission">
                  <h2>
                    GOALS
                    <span>
                      <img src={mission} alt="" />
                    </span>
                  </h2>
                </div>
                <div className="col-lg-6 col-md-6 col-12">
                  <div className="mission">
                    <h2 style={{ color: "#7998EA" }}>Specific</h2>
                    <p>
                      Category focused approach, will differentiate
                      <br className="d-lg-none d-block" /> from the over
                      <br className="d-lg-block d-none" />
                      saturated market on the
                      <br className="d-lg-none d-block" /> Victoria Avenue
                      precint. This approach
                      <br className="d-lg-block d-none" />
                      via
                      <br className="d-lg-none d-block" /> unique offerings from
                      premium aspiration
                      <br className="d-lg-none d-block" /> product range,
                      <br className="d-lg-none d-none" />
                      consumer focused
                      <br className="d-lg-none d-block" /> (needs analysis),
                      retail education programs
                      <br className="d-lg-none d-none" />
                      local area marketing.
                    </p>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 col-12">
                  <div className="mission">
                    <h2 style={{ color: "#7998EA" }}>Measurable</h2>
                    <p>
                      Through enhanched offers on premium <br /> cigar range and
                      in-store education for <br className="d-lg-none d-none" />
                      next generation products
                      <br className="d-lg-none d-block" /> (Vaporized{" "}
                      <br className="d-lg-none d-none" />
                      product range).
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <Partner />

          <Footer />
        </div>
      </div>
    </div>
  );
}

export default About;
