import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import SideBar from "../../../Components/Admin/SideBar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircleChevronLeft,
  faListUl,
  faPencil,
  faPlus,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { AuthorizationToken, baseUrl } from "../../../assets/utils/IP";
import axios from "axios";
import Swal from "sweetalert2";

export default function AdminProduct() {
  const [allProducts, setAllProducts] = useState()
  const [deletedId, setDeletedId] = useState()
  const [visibleProducts, setVisibleProducts] = useState(10);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [selectedValue, setSelectedValue] = useState('');
  const [productType, setProductType] = useState('');

  useEffect(() => {
    const fetchData = async () => {
      try {
        const config = {
          method: 'get',
          maxBodyLength: Infinity,
          url: `${baseUrl}/get-all-products`,
        };
        const response = await axios.request(config);
        const reversedProducts = [...response.data.results];
        setAllProducts(reversedProducts)
      } catch (error) {
      }
    };

    fetchData();
  }, []);


  const handleDropdownChange = (e) => {
    setSelectedValue(e.target.value);
  };
  const handleProductDropdownChange = (e) => {
    setProductType(e.target.value);
  };
  const DeleteByID = (pid) => {
    setDeletedId(pid)
  }

  const handleDelete = (id) => {
    const config = {
      method: 'delete',
      maxBodyLength: Infinity,
      url: `${baseUrl}/delete-product/${id}`,
      headers: {
        'Authorization':
          `Bearer ${AuthorizationToken}`,
      },
    };

    axios
      .request(config)
      .then((response) => {
        setAllProducts((prevProd) =>
          prevProd.filter((product) => product.product_id !== id)
        );
      })
      .catch((error) => {
      });
  };

  const loadMoreProducts = () => {
    setVisibleProducts((prevVisibleProducts) => prevVisibleProducts + 10);
  };

  const displayedProducts = allProducts?.slice(0, visibleProducts);


  const handleSelectAll = () => {
    setSelectedProducts(allProducts?.map((products) => products?.product_id)); // Step 2
  };

  const handleDeselectAll = () => {
    setSelectedProducts([]);
  };

  const handleBrandSelect = (productID) => {
    if (selectedProducts.includes(productID)) {
      setSelectedProducts(selectedProducts.filter((id) => id !== productID));
    } else {
      setSelectedProducts([...selectedProducts, productID]);
    }
  };
  const deleteAll = () => {
    const productIdsToDelete = selectedProducts;
    if (productIdsToDelete.length === 0) {
      Swal.fire({
        icon: "error",
        title: "Sorry",
        text: "Please select a product",
        customClass: {
          container: "customstyle",
        }
      }); return;
    }

    axios
      .delete(`${baseUrl}/delete-product`, {
        data: { productIds: productIdsToDelete },
        headers: {
          'Authorization':
            `Bearer ${AuthorizationToken}`,
        },
      })
      .then((response) => {
        Swal.fire({
          icon: "success",
          title: "Product Successfully Deleted",
          text: "Thank you",
          customClass: {
            container: "customstyle",
          }
        });
        setAllProducts((prevProd) =>
          prevProd.filter(
            (product) => !productIdsToDelete.includes(product.product_id)
          )
        );
        setSelectedProducts([]);
      })
      .catch((error) => {
      });
  };
  return (
    <section className="bg-main">
      <SideBar />
      <div className="second-main-side">
        <div className="sec-main-side-parent p-md-4 p-lg-5">
          <div className="blur-pages px-5">
            <div className="border-text">
              <div className="d-flex justify-content-between">
                <h1>PRODUCTS</h1>
                <div className="boxs-flex">
                  <Link
                    className="p-3 justify-content-center bg-yellow w-25 h-25 d-flex align-items-center text-dark rounded-2"
                    to="/AddProduct"
                  >
                    <FontAwesomeIcon icon={faPlus} />
                  </Link>
                  <Link
                    className="p-3 justify-content-center bg-yellow w-25 h-25 d-flex align-items-center text-dark rounded-2"
                    to=""
                  >
                    <FontAwesomeIcon color="red" icon={faTrash} data-bs-toggle="modal" data-bs-target="#exampleModalDA" />
                  </Link>
                  {/* <Link
                    className="p-3 justify-content-center bg-light w-25 h-25 d-flex align-items-center text-dark rounded-2"
                    to="/SelectCatalog"
                  >
                    <FontAwesomeIcon icon={faCircleChevronLeft} />
                  </Link> */}
                </div>
              </div>
              <hr className="text-light opacity-100" />
            </div>
            <div className="d-flex porductblock justify-content-end my-4">
              <div className="d-flex mt-3">
              <Link
                className="add-categorie-btn text-dark rounded-2 px-5 py-2"
                to="/AddProduct"
              >
                Add Product
              </Link>
              <Link
                className="add-categorie-btn text-dark rounded-2 px-5 py-2 mx-2"
                to="/DeletedProducts"
              >
                Deleted products
              </Link>
              </div>
               <div className="d-flex">
              <div className="dropdown drop-cust">
                <select className="border-btn bg-dark text-light p-2 rounded mx-2 " value={selectedValue} onChange={handleDropdownChange}>
                  <option value="">Select</option>
                  <option value="In Stock">In Stock</option>
                  <option value="Out Stock">Out Stock</option>
                </select>
              </div>
              <div className="dropdown drop-cust">
                <select className="border-btn bg-dark text-light p-2 rounded" value={productType} onChange={handleProductDropdownChange}>
                  <option value="">Select</option>
                  <option value="Promotion">Promotion Products</option>
                  <option value="Non-Promotion">Non promotion Products</option>
                </select>
              </div>
              </div>
            </div>
            <div className="add-categorie-list">
              <FontAwesomeIcon color="#fff" icon={faListUl} />
              <span className="mx-2">Product List</span>
            </div>
            <div className="main-fi-order-box-master">
              <div className="main-fi-order-box">
            <div className="product-table-parent">
              <div className="product-grid-divide w-100 align-items-center rounded-3 my-3">
                <div className="categori-name p-3">
                  <input
                    type="checkbox"
                    onChange={() =>
                      selectedProducts?.length < allProducts?.length
                        ? handleSelectAll()
                        : handleDeselectAll()
                    }
                    checked={selectedProducts.length === allProducts?.length}
                  />                     </div>
                <div className="categori-name p-1">
                  <p className="mx-3">Images</p>
                </div>
                <div className="categori-name d-flex align-items-center p-1">
                  <p className="mx-3">Product Name</p>
                </div>
                <div className="categori-name border-end p-1 text-start">
                  <p>Category</p>
                </div>
                <div className="categori-name border-end p-1 text-center">
                  <p>Price</p>
                </div>
                <div className="categori-name border-end p-1 text-center">
                  <p>Quantity</p>
                </div>
                <div className="categori-name p-1 text-center border-end-0">
                  <p>Action</p>
                </div>
              </div>

              {displayedProducts
                ?.filter((product) => {
                  if (selectedValue === 'In Stock') {
                    return product.status === 1;
                  } else if (selectedValue === 'Out Stock') {
                    return product.status === 0;
                  }
                  else if (productType === "Promotion") {
                    return product?.promotion_status === 1;
                  }
                  else if (productType === "Non-Promotion") {
                    return product?.promotion_status === 0;
                  }
                  return true;
                })
                .map((product) => (<div key={product.id} className="product-grid-divide-box w-100 align-items-center rounded-3 my-3 text-light">
                  <div className="h-100 d-flex align-items-center categori-name p-2">
                    <input
                      type="checkbox"
                      onChange={() => handleBrandSelect(product.product_id)}
                      checked={selectedProducts.includes(product.product_id)}
                    />
                  </div>
                  <div className="categori-name text-center p-2 productImgCatalog">
                    <img className="" src={`${baseUrl}/${product.main_image}`} alt="" />
                  </div>
                  <div className="h-100 categori-name justify-content-between d-flex align-items-center p-2">
                    <p className="mx-3">{product.product_name}</p>
                    <p className={`fw-bold ${product.quantity > 0 ? 'text-success' : 'text-danger'}`}>
                      {product.quantity > 0 ? 'IN STOCK' : 'OUT OF STOCK'}
                    </p>
                  </div>
                  <div className="h-100 d-flex align-items-center categori-name border-end p-2 text-start">
                    <p>{product.category_name}</p>
                  </div>
                  <div className="h-100 categori-name border-end p-2 text-end">
                    {product.sale_price == 0 ?
                      (<p className="text-warning">${product.regular_price}</p>)
                      :
                      (<p className="text-decoration-line-through">${product.regular_price}</p>)

                    }
                    <p className="text-warning">${product.sale_price}</p>
                  </div>
                  <div className="h-100 d-flex align-items-center categori-name border-end p-2 justify-content-end">
                    <p>{product.quantity}</p>
                  </div>
                  <div className="h-100 d-flex align-items-center justify-content-end categori-name p-2 px-3 text-end">
                    {/* Button trigger modal */}
                    <button type="button" className="btn bg-yellow product-icon-align" data-bs-toggle="modal" data-bs-target="#exampleModal" onClick={() => DeleteByID(product.product_id)}>
                      <FontAwesomeIcon color="red" icon={faTrash} />
                    </button>
                    <Link to={`/EditProduct/${product.product_id}`} type="button" className="btn ms-3 product-icon-align bg-yellow">
                      <FontAwesomeIcon icon={faPencil} />
                    </Link>
                  </div>
                </div>
                ))}
            </div>
            </div>
            </div>
            <div className="text-center mt-3">
                {visibleProducts < allProducts?.length && (
                  <button className="btn-gradient px-4 py-1 rounded border-0" onClick={loadMoreProducts}>Load More</button>
                )}
              </div>
          </div>
        </div>
      </div>
      {/* Modal */}
      <div
        className="modal fade"
        id="exampleModal"
        tabIndex={-1}
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog delet-popup d-flex align-items-center justify-content-center h-100">
          <div className="modal-content p-3 rounded-5">
            <div className="modal-header border-0">
              <button
                type="button"
                className="btn-close text-light position-relative"
                data-bs-dismiss="modal"
                aria-label="Close"
              />
            </div>
            <div className="modal-body text-center delete-modal-responsive">
              <FontAwesomeIcon color="red" fontSize="80px" icon={faTrash} />{" "}
              <h1 className="text-light">
                You are about to delete this product
              </h1>
            </div>
            <div className="modal-footer justify-content-center border-0">
              <button
                type="button"
                className="btn btn-gradient"
                data-bs-dismiss="modal"
              >
                Go Back
              </button>
              <button
                type="button"
                data-bs-dismiss="modal"
                className="btn btn-danger"
                onClick={() => handleDelete(deletedId)}
              >
                Delete Anyway
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* Modal */}


      {/* Modal for Delete All button */}
      <div
        className="modal fade"
        id="exampleModalDA"
        tabIndex={-1}
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog delet-popup d-flex align-items-center justify-content-center h-100">
          <div className="modal-content p-3 rounded-5">
            <div className="modal-header border-0">
              <button
                type="button"
                className="btn-close text-light position-relative"
                data-bs-dismiss="modal"
                aria-label="Close"
              />
            </div>
            <div className="modal-body text-center delete-modal-responsive">
              <FontAwesomeIcon color="red" fontSize="80px" icon={faTrash} />{" "}
              <h1 className="text-light">
                You are sure you want to delete these products
              </h1>
            </div>
            <div className="modal-footer justify-content-center border-0">
              <button
                type="button"
                className="btn btn-gradient"
                data-bs-dismiss="modal"
              >
                Go Back
              </button>
              <button
                type="button"
                data-bs-dismiss="modal"
                className="btn btn-danger"
                onClick={deleteAll}
              >
                Delete Anyway
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* Modal */}
      <div
        className="modal fade"
        id="exampleModal1"
        tabIndex={-1}
        aria-labelledby="exampleModalLabel1"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="exampleModalLabel">
                Modal title
              </h1>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              />
            </div>
            <div className="modal-body"></div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Close
              </button>
              <button type="button" className="btn btn-primary">
                Save changes
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}