import { React, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import SideBar from "../../../Components/User/SideBar";
import { baseUrl } from "../../../assets/utils/IP";
import axios from "axios";
import Swal from "sweetalert2";

export default function MyProfile() {
  const [isInputVisible, setInputVisible] = useState(true);
  // const [user, setUser] = useState();
  const [state, setState] = useState([]);
  const [countries, setCountries] = useState([]);
  const [data, setData] = useState([]);
  const [cities, setCities] = useState([]);
  const [refetch, setRefetch] = useState();
  const [selectedFile, setSelectedFile] = useState(null);
  // const [displayedImage, setDisplayedImage] = useState(null);
  const [selectedCity, setSelectedCity] = useState('');
  const [updateUser, setUpdateUser] = useState({
    user_email: '',
    user_first_name: '',
    user_last_name: '',
    phone_number: '',
    street_address: '',
    city: '',
    country: '',
    state: '',
    passport_number: '',
  });


  const toggleInputVisibility = () => {
    setInputVisible(!isInputVisible);
  };

  const token = localStorage.getItem("root")


  const userId = localStorage.getItem("userId")
  useEffect(() => {
    const fetchData = async () => {
      const config = {
        method: 'get',
        maxBodyLength: Infinity,
        url: `${baseUrl}/users/${userId}`,
        // headers: {
        //   'Authorization':
        //     'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2vySWQiOjE3LCJpYXQiOjE2OTM1NTE3NzQsImV4cCI6MTY5MzU1NTM3NH0.xlGc9eMxHnCGN__n5c_rcERWRt_46BVBEb_-ezQGRlo',
        // },
      };

      try {
        const response = await axios.request(config);
        setUpdateUser(response.data)
      } catch (error) {
      }
    };

    fetchData();
  }, [userId]);

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setSelectedFile(file);

      const reader = new FileReader();
      reader.onloadend = () => {
        // setDisplayedImage(reader.result);
        setUpdateUser({ ...updateUser, user_image: reader.result })
      };
      reader.readAsDataURL(file);
    }
  };

  const handleCountry = (e) => {
    setUpdateUser({ ...updateUser, state: "", city: "" })
    const selectedCountry = e.target.value;

    setUpdateUser({ ...updateUser, country: selectedCountry })

    const filteredStates = data
      .filter((item) => item.country === selectedCountry)
      .map((item) => item.subcountry)
      .filter((subcountry, index, self) => self.indexOf(subcountry) === index)
      .sort();


    setState(filteredStates);
  };



  const handleState = (e) => {
    const selectedState = e.target.value;
    setUpdateUser({ ...updateUser, state: selectedState })

    // Filter the cities based on the selected country and state
    const filteredCities = data.filter(
      (item) =>
        item?.country === updateUser?.country && item?.subcountry === selectedState
    );

    // Get unique cities by using a Set to eliminate duplicates
    const uniqueCities = Array.from(
      new Set(filteredCities.map((item) => item.name))
    );

    // Map the unique city names to objects containing the name property
    const uniqueCityObjects = uniqueCities.map((city) => ({ name: city }));

    // Update the 'cities' state with the unique city objects
    setCities(uniqueCityObjects);
  };



  useEffect(() => {
    axios
      .get(
        "https://pkgstore.datahub.io/core/world-cities/world-cities_json/data/5b3dd46ad10990bca47b04b4739a02ba/world-cities_json.json"
      )
      .then((res) => {
        setData(res.data);
        const allCountries = res.data;
        let filteredCountries = [
          ...new Set(allCountries.map((item) => item?.country)),
        ];

        filteredCountries = filteredCountries.sort();

        setCountries(filteredCountries);

      })
      .catch((err) => console.log(""));
  }, []);





  function handleUpdate() {
    if (
      !updateUser.user_first_name ||
      !updateUser.user_last_name ||
      !updateUser.phone_number ||
      !updateUser.street_address ||
      !updateUser.city ||
      !updateUser.country ||
      !updateUser.state ||
      !updateUser.passport_number
    ) {
      // Show an error message and return to prevent the update
      Swal.fire({
        icon: "error",
        title: "Required Fields",
        text: "All fields are required.",
      });
      return;
    }
    let data = new FormData();
    data.append('email', updateUser?.user_email);
    data.append('firstname', updateUser?.user_first_name);
    data.append('lastname', updateUser?.user_last_name);
    data.append('contact', updateUser?.phone_number);
    data.append('streetaddress', updateUser?.street_address);
    data.append('city', updateUser?.city);
    data.append('country', updateUser?.country);
    data.append('state', updateUser?.state);
    data.append('passport', updateUser?.passport_number);
    data.append('user_image', selectedFile?.name ? selectedFile : updateUser?.user_image);

    let config = {
      method: 'put',
      maxBodyLength: Infinity,
      url: `${baseUrl}/edit-profile`,
      headers: {
        'Authorization': `Bearer ${token} `,
      },
      data: data
    };
    axios
      .request(config)
      .then((response) => {
        setRefetch("refetch")
        Swal.fire({
          icon: "success", // Use "success" for the icon
          title: "Profile updated successfully",
          text: "Thankyou",
        });
      })

      .catch((error) => {
        if (error?.response?.data.message == "Invalid token") {
          Swal.fire({
            icon: "error",
            title: "Unable to update",
            text: "Token Expire",
          });
        }
        else {
          Swal.fire({
            icon: "error", // Use "success" for the icon
            title: "Profile not updated",
            text: "Something went wrong",
          });
        }
      });

  }

  const handleCity = (e) => {
    const selectedCity = e.target.value;
    setUpdateUser({ ...updateUser, city: selectedCity });
    setSelectedCity(selectedCity);


  };

  return (
    <section className="bg-main customstyle ">
      <SideBar />
      <div className="second-main-side">
        <div className="sec-main-side-parent p-5">
          <div className="blur-pages px-5">
            <div className="border-text">
              <div className="d-flex justify-content-between">
                <h1>My Profile</h1>
              </div>
              <hr className="text-light opacity-100" />
            </div>
            <form action="">
              <div className="profile-main-box">
                <div className="parent-payment-box set-profile-box">
                  <div className="text-light">
                    <p>Upload Profile Picture</p>
                    <div className="imgUploadMyProfile">
                      {/* <ImageUploader title="Upload Image" /> */}

                      <div className="grid-add profile-set d-grid imgBgProfile cursor-pointer my-5">
                        <label className="text-light d-block text-end" htmlFor="">
                          <span className="text-warning">*</span>
                        </label>
                        <div className="row">
                          <div className="col-lg-4">
                            <input
                              type="file"
                              className="image-input cursor-pointer	"
                              id="fileInput"
                              accept="image/*"
                              onChange={handleFileChange}
                            />
                            <label
                              htmlFor="fileInput"
                              className="imgNew"
                              style={{ width: "200px" }}
                            >
                              {
                                (updateUser?.user_image && updateUser?.user_image !== `null`) ? (
                                  <img
                                    src={updateUser?.user_image?.includes(`base64`) ? updateUser?.user_image : `${baseUrl}/${updateUser?.user_image}`}
                                    alt="no im"
                                    style={{ width: "100%" }}
                                  />
                                )
                                  :
                                  (
                                    <span className="cursor-pointer" style={{ color: "yellow" }}>+ Click to upload image</span>
                                  )
                              }
                            </label>
                          </div>
                        </div>
                      </div>





                    </div>
                    <input type="file" className="image-input" />
                  </div>
                  <div className="parent-profile-form text-light py-3">
                    <div className="row">
                      <div className="col-lg-6 py-2">
                        <label htmlFor="">Full Name</label>
                        <input className="w-100 my-1 p-1" type="text"
                          defaultValue={updateUser?.user_first_name}
                          required
                          onChange={(e) => setUpdateUser({ ...updateUser, user_first_name: e.target.value })}
                        />
                      </div>
                      <div className="col-lg-6 py-2">
                        <label htmlFor="">Last Name</label>
                        <input className="w-100 my-1 p-1" type="text"
                          defaultValue={updateUser?.user_last_name}
                          required
                          onChange={(e) => setUpdateUser({ ...updateUser, user_last_name: e.target.value })}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-6 py-2">
                        <label htmlFor="">Email</label>
                        <input className="w-100 my-1 p-1" type="email"
                          defaultValue={updateUser?.user_email}
                          onChange={(e) => setUpdateUser({ ...updateUser, user_email: e.target.value })}
                          readOnly
                        />
                      </div>
                      <div className="col-lg-6 py-2">
                        <div>
                          <label htmlFor="">Contact Number</label>
                          <input min="1" max="11" className="w-100 my-1 p-1" type="number"
                            defaultValue={updateUser?.phone_number}
                            required
                            onChange={(e) => setUpdateUser({ ...updateUser, phone_number: e.target.value })}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-12 py-2">
                        <label htmlFor="">Street Address</label>
                        <input className="w-100 my-1 p-1" type="text"
                          defaultValue={updateUser?.street_address}
                          onChange={(e) => setUpdateUser({ ...updateUser, street_address: e.target.value })}
                        />
                      </div>
                    </div>
                    {/* -------------------------------------------------- */}

                    <div className="row">
                      <div className="col-lg-6 py-2">
                        <label htmlFor="">Country</label>
                        <br></br>
                        <select
                          className="poppins w-100 my-1 p-1"
                          defaultValue={updateUser?.country}
                          onChange={handleCountry}
                        >
                          <option value=""
                            disabled>
                            {updateUser?.country}
                          </option>
                          {countries?.map((item) => (
                            <option>
                              {item}
                            </option>
                          ))}
                        </select>


                      </div>
                      <div className="col-lg-6 py-2">
                        <label htmlFor="">City</label>
                        <br></br>
                        <select
                          className="poppins w-100 my-1 p-1"
                          value={updateUser?.city}
                          onChange={handleCity}
                        >
                          <option value="" disabled>
                            {updateUser?.city}
                          </option>
                          {cities?.map((item) => (
                            <option key={item.name} value={item.name}>
                              {item.name}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-6 py-2">
                        <label htmlFor="">State</label>

                        <br></br>
                        <select
                          className="poppins w-100 my-1 p-1"
                          value={updateUser?.state}
                          onChange={handleState}
                        >
                          <option className="poppins">
                            {updateUser?.state ? updateUser?.state : "State"}
                          </option>
                          {state?.map((item) => (
                            <option key={item} value={item}>
                              {item}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div className="col-lg-6 py-2">
                        <label htmlFor="">
                          Passport Number / identity Number
                        </label>
                        <input className="w-100 my-1 p-1" type="text"
                          defaultValue={updateUser?.passport_number}
                          required
                          readOnly
                          onChange={(e) => setUpdateUser({ ...updateUser, passport_number: e.target.value })}
                        />
                      </div>
                    </div>
                    <Link
                      className="btn-gradient mt-3 p-2 myprofile-addclass px-5 rounded font-bebas text-dark mt-4"
                      onClick={handleUpdate}
                    >
                      Update
                    </Link>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
}
