import {
  faCircleChevronLeft,
  faPencil,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useRef, useState } from "react";
import SideBar from "../../../Components/Admin/SideBar";
import { Link, useNavigate, useParams } from "react-router-dom";
import Editor from "../../../Components/Admin/CKeditor";
import ImgUpload from "../../../Components/Admin/ImgUpload";
import axios from "axios";
import { AuthorizationToken, baseUrl } from "../../../assets/utils/IP";
import ImageUploader from "./ImageUploader";
import { Button } from "react-bootstrap";
import Swal from "sweetalert2";

export default function AddProduct() {
  const [editorLoaded, setEditorLoaded] = useState(false);
  const [data, setData] = useState("");
  const [showStock, setShowStock] = useState(false);
  const [galleryImages, setGalleryImage] = useState();


  useEffect(() => {
    setEditorLoaded(true);
  }, []);

  const [selectedImage, setSelectedImage] = useState(null);
  const [imageUrl, setImageUrl] = useState(null);
  const imageRef = useRef()


  useEffect(() => {
    if (selectedImage) {
      setImageUrl(URL.createObjectURL(selectedImage));
    }
  }, [selectedImage]);
  // const [data, setData] = useState("");
  // const [showStock, setShowStock] = useState(false);
  //category data
  const [category, setCategory] = useState([]);
  const [brand, setBrand] = useState([]);
  const [selectedImages, setSelectedImages] = useState([]);
  const [product, setProduct] = useState({
    product_name: "",
    brand_id: "",
    category_id: "",
    description_1: "",
    description_2: "",
    regular_price: "",
    sale_price: "",
    quantity: "",
    gallery_images: [],
    promotion_status: 0,
  });
  const handleImageChange = (index, images) => {
    const updatedSelectedImages = [...selectedImages];
    updatedSelectedImages[index] = images;
    setSelectedImages(updatedSelectedImages);
  };
  const prodId = useParams()
  useEffect(() => {
    // Axios request configuration
    let config = {
      method: 'get',
      maxBodyLength: Infinity,
      url: `${baseUrl}/get-productById/${prodId.id}`,
    };

    // Make the Axios request
    axios
      .request(config)
      .then((response) => {

        setProduct(response.data)

        setSelectedImages(response.data.main_image)
        if (Number(response?.data?.quantity) > 0) {
          setShowStock(true)
        }
        else {
          setShowStock(false)
        }
        // You can set the response data to state or perform other actions with it here.
      })
      .catch((error) => {

      });

  }, [prodId.id]);



  //sub categories useEffect
  useEffect(() => {
    const getAllCategory = async () => {
      try {
        const res = await axios.get(`${baseUrl}/get-all-category`);

        setCategory(res.data);
      } catch (error) {

      }
    };
    getAllCategory();
  }, []);

  // get all Brands category
  useEffect(() => {
    const getBrands = async () => {

      try {
        const response = await axios.get(`${baseUrl}/get-all-brands`);
        if (response?.status == 200) {
          setBrand(response?.data);

        }
      } catch (err) {

      }
    };
    getBrands();
  }, []);


  useEffect(() => {
    setEditorLoaded(true);
  }, []);

  const navigate = useNavigate()

  const handleUpdate = async (e) => {
    e.preventDefault();


    try {
      const formData = new FormData();
      formData.append("product_name", product.product_name.trim());
      formData.append("brand_id", product.brand_id);
      formData.append("category_id", product.category_id);
      formData.append("description_1", product.description_1);
      formData.append("description_2", data);
      formData.append("regular_price", product.regular_price);
      formData.append("sale_price", product.sale_price ? product.sale_price : '');
      formData.append("quantity", product.quantity);
      formData.append("promotion_status", product.promotion_status);

      formData.append("product_image", selectedImage ? selectedImage : product?.main_image);

      if (product?.gallery_images) {

        if (typeof product.gallery_images === "string") {
          let list = []
          list = product.gallery_images.split(",")

          setProduct({ ...product, gallery_images: list })
          list.map((item) => {
            formData.append(`gallery_images`, item);
          })

        }
        else {
          product?.gallery_images.forEach((elem, ind) => {
            formData.append(`gallery_images`, product.gallery_images[ind]);
          })
        }
      }

      if (parseInt(product.sale_price) >= parseInt(product.regular_price)) {
        Swal.fire({
          icon: "error",
          title: "Price not set properly",
          text: "Sale price should be less than regular price",
          customClass: {
            container: "customstyle",
          }
        });
      }
      else {
        const headers = {
          'Authorization':
            `Bearer ${AuthorizationToken}`,
        };
        const res = await axios.put(`${baseUrl}/update-product/${prodId.id}`, formData, { headers });

        Swal.fire({
          icon: "success",
          title: "Product updated",
          text: "ThankYou",
          customClass: {
            container: "customstyle",
          }
        });
        navigate("/AdminProduct")
      }
    } catch (error) {
      if (error?.response?.data?.message == "main Image are required") {
        Swal.fire({
          icon: "error",
          title: "Required Feilds",
          text: "main Image are required",
          customClass: {
            container: "customstyle",
          }
        });
      }
      else if (error?.response?.data?.message == "File is too large. Max file size is 5 MB.") {
        Swal.fire({
          icon: "error",
          title: "Image issue",
          text: "File is too large. Max file size is 5 MB.",
          customClass: {
            container: "customstyle",
          }
        });
      }
      else {
        Swal.fire({
          icon: "error",
          title: "something went wrong",
          text: "Product not update",
          customClass: {
            container: "customstyle",
          }
        });
      }
    }
  };

  const handleImageUpload = (images) => {
    setProduct({ ...product, gallery_images: images })
  };

  return (
    <section className="bg-main">
      <SideBar />
      <div className="second-main-side">
        <div className="sec-main-side-parent p-md-4 p-lg-5">
          <div className="blur-pages px-59">
            <div className="border-text">
              <div className="d-flex justify-content-between">
                <h1>Edit Products</h1>
                <div className="boxs-flex">
                  <Link
                    className="p-3 justify-content-center bg-light w-25 h-25 d-flex align-items-center text-dark rounded-2"
                    to="/AdminProduct"
                  >
                    <FontAwesomeIcon icon={faCircleChevronLeft} />
                  </Link>
                </div>
              </div>
              <hr className="text-light opacity-100" />
            </div>
            <div className="add-categorie-list mt-5">
              <FontAwesomeIcon color="#fff" icon={faPencil} />
              <span className="mx-2">Add Product</span>
            </div>
            <form action="" onSubmit={handleUpdate}>
              <div className="row">
                <div className="col-lg-9">
                  <div className="grid-add d-grid align-items-center my-4">
                    <label className="text-light d-block text-end" htmlFor="">
                      <span className="text-warning">*</span>Product Name
                    </label>
                    <input className="py-1 px-2" type="text"
                      name="product_name"
                      defaultValue={product.product_name}
                      required
                      maxLength={18}
                      onChange={(e) => setProduct({ ...product, product_name: e.target.value })} />
                  </div>
                  <hr className="text-light my-4" />
                  <div className="grid-add d-grid align-items-center my-4">
                    <label className="text-light d-block text-end" htmlFor="brand">
                      Select Brand
                    </label>
                    <select className="py-1 px-2 border" name="brand" id="brand" value={product.brand_id}
                      onChange={(e) => setProduct({ ...product, brand_id: e.target.value })}
                    >
                      <option value="">Select Brand</option>
                      {brand.map((brand) => (
                        <option key={brand.brand_id} value={brand.brand_id}>
                          {brand.brand_name}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="grid-add d-grid align-items-center my-4">
                    <label className="text-light d-block text-end" htmlFor="category">
                      Select Category
                    </label>
                    <select
                      className="py-1 px-2 border"
                      name="category"
                      id="category"
                      value={product?.category_id}
                      onChange={(e) =>
                        setProduct({ ...product, category_id: e.target.value })
                      }
                    >
                      <option value="">Select Category</option>
                      {category.map((category) => (
                        <option key={category.category_id} value={category.category_id}>
                          {category.category_name}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="grid-add d-grid my-4">
                    <label
                      className="text-light d-block text-end p-"
                      htmlFor=""
                    >
                      Short Description
                    </label>
                    {/* <input type="text" /> */}
                    <textarea name="description_1" className="p-1" rows="3"
                      defaultValue={product.description_1}
                      onChange={(e) => setProduct({ ...product, description_1: e.target.value })}></textarea>
                  </div>
                  <div className="grid-add d-grid my-4">
                    <label className="text-light d-block text-end" htmlFor="">
                      Long Description
                    </label>
                    <Editor
                      name="description"
                      value={product.description_2}
                      onChange={(data) => {
                        setData(data);
                      }}
                      editorLoaded={editorLoaded}
                    />
                  </div>
                  <hr className="text-light my-4" />
                  <div className="grid-add d-grid my-4">
                    <label className="text-light d-block text-end" htmlFor="">
                      Regular Price
                    </label>
                    <input type="number" name="regular_price"
                      value={product.regular_price}
                      onChange={(e) => {

                        const sanitizedValue3 = e.target.value.replace(/[^0-9.]/g, '');

                        // Ensure the value is not negative
                        if (sanitizedValue3 >= 0) {
                          const ind = sanitizedValue3.indexOf('.')
                          if (ind !== -1) {
                            const limitedValue = sanitizedValue3.slice(0, ind + 3);
                            setProduct({
                              ...product, regular_price: limitedValue
                            })
                          }
                          else {
                            setProduct({
                              ...product, regular_price: sanitizedValue3
                            })
                          }
                          }
                      }} />
                  </div>
                  <div className="grid-add d-grid my-4">
                    <label className="text-light d-block text-end" htmlFor="">
                      Sale Price
                    </label>
                    <input type="number" name="sale_price"
                      value={product.sale_price}
                      onChange={(e) => {
                        const sanitizedValue2 = e.target.value.replace(/[^0-9.]/g, '');

                        // Ensure the value is not negative
                        if (sanitizedValue2 >= 0) {
                          const ind = sanitizedValue2.indexOf('.')
                          if (ind !== -1) {
                            const limitedValue = sanitizedValue2.slice(0, ind + 3);
                            setProduct({
                              ...product, sale_price: limitedValue
                            })
                          }
                          else {
                            setProduct({
                              ...product, sale_price: sanitizedValue2
                            })
                          }
                        }

                      }} />
                  </div>
                  <hr className="text-light my-4" />
                  <div className="grid-add d-grid my-4">
                    <label className="text-light d-block text-end" htmlFor="">
                      Manage Stock
                    </label>
                    <div className="text-start">
                      <input
                        type="checkbox"
                        // defaultChecked={product.quantity > 0 ? showStock : <></> }
                        checked={showStock}
                        onChange={(e) => {

                          setShowStock(e.target.checked)
                        }}
                      />
                    </div>
                  </div>
                  <div className="grid-add d-grid my-4">
                    <label className="text-light d-block text-end" htmlFor="">
                      Promotions Product
                    </label>
                    <div className="text-start">
                      <input type="checkbox" checked={product.promotion_status}
                        onChange={(e) => setProduct({ ...product, promotion_status: product.promotion_status === 1 ? 0 : 1 })} />
                    </div>

                  </div>

                  {showStock === true || product.quantity >= 0 ? (<div className="grid-add d-grid my-4">
                    <label className="text-light d-block text-end" htmlFor="">
                      Quantity
                    </label>
                    <input
                      type="number"
                      className="py-1 px-2"
                      name="quantity"
                      value={product.quantity}
                      onChange={(e) => {
                        // Remove non-numeric characters
                        const sanitizedValue = e.target.value.replace(/[^0-9]/g, '');

                        // Ensure the value is not negative
                        if (sanitizedValue >= 0) {
                          const limitedValue = sanitizedValue.slice(0, 5);
                          setProduct({ ...product, quantity: limitedValue });
                        }
                      }}
                    />
                  </div>) : ""}
                  {/* data */}
                  <div className="grid-add d-grid my-4">
                    <label htmlFor=""></label>
                    <input
                      className="fw-bold add-save-btn rounded-3 border-0 ms-auto"
                      type="submit"
                      value="Save"
                    />
                  </div>
                </div>
                <div className="col-lg-3">
                  <div className="row">
                    <div className="border-bottom border-secondary col-lg-12 mb-3 pb-2">
                      {/* {product.main_image ? (
                        <div className="mb-3" textAlign="center">
                          <img src={`${baseUrl}/${product.main_image}`} alt={selectedImage} width="100%" className="border border-secondary p-1 rounded-3" />
                        </div>
                      ) : (
                        imageUrl && selectedImage && (
                          <div className="mb-3" textAlign="center">
                            <img src={imageUrl} alt={selectedImage.name} width="100%" className="border border-secondary p-1 rounded-3" />
                          </div>
                        )
                      )} */}
                      {imageUrl && selectedImage ? (
                        <div className="mb-3" textAlign="center">
                          <img ref={imageRef} src={imageUrl} alt={selectedImage.name} width="100%" className="border border-secondary p-1 rounded-3" />

                        </div>
                      ) : (
                        <div className="mb-3" textAlign="center">
                          <img src={`${baseUrl}/${product.main_image}`} alt={selectedImage} width="100%" className="border border-secondary p-1 rounded-3" />
                        </div>
                      )}
                      <input
                        accept="image/*"
                        type="file"
                        id="select-image"
                        onChange={(e) => setSelectedImage(e.target.files[0])}
                      />
                      <label htmlFor="select-image" className="bg-gradient cursor-pointer mb-2 px-3 py-3 rounded-4 text-white upl-img--resp">
                        {!imageUrl && !selectedImage ? "Upload Featured Image" : "Change Featured Image"}
                      </label>

                    </div>
                    <div className="col-lg-12">
                      <ImageUploader onImagesChange={handleImageUpload} title="Upload Multiple Images" getGalleryImages={product.gallery_images} />
                    </div>

                  </div>
                </div>

              </div>
            </form>
          </div>
        </div>
      </div >
    </section >
  );
}