import React, { useEffect, useState } from "react";
import brand1 from "../assets/images/raw-logo.png";
import brand2 from "../assets/images/coh-logo.png";
import brand3 from "../assets/images/gato-logo.png";
import brand4 from "../assets/images/art-logo.png";
// import brand5 from "../assets/images/brand5.png";
import brandhover from "../assets/images/brandhover.png";
import featurebuttonhover from "../assets/images/featurebuttonhover.png";
import smokering1 from "../assets/images/smokering1.png";
import product1 from "../assets/images/product1.png";
import scrollerimg1 from "../assets/images/scrollerimg1.png";
import scrollerimg2 from "../assets/images/scrollerimg2.png";
import scrollerimg3 from "../assets/images/scrollerimg3.png";
import arrows from "../assets/images/arrows.png";
import smoke1 from "../assets/images/smoke1.png";
import axios from "axios";
import { baseUrl } from "../assets/utils/IP";

function Partner() {
  const [brndLogo, setBrndLogo] = useState();

  useEffect(() => {
    const getBrands = async () => {
      try {
        const response = await axios.get(`${baseUrl}/get-all-brands`);
        if (response?.status == 200) {
          setBrndLogo(response?.data);
        }
      } catch (err) {}
    };
    getBrands();
  }, []);
  return (
    <section className="sec-partner bg-black tab-ovf-x-y-hide">
      <span className="seccoverline"></span>

      <div class="smoke-section-parent">
        <div class="ag-smoke-block">
          <div>
            <img class="ag-smoke-1" src={smoke1} alt="Smoke" />
            <img class="ag-smoke-2" src={smoke1} alt="Smoke" />
            <img class="ag-smoke-3" src={smoke1} alt="Smoke" />
            <img class="ag-smoke-4" src={smoke1} alt="Smoke" />
          </div>
        </div>
      </div>
      <div className="container-1640">
        <h1 className="title-mean">
          
          <span>OUR</span> BRANDS
        </h1>
        {/* <div className="hoverul HomePartners">

          {brndLogo?.slice(0, 5).map((brand, index) => (
            <span key={index}>
              <img src={brandhover} alt='' />
              <img src={`${baseUrl}/${brand?.brand_logo}`} alt={brand.brand_name} />
            </span>
          ))}
        </div> */}
        {/* <div className="hoverul">
          <span>
            <img src={brandhover} alt='' />
            <img src={brand1} alt='' />
          </span>
          <span>
            <img src={brandhover} alt='' />
            <img src={brand2} alt='' />
          </span>
          <span>
            <img src={brandhover} alt='' />
            <img src={brand3} alt='' />
          </span>
          <span>
            <img src={brandhover} alt='' />
            <img src={brand4} alt='' />
          </span>
          <span>
            <img src={brandhover} alt='' />
            <img src={brand5} alt='' />
          </span>
        </div> */}
        <div className="row d-lg-flex d-md-flex justify-content-lg-between justify-content-md-center align-items-lg-center align-items-md-center flex-lg-row flex-column gap-lg-0 pt-lg-0 pt-4 custom-row-gap-tab-partner">
          <div className="brandHome col-md-4 col-lg-2 partner-logo-same-parent">
            <img className="hoverSmog" src={brandhover} alt="" />
            <img className="partner-logo-same" src={brand1} alt="" />
            {/* className="resp-dunhill-logo" */}
          </div>
          <div className="brandHome col-md-4 col-lg-2 partner-logo-same-parent">
            <img className="hoverSmog" src={brandhover} alt="" />
            <img className="partner-logo-same" src={brand2} alt="" />
            {/* className="benson-logo-resp" */}
          </div>
          <div className="brandHome col-md-4 col-lg-2 partner-logo-same-parent">
            <img className="hoverSmog" src={brandhover} alt="" />
            <img className="partner-logo-same" src={brand3} alt="" />
          </div>
          <div className="brandHome col-md-6 col-lg-2 partner-logo-same-parent">
            <img className="hoverSmog" src={brandhover} alt="" />
            <img className="partner-logo-same" src={brand4} alt="" />
          </div>
          {/* <div className="brandHome col-md-6 col-lg-2">
            <img className="hoverSmog" src={brandhover} alt="" />
            <img src={brand5} alt="" />
          </div> */}
        </div>
      </div>
      <span className="tab-blend-bottom-of-sec"></span>
    </section>
    //
  );
}

export default Partner;
