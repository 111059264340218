import React, { useEffect, useState } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import faker from "faker";
import axios from "axios";
import { baseUrl } from "../../assets/utils/IP";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);


function App() {

  const [graphData, setGraphData] = useState([]);
  const [graphData2, setGraphData2] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const config = {
          method: 'get',
          maxBodyLength: Infinity,
          url: `${baseUrl}/get-all-orders`,
          headers: {
            'Cookie': 'jwttoken=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiJhZG1pbiIsImlhdCI6MTY5NTgxMzUzOSwiZXhwIjoxNjk1ODE3MTM5fQ.fK8-sArM9gGejosm2uixBOVBkZGP34QYomY3SUmd4Qw'
          }
        };

        const response = await axios.request(config);
        setGraphData(response.data)
      } catch (error) {
      }
    };

    // Call the fetchData function within the useEffect
    fetchData();
  }, []);
  const token = localStorage.getItem("root")

  useEffect(() => {
    // Axios request configuration
    let config = {
      method: 'get',
      maxBodyLength: Infinity,
      url: `${baseUrl}/get-all-users`,
      headers: {
        'Authorization':
          `Bearer ${token}`,
      },
    };

    // Make the Axios request when the component mounts
    axios
      .request(config)
      .then((response) => {
        setGraphData2(response.data)
      })
      .catch((error) => {
      });
  }, []);



  const monthCounts = {};

  //months reset to 0 if no value
  for (let i = 0; i < 12; i++) {
    monthCounts[i] = 0;
  }
  const monthCounts2 = {};

  //months reset to 0 if no value
  for (let i = 0; i < 12; i++) {
    monthCounts2[i] = 0;
  }

  graphData.orders?.forEach((item) => {
    const date = new Date(item.created_at);
    const month = date.getMonth();
    monthCounts[month]++;
  })
  graphData2?.forEach((item) => {
    const date = new Date(item.created_at);
    const month = date.getMonth();
    monthCounts2[month]++;
  })
  const monthCountsArray = Object.keys(monthCounts).map((month) => ({
    month: parseInt(month),
    count: monthCounts[month]
  }));
  const monthCountsArray2 = Object.keys(monthCounts2).map((month) => ({
    month: parseInt(month),
    count: monthCounts2[month]
  }));

  //Sort months
  monthCountsArray.sort((x, y) => x.month - y.month);

  monthCountsArray2.sort((x, y) => x.month - y.month);

  const graphResult = monthCountsArray.map((entry) => entry.count)



  const graphResult2 = monthCountsArray2.map((entry) => entry.count)


  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: true,
        text: ["Sales Analytics", "Position", "Monthly"],
      },
    },
  };

  // {const mufaddal = new Date('2023-03-25T09:03:44.000Z');

  const labels = ["Jan", "Feb", "Mar", "Apr", "May", "June", "July", "Aug", "Sep", "Oct", "Nov", "Dec"];


  const data = {
    labels,
    datasets: [
      {
        label: "Order",
        data: graphResult,
        backgroundColor: "rgb(244, 201, 16)",
        color: "rgb(255, 255, 255)"
      },
      {
        label: "Customer",
        data: graphResult2,
        backgroundColor: "rgb(255, 255, 255)",
      },
    ],
  };

  return <Bar options={options} data={data} />;
}

export default App;