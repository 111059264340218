import React, { useEffect } from "react";
import Footer from "../Components/Footer/Footer";
import Header from "../Components/Header/Header";
import Warning from "../Components/Warning";
import { Link, useNavigate } from "react-router-dom";
import buyleftspan from "../assets/images/buyleftspan.png";
import buyrightspan from "../assets/images/buyrightspan.png";
import { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import axios from "axios";
import Swal from "sweetalert2";
import { baseUrl } from "../assets/utils/IP";
import { useFormik } from "formik";
import validationSchema from "./ValidationSchema";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";

function Guest() {
  const [show, setShow] = useState(false);
  const [countries, setCountries] = useState([]);
  const [state, setState] = useState([]);
  const [cities, setCities] = useState([]);
  const [data, setData] = useState([]);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [validationCity, setValidationCity] = useState();
  const [user, setUser] = useState({
    country: "",
    state: "",
    city: "",
  });

  const navigate = useNavigate();
  const handleClose = () => setShow(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const togglePasswordVisibility2 = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const handleCountry = (e) => {
    setState([]);
    setCities([]);
    const selectedCountry = e.target.value;

    setUser({ ...user, country: selectedCountry });

    const filteredStates = data
      .filter((item) => item.country === selectedCountry)
      .map((item) => item.subcountry)
      .filter((subcountry, index, self) => self.indexOf(subcountry) === index)
      .sort();

    setState(filteredStates);
  };

  const handleState = (e) => {
    const selectedState = e.target.value;
    setUser({ ...user, state: selectedState });

    // Filter the cities based on the selected country and state
    const filteredCities = data.filter(
      (item) =>
        item.country === user.country && item.subcountry === selectedState
    );

    // Get unique cities by using a Set to eliminate duplicates
    const uniqueCities = Array.from(
      new Set(filteredCities.map((item) => item.name))
    );

    // Map the unique city names to objects containing the name property
    const uniqueCityObjects = uniqueCities.map((city) => ({ name: city }));

    setCities(uniqueCityObjects);
  };

  const handleFormSubmit = (values) => {

    if (
      !values.fullName ||
      !values.email ||
      !values.phone ||
      !values.streetAddress ||
      !values.post_code
      // !values.passportNumber
    ) {
      Swal.fire({
        icon: "error",
        title: "Registration Unsuccessful",
        text: "All fields are required",
        customClass: {
          container: "customstyle",
        },
      });
      return; // Stop the function execution
    }

    if (
      formik.errors.fullName ||
      formik.errors.email ||
      formik.errors.streetAddress
    ) {
      Swal.fire({
        icon: "error",
        title: "Registration Unsuccessful",
        text: "Registration Unsuccessful",
        customClass: {
          container: "customstyle",
        },
      });
      return;
    }

    let data = JSON.stringify({
      full_name: values.fullName.trim(),
      email: values.email,
      contact: values.phone,
      streetaddress: values.streetAddress.trim(),
      city: values.city,
      country: values.country,
      state: values.state,
      // passport: values.passportNumber,
      post_code: values.post_code,

    });

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${baseUrl}/create-guest-order`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    // axios
    //   .request(config)
    //   .then((response) => {
    //     Swal.fire({
    //       icon: "success",
    //       title: "Registration Successful",
    //       text: "You may proceed to the login screen",
    //       customClass: {
    //         container: "customstyle",
    //       },
    //     });
    //     setValidationCity("");
    //     // navigate("/login");
    //   })
    //   .catch((error) => {
    //     if (error?.response?.data.message === "Email is already in use") {
    //       Swal.fire({
    //         icon: "error",
    //         title: "Registration Unsuccessful",
    //         text: "Email has already been registered",
    //         customClass: {
    //           container: "customstyle",
    //         },
    //       });
    //     } else if (
    //       error?.response?.data.message === "Passport number is already in use"
    //     ) {
    //       Swal.fire({
    //         icon: "error",
    //         title: "Registration Unsuccessful",
    //         text: "Passport number is already in use",
    //         customClass: {
    //           container: "customstyle",
    //         },
    //       });
    //     } else if (
    //       error?.response?.data.message ===
    //       "firstname and lastname are required"
    //     ) {
    //       Swal.fire({
    //         icon: "error",
    //         title: "Registration Unsuccessful",
    //         text: "Kindly fill the Required feilds",
    //         customClass: {
    //           container: "customstyle",
    //         },
    //       });
    //     } else if (
    //       error?.response?.data.message === "city or country are required"
    //     ) {
    //       // Swal.fire({
    //       //   icon: "error",
    //       //   title: "Registration Unsuccessful",
    //       //   text: "Kindly fill the Required City/Country feilds",
    //       //   customClass: {
    //       //     container: "customstyle",
    //       //   }
    //       // });
    //       setValidationCity("City/Country/state are required feilds");
    //     }
    //   });

    localStorage.setItem('guestUserData', data)

    navigate('/shipping-cart')

  };

  const handleSubmit = async (e, values) => {
    e.preventDefault();
    try {
      const response = await axios.post(`${baseUrl}/register`, {
        firstname: values.firstName.trim(),
        lastname: values.lastName.trim(),
        email: values.email,
        password: values.password,
        contact: values.phone,
        streetaddress: values.streetAddress.trim(),
        // passport: values.passportNumber,
        country: values.country,
        state: values.state,
        city: values.city,
        post_code: values.post_code,
      });

      if (response.status === 201) {
        Swal.fire({
          icon: "success",
          title: "Registration Successful",
          text: "You may proceed to the login screen",
          customClass: {
            container: "customstyle",
          },
        });

        localStorage.setItem('guestUserData', {
          firstname: values.firstName.trim(),
          lastname: values.lastName.trim(),
          email: values.email,
          password: values.password,
          contact: values.phone,
          streetaddress: values.streetAddress.trim(),
          // passport: values.passportNumber,
          country: values.country,
          state: values.state,
          city: values.city,
          post_code: values.post_code,
        })

        navigate('/shipping-cart')
      }

      // navigate("/login");
    } catch (err) {
      if (err?.response?.data.message === "Email is already in use") {
        Swal.fire({
          icon: "error",
          title: "Registration Unsuccessful",
          text: "Email has already been registered",
          customClass: {
            container: "customstyle",
          },
        });
      } else if (
        err?.response?.data.message === "Passport number is already in use"
      ) {
        Swal.fire({
          icon: "error",
          title: "Registration Unsuccessful",
          text: "Passport number is already in use",
          customClass: {
            container: "customstyle",
          },
        });
      } else if (
        err?.response?.data.message === "Invalid contact number format"
      ) {
        Swal.fire({
          icon: "error",
          title: "Registration Unsuccessful",
          text: "Invalid contact number format",
          customClass: {
            container: "customstyle",
          },
        });
      }
    }
  };

  const formik = useFormik({
    initialValues: {
      fullName: "",
      email: "",
      streetAddress: "",
      // passportNumber: "",
      post_code: "",
      country: "",
      state: "",
      city: "",
      phone: "",
      post_code: "",
    },
    validationSchema: validationSchema, // Use the default export
    onSubmit: handleSubmit,
  });

  useEffect(() => {
    axios
      .get(
        "https://pkgstore.datahub.io/core/world-cities/world-cities_json/data/5b3dd46ad10990bca47b04b4739a02ba/world-cities_json.json"
      )
      .then((res) => {
        setData(res.data);
        const allCountries = res.data;
        let filteredCountries = [
          ...new Set(allCountries.map((item) => item.country)),
        ];

        filteredCountries = filteredCountries.sort();

        setCountries(filteredCountries);
      });
  }, []);

  return (
    <div className="font-bebas">
      <Warning />
      <Header />
      <section className="signupsection guestsec-resp">
        <span className="buyleftspan">
          <img src={buyleftspan} alt="" />
        </span>
        <span className="buyrightspan">
          <img src={buyrightspan} alt="" />
        </span>
        <div className="heading mb-5">
          <h1 class="login-title beige-rep text-center">Guest</h1>
        </div>
        <div className="container-1640 z-10 position-relative">
          <div className="row justify-content-center signrow border-top">
            <div className="col-lg-7">
              <h1 class="carttitle text-center justify-content-center">
                Add your account information
              </h1>
              <form

                className="form signupform"
              >
                <div className="row">
                  <div className="col-lg-6 position-relative">
                    <label>Full Name*</label>

                    <input
                      type="text"
                      name="firstName"
                      {...formik.getFieldProps("fullName")}
                      required
                    />
                    {formik.touched.fullName && formik.errors.fullName && (
                      <div className="error-message" style={{ color: "red" }}>
                        {formik.errors.fullName}
                      </div>
                    )}
                  </div>
                  <div className="col-lg-6">
                    <label>Email Address*</label>
                    <input
                      type="email"
                      name="email"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.email}
                      required
                    />
                    {formik.touched.email && formik.errors.email && (
                      <div className="error-message" style={{ color: "red" }}>
                        {formik.errors.email}
                      </div>
                    )}
                  </div>

                  {/* <div className="col-lg-6">
                    <label>Country*</label>
                    <select
                      className="poppins"
                      value={user.country}
                      onChange={handleCountry}
                      required
                    >
                      <option value="" disabled>
                        Country
                      </option>
                      {countries?.map((item) => (
                        <option key={item} value={item}>
                          {item}
                        </option>
                      ))}
                    </select>
                  </div> */}

                  <div className="col-lg-6">
                    <label>Country*</label>
                    <input
                      type="text"
                      name="country"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.country}
                      required
                    />
                  </div>

                  {/* <div className="col-lg-6">
                    <label>City*</label>
                    <select
                      value={formik.values.city}
                      className="poppins"
                      onChange={(e) => {
                        formik.setFieldValue("city", e.target.value);
                        setValidationCity("");
                      }}
                      onBlur={formik.handleBlur}
                      required
                    >
                      <option value="" disabled>
                        City
                      </option>
                      {cities?.map((item) => (
                        <option key={item.name} value={item.name}>
                          {item.name}
                        </option>
                      ))}
                    </select>

                    {formik.touched.city && formik.errors.city && (
                      <p className="error-message" style={{ color: "red" }}>
                        {formik.errors.city}
                      </p>
                    )}
                    {validationCity && (
                      <p className="error-message" style={{ color: "red" }}>
                        {validationCity}
                      </p>
                    )}
                  </div> */}

                  <div className="col-lg-6">

                    <label>State*</label>
                    <input
                      type="text"
                      name="state"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.state}
                      required
                    />
                  </div>

                  <div className="col-lg-6">
                    <label>Street Address*</label>
                    <textarea
                      name="streetAddress"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.streetAddress}
                      maxLength={128}
                    ></textarea>
                    {formik.touched.streetAddress &&
                      formik.errors.streetAddress && (
                        <div className="error-message" style={{ color: "red" }}>
                          {formik.errors.streetAddress}
                        </div>
                      )}
                  </div>

                  {/* <div className="col-lg-6">
                    <label>State*</label>
                    <select
                      className="poppins"
                      value={user.state}
                      onChange={handleState}
                      required
                    >
                      <option value="" disabled className="poppins">
                        State
                      </option>
                      {state?.map((item) => (
                        <option key={item} value={item}>
                          {item}
                        </option>
                      ))}
                    </select>

                    <label>Contact Number*</label>
                    <input
                      type="text"
                      name="phone"
                      className="poppins"
                      placeholder="0000-000-000"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.phone}
                      required
                    />
                    {formik.touched.phone && formik.errors.phone && (
                      <div className="error-message" style={{ color: "red" }}>
                        {formik.errors.phone}
                      </div>
                    )}
                  </div> */}

                  <div className="col-lg-6">
                    <label>City*</label>
                    <input
                      type="text"
                      name="city"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.city}
                      required
                    />

                    <label>Contact Number*</label>
                    <input
                      type="text"
                      name="phone"
                      className="poppins"
                      placeholder="0000-000-000"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.phone}
                      required
                    />
                    {formik.touched.phone && formik.errors.phone && (
                      <div className="error-message" style={{ color: "red" }}>
                        {formik.errors.phone}
                      </div>
                    )}
                  </div>

                  <div className="col-lg-6">
                    <label>Postcode</label>
                    <input
                      type="text"
                      name="post_code"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.post_code}
                    />
                    {formik.touched.post_code &&
                      formik.errors.post_code && (
                        <div className="error-message" style={{ color: "red" }}>
                          {formik.errors.post_code}
                        </div>
                      )}
                  </div>
                  <div className="col-lg-6 d-flex align-items-center justify-content-lg-end justify-content-start">
                    <div className="signinbtn">
                      <Link
                        onClick={(e) => {
                          e.preventDefault()
                          handleFormSubmit(formik.values)
                        }}
                      >
                        Next
                      </Link>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
      <Footer />

      <Modal className="accountcreated" show={show} onHide={handleClose}>
        <Modal.Header>
          <Button onClick={handleClose}>
            <i class="fa-solid fa-circle-xmark"></i>
          </Button>
        </Modal.Header>
        <Modal.Body>
          <h2>
            <i class="fa-solid fa-check"></i> Your Account has been created !
          </h2>
          <p>
            An email sent to your registered Email Address with your login
            credentials
          </p>
          <div class="signinbtn ">
            <Link to="/Login">Continue</Link>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default Guest;
