import React, { useEffect, useState } from "react";
import Warning from "../Components/Warning";
import Header from "../Components/Header/Header";
import brand1 from "../assets/images/brand1.png";
import shoptria from "../assets/images/shoptria.png";
import shoptria2 from "../assets/images/shoptria2.png";
import line from "../assets/images/pathline.png";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";
import { Link, useNavigate, useParams } from "react-router-dom";
import flower from "../assets/images/flower.png";
import smokepromotionspan from "../assets/images/smoke-promotion-span.png";
import smoke1 from "../assets/images/smoke1.png";
import filterproduct1 from "../assets/images/filterproduct1.png";
import span1 from "../assets/images/span1.png";
import span2 from "../assets/images/span2.png";
import Footer from "../Components/Footer/Footer";
import axios from "axios";
import { baseUrl } from "../assets/utils/IP";
import Pagination from "../Components/Pagination";
import { useContext } from "react";
import { CartContext } from "../Components/Cart";
import Swal from "sweetalert2";

import AddToCartModal from "../Components/Modals/AddToCartModal";

import TrunctedText from "../Components/TrunctedText";

function Shop() {
  const [minPrice, setMinPrice] = useState(2);
  const [maxPrice, setMaxPrice] = useState(2000);
  const [brandProducts, setBrandProducts] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [brandLogo, setBrndLogo] = useState();
  const [category, setCategory] = useState();
  const [options, setOptions] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [selectedBrands, setSelectedBrands] = useState([]);
  const [selectedBrandId, setSelectedBrandId] = useState([]);
  const [selectedProductId, setSelectedProductId] = useState([]);
  const [selectedItem, setSelectedItem] = useState(); // Default value is "1" or any other default value

  const [show, setShow] = useState(false);
  const [cartvalue, setcartvalue] = useState(1);
  const [cartProduct, setCartProduct] = useState();

  const navigate = useNavigate();

  const { updateQuantity } = useContext(CartContext);

  const handleSliderChange = (values) => {
    setMinPrice(values[0]);
    setMaxPrice(values[1]);
  };

  const { id } = useParams();
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${baseUrl}/get-product-by-brand/${id}`
        );
        setBrandProducts(response.data);
      } catch (error) {}
    };

    fetchData();
  }, [id]);

  useEffect(() => {
    const getCategory = async () => {
      try {
        const response = await axios.get(`${baseUrl}/get-all-category`);
        if (response?.status == 200) {
          setCategory(response?.data);
        }
      } catch (err) {}
    };
    getCategory();
  }, []);

  useEffect(() => {
    const getBrands = async () => {
      try {
        const response = await axios.get(`${baseUrl}/get-all-brands`);
        if (response?.status == 200) {
          setBrndLogo(response?.data);
        }
      } catch (err) {}
    };
    getBrands();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${baseUrl}/get-most-view`);
        const data = response.data;
        const filteredOptions = data.filter((option) => option.views_count > 1);

        // Assuming the API response is an array of options
        setOptions(filteredOptions);
      } catch (error) {}
    };

    fetchData();
  }, []);

  // ------------------------------

  const filterProducts = () => {
    if (!brandProducts || !brandProducts.length) {
      return [];
    }

    // Filter products based on selected categories and brands
    const filteredProducts = brandProducts.filter((product) => {
      // Check if the product's category is among the selected categories
      const isInSelectedCategories =
        selectedCategories.length === 0 ||
        selectedCategories.includes(product.category_id);

      // Check if the product's brand is among the selected brands
      const isInSelectedBrands =
        selectedBrands.length == 0 || selectedBrands.includes(product.brand_id);

      const isMostViewed =
        selectedProductId.length == 0 ||
        selectedProductId.includes(product.product_id);

      const isSelectedBrand =
        selectedBrandId.length == 0 ||
        selectedBrandId.includes(product.brand_id);

      const isWithinPriceRange =
        product?.sale_price >= minPrice && product?.sale_price <= maxPrice;

      // Return true if both category and brand filters match
      return (
        isInSelectedCategories &&
        isInSelectedBrands &&
        isMostViewed &&
        isSelectedBrand &&
        isWithinPriceRange
      );
    });
    return filteredProducts;
  };

  const handleSelectChange = (event) => {
    const selectedValue = event.target.value;
    const selectedValueAsInt = parseInt(selectedValue, 10);
    const updatedProductIds = [selectedValueAsInt];
    setSelectedProductId(updatedProductIds);

    // You can now use updatedProductIds as an array of selected product IDs
  };
  const handleSelectBrand = (event) => {
    const selectedBrandId = event.target.value;
    const selectedBrandIdAsInt = parseInt(selectedBrandId, 10);
    const updatedBrandIds = [selectedBrandIdAsInt];
    setSelectedBrandId(updatedBrandIds);

    // You can now use updatedProductIds as an array of selected product IDs
  };

  const handleCategoryChange = (category_id) => {
    setSelectedCategories((prevSelected) => {
      if (prevSelected.includes(category_id)) {
        // Remove the category from the selected list
        return prevSelected.filter((id) => id !== category_id);
      } else {
        // Add the category to the selected list
        return [...prevSelected, category_id];
      }
    });
  };

  const handleBrandChange = (brand_id) => {
    navigate(`/shop/${brand_id}`);
    return;
  };

  const filteredProducts = filterProducts();

  const clearSelectedProductId = () => {
    setSelectedProductId([]);
    setSelectedBrandId([]);
  };

  // ----

  const p_quantity = 1;

  const handleShop = (product) => {
    updateQuantity(product, p_quantity);
    Swal.fire({
      icon: "success",
      title: "Add to Cart",
      text: "Your Product is Added to Cart",
      showCancelButton: true,
      confirmButtonText: "Proceed to Checkout",
      cancelButtonText: "Back",
      customClass: {
        container: "customstyle",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        navigate("/cart");
        window.scrollTo(0, 0);
        console.log("Ok button clicked");
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        // Handle Back button click
      }
    });
  };

  const handleShow = (product) => {
    updateQuantity(product, p_quantity);
    setCartProduct(product);
    setShow(true);
  };
  const handleClose = () => setShow(false);

  // pagination
  const itemsPerPage = selectedItem ? selectedItem : 10;

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const ordersToDisplay = filteredProducts
    .reverse()
    .slice(startIndex, endIndex);
  const totalPages = Math.ceil(
    filteredProducts.reverse().length / itemsPerPage
  );
  //
  const handlePageItemChange = (event) => {
    const selectedValue = event.target.value;
    setSelectedItem(selectedValue);
  };

  // mobile filter usestate
  const [showFilters, setShowFilters] = useState(false);

  const handleFilterHeadingClick = () => {
    setShowFilters(!showFilters);
  };

  // mobile filter usestate

  return (
    <div className="font-bebas">
      <Warning />

      <div className="brand-hover">
        <Header />
      </div>

      <section className="secshop">
        <div class="smoke-section-parent">
          <div class="ag-smoke-block">
            <div>
              <img class="ag-smoke-1" src={smoke1} alt="Smoke" />
              <img class="ag-smoke-2" src={smoke1} alt="Smoke" />
              <img class="ag-smoke-4" src={smoke1} alt="Smoke" />
            </div>
          </div>
        </div>
        <span className="EXQUISITE-span1">
          <img src={span1} alt="" />
        </span>
        <span className="EXQUISITE-span2">
          <img src={span2} alt="" />
        </span>

        <div className="container-1640 position-relative z-10">
          <div className="toplinecon">
            <div className="hamicons">
              <ul>
                <li>
                  <Link>Home</Link>
                </li>
                <li>
                  <Link>
                    {" "}
                    <i class="fa-solid fa-chevron-right"></i>{" "}
                  </Link>
                </li>
                <li>
                  <Link>Shop</Link>
                </li>
              </ul>
            </div>
            <div className="pagin">
              {/* <div className="bx">
                <img src={shoptria} />
                <img src={line} />
                <img src={shoptria2} />
              </div> */}
              <div>
                <select
                  class="form-select"
                  aria-label="Default select example"
                  value={selectedItem} // Set the value of the select element to the state variable
                  onChange={handlePageItemChange}
                >
                  <option selected value="9">
                    Items
                  </option>
                  <option value="5">5</option>
                  <option value="10">10</option>
                  <option value="15">15</option>
                </select>
              </div>
            </div>
          </div>

          <div className="filter-mean filter-mean-mob position-relative">
            <div className="filterleft">
              <div className="filters d-md-block d-none">
                <div className="fitlertitel">
                  <h2>Filters</h2>
                </div>

                <div className="range" style={{ marginTop: "20px" }}>
                  <Slider
                    range
                    min={2}
                    max={2000}
                    defaultValue={[minPrice, maxPrice]}
                    onChange={handleSliderChange}
                  />
                  <p>
                    <span> Min:$ {minPrice}</span>
                    <span> Max:$ {maxPrice}</span>
                  </p>
                </div>
                <div className="select-apn">
                  <select
                    className="form-select"
                    aria-label="Default select example"
                    onChange={handleSelectChange}
                    value={selectedProductId}
                  >
                    <option
                      style={{
                        color: "black",
                      }}
                    >
                      Most viewed
                    </option>
                    {options?.map((option, index) => (
                      <option
                        key={index}
                        value={option.product_id}
                        style={{
                          color: "black",
                        }}
                      >
                        {option.product_name}{" "}
                        {/* Change 'label' to the actual property you want to display */}
                      </option>
                    ))}
                  </select>
                </div>
                <div class="p-3">
                  <button
                    onClick={clearSelectedProductId}
                    class="btn btn-gradient w-100 rounded"
                  >
                    Reset
                  </button>
                </div>
                {/* <div className="resetBtn"> <button onClick={clearSelectedProductId}>Rest</button>
                  </div> */}
                {/* <div className="categories">
                  <h2>Category</h2>

                  {category?.map((category) => (
                    <div>
                      <div key={category.category_id} className="categoriesitem">
                        <div className="form-group">
                          <input type="checkbox" id={category.category_id}
                            checked={selectedCategories.includes(category.category_id)}
                            onChange={() => handleCategoryChange(category.category_id)}
                          />
                          <label htmlFor={category.category_id}></label>
                        </div>
                        <div className="catecon">
                          <p>{category?.category_name}</p>
                          <p>{category?.product_count}</p>

                        </div>
                      </div>
                    </div>
                  ))}

                </div> */}
                <div className="categories salespage">
                  <div className="brandtitle">
                    <h2>Brands</h2>
                  </div>
                  {brandLogo?.slice(0, 5).map((brand) => (
                    <div className="categoriesitem BrandItems">
                      <div key={brand.brand_id} className="form-group">
                        <input
                          type="checkbox"
                          id={brand.brand_id}
                          onChange={() => handleBrandChange(brand.brand_id)}
                        />
                        <label htmlFor={brand.brand_id}></label>
                      </div>
                      <div className="catecon">
                        <img src={`${baseUrl}/${brand.brand_logo}`} alt="" />
                      </div>
                    </div>
                  ))}
                </div>

                <div className="categories salespage">
                  <div className="brandtitle"></div>
                </div>
              </div>
              <div className="filters filter-mobleft position-absolute d-md-none d-block">
                <div className="fitlertitel" onClick={handleFilterHeadingClick}>
                  <h2>Filters</h2>
                </div>
                {showFilters && (
                <>
                <div className="range" style={{ marginTop: "20px" }}>
                  <Slider
                    range
                    min={2}
                    max={2000}
                    defaultValue={[minPrice, maxPrice]}
                    onChange={handleSliderChange}
                  />
                  <p>
                    <span> Min:$ {minPrice}</span>
                    <span> Max:$ {maxPrice}</span>
                  </p>
                </div>
                <div className="select-apn">
                  <select
                    className="form-select"
                    aria-label="Default select example"
                    onChange={handleSelectChange}
                    value={selectedProductId}
                  >
                    <option
                      style={{
                        color: "black",
                      }}
                    >
                      Most viewed
                    </option>
                    {options?.map((option, index) => (
                      <option
                        key={index}
                        value={option.product_id}
                        style={{
                          color: "black",
                        }}
                      >
                        {option.product_name}{" "}
                        {/* Change 'label' to the actual property you want to display */}
                      </option>
                    ))}
                  </select>
                </div>
                <div class="p-3">
                  <button
                    onClick={clearSelectedProductId}
                    class="btn btn-gradient w-100 rounded"
                  >
                    Reset
                  </button>
                </div>
                {/* <div className="resetBtn"> <button onClick={clearSelectedProductId}>Rest</button>
                  </div> */}
                {/* <div className="categories">
                  <h2>Category</h2>

                  {category?.map((category) => (
                    <div>
                      <div key={category.category_id} className="categoriesitem">
                        <div className="form-group">
                          <input type="checkbox" id={category.category_id}
                            checked={selectedCategories.includes(category.category_id)}
                            onChange={() => handleCategoryChange(category.category_id)}
                          />
                          <label htmlFor={category.category_id}></label>
                        </div>
                        <div className="catecon">
                          <p>{category?.category_name}</p>
                          <p>{category?.product_count}</p>

                        </div>
                      </div>
                    </div>
                  ))}

                </div> */}
                <div className="categories salespage">
                  <div className="brandtitle">
                    <h2>Brands</h2>
                  </div>
                  {brandLogo?.slice(0, 5).map((brand) => (
                    <div className="categoriesitem BrandItems">
                      <div key={brand.brand_id} className="form-group">
                        <input
                          type="checkbox"
                          id={brand.brand_id}
                          onChange={() => handleBrandChange(brand.brand_id)}
                        />
                        <label htmlFor={brand.brand_id}></label>
                      </div>
                      <div className="catecon">
                        <img src={`${baseUrl}/${brand.brand_logo}`} alt="" />
                      </div>
                    </div>
                  ))}
                </div>

                <div className="categories salespage">
                  <div className="brandtitle"></div>
                </div>
                
                </>
              )}
              </div>
            </div>
            <div className="filterright filter-product w-100">
              <div className="d-flex flex-wrap">
                {filteredProducts?.map((product) => (
                  <div
                    key={product.product_id}
                    className="d-flex position-relative product-main"
                  >
                    <div className="align-items-center d-flex mx-2 pro2-content">
                      <div className="position-relative product-img z-1 w-25">
                        {/* Use product.image or any property that represents the image */}
                        {product.main_image != null ? (
                          <Link to={`/Buynow/${product.product_id}`}>
                            <img
                              src={`${baseUrl}/${product.main_image}`}
                              alt={product.name}
                              className="w-100"
                            />{" "}
                          </Link>
                        ) : (
                          <img
                            src={filterproduct1}
                            alt={product}
                            className="w-100"
                          />
                        )}
                      </div>
                      <div className="position-relative procontent justify-content-center">
                        <Link to={`/Buynow/${product.product_id}`}>
                          <img src={flower} alt="" />
                          <h1>{product.product_name}</h1>
                          <TrunctedText
                            text={product.description_1}
                            maxLength={53}
                          />
                          {/* <p>{product.description_1}</p> */}
                          <h6>
                            $
                            {product?.sale_price == 0
                              ? product?.regular_price
                              : product?.sale_price}
                          </h6>
                          <span>incl. GST</span>
                        </Link>
                        <button
                          onClick={() => handleShow(product)}
                          className="probtn border-0"
                        >
                          Add to Cart
                        </button>
                      </div>
                    </div>
                    <span className="position-absolute smokehover">
                      <img src={smokepromotionspan} alt="" />
                    </span>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
        <div
          class="d-flex justify-content-between mx-5 pagiban ms-auto"
          style={{ width: "10%" }}
        >
          {/* <p>Page 1 of 12</p> */}

          <Pagination
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={(page) => setCurrentPage(page)}
          />
        </div>
      </section>

      <Footer />

      {show && (
        <AddToCartModal
          show={show}
          handleClose={handleClose}
          products={cartProduct}
          cartvalue={cartvalue}
        />
      )}
    </div>
  );
}

export default Shop;
