import React from "react";

import Footer from "../Components/Footer/Footer";
import Header from "../Components/Header/Header";
import CartCancelImg from "../assets/images/cart-cancel.png"
import Warning from "../Components/Warning";
import { Link } from "react-router-dom";
import buyleftspan from "../assets/images/buyleftspan.png";
import buyrightspan from "../assets/images/buyrightspan.png";
import cohibalighter from "../assets/images/cohibalighter.png";
import { useContext } from "react";
import { CartContext } from "../Components/Cart";
import { useEffect } from "react";
import { useState } from "react";
import Swal from "sweetalert2";
import { baseUrl } from "../assets/utils/IP";

function Cart() {
  const { cartItems, getCartTotal, CancelProduct, updateQuantity, incrementQuantity, decrementQuantity } =
    useContext(CartContext);
  const [qty, setQty] = useState()
  const UserID = localStorage.getItem("userId")


  const handleClick = (i) => {
    CancelProduct(i)
  }


  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  }, [])


  // qty update states and functions here 
  const [p_quantity, setQuantity] = useState(0)

  console.log(p_quantity, "this page quantity")

  const handleShow = (item) => {
    updateQuantity(item, item.p_quantity + p_quantity)

  }
  // qty update states and functions here end

  return (
    <div className="font-bebas">
      <Warning />

      <Header />
      <div className="bef-aft">
        <div className="container-1640">
          <div className="toplinecon">
            <div className="hamicons">
              <ul>
                <li>
                  <Link>Home</Link>
                </li>
                <li>
                  <Link>
                    {" "}
                    <i class="fa-solid fa-chevron-right"></i>{" "}
                  </Link>
                </li>
                <li>
                  <Link>Lighters</Link>
                </li>
                <li>
                  <Link>
                    {" "}
                    <i class="fa-solid fa-chevron-right"></i>{" "}
                  </Link>
                </li>
                <li>
                  <Link>Product Name</Link>
                </li>
                <li>
                  <Link>
                    {" "}
                    <i class="fa-solid fa-chevron-right"></i>{" "}
                  </Link>
                </li>
                <li>
                  <Link>Shopping Cart</Link>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <section className="cartsection1">
          <span className="buyleftspan">
            {" "}
            <img src={buyleftspan} />
          </span>
          <span className="buyrightspan">
            {" "}
            <img src={buyrightspan} />
          </span>
          <div className="container-1640">
            <h1 className="carttitle your-shop-cart d-block">
              <i class="fa-solid fa-cart-shopping me-3 text-white"></i>Your Shopping <span>Cart</span>
            </h1>

            {cartItems?.map((item, index) => (
              <div key={index} className="cartdiscription mb-4">
                <div className="cartProd">
                  <img src={`${baseUrl}/${item?.main_image}`} alt={`Product ${index}`} />

                </div>
                <div className="prodetailcart">
                  <h4>{item.product_name}</h4>
                  <p>${item.sale_price == 0 ? item.regular_price : item.sale_price} x {item.p_quantity}</p>
                  <p className="Avalibility" style={{ fontSize: "12px" }}>Availability: {item?.quantity}</p>
                  <div className="quantity-parent-prdct brdr-left-prdct">
                    <div className="quantity-heading-prdt">
                      <h5 className="text-light pt-3">Update Quantity</h5>
                    </div>
                    <div id="field1" className="productCounter">
                      {item.quantity == item.p_quantity ? "" :
                        (<button
                          type="button"
                          id="add"
                          className="plus add-minus-btns"
                          onClick={() => incrementQuantity(item)}>
                          +
                        </button>)}                    <input
                        type="number"
                        value={item.p_quantity ? item.p_quantity : 1}
                        min={1}
                        max={item?.quantity}
                        className="quantity input-add-prdct"
                        readOnly
                        style={{ color: "black" }}
                      />
                      {item.p_quantity > 1 &&
                        <button
                          type="button"
                          id="sub"
                          className="minus add-minus-btns"
                          onClick={() => decrementQuantity(item)}

                        >
                          -
                        </button>}
                    </div>
                  </div>
                </div>
                <img src={CartCancelImg} style={{ position: "absolute", right: "20px" }} onClick={() => handleClick(item)} />

                {/* Qnty update */}



                {/* Qnty update */}
                {/* <div className="quantity-parent-prdct brdr-left-prdct">
                  <div className="quantity-heading-prdt">
                    <h5 className="text-light pt-3">Update Quantity</h5>
                  </div>
                  <div id="field1" className="productCounter">
                    {item.quantity == item.p_quantity ? "" :
                      (<button
                        type="button"
                        id="add"
                        className="plus add-minus-btns"
                        onClick={() => incrementQuantity(item)}>
                        +
                      </button>)}                    <input
                      type="number"
                      value={item.p_quantity ? item.p_quantity : 1}
                      min={1}
                      max={item?.quantity}
                      className="quantity input-add-prdct"
                      readOnly
                      style={{ color: "black" }}
                    />
                    {item.p_quantity > 1 &&
                      <button
                        type="button"
                        id="sub"
                        className="minus add-minus-btns"
                        onClick={() => decrementQuantity(item)}

                      >
                        -
                      </button>}
                  </div>
                </div> */}

              </div>
            ))}

            <div className="subtotal">
              <h6>Sub Total:</h6>
              <p>${getCartTotal()}</p>
              {getCartTotal() === 0 ?
                (<div className="subtotalbtn mt-4">
                  <Link to="/sales">
                    <i class="fa-solid fa-chevron-left"></i> Continue Shopping
                  </Link>
                </div>
                ) :
                <div className="subtotalbtn mt-4">
                  <Link to={UserID ? "/shipping-cart" : "/checkout"}>
                    <i class="fa-solid fa-cart-shopping"></i> Checkout
                  </Link>
                  <Link to="/sales">
                    <i class="fa-solid fa-chevron-left"></i> Continue Shopping
                  </Link>
                </div>
              }
            </div>
          </div>
        </section>

        <Footer />
      </div>
    </div>
  );
}

export default Cart;
