import React from "react";
import { Link } from "react-router-dom";
import SideBar from "../../../Components/Admin/SideBar";
import ProductImg from "../../../assets/images/product1.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronUp,
  faCircleChevronLeft,
  faListUl,
  faFilter,
  faPencil,
  faPlus,
  faTrash,
  faEye,
  faStar,
} from "@fortawesome/free-solid-svg-icons";
import { faDotCircle } from "@fortawesome/free-regular-svg-icons";
import { useState } from "react";
import { baseUrl } from "../../../assets/utils/IP";
import axios from "axios";
import { useEffect } from "react";
import { RatingDisplay } from "../../../Components/RatingDisplay";

export default function ManageReviews() {
  const [allReviews, setAllReviews] = useState()
  const [visibleReviews, setVisibleReviews] = useState(10); // Number of customers to display

  useEffect(() => {
    const fetchData = async () => {
      try {
        let config = {
          method: 'get',
          maxBodyLength: Infinity,
          url: `${baseUrl}/get-all-review`,
        };

        const response = await axios.request(config);
        setAllReviews(response.data)
      } catch (error) {
      }
    };

    fetchData();
  }, []);

  const loadMoreReviews = () => {
    // Increase the number of visible customers
    setVisibleReviews((prevVisibleReviews) => prevVisibleReviews + 10);
  };

  const displayedReviews = allReviews?.slice(0, visibleReviews);

  return (
    <section className="bg-main">
      <SideBar />
      <div className="second-main-side">
        <div className="sec-main-side-parent p-md-4 p-lg-5">
          <div className="blur-pages px-5">
            <div className="border-text">
              <div className="d-flex justify-content-between">
                <h1>MANAGE REVIEWS</h1>
              </div>
              <hr className="text-light opacity-100" />
            </div>
            <div className="main-fi-order-box-master">
              <div className="main-fi-order-box">
            <div className="grid-manage-parent-sup">
              <div className="grid-manage-parent">
                <div className="grid-manage-inner">
                  <p className="text-dark">Customer Name</p>
                </div>
                <div className="grid-manage-inner">
                  <p className="text-dark">Product Name</p>
                </div>
                <div className="grid-manage-inner">
                  <p className="text-dark">Review Title</p>
                </div>
                <div className="grid-manage-inner">
                  <p className="text-dark">Rating</p>
                </div>
                <div className="grid-manage-inner">
                  <p className="text-dark">Status</p>
                </div>
                <div className="grid-manage-inner">
                  <p className="text-dark">Action</p>
                </div>
              </div>
              {displayedReviews?.map((review, index) => (
                <div key={index} className="grid-manage-parent grid-manage-parent-rev">
                  <div className="grid-manage-inner">
                    <p>{review.user_first_name}</p>
                  </div>
                  <div className="grid-manage-inner">
                    <p>{review.product_name}</p>
                  </div>
                  <div className="grid-manage-inner">
                    <p>{review.review_message}</p>
                  </div>
                  <div className="grid-manage-inner">
                    <div className="mange-star-parent">
                      <RatingDisplay review_rating={review?.review_rating} />
                    </div>
                  </div>
                  <div className="grid-manage-inner">


                    {review.review_status == "pending" ?
                      (<p><FontAwesomeIcon color="yellow" icon={faDotCircle} /> Pending</p>)
                      : review.review_status == "Approved" ?
                        (<p><FontAwesomeIcon color="green" icon={faDotCircle} /> Approved </p>) :
                        review.review_status == "Declined" ?
                          (<p><FontAwesomeIcon color="red" icon={faDotCircle} /> Declined </p>) : <></>
                    }

                  </div>
                  <div className="grid-manage-inner">
                    <Link to={`/ReviewsEdit/${review.review_id}`}>
                      <FontAwesomeIcon icon={faEye} />
                    </Link>
                  </div>
                </div>
              ))}

            </div>
            </div>
            </div>
            <div className="d-flex justify-content-between">
              <div className="text-center mt-3">
                {visibleReviews < allReviews?.length && (
                  <button className="btn-gradient px-4 py-1 rounded border-0" onClick={loadMoreReviews}>Load More</button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}