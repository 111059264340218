import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import SideBar from "../../../Components/Admin/SideBar";
import ProductImg from "../../../assets/images/product1.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronUp,
  faCircleChevronLeft,
  faListUl,
  faFilter,
  faPencil,
  faPlus,
  faTrash,
  faEye,
  faCalendar,
  faCheck,
} from "@fortawesome/free-solid-svg-icons";
import { baseUrl } from "../../../assets/utils/IP";
import axios from "axios";
import CustomerFilter from "../../404Error/CustomFilter/CustomerFilter";
import Swal from "sweetalert2";

export default function Customer() {
  const [allCustomers, setAllCustomers] = useState()
  const [visibleCustomers, setVisibleCustomers] = useState(10); // Number of customers to display
  const [customersCopy, setCustomersCopy] = useState()



  const applyFilters = (orderId, name, Lname, brand, total, dateFrom, dateTo) => {
    let filteredCustomers = [...customersCopy]; // Make a copy of the original data

    if (name) {
      filteredCustomers = filteredCustomers.filter(
        (customer) => customer?.user_first_name?.toLowerCase().includes(name?.toLowerCase())
      );
    }
    if (Lname) {
      filteredCustomers = filteredCustomers.filter(
        (customer) => customer?.user_last_name?.toLowerCase().includes(Lname?.toLowerCase())
      );
    }
    if (brand) {
      filteredCustomers = filteredCustomers.filter(
        (customer) => customer?.brand_name?.toLowerCase().includes(brand?.toLowerCase())
      );
    }
    if (orderId) {
      filteredCustomers = filteredCustomers.filter(
        (customer) => customer?.user_email?.toLowerCase().includes(orderId?.toLowerCase())
      );
    }

    // date filter 
    if (dateFrom || dateTo) {
      // Parse dateFrom and dateTo strings to Date objects
      const fromDate = dateFrom ? new Date(dateFrom) : null;
      const toDate = dateTo ? new Date(dateTo) : null;

      filteredCustomers = filteredCustomers.filter((order) => {
        const orderDate = new Date(order?.created_at);


        const inputDate = new Date(orderDate);
        const year = inputDate.getFullYear();
        const month = (inputDate.getMonth() + 1).toString().padStart(2, '0'); // Month is 0-based
        const day = inputDate.getDate().toString().padStart(2, '0');

        const formattedDate = `${year}-${month}-${day}`;



        if (dateFrom && dateTo) {
          // Filter orders within the date range
          return formattedDate >= dateFrom && formattedDate <= dateTo;
        } else if (dateFrom) {
          // Filter orders from the fromDate
          return formattedDate >= dateFrom;
        } else if (dateTo) {
          // Filter orders up to the toDate
          return formattedDate <= dateTo;
        }

        return true; // If no date range specified, include all orders
      });
    }
    if (total) {
      filteredCustomers = filteredCustomers.filter(
        (customer) => customer?.total_amount.includes(total)
      );
    }
    // // Update the state with filtered data
    setAllCustomers(filteredCustomers);
  };


  const token = localStorage.getItem("root")
  const navigate = useNavigate()

  useEffect(() => {
    const fetchData = async () => {
      try {
        let data = "";

        let config = {
          method: "get",
          maxBodyLength: Infinity,
          url: `${baseUrl}/get-all-users`,
          headers: {
            'Authorization':
              `Bearer ${token}`,
          },
          data: data,
        };

        const response = await axios.request(config);
        setAllCustomers(response.data);
        setCustomersCopy(response.data);

      } catch (error) {
        if (error?.response?.data.message == "Invalid token") {
          Swal.fire({
            icon: "success",
            title: "Add to Cart",
            text: "Your Product is Added to Cart",
            showCancelButton: true,
            confirmButtonText: "OK",
            customClass: {
              container: "customstyle",
            },
          }).then((result) => {
            if (result.isConfirmed) {
              localStorage.removeItem("root")
              navigate("/Login")
              window.scrollTo(0, 0)
              console.log("Ok button clicked");
            } else if (result.dismiss === Swal.DismissReason.cancel) {
              // Handle Back button click
            }
          });
        }
      }
    };

    fetchData();
  }, []);

  const loadMoreCustomers = () => {
    // Increase the number of visible customers
    setVisibleCustomers((prevVisibleCustomers) => prevVisibleCustomers + 10);
  };

  const displayedCustomers = allCustomers?.reverse().slice(0, visibleCustomers);

  return (
    <section className="bg-main">
      <SideBar />
      <div className="second-main-side">
        <div className="sec-main-side-parent p-md-4 p-lg-5">
          <div className="blur-pages px-5">
            <div className="border-text">
              <div className="d-flex justify-content-between">
                <h1>Customer</h1>
                <div className="boxs-flex"></div>
              </div>
              <hr className="text-light opacity-100" />
            </div>

            <div className="row pd-customer-row">
              <div className="col-lg-8 border border-2 rounded-5 px-5 py-3">
                <div className="add-categorie-list">
                  <FontAwesomeIcon color="#fff" icon={faListUl} />
                  <span className="mx-2">Customers List</span>
                </div>
                <div className="main-fi-order-box-master">
              <div className="main-fi-order-box">
                <div className="grid-sales-parent grid-customer-parent py-4">
                  <div className="grid-sales grid-customer text-dark">
                    <div className="inner-grid-sales">
                      <p>Customer Name</p>
                    </div>
                    <div className="inner-grid-sales text-start ">
                      <p className="justify-content-start">Email</p>
                    </div>
                    <div className="inner-grid-sales">
                      <p>Contact #</p>
                    </div>
                    <div className="inner-grid-sales">
                      <p>Status</p>
                    </div>
                    <div className="inner-grid-sales">
                      <p>Date Added</p>
                    </div>
                    <div className="inner-grid-sales">
                      <p>Action</p>
                    </div>
                  </div>

                  {displayedCustomers?.map((customer, index) => (
                    <div key={index} className="grid-sales grid-customer grid-sales-two border border-1 text-light">
                      <div className="inner-grid-sales">
                        <p>{customer.user_first_name}<span> </span>{customer.user_last_name}</p>
                      </div>
                      <div className="inner-grid-sales">
                        <p className="justify-content-start">
                          {customer.user_email}
                        </p>
                      </div>
                      <div className="inner-grid-sales">
                        <p>{customer.phone_number}</p>
                      </div>
                      <div className="inner-grid-sales">
                        <p>{customer.user_status === 0 ? "Disable" : "Enable"}</p>
                      </div>
                      <div className="inner-grid-sales">
                        <p>{customer.created_at.substring(0, 10)}</p>
                      </div>
                      <div className="inner-grid-sales">
                        <p>
                          <Link to={`/CustomerEdit/${customer.user_id}`}>
                            <FontAwesomeIcon icon={faEye} />
                          </Link>
                        </p>
                      </div>
                    </div>
                  ))}


                  <div className="text-center mt-3">
                    {visibleCustomers < allCustomers?.length && (
                      <button className="btn-gradient px-4 py-1 rounded border-0" onClick={loadMoreCustomers}>Load More</button>
                    )}
                  </div>
                  {/* <div className="grid-sales grid-customer grid-sales-two border border-1 text-light">
                    <div className="inner-grid-sales">
                      <p>Mark Allen</p>
                    </div>
                    <div className="inner-grid-sales">
                      <p className="justify-content-start">
                        mark_allen@gmail.com
                      </p>
                    </div>
                    <div className="inner-grid-sales">
                      <p>123 456 7890</p>
                    </div>
                    <div className="inner-grid-sales">
                      <p>Disabled</p>
                    </div>
                    <div className="inner-grid-sales">
                      <p>20/12/2023</p>
                    </div>
                    <div className="inner-grid-sales">
                      <p>
                        <Link to="/CustomerEdit">
                          <FontAwesomeIcon icon={faEye} />
                        </Link>
                      </p>
                    </div>
                  </div>
                  <div className="grid-sales grid-customer grid-sales-two border border-1 text-light">
                    <div className="inner-grid-sales">
                      <p>Mark Allen</p>
                    </div>
                    <div className="inner-grid-sales">
                      <p className="justify-content-start">
                        mark_allen@gmail.com
                      </p>
                    </div>
                    <div className="inner-grid-sales">
                      <p>123 456 7890</p>
                    </div>
                    <div className="inner-grid-sales">
                      <p>Enabled</p>
                    </div>
                    <div className="inner-grid-sales">
                      <p>20/12/2023</p>
                    </div>
                    <div className="inner-grid-sales">
                      <p>
                        <Link to="/CustomerEdit">
                          <FontAwesomeIcon icon={faEye} />
                        </Link>
                      </p>
                    </div>
                  </div>
                  <div className="grid-sales grid-customer grid-sales-two border border-1 text-light">
                    <div className="inner-grid-sales">
                      <p>Mark Allen</p>
                    </div>
                    <div className="inner-grid-sales">
                      <p className="justify-content-start">
                        mark_allen@gmail.com
                      </p>
                    </div>
                    <div className="inner-grid-sales">
                      <p>123 456 7890</p>
                    </div>
                    <div className="inner-grid-sales">
                      <p>Enabled</p>
                    </div>
                    <div className="inner-grid-sales">
                      <p>20/12/2023</p>
                    </div>
                    <div className="inner-grid-sales">
                      <p>
                        <Link to="/CustomerEdit">
                          <FontAwesomeIcon icon={faEye} />
                        </Link>
                      </p>
                    </div>
                  </div>
                  <div className="grid-sales grid-customer grid-sales-two border border-1 text-light">
                    <div className="inner-grid-sales">
                      <p>Mark Allen</p>
                    </div>
                    <div className="inner-grid-sales">
                      <p className="justify-content-start">
                        mark_allen@gmail.com
                      </p>
                    </div>
                    <div className="inner-grid-sales">
                      <p>123 456 7890</p>
                    </div>
                    <div className="inner-grid-sales">
                      <p>Disabled</p>
                    </div>
                    <div className="inner-grid-sales">
                      <p>20/12/2023</p>
                    </div>
                    <div className="inner-grid-sales">
                      <p>
                        <Link to="/CustomerEdit">
                          <FontAwesomeIcon icon={faEye} />
                        </Link>
                      </p>
                    </div>
                  </div>
                  <div className="grid-sales grid-customer grid-sales-two border border-1 text-light">
                    <div className="inner-grid-sales">
                      <p>Mark Allen</p>
                    </div>
                    <div className="inner-grid-sales">
                      <p className="justify-content-start">
                        mark_allen@gmail.com
                      </p>
                    </div>
                    <div className="inner-grid-sales">
                      <p>123 456 7890</p>
                    </div>
                    <div className="inner-grid-sales">
                      <p>Enabled</p>
                    </div>
                    <div className="inner-grid-sales">
                      <p>20/12/2023</p>
                    </div>
                    <div className="inner-grid-sales">
                      <p>
                        <Link to="/CustomerEdit">
                          <FontAwesomeIcon icon={faEye} />
                        </Link>
                      </p>
                    </div>
                  </div>
                  <div className="grid-sales grid-customer grid-sales-two border border-1 text-light">
                    <div className="inner-grid-sales">
                      <p>Mark Allen</p>
                    </div>
                    <div className="inner-grid-sales">
                      <p className="justify-content-start">
                        mark_allen@gmail.com
                      </p>
                    </div>
                    <div className="inner-grid-sales">
                      <p>123 456 7890</p>
                    </div>
                    <div className="inner-grid-sales">
                      <p>Disabled</p>
                    </div>
                    <div className="inner-grid-sales">
                      <p>20/12/2023</p>
                    </div>
                    <div className="inner-grid-sales">
                      <p>
                        <Link to="/CustomerEdit">
                          <FontAwesomeIcon icon={faEye} />
                        </Link>
                      </p>
                    </div>
                  </div> */}
                </div>
                </div>
                </div>
                {/* <div className="d-flex justify-content-between">
                  <div className="pagi"><img src={Pagi} alt="" /></div>
                  <div className="txt-right-pagi text-light">
                    <p>Showing 1 to 10 of 90 (9 Pages)</p>
                  </div>
                </div> */}
              </div>
              <CustomerFilter onFilter={applyFilters} RemoveOrderId="orderid" RemoveTotal1="total" reset="none" filter="none" lastName="last name" customer="customer" />

            </div>
          </div>
        </div>
      </div>

    </section>
  );
}
