import React from "react";
import promotionimg from "../assets/images/promotionimg.png";
import promotionpro1 from "../assets/images/promotionpro1.png";
import flower from "../assets/images/flower.png";
import smokepromotionspan from "../assets/images/smoke-promotion-span.png";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import smoke1 from "../assets/images/smoke1.png";
import { useState } from "react";
import { useEffect } from "react";
import axios from "axios";
import { baseUrl } from "../assets/utils/IP";
import { Link } from "react-router-dom";

import TrunctedText from "./TrunctedText";

function Promotions() {
  const [promotions, setPromotions] = useState();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${baseUrl}/get-all-promoted-products`
        );
        setPromotions(response.data.results);
      } catch (error) {}
    };

    fetchData();
  }, []);

  console.log(promotions, "promotions ");

  const settings = {
    dots: false,
    infinite: true,
    speed: 1200,
    slidesToShow: 1,
    responsive: [
      {
        breakpoint: 999,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
    slidesToScroll: 1,
    autoplay: true, // Enable auto sliding
    autoplaySpeed: 19000, // Set the time interval in milliseconds (e.g., 2000ms = 2 seconds)
  };
  return (
    <section className="sec-Promotions bg-black tab-ovf-x-y-hide">
      <span className="seccoverline"></span>

      <div className="container-1640 z-10">
        <h1 className="title-mean">
          <span>OUR</span> Promotions
        </h1>

        <div className="row">
          <div className="col-xxl-6 col-xl-5 col-lg-5 d-flex align-items-center">
            <img
              alt=""
              src={promotionimg}
              className="w-100 promotion-img-tab-resp"
            />
            {/* <div className="relative">
              <h3 className="specialOfferTxt">Special <span>Offer</span></h3>
              <h2 className=""></h2>
              <h3 className=""></h3>
            </div> */}
          </div>
          <div className="col-xxl-6 col-xl-7 col-lg-7 p-0">
            <Slider {...settings}>
              {promotions?.map((item, index) => (
                <div>
                  {/* <span> <img alt="" src={smokepromotionspan} /></span> */}
                  <div key={index} className="proslider">
                    <img
                      alt=""
                      src={`${baseUrl}/${item.main_image}`}
                      className="pro-slider-img"
                    />
                    <div class="per-off promotionPeroff">
                      {" "}
                      {parseInt(item.discount_percentage)}%<sub>&nbsp; Off</sub>
                      {/* {parseInt(product.discount_percentage)}%<sub>Off</sub> */}
                    </div>
                    <div className="procontent justify-content-center">
                      <img alt="" src={flower} />
                      <h1>{item.product_name}</h1>
                      <TrunctedText text={item.description_1} maxLength={53} />
                      {/* <p>{item.description_1}</p> */}
                      
                        <h6 className="mb-0 mt-4">${item.sale_price}</h6>
                        <span>incl. GST</span>
                        <Link
                          to={`/Buynow/${item.product_id}`}
                          className="probtn px-3 py-1"
                        >
                          View Product
                        </Link>
                    </div>
                  </div>
                </div>
              ))}
            </Slider>
            <div className="d-flex justify-content-center custom-margin-promotion-btn">
              <Link to="/Promotions" className="pro-btn-dec px-3 py-1">
                View More
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div class="smoke-section-parent">
        <div class="ag-smoke-block">
          <div>
            <img class="ag-smoke-1" src={smoke1} alt="Smoke" />
            <img class="ag-smoke-2" src={smoke1} alt="Smoke" />
            <img class="ag-smoke-3" src={smoke1} alt="Smoke" />
            <img class="ag-smoke-4" src={smoke1} alt="Smoke" />
          </div>
        </div>
      </div>
    </section>
  );
}

export default Promotions;
