import { createContext, useState, useEffect } from "react";

export const CartContext = createContext();

export const CartProvider = ({ children }) => {
  const [cartItems, setCartItems] = useState(
    localStorage.getItem("cartItems")
      ? JSON.parse(localStorage.getItem("cartItems"))
      : []
  );
  const [cartItemsCopy, setCartItemsCopy] = useState(
    localStorage.getItem("cartItems")
      ? JSON.parse(localStorage.getItem("cartItems"))
      : []
  );
  const [totalQuantity, setTotalQuantity] = useState(0);
  const [totalQuantityCopy, setTotalQuantityCopy] = useState(0);


  const addToCart = (item) => {
    const isItemInCart = cartItems.find((cartItem) => cartItem.product_id === item.product_id);
    if (!isItemInCart) {
      setCartItems([...cartItems, { ...item, p_quantity: 1 }]);
      setCartItemsCopy([...cartItems, { ...item, p_quantity: 1 }]);
    } else {

    }
    setTotalQuantity(totalQuantity + 1);
    setTotalQuantityCopy(totalQuantity + 1);
  };

  const incrementQuantity = (item) => {
    setCartItems(
      cartItems.map((cartItem) =>
        cartItem.product_id === item.product_id
          ? { ...cartItem, p_quantity: cartItem.p_quantity + 1 }
          : cartItem
      )
    );
    setCartItemsCopy(
      cartItems.map((cartItem) =>
        cartItem.product_id === item.product_id
          ? { ...cartItem, p_quantity: cartItem.p_quantity + 1 }
          : cartItem
      )
    );
    setTotalQuantity(totalQuantity + 1);
    setTotalQuantityCopy(totalQuantity + 1);
  };










  const decrementQuantity = (item) => {
    const updatedCartItems = cartItems.map((cartItem) =>
      cartItem.product_id === item.product_id
        ? { ...cartItem, p_quantity: cartItem.p_quantity - 1 }
        : cartItem
    );

    const filteredCartItems = updatedCartItems.filter(
      (cartItem) => cartItem.p_quantity > 0
    );

    setTotalQuantity(totalQuantity - 1);
    setTotalQuantityCopy(totalQuantity - 1);
    setCartItemsCopy(filteredCartItems);
    setCartItems(filteredCartItems);
  };

  const updateQuantity = (item, addtocartqty) => {
    const qtyDifference = addtocartqty - item.p_quantity;
    const itemInCart = cartItems.find((cartItem) => cartItem.product_id === item.product_id);

    if (itemInCart) {
      const items = cartItems.map((cartItem) => {
        if (cartItem.product_id === item.product_id) {
          return { ...cartItem, p_quantity: addtocartqty };
        }
        return cartItem;
      })
      setCartItems(items);
      setCartItemsCopy(items);

    } else {
      const items = [...cartItems, { ...item, p_quantity: addtocartqty }]
      setCartItems(items);
      setCartItemsCopy(items);
    }
    setTotalQuantity(totalQuantity + qtyDifference);
    setTotalQuantityCopy(totalQuantity + qtyDifference);
  };


  const updateQuantityArr = (arr) => {
    let cartsArr = [...cartItems]

    arr.forEach(item => {
      const itemInCart = cartsArr.find((cartItem) => cartItem.product_id === item.product_id);

      if (itemInCart) {
        cartsArr = cartsArr.map((cartItem) => {
          if (cartItem.product_id === item.product_id) {
            return { ...cartItem, p_quantity: item.p_quantity };
          }
          return cartItem;
        })
      } else {
        cartsArr.push(item)
      }
    })

    setCartItems(cartsArr)
    setCartItemsCopy(cartsArr)
  };

  const CancelProduct = (item) => {
    const updatedCartItems = cartItems.filter(
      (cartItem) => cartItem.product_id !== item.product_id
    );
    const newTotalQuantity = totalQuantity - item.p_quantity;

    setCartItems(updatedCartItems);
    setCartItemsCopy(updatedCartItems);

    setTotalQuantity(newTotalQuantity);
    setTotalQuantityCopy(newTotalQuantity);
  };


  const removeFromCart = (item) => {
    const isItemInCart = cartItems.find((cartItem) => cartItem.product_id === item.product_id);

    if (isItemInCart.p_quantity !== 0) {
      setCartItems(
        cartItems.map((cartItem) =>
          cartItem.product_id === item.product_id
            ? { ...cartItem, p_quantity: cartItem.p_quantity - 1 }
            : cartItem
        )
      );
      setCartItemsCopy(
        cartItems.map((cartItem) =>
          cartItem.product_id === item.product_id
            ? { ...cartItem, p_quantity: cartItem.p_quantity - 1 }
            : cartItem
        )
      );
      setTotalQuantity(totalQuantity - 1);
      setTotalQuantityCopy(totalQuantity - 1);

    } else {

    }
  };


  const getProductById = (productId) => {
    const product = cartItems.find((item) => item.product_id === productId);
    return product || null;
  };

  const getCartTotal = () => {
    const value = cartItems.reduce(
      (total, item) => total + (item.sale_price == 0 ? item.regular_price : item.sale_price) * item.p_quantity,
      0
    );
    return value;
  };
  const getCartTotalCopy = () => {
    const value = cartItemsCopy.reduce(
      (total, item) => total + (item.sale_price == 0 ? item.regular_price : item.sale_price) * item.p_quantity,
      0
    );
    return value;
  };
  useEffect(() => {
    localStorage.setItem("cartItems", JSON.stringify(cartItems), (totalQuantity));

  }, [cartItems]);

  useEffect(() => {
    const cartItems = localStorage.getItem("cartItems");
    if (cartItems) {
      setCartItems(JSON.parse(cartItems));
      setCartItemsCopy(JSON.parse(cartItems))
    }
  }, []);

  useEffect(() => {
    const newTotalQuantity = cartItems.reduce(
      (total, item) => total + item.p_quantity,
      0
    );
    setTotalQuantity(newTotalQuantity);
    setTotalQuantityCopy(newTotalQuantity);
  }, [cartItems]);

  const clearCart = () => {
    setCartItems([]);
    setTotalQuantity(0);
  };



  return (
    <CartContext.Provider
      value={{
        cartItems,
        cartItemsCopy,
        addToCart,
        removeFromCart,
        totalQuantity,
        getCartTotalCopy,
        getProductById,
        getCartTotal,
        clearCart,
        decrementQuantity,
        updateQuantity,
        updateQuantityArr,
        CancelProduct,
        incrementQuantity,
      }}
    >
      {children}
    </CartContext.Provider>
  );
};