import React from "react";
import smoke1 from "../assets/images/smoke1.png";
import Vidone from "../assets/video/btmsec-vid-one.mp4";
import Vidtwo from "../assets/video/btmsec-vid-two.mp4";
// import lighter1 from "../assets/images/lighter1.png";
// import lighter2 from "../assets/images/lighter2.png";
// import { Link } from "react-router-dom";
function Lighter() {
  return (
    <section className="sec-Promotions Lighter sec-lighter-resp bg-black tab-ovf-x-y-hide">
      <span className="seccoverline"></span>

      <div className="container-1640 z-10">
        <div className="row lighter">
          <div className="col-lg-6 lighter-tab-resp-col col-12 p-0">
          <div className="parent-vide-one parent-vide d-lg-block d-flex align-items-end justify-content-end">
          <video className="vid-one" src={Vidone} muted loop autoPlay playsInline></video>
          </div>
            {/* <Link
              to="https://www.youtube.com/watch?v=zjtegz4JwMY"
              target="_blank"
            >
              <img
                src={lighter1}
                alt=""
                className="w-100 tab-resp-lighter-home-img"
              />
            </Link> */}
          </div>
          <div className="col-lg-6 lighter-tab-resp-col col-12 p-0">
          <div className="parent-vide-two parent-vide">
          <video className="vid-two" src={Vidtwo} muted loop autoPlay playsInline></video>
          </div>
            {/* <Link
              to="https://www.youtube.com/watch?v=zjtegz4JwMY"
              target="_blank"
            >
              {" "}
              <img
                src={lighter2}
                alt=""
                className="w-100 tab-resp-lighter-home-img"
              />
            </Link> */}
          </div>
        </div>
      </div>
      <div class="smoke-section-parent">
        <div class="ag-smoke-block">
          <div>
            <img class="ag-smoke-1" src={smoke1} alt="Smoke" />
            <img class="ag-smoke-2" src={smoke1} alt="Smoke" />
            <img class="ag-smoke-3" src={smoke1} alt="Smoke" />
            <img class="ag-smoke-4" src={smoke1} alt="Smoke" />
          </div>
        </div>
      </div>
    </section>
  );
}

export default Lighter;
