import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCartShopping,
  faChevronDown,
  faHeart,
  faSearch,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import bannersmoke from "../assets/video/bannersmoke.mp4";
import bannerpro from "../assets/images/bannerpro.png";
import Headerlogotab from "../assets/images/headerlogo.png";
import { Link } from "react-router-dom";
function Banner() {
  const [isChecked, setIsChecked] = useState(false);

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };
  return (
    <>
      <div className="top-banner w-100 ovf-X-hidden">
        <div className="war-policies">
          <div className="warning">
            <p>
              WARNING: This product contains nicotine. Nicotine is an addictive
              chemical.
            </p>
            <p className="d-md-block d-none">|</p>
            <p className="d-md-block d-none">This week's promotion </p>
            <p className="d-md-block d-none">|</p>
            <p className="d-md-block d-none">50% Off on Cohiba Cigars</p>
          </div>

          <div className="Refundpolicy tablet-hidden">
            <ul>
              <li>
                {" "}
                <Link to="/Refund">Refund Policy</Link>
              </li>
              <li>
                {" "}
                <span>|</span>{" "}
                <Link to="/privacy">Security and Cookie Policy</Link>
              </li>
              <li>
                {" "}
                <Link to="/Privacy-Policy">
                  <span>|</span> Privacy Policy
                </Link>
              </li>
              <li>
                {" "}
                <Link to="/Delivery">
                  <span>|</span> Same Day Delivery
                </Link>
              </li>
              <li>
                {" "}
                <Link to="/How-Order">
                  <span>|</span>How To Order{" "}
                </Link>
              </li>
              <li>
                {" "}
                <Link to="/Delivery-Time">
                  <span>|</span>Delivery Times{" "}
                </Link>
              </li>
              <li>
                {" "}
                <Link to="/Track-Order">
                  <span>|</span> Track Your Order
                </Link>
              </li>
              <li>
                {" "}
                <Link to="/Product-Availability">
                  <span>|</span> Product Availability{" "}
                </Link>
              </li>
              <li>
                {" "}
                <Link to="/FAQ">
                  <span>|</span> FAQ
                </Link>
              </li>
              <li>
                {" "}
                <Link to="/TermsCondition">
                  <span>|</span> Terms And Conditions Of Use
                </Link>
              </li>
            </ul>
          </div>

          {/* <div className="tab-grid-header-parent d-lg-none d-grid">
            <div className="hamburger">
              <div className="header-left d-lg-none d-block">
                <label className="hamburger-icon">
                  <input type="checkbox" className="hamburger-checkbox" />
                  <span />
                  <span />
                  <span />
                </label>
              </div>
            </div>
            <div className="tab-logo text-center">
              <Link to="/">
                <img src={Headerlogotab} alt="" />
              </Link>
            </div>
            <div className="right-drp-down-header-tab position-relative w-max mx-auto">
              <label>
                <input
                  className="d-none"
                  type="checkbox"
                  checked={isChecked}
                  onChange={handleCheckboxChange}
                />
                <FontAwesomeIcon
                  className="text-white chevron-header"
                  icon={faChevronDown}
                  style={{
                    transform: isChecked ? "rotate(180deg)" : "rotate(0deg)",
                  }}
                />
              </label>

              {isChecked && (
                <div className="position-absolute d-flex flex-column align-items-center tab-header-righ-arrow-open">
                  <Link>
                    <FontAwesomeIcon
                      className="text-white chevron-header"
                      icon={faHeart}
                    />
                  </Link>
                  <Link>
                    <FontAwesomeIcon
                      className="text-white chevron-header"
                      icon={faUser}
                    />
                  </Link>
                  <Link className="position-relative" to="/cart">
                    <FontAwesomeIcon
                      className="text-white chevron-header"
                      icon={faCartShopping}
                    />
                  </Link>
                  <Link>
                    <FontAwesomeIcon
                      className="text-white chevron-header"
                      icon={faSearch}
                    />
                  </Link>
                </div>
              )}
            </div>
          </div> */}
        </div>

        <section className="bannersec homebannerresp ovf-X-hidden">
          <div class="bg-video-wrap">
            <video src={bannersmoke} muted loop autoPlay playsInline></video>
            <div class="overlay"></div>
            <div className="row">
              <div className="col-lg-6 m-auto">
                <h1>
                  Smoother <br className="d-md-none d-block" /> than ever
                </h1>
                <h4>
                  Engineered with <br className="d-md-none d-block" /> accuracy
                </h4>
              </div>
              <div className="col-lg-6 banner-img-center-tab-resp">
                <img src={bannerpro} alt="" />
              </div>
            </div>
          </div>
        </section>
      </div>

      <div className="spacer"></div>
    </>
  );
}

export default Banner;
