import React from "react";

import Footer from "../Components/Footer/Footer";
import Header from "../Components/Header/Header";
import CartCancelImg from "../assets/images/cart-cancel.png"
import Warning from "../Components/Warning";
import { Link } from "react-router-dom";
import buyleftspan from "../assets/images/buyleftspan.png";
import buyrightspan from "../assets/images/buyrightspan.png";
import cohibalighter from "../assets/images/cohibalighter.png";
import { useContext } from "react";
import { CartContext } from "../Components/Cart";
import { useEffect } from "react";
import { useState } from "react";
import Swal from "sweetalert2";
import axios from "axios";
import { baseUrl } from "../assets/utils/IP";

function WishList() {
    const [wishList, setWishList] = useState()



    const UserID = localStorage.getItem("userId")

    useEffect(() => {
        getData()
    }, [UserID]);

    const getData = () => {
        let config = {
            method: 'get',
            maxBodyLength: Infinity,
            url: `${baseUrl}/get-wishlist-byId/${UserID}`,
            headers: {}
        };

        // Make the Axios request within the useEffect
        axios.request(config)
            .then((response) => {
                setWishList(response.data)
                // You can handle the response data here.
            })
            .catch((error) => {
                Swal.fire({
                    icon: "error",
                    title: "something went wrong",
                    text: "Product not Remove",
                });
            });
    }

    const handleRemoveWL = (id) => {
        const config = {
            method: 'delete',
            maxBodyLength: Infinity,
            url: `${baseUrl}/delete-wishlist/${id} `,
            headers: {}
        };

        axios.request(config)
            .then((response) => {
                Swal.fire({
                    icon: "success",
                    title: "Product Remove from Wish List",
                })
                getData()
            })
            .catch((error) => {
                Swal.fire({
                    icon: "error",
                    title: "something went wrong",
                    text: "Product not Remove",
                });
            });
    };

    return (
        <div className="font-bebas">
            <Warning />

            <Header />
            <div className="bef-aft">
                <div className="container-1640">
                    <div className="toplinecon">
                        <div className="hamicons">
                            <ul>
                                <li>
                                    <Link>Home</Link>
                                </li>
                                <li>
                                    <Link>
                                        {" "}
                                        <i class="fa-solid fa-chevron-right"></i>{" "}
                                    </Link>
                                </li>
                                <li>
                                    <Link>Lighters</Link>
                                </li>
                                <li>
                                    <Link>
                                        {" "}
                                        <i class="fa-solid fa-chevron-right"></i>{" "}
                                    </Link>
                                </li>
                                <li>
                                    <Link>Product Name</Link>
                                </li>
                                <li>
                                    <Link>
                                        {" "}
                                        <i class="fa-solid fa-chevron-right"></i>{" "}
                                    </Link>
                                </li>
                                <li>
                                    <Link>Shopping Cart</Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>

                <section className="cartsection1">
                    <span className="buyleftspan">
                        {" "}
                        <img src={buyleftspan} />
                    </span>
                    <span className="buyrightspan">
                        {" "}
                        <img src={buyrightspan} />
                    </span>
                    <div className="container-1640">
                        <h1 className="carttitle">
                            <i class="fa-solid fa-cart-shopping"></i>Your Wish List Products
                        </h1>

                        {wishList?.map((item, index) => (
                            <div key={index} className="cartdiscription mb-3">
                                <div className="cartProd">
                                    <img src={`${baseUrl}/${item.main_image}`} alt={`Product ${index}`} />
                                </div>
                                <div className="prodetailcart">
                                    <h4>{item.product_name}</h4>
                                    <p className="font-bebas">{item.description_1}</p>
                                    <p>${item.sale_price == 0 ? item.regular_price : item.sale_price}</p>
                                </div>
                                <img src={CartCancelImg} style={{ position: "absolute", right: "20px" }} onClick={() => handleRemoveWL(item.product_id)} />

                            </div>
                        ))}

                        <div className="subtotal">
                            <div className="subtotalbtn mt-4">
                                <Link to="/sales">
                                    <i class="fa-solid fa-chevron-left"></i> Continue Shopping
                                </Link>
                            </div>
                        </div>
                    </div>
                </section>

                <Footer />
            </div>
        </div>
    );
}

export default WishList;
