import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar } from '@fortawesome/free-solid-svg-icons';

export default function Rating(props) {
    const [rating, setRating] = useState(props.rating || null);
    const [tempRating, setTempRating] = useState(null);
    const [selectedStars, setSelectedStars] = useState(null);

    useEffect(() => {
        setRating(props.rating || null);
        setTempRating(props.rating || null);
        setSelectedStars(props.rating || null);
    }, [props.rating]);

    const rate = (newRating) => {
        setRating(newRating);
        setTempRating(newRating);
        setSelectedStars(newRating);
    };

    const starOver = (newRating) => {
        setTempRating(rating);
        setRating(newRating);
    };

    const starOut = () => {
        setRating(tempRating);
    };

    useEffect(() => {
        if (props.onStarsSelected) {
            props.onStarsSelected(selectedStars);
        }
    }, [selectedStars, props]);

    // Determine the total number of stars based on reviewPercentage?.final_rating
    const totalStars = Math.ceil(props.rating || 5);

    const stars = [];
    for (let i = 0; i < totalStars; i++) {
        let klass = 'star-rating__star';
        if (rating >= i && rating !== null) {
            klass += ' is-selected';
        }

        stars.push(
            <label
                key={i}
                className={klass}
                onClick={() => rate(i)}
            >
                <FontAwesomeIcon icon={faStar} />
            </label>
        );
    }

    return (
        <div className="star-rating">
            {stars}
        </div>
    );
}
