import React, { useEffect } from 'react'
import { useState } from 'react';
import { Link, Navigate, useNavigate, useParams } from "react-router-dom";
import filter from "../assets/images/filtericon.png";
import edit from "../assets/images/edit.png";
import kl from "../assets/images/kl.png";
import b from "../assets/images/b.png";
import like from "../assets/images/like.png";
import unlike from "../assets/images/unlike.png";
import axios from 'axios';
import { baseUrl } from '../assets/utils/IP';
import { RatingDisplay } from '../Components/RatingDisplay';
import Swal from 'sweetalert2';

function ReviewComponent() {
    const [isStarBoxVisible, setIsStarBoxVisible] = useState(true);
    const [state1, setState1] = useState("");
    const [state2, setState2] = useState("");
    const [state3, setState3] = useState("");
    const [state4, setState4] = useState("");
    const [state5, setState5] = useState("");
    const [review, setReview] = useState([]);

    const handleToggleClick = () => {
        setIsStarBoxVisible(isStarBoxVisible);
        setState1(true);
        setState2(true);
        setState3(true);
        setState4(true);
        setState5(true);
    };

    useEffect(() => {
        setState1(true);
        setState2(true);
        setState3(true);
        setState4(true);
        setState5(true);
    }, [])


    const { id } = useParams()

    const handleClick = (val) => {
        setState1("");
        setState2("");
        setState3("");
        setState4("");
        setState5("");
        if (val === 1) {
            setState1("highlight");
        } else if (val === 2) {
            setState2("highlight");
        } else if (val === 3) {
            setState3("highlight");
        } else if (val === 4) {
            setState4("highlight");
        } else if (val === 5) {
            setState5("highlight");
        }
    };

    const handleResetFilter = () => {
        setState1("");
        setState2("");
        setState3("");
        setState4("");
        setState5("");
    }
    const navigate = useNavigate()
    const RestrictForReview = () => {
        navigate("/Login")
    }


    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`${baseUrl}/get-review-by-product/${id}`);
                setReview(response?.data)
            } catch (error) {
            }
        };

        fetchData();
    }, [id]);

    function getTimeAgo(targetTime) {
        const originalTime = new Date(targetTime);
        const newTimeCopy = new Date(originalTime.getTime() + 5 * 60 * 60 * 1000);
        const newTime = newTimeCopy.toISOString()

        const targetDate = new Date(newTime); // Parse the target time string to a Date object
        const currentDate = new Date(); // Get the current time as a Date object

        const timeDifferenceMs = currentDate - targetDate; // Calculate the time difference in milliseconds

        const secondsAgo = Math.floor(timeDifferenceMs / 1000); // Convert milliseconds to seconds
        const minutesAgo = Math.floor(secondsAgo / 60); // Convert seconds to minutes
        const hoursAgo = Math.floor(minutesAgo / 60); // Convert minutes to hours
        const daysAgo = Math.floor(hoursAgo / 24); // Convert hours to days

        if (daysAgo > 0) {
            return daysAgo + (daysAgo === 1 ? ' day ago' : ' days ago');
        } else if (hoursAgo > 0) {
            return hoursAgo + (hoursAgo === 1 ? ' hour ago' : ' hours ago');
        } else if (minutesAgo > 0) {
            return minutesAgo + (minutesAgo === 1 ? ' minute ago' : ' minutes ago');
        } else {
            return secondsAgo + (secondsAgo === 1 ? ' second ago' : ' seconds ago');
        }
    }

    const filterReviews = (review) => {
        if (
            (state1 && review.review_rating === 1) ||
            (state2 && review.review_rating === 2) ||
            (state3 && review.review_rating === 3) ||
            (state4 && review.review_rating === 4) ||
            (state5 && review.review_rating === 5)
        ) {
            return true;
        }
        return false;
    };

    const userid = localStorage.getItem("userId")

    const nonPendingReviewsLength = review?.filter(rev => rev.review_status !== 'pending' && rev.review_status !== 'Declined');

    return (

        <div class="reviews-descrip-parent">
            <div class="reviews-heading">
                <div class="reques">
                    <h3>Reviews ({nonPendingReviewsLength.length})</h3>

                    <h3>
                        {/* <Link
                            data-bs-toggle="modal"
                            data-bs-target="#exampleModal1"
                        >
                            Questions
                        </Link> */}
                    </h3>
                </div>
                <hr class="hl-pr" />
            </div>
            <div class="filter-and-write-parent">
                <div class="filter-parent">
                    <div class="btn-filt-pr">
                        <button className='btn' id="toggleButton" onClick={handleToggleClick}>
                            <span>
                                <img src={filter} alt="" />
                            </span>
                            Filter/Reset
                        </button>
                        <div
                            className={`star-box-parent ${isStarBoxVisible ? "flex" : "none"
                                }`}
                        >
                            <div
                                className={`star-box-inner ${state1}`}
                                onClick={() => handleClick(1)}
                            >
                                <i class="fa-solid fa-star"></i>
                            </div>
                            <div
                                className={`star-box-inner ${state2}`}
                                onClick={() => handleClick(2)}
                            >
                                <i class="fa-solid fa-star"></i>
                                <i class="fa-solid fa-star"></i>
                            </div>
                            <div
                                className={`star-box-inner ${state3}`}
                                onClick={() => handleClick(3)}
                            >
                                <i class="fa-solid fa-star"></i>
                                <i class="fa-solid fa-star"></i>
                                <i class="fa-solid fa-star"></i>
                            </div>
                            <div
                                className={`star-box-inner ${state4}`}
                                onClick={() => handleClick(4)}
                            >
                                <i class="fa-solid fa-star"></i>
                                <i class="fa-solid fa-star"></i>
                                <i class="fa-solid fa-star"></i>
                                <i class="fa-solid fa-star"></i>
                            </div>
                            <div
                                className={`star-box-inner ${state5}`}
                                onClick={() => handleClick(5)}
                            >
                                <i class="fa-solid fa-star"></i>
                                <i class="fa-solid fa-star"></i>
                                <i class="fa-solid fa-star"></i>
                                <i class="fa-solid fa-star"></i>
                                <i class="fa-solid fa-star"></i>
                            </div>
                        </div>
                    </div>
                </div>
                {userid ? (<div class="write-modal-button">

                    <button
                        data-bs-toggle="modal"
                        data-bs-target="#exampleModal"
                    >
                        <span>
                            <img src={edit} alt="" />
                        </span>
                        Write a Review
                    </button>
                </div>) : (<div class="write-modal-button">

                    <button
                        onClick={RestrictForReview}
                    >
                        <span>
                            <img src={edit} alt="" />
                        </span>
                        Write a Review
                    </button>
                </div>)}
            </div>

            <div class="review-two-head">
                <div class="review-two-head-inner">
                    <h3>{nonPendingReviewsLength.length} Reviews</h3>
                </div>
                {/* <div class="review-two-head-inner review-two-head-innerrev">
                          <h3>Sort</h3>
                          <select>
                            <option>Most Recent</option>
                            <option>Oldest</option>
                            <option>High Rated</option>
                            <option>lowest Rated</option>
                          </select>
                        </div> */}
            </div>
            <hr class="hl-pr" />
            <div>
                {review?.filter(filterReviews).filter(rev => rev.review_status !== 'pending' && rev.review_status !== 'Declined').map((rev, index) => {
                    const timeAgo = getTimeAgo(rev.created_at);

                    return (
                        <div key={index} className="review-two-inner-box-parent">
                            <div className="rv-two-left-box ">
                                <div className="rv-two-left-box-head">
                                    {/* <img src={kl} alt="" /> */}
                                    <div className='profile-Name-Display'><p style={{
                                        margin: "5px 13px",
                                        fontSize: "20px",
                                        display: "contents",
                                    }}>{rev?.user_first_name.slice(0, 1)} {rev?.user_last_name.slice(0, 1)}
                                    </p></div>
                                    <span>
                                        {rev?.user_first_name} {rev?.user_last_name}
                                    </span>
                                </div>
                                <div className="recomm-rev-box-two">
                                    <span>
                                        {rev?.review_rating >= 3
                                            ? <img src={like} alt="" />
                                            : <img style={{ width: "20px" }} src={unlike} alt="" />}

                                    </span>
                                    <span>
                                        {rev?.review_rating >= 3
                                            ? 'I recommend this product'
                                            : 'I do not recommend this product'}
                                    </span>
                                </div>
                            </div>
                            <div className="rv-two-right-box">
                                <div className="rv-two-star-and-due-parent">
                                    <div className="rv-two-star">
                                        <RatingDisplay review_rating={rev?.review_rating} />
                                    </div>
                                    <div className="due">
                                        <p>{timeAgo}</p>
                                        {/* <p>{rev.created_at}</p> */}
                                    </div>
                                </div>
                                <div className="well-head-rv-two">
                                    <h3>{rev?.review_title}</h3>
                                </div>
                                <div className="rv-two-m-para">
                                    <p>{rev?.review_message}</p>
                                </div>
                            </div>
                        </div>
                    );
                })}
            </div>
            <hr class="hl-pr" />
        </div>)
}

export default ReviewComponent