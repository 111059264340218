import React, { useEffect, useRef, useState } from "react";

import gsap from "gsap"; // Import GSAP library
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";
import arrows from "../assets/images/arrows.png";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import CircResOneImg from "../assets/images/circ-one-tab.png";
import CircResTwoImg from "../assets/images/circ-two-tab.png";
import CircResThreeImg from "../assets/images/circ-three-tab.png";
import { baseUrl } from "../assets/utils/IP";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import { useContext } from "react";
import { CartContext } from "./Cart";
import Swal from "sweetalert2";
import $ from "jquery";
import garryCircle from '../assets/images/garry-circle.png'
gsap.registerPlugin(ScrollTrigger);

function FeatureProduct() {
  const { updateQuantity, updateQuantityArr, getProductById, cartItems } =
    useContext(CartContext);

  // -------------integration (start)
  const [featuredProductA, setFeaturedProductA] = useState();
  const [show, setShow] = useState(false);
  const [featuredProduct, setFeaturedProduct] = useState();
  const [featuredProduct2, setFeaturedProduct2] = useState();
  const [featuredProduct3, setFeaturedProduct3] = useState();
  const [featuredProduct4, setFeaturedProduct4] = useState();
  const [featuredProduct5, setFeaturedProduct5] = useState();
  const [featuredProduct6, setFeaturedProduct6] = useState();
  const [featuredProduct7, setFeaturedProduct7] = useState();
  const [featuredProduct8, setFeaturedProduct8] = useState();
  const [featuredProduct9, setFeaturedProduct9] = useState();
  const [featuredProduct10, setFeaturedProduct10] = useState();
  const [featuredProduct11, setFeaturedProduct11] = useState();
  const [featuredProduct12, setFeaturedProduct12] = useState();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${baseUrl}/featured-products`);
        setFeaturedProductA(response?.data.categoryProducts);
        setFeaturedProduct(response?.data?.categoryProducts[0].products[0]);
        setFeaturedProduct2(response?.data?.categoryProducts[0].products[1]);
        setFeaturedProduct3(response?.data?.categoryProducts[0].products[2]);
        setFeaturedProduct4(response?.data?.categoryProducts[0].products[3]);
        setFeaturedProduct5(response?.data?.categoryProducts[1].products[0]);
        setFeaturedProduct6(response?.data?.categoryProducts[1].products[1]);
        setFeaturedProduct7(response?.data?.categoryProducts[1].products[2]);
        setFeaturedProduct8(response?.data?.categoryProducts[1].products[3]);
        setFeaturedProduct9(response?.data?.categoryProducts[2].products[0]);
        setFeaturedProduct10(response?.data?.categoryProducts[2].products[1]);
        setFeaturedProduct11(response?.data?.categoryProducts[2].products[2]);
        setFeaturedProduct12(response?.data?.categoryProducts[2].products[3]);
      } catch (error) {}
    };

    fetchData();
  }, []);

  const navigate = useNavigate();

  const handleAddToCart2 = () => {
    updateQuantity(featuredProduct2, 1);
    Swal.fire({
      icon: "sucess",
      title: "Buy Now",
      text: "Your product is added successfully",
      showCancelButton: true,
      confirmButtonText: "Proceed to Checkout",
      cancelButtonText: "Back",
      customClass: {
        container: "customstyle",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        navigate("/cart");
        window.scrollTo(0, 0);
        console.log("Ok button clicked");
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        // Handle Back button click
        console.log("Back button clicked");
      }
    });
  };
  const handleAddToCart3 = () => {
    updateQuantity(featuredProduct3, 1);
    Swal.fire({
      icon: "sucess",
      title: "Buy Now",
      text: "Your product is added successfully",
      showCancelButton: true,
      confirmButtonText: "Proceed to Checkout",
      cancelButtonText: "Back",
      customClass: {
        container: "customstyle",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        navigate("/cart");
        window.scrollTo(0, 0);
        console.log("Ok button clicked");
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        // Handle Back button click
        console.log("Back button clicked");
      }
    });
  };
  const handleAddToCart4 = () => {
    updateQuantity(featuredProduct4, 1);
    Swal.fire({
      icon: "sucess",
      title: "Buy Now",
      text: "Your product is added successfully",
      showCancelButton: true,
      confirmButtonText: "Proceed to Checkout",
      cancelButtonText: "Back",
      customClass: {
        container: "customstyle",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        navigate("/cart");
        window.scrollTo(0, 0);
        console.log("Ok button clicked");
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        // Handle Back button click
        console.log("Back button clicked");
      }
    });
  };
  // slide 2
  const handleAddToCart5 = () => {
    updateQuantity(featuredProduct5, 1);
    Swal.fire({
      icon: "sucess",
      title: "Buy Now",
      text: "Your product is added successfully",
      showCancelButton: true,
      confirmButtonText: "Proceed to Checkout",
      cancelButtonText: "Back",
      customClass: {
        container: "customstyle",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        navigate("/cart");
        window.scrollTo(0, 0);
        console.log("Ok button clicked");
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        // Handle Back button click
        console.log("Back button clicked");
      }
    });
  };
  const handleAddToCart6 = () => {
    updateQuantity(featuredProduct6, 1);
    Swal.fire({
      icon: "sucess",
      title: "Buy Now",
      text: "Your product is added successfully",
      showCancelButton: true,
      confirmButtonText: "Proceed to Checkout",
      cancelButtonText: "Back",
      customClass: {
        container: "customstyle",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        navigate("/cart");
        window.scrollTo(0, 0);
        console.log("Ok button clicked");
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        // Handle Back button click
        console.log("Back button clicked");
      }
    });
  };
  const handleAddToCart7 = () => {
    updateQuantity(featuredProduct7, 1);
    Swal.fire({
      icon: "sucess",
      title: "Buy Now",
      text: "Your product is added successfully",
      showCancelButton: true,
      confirmButtonText: "Proceed to Checkout",
      cancelButtonText: "Back",
      customClass: {
        container: "customstyle",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        navigate("/cart");
        window.scrollTo(0, 0);
        console.log("Ok button clicked");
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        // Handle Back button click
        console.log("Back button clicked");
      }
    });
  };
  const handleAddToCart8 = () => {
    updateQuantity(featuredProduct8, 1);
    Swal.fire({
      icon: "sucess",
      title: "Buy Now",
      text: "Your product is added successfully",
      showCancelButton: true,
      confirmButtonText: "Proceed to Checkout",
      cancelButtonText: "Back",
      customClass: {
        container: "customstyle",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        navigate("/cart");
        window.scrollTo(0, 0);
        console.log("Ok button clicked");
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        // Handle Back button click
        console.log("Back button clicked");
      }
    });
  };
  const handleAddToCart9 = () => {
    updateQuantity(featuredProduct9, 1);
    Swal.fire({
      icon: "sucess",
      title: "Buy Now",
      text: "Your product is added successfully",
      showCancelButton: true,
      confirmButtonText: "Proceed to Checkout",
      cancelButtonText: "Back",
      customClass: {
        container: "customstyle",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        navigate("/cart");
        window.scrollTo(0, 0);
        console.log("Ok button clicked");
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        // Handle Back button click
        console.log("Back button clicked");
      }
    });
  };
  const handleAddToCart10 = () => {
    updateQuantity(featuredProduct10, 1);
    Swal.fire({
      icon: "sucess",
      title: "Buy Now",
      text: "Your product is added successfully",
      showCancelButton: true,
      confirmButtonText: "Proceed to Checkout",
      cancelButtonText: "Back",
      customClass: {
        container: "customstyle",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        navigate("/cart");
        window.scrollTo(0, 0);
        console.log("Ok button clicked");
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        // Handle Back button click
        console.log("Back button clicked");
      }
    });
  };
  const handleAddToCart11 = () => {
    updateQuantity(featuredProduct11, 1);
    Swal.fire({
      icon: "sucess",
      title: "Buy Now",
      text: "Your product is added successfully",
      showCancelButton: true,
      confirmButtonText: "Proceed to Checkout",
      cancelButtonText: "Back",
      customClass: {
        container: "customstyle",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        navigate("/cart");
        window.scrollTo(0, 0);
        console.log("Ok button clicked");
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        // Handle Back button click
        console.log("Back button clicked");
      }
    });
  };
  const handleAddToCart12 = () => {
    updateQuantity(featuredProduct12, 1);
    Swal.fire({
      icon: "sucess",
      title: "Buy Now",
      text: "Your product is added successfully",
      showCancelButton: true,
      confirmButtonText: "Proceed to Checkout",
      cancelButtonText: "Back",
      customClass: {
        container: "customstyle",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        navigate("/cart");
        window.scrollTo(0, 0);
        console.log("Ok button clicked");
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        // Handle Back button click
        console.log("Back button clicked");
      }
    });
  };
  // -------------integration (end)

  // animation circle
  useEffect(() => {
    const proUp = $(".home_p").offset().top;
    console.log("homepage scroll", proUp);
    const winH = $(window).height();
    const pJsB = $(".p_js_b .img-inner img");
    const homePJs1 = $(".home_p .home_p_js_1");
    const homePJs2 = $(".home_p .home_p_js_2");
    const homePJs3 = $(".home_p .home_p_js_3");

    const homePChange = (index) => {
      switch (index) {
        case 0:
          pJsB.css({
            transform: "rotate(0deg)",
            filter: "hue-rotate(0deg)",
          });
          homePJs1.css({
            transform: "rotate(0deg)",
            opacity: 1,
          });
          homePJs2.css({
            transform: "rotate(180deg)",
            opacity: 0,
          });
          homePJs3.css({
            transform: "rotate(180deg)",
            opacity: 0,
          });

          $(".home_p_tit_1 h2").css("margin-left", "0");
          $(".home_p_tit_2 h2").css("margin-left", "100%");
          $(".home_p_tit_3 h2").css("margin-left", "100%");

          break;
        case 1:
          pJsB.css({
            transform: "rotate(90deg)",
            filter: "hue-rotate(50deg)",
          });
          homePJs1.css({
            transform: "rotate(180deg)",
            opacity: 0,
          });
          homePJs2.css({
            transform: "rotate(360deg)",
            opacity: 1,
          });
          homePJs3.css({
            transform: "rotate(180deg)",
            opacity: 0,
          });

          $(".home_p_tit_1 h2").css("margin-left", "-100%");
          $(".home_p_tit_2 h2").css("margin-left", "0");
          $(".home_p_tit_3 h2").css("margin-left", "100%");

          break;
        case 2:
          pJsB.css({
            transform: "rotate(180deg)",
            filter: "hue-rotate(220deg)",
          });
          homePJs1.css({
            transform: "rotate(180deg)",
            opacity: 0,
          });
          homePJs2.css({
            transform: "rotate(540deg)",
            opacity: 0,
          });
          homePJs3.css({
            transform: "rotate(360deg)",
            opacity: 1,
          });

          $(".home_p_tit_1 h2").css("margin-left", "-100%");
          $(".home_p_tit_2 h2").css("margin-left", "-100%");
          $(".home_p_tit_3 h2").css("margin-left", "0");

          break;
        default:
          break;
      }
    };

    const handleScroll = () => {
      const top = $(document).scrollTop();

      if (top - proUp < winH * 1) {
        homePChange(0);
      } else if (top - proUp > winH * 1 && top - proUp < winH * 2) {
        homePChange(1);
      } else if (top - proUp > winH * 2 && top - proUp < winH * 3) {
        homePChange(2);
      }
    };

    const handleClickChange = (index) => {
      homePChange(index);
    };

    $(".circle-1").on("click", () => handleClickChange(0));
    $(".circle-2").on("click", () => handleClickChange(1));
    $(".circle-3").on("click", () => handleClickChange(2));

    if (window.innerWidth > 991) {
      $(window).on("scroll", handleScroll);

      return () => {
        $(window).off("scroll", handleScroll);
      };
    }
  }, []);

  const [activeCircle, setActiveCircle] = useState(null);

  const handleClick = (circleNumber) => {
    setActiveCircle(circleNumber);
  };

  return (
    <>
      <section className="sec-featured sec-scroll-anim bg-black">
        <div className="tab-four-circle-parent d-lg-none d-flex align-items-center justify-content-between">
          <div
            className={`tab-four-circle-inner tab-circle-img-main-width position-relative circle-1 same-before-tab same-before-tabone ${
              activeCircle === 1 ? "active-circle-one" : ""
            }`}
            onClick={() => handleClick(1)}
          >
            <img
              className="blend-screen-tab blend-screen-tabone w-100"
              // src="https://tobaccovapesmart.s3.ap-southeast-2.amazonaws.com/smokering.png"
              src="https://tobaccovapesmart.backslashwebs.com/tobaccovapesmart%2Fsmokering.png"
              alt=""
            />
            <div className="CircResOneImg CircRes"><img src={CircResOneImg} alt="" /></div>
          </div>
          <div
            className={`tab-four-circle-inner tab-circle-img-main-width position-relative circle-2 same-before-tab same-before-tabtwo ${
              activeCircle === 2 ? "active-circle-two" : ""
            }`}
            onClick={() => handleClick(2)}
          >
            <img
              className="blend-screen-tab blend-screen-tabtwo w-100"
              // src="https://tobaccovapesmart.s3.ap-southeast-2.amazonaws.com/smokering.png"
              src="https://tobaccovapesmart.backslashwebs.com/tobaccovapesmart%2Fsmokering.png"
              alt=""
            />
            <div className="CircResTwoImg CircRes"><img src={CircResTwoImg} alt="" /></div>
          </div>
          <div
            className={`tab-four-circle-inner tab-circle-img-main-width position-relative circle-3 same-before-tab same-before-tabthree ${
              activeCircle === 3 ? "active-circle-three" : ""
            }`}
            onClick={() => handleClick(3)}
          >
            <img
              className="blend-screen-tab blend-screen-tabthree w-100"
              // src="https://tobaccovapesmart.s3.ap-southeast-2.amazonaws.com/smokering.png"
              src="https://tobaccovapesmart.backslashwebs.com/tobaccovapesmart%2Fsmokering.png"
              alt=""
            />
            <div className="CircResThreeImg CircRes"><img src={CircResThreeImg} alt="" /></div>
          </div>
        </div>
        <div className="row row-collapse row-full-width align-center home_p">
          <div className="col">
            <div className="col-inner">
              <div
                className="row row-collapse home_p_b"
                style={{ maxWidth: 2500 }}
              >
                <div className="col home_p_star basis-1/2">
                  <div className="col-inner">
                    <div className="img has-hover p_js_b">
                      <div className="img-inner dark">
                        <img
                          src={garryCircle}
                          alt=""
                        />
                      </div>
                    </div>
                    <div className="text home_p_tit_1">
                      <h2>
                        <span>Ash</span> Trays
                      </h2>
                    </div>
                    <div className="text home_p_tit_2">
                      <h2>Cutters</h2>
                    </div>
                    <div className="text home_p_tit_3">
                      <h2>Lighters</h2>
                    </div>
                  </div>
                </div>
                <div className="col home_p_js medium-7 small-12 large-7">
                  <div className="col-inner">
                    <section className="section home_p_js_1">
                      <div className="section-content relative">
                        <div className="img has-hover">
                          <div className="img-inner dark">
                            <img
                              width={824}
                              height={625}
                              // src="https://tobaccovapesmart.s3.ap-southeast-2.amazonaws.com/slide-1.png"
                              src="https://tobaccovapesmart.backslashwebs.com/tobaccovapesmart%2Fslide-1.png"
                              
                              alt=""
                            />
                          </div>
                        </div>
                        <div className="img has-hover">
                          <img
                            width={824}
                            height={625}
                            // src="https://tobaccovapesmart.s3.ap-southeast-2.amazonaws.com/slide-1-blurred.png"
                            src="https://tobaccovapesmart.backslashwebs.com/tobaccovapesmart%2Fslide-1-blurred.png"
                            alt=""
                          />
                        </div>
                      </div>
                    </section>
                    <section className="section home_p_js_2">
                      <div className="section-content relative">
                        <div className="img has-hover">
                          <div className="img-inner dark">
                            <img
                              width={875}
                              height={625}
                              // src="https://tobaccovapesmart.s3.ap-southeast-2.amazonaws.com/slide-2.png"
                              src="https://tobaccovapesmart.backslashwebs.com/tobaccovapesmart%2Fslide-2.png"
                              alt=""
                            />
                          </div>
                        </div>
                        <div className="img has-hover">
                          <div className="img-inner dark">
                            <img
                              width={824}
                              height={625}
                              // src="https://tobaccovapesmart.s3.ap-southeast-2.amazonaws.com/slide-2-blurred.png"
                              src="https://tobaccovapesmart.backslashwebs.com/tobaccovapesmart%2Fslide-2-blurred.png"
                              alt=""
                            />
                          </div>
                        </div>
                      </div>
                    </section>
                    <section className="section home_p_js_3">
                      <div className="section-content relative">
                        <div className="img has-hover ">
                          <div className="img-inner dark">
                            <img
                              width={824}
                              height={626}
                              // src="https://tobaccovapesmart.s3.ap-southeast-2.amazonaws.com/slide-3.png"
                              src="https://tobaccovapesmart.backslashwebs.com/tobaccovapesmart%2Fslide-3.png"
                              alt=""
                            />
                          </div>
                        </div>
                        <div className="img has-hover">
                          <div className="img-inner dark">
                            <img
                              width={824}
                              height={626}
                              // src="https://tobaccovapesmart.s3.ap-southeast-2.amazonaws.com/slide-3-blurred.png"
                              src="https://tobaccovapesmart.backslashwebs.com/tobaccovapesmart%2Fslide-3-blurred.png"
                              alt=""
                            />
                          </div>
                        </div>
                      </div>
                    </section>
                  </div>
                </div>
                <div className="allproducts home_p_star w-50">
                  <div className="col-inner">
                    <div className="text home_p_tit_1">
                      <h2>
                        <div className=" slide-product-1 mb-5 position-relative">
                          <h3 className="btnname w-110px text-wrap">
                            {featuredProduct?.product_name}{" "}
                            <span className="text-color-blue"></span>
                          </h3>
                          {/* <button onClick={handleAddToCart11} className="border-0 buynow px-3 py-1">Buy Now</button> */}
                          <Link
                            to={`/Buynow/${featuredProduct?.product_id}`}
                            className="buynow px-3 py-1"
                          >
                            View Product
                          </Link>
                          <div className="SliderFeaturedImg">
                            <img
                              src={`${baseUrl}/${featuredProduct?.main_image}`}
                              className="slide-right-img"
                              alt=""
                            />
                          </div>
                          <Link to="">
                            <img src={arrows} alt="" />
                          </Link>
                        </div>

                        <div className=" slide-product-1 mb-5 position-relative">
                          <h3 className="btnname w-110px text-wrap">
                            {featuredProduct2?.product_name}{" "}
                            <span className="text-color-blue"></span>
                          </h3>
                          {/* <h3 className="btnname fs-70">
                          {featuredProduct11?.product_name} <span className="text-color-blue"></span></h3> */}
                          {/* <button onClick={handleAddToCart11} className="border-0 buynow px-3 py-1">Buy Now</button> */}
                          <Link
                            to={`/Buynow/${featuredProduct2?.product_id}`}
                            className="buynow px-3 py-1"
                          >
                            View Product
                          </Link>
                          <div className="SliderFeaturedImg">
                            <img
                              src={`${baseUrl}/${featuredProduct2?.main_image}`}
                              className="slide-right-img"
                              alt=""
                            />
                          </div>
                          <Link>
                            <img src={arrows} alt="" />
                          </Link>
                        </div>
                        {/* slide-product-1 end */}

                        <div className=" slide-product-1 mb-5 position-relative">
                          <h3 className="btnname w-110px text-wrap">
                            {featuredProduct3?.product_name}
                            <span className="text-color-blue"></span>
                          </h3>
                          {/* <h3 className="btnname fs-70">
                          {featuredProduct11?.product_name} <span className="text-color-blue"></span></h3> */}
                          {/* <button onClick={handleAddToCart11} className="border-0 buynow px-3 py-1">Buy Now</button> */}
                          <Link
                            to={`/Buynow/${featuredProduct3?.product_id}`}
                            className="buynow px-3 py-1"
                          >
                            View Product
                          </Link>
                          <div className="SliderFeaturedImg">
                            <img
                              src={`${baseUrl}/${featuredProduct3?.main_image}`}
                              className="slide-right-img"
                              alt=""
                            />
                          </div>
                          <Link>
                            <img src={arrows} alt="" />
                          </Link>
                        </div>
                        {/* slide-product-1 end */}
                      </h2>
                    </div>
                    <div className="text home_p_tit_2">
                      <h2>
                        <div className=" slide-product-1 mb-5 position-relative">
                          <h3 className="btnname w-110px text-wrap">
                            {featuredProduct5?.product_name}{" "}
                            <span className="text-color-blue"></span>
                          </h3>
                          {/* <button onClick={handleAddToCart11} className="border-0 buynow px-3 py-1">Buy Now</button> */}
                          <Link
                            to={`/Buynow/${featuredProduct5?.product_id}`}
                            className="buynow px-3 py-1"
                          >
                            View Product
                          </Link>
                          <div className="SliderFeaturedImg">
                            <img
                              src={`${baseUrl}/${featuredProduct5?.main_image}`}
                              className="slide-right-img"
                              alt=""
                            />
                          </div>
                          <Link>
                            <img src={arrows} alt="" />
                          </Link>
                        </div>
                        {/* slide-product-1 end */}

                        <div className=" slide-product-1 mb-5 position-relative">
                          <h3 className="btnname w-110px text-wrap">
                            {featuredProduct6?.product_name}{" "}
                            <span className="text-color-blue"></span>
                          </h3>
                          {/* <button onClick={handleAddToCart11} className="border-0 buynow px-3 py-1">Buy Now</button> */}
                          <Link
                            to={`/Buynow/${featuredProduct6?.product_id}`}
                            className="buynow px-3 py-1"
                          >
                            View Product
                          </Link>
                          <div className="SliderFeaturedImg">
                            <img
                              src={`${baseUrl}/${featuredProduct6?.main_image}`}
                              className="slide-right-img"
                              alt=""
                            />
                          </div>
                          <Link>
                            <img src={arrows} alt="" />
                          </Link>
                        </div>
                        {/* slide-product-1 end */}

                        <div className=" slide-product-1 mb-5 position-relative">
                          <h3 className="btnname w-110px text-wrap">
                            {featuredProduct7?.product_name}{" "}
                            <span className="text-color-blue"></span>
                          </h3>
                          {/* <button onClick={handleAddToCart11} className="border-0 buynow px-3 py-1">Buy Now</button> */}
                          <Link
                            to={`/Buynow/${featuredProduct7?.product_id}`}
                            className="buynow px-3 py-1"
                          >
                            View Product
                          </Link>
                          <div className="SliderFeaturedImg">
                            <img
                              src={`${baseUrl}/${featuredProduct7?.main_image}`}
                              className="slide-right-img"
                              alt=""
                            />
                          </div>
                          <Link>
                            <img src={arrows} alt="" />
                          </Link>
                        </div>
                        {/* slide-product-1 end */}
                      </h2>
                    </div>
                    <div className="text home_p_tit_3">
                      <h2>
                        <div className=" slide-product-1 mb-5 position-relative">
                          <h3 className="btnname w-110px text-wrap">
                            {featuredProduct9?.product_name}{" "}
                            <span className="text-color-blue"></span>
                          </h3>
                          {/* <button onClick={handleAddToCart11} className="border-0 buynow px-3 py-1">Buy Now</button> */}
                          <Link
                            to={`/Buynow/${featuredProduct9?.product_id}`}
                            className="buynow px-3 py-1"
                          >
                            View Product
                          </Link>
                          <div className="SliderFeaturedImg">
                            <img
                              src={`${baseUrl}/${featuredProduct9?.main_image}`}
                              className="slide-right-img"
                              alt=""
                            />
                          </div>
                          <Link>
                            <img src={arrows} alt="" />
                          </Link>
                        </div>
                        {/* slide-product-1 end */}

                        <div className=" slide-product-1 mb-5 position-relative">
                          <h3 className="btnname w-110px text-wrap">
                            {featuredProduct10?.product_name}{" "}
                            <span className="text-color-blue"></span>
                          </h3>
                          {/* <button onClick={handleAddToCart11} className="border-0 buynow px-3 py-1">Buy Now</button> */}
                          <Link
                            to={`/Buynow/${featuredProduct10?.product_id}`}
                            className="buynow px-3 py-1"
                          >
                            View Product
                          </Link>
                          <div className="SliderFeaturedImg">
                            <img
                              src={`${baseUrl}/${featuredProduct10?.main_image}`}
                              className="slide-right-img"
                              alt=""
                            />
                          </div>
                          <Link>
                            <img src={arrows} alt="" />
                          </Link>
                        </div>
                        {/* slide-product-1 end */}

                        <div className=" slide-product-1 mb-5 position-relative">
                          <h3 className="btnname w-110px text-wrap">
                            {featuredProduct11?.product_name}{" "}
                            <span className="text-color-blue"></span>
                          </h3>
                          {/* <button onClick={handleAddToCart11} className="border-0 buynow px-3 py-1">Buy Now</button> */}
                          <Link
                            to={`/Buynow/${featuredProduct11?.product_id}`}
                            className="buynow px-3 py-1"
                          >
                            View Product
                          </Link>
                          <div className="SliderFeaturedImg">
                            <img
                              src={`${baseUrl}/${featuredProduct11?.main_image}`}
                              className="slide-right-img"
                              alt=""
                            />
                          </div>
                          <Link>
                            <img src={arrows} alt="" />
                          </Link>
                        </div>
                        {/* slide-product-1 end */}
                      </h2>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default FeatureProduct;
