import React, { useEffect, useRef, useState } from "react";
import gsap from "gsap"; // Import GSAP library
import flower from "../assets/images/flower.png";
import bannersmoke from "../assets/video/bannersmoke.mp4";
import bannerpro from "../assets/images/bannerpro.png";

import Header from "../Components/Header/Header";
import Warning from "../Components/Warning";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import Banner from "../Components/Banner";
import buyleftspan from "../assets/images/buyleftspan.png";
import buyrightspan from "../assets/images/buyrightspan.png";
import Partner from "../Components/Partner";
import headerlogo from "../assets/images/headerlogo.png";
import Footer from "../Components/Footer/Footer";
import { Link } from "react-router-dom";
import mission from "../assets/images/mission.png";
import seccoverline from "../assets/images/cover2.png";
import arrowul from "../assets/images/arrowul.png";

function TermsCondition() {
  return (
    <div className="font-bebas">
      <Warning />

      <Header />
      <div className="bef-aft">
        <section className="policiessection">
          <span className="buyleftspan">
            {" "}
            <img src={buyleftspan} />
          </span>
          <span className="buyrightspan">
            {" "}
            <img src={buyrightspan} />
          </span>
          <div className="container-1640 pt-3 ">
            <div className="refundcontent border-top">
              <div className="imgrefund">
                {" "}
                <img src={headerlogo} />
              </div>
              <h1 class="carttitle">
                <span className="blue-color-thme">Terms and</span>
                <span className="beige-color"> Conditions of Use</span>
              </h1>

              <p
                className="paratitle bold  mt-4 mb-2"
                style={{ marginBottom: "10px" }}
              >
                1. Terms and Conditions of Use
              </p>
              <p className="paratitle">
                By using the Tobacco Store website you are subject to the
                following terms and conditions of use. These Terms and
                Conditions of use govern your use of this web site and your use
                of this web site indicates your acceptance of these Terms and
                Conditions. Tobacco Store "We, Us") may from time to time amend,
                update or change this web site including these Terms and
                Conditions without prior notice. Any promotion, competition,
                product or service identified or contained in this website is
                subject to these Terms and Conditions or those specified on the
                applicable webpage.
              </p>

              <p
                className="paratitle bold  mt-4 mb-2"
                style={{ marginBottom: "10px" }}
              >
                2. General Disclaimer
              </p>
              <p className="paratitle">
                Tobacco Store is not liable for any loss suffered in connection
                with the use of this website or a linked website. This general
                disclaimer is not restricted or modified by any of the following
                specific warnings and disclaimers
              </p>

              <p
                className="paratitle bold  mt-4 mb-2"
                style={{ marginBottom: "10px" }}
              >
                3. Content
              </p>
              <p className="paratitle">
                Publication of electronic addresses on the website is for the
                purpose of professional communication only and must not be used
                to infer consent to the receipt of unsolicited commercial
                electronic messages. We reserve the right to change any of the
                material on this web site at any time. We do not undertake to
                keep this web site updated and are not liable to you or anyone
                else if errors occur in the information on this web site or if
                that information is not up to date.{" "}
              </p>

              <p
                className="paratitle bold  mt-4 mb-2"
                style={{ marginBottom: "10px" }}
              >
                4. Intellectual Property Rights
              </p>
              <p className="paratitle">
                All materials and third party logos, brands and trade names
                displayed on the website are either the property of, or used
                with permission by Us and protected by copyright. The use of
                these materials by you, or anyone else authorised by you, is
                prohibited unless specifically permitted by legislation, these
                Terms and Conditions or express written permission from Us. You
                may not, in any form or by any means: adapt, reproduce,
                broadcast, decompile, disassemble, download, copy, store,
                distribute, transmit, print, display, perform, publish or create
                derivative works from any part of this website; or commercialise
                any information, products or services obtained from any part of
                this website, without prior written permission from Us. The
                trade marks must not be used or modified in any way without our
                express written permission or the relevant third party owner.
                Any unauthorised use of images and third party logos, brands and
                trade names may violate copyright laws, trade mark laws, the
                laws of privacy and other civil and criminal statues.
              </p>

              <p
                className="paratitle bold  mt-4 mb-2"
                style={{ marginBottom: "10px" }}
              >
                5. Hyperlinks
              </p>
              <p className="paratitle">
                Tobacco Store no representations about websites accessed through
                this site which are not maintained, controlled or created by
                Tobacco Store. Tobacco Store does not endorse these sites and is
                not responsible for their content.{" "}
              </p>

              <p
                className="paratitle bold  mt-4 mb-2"
                style={{ marginBottom: "10px" }}
              >
                6. Site Policies, Modification and Severability
              </p>
              <p className="paratitle">
                We reserve the right to make changes to our site, policies, and
                these Terms and conditions of Use at any time. If any of these
                terms shall be deemed invalid, void, or for any reason
                unenforceable, that term shall be deemed severable and shall not
                affect the validity and enforceability of any remaining term.{" "}
              </p>

              <p
                className="paratitle bold  mt-4 mb-2"
                style={{ marginBottom: "10px" }}
              >
                7. Limitation of Liability
              </p>
              <p className="paratitle">
                Your use of the website is at your risk. We make no warranties
                or representations as to its accuracy and, to the extent
                permitted by law. We specifically disclaim any liability or
                responsibility for any errors or omissions in the content on the
                website. We will not be liable for any direct, incidental,
                consequential or indirect damages, loss or corruption of data,
                loss of profits, goodwill, bargain or opportunity or loss of
                anticipated savings resulting from your access to, or use of, or
                inability to use this web site and the content, whether based on
                warranty, contract, tort, negligence or any other legal basis,
                and whether or not We know of the possibility of such damage. If
                a jurisdiction does not allow the exclusion or limitation of
                liability in accordance with this paragraph but allows a
                limitation of a certain maximum extent then our liability is
                limited to that extent.
              </p>

              <p
                className="paratitle bold  mt-4 mb-2"
                style={{ marginBottom: "10px" }}
              >
                8. Warranties
              </p>
              <p className="paratitle">
                Except as otherwise stated in the text of a webpage of this
                website or as required by law, everything on the website and all
                products displayed on the website are provided to you "as is"
                and, to the extent permitted by law, is provided without
                warranty of any kind, either express or implied, including but
                not limited to, the implied warranties of merchantability,
                fitness for a particular purpose or non-infringement.
                Information on this Web site may contain typographical errors,
                technical inaccuracies or other errors and may be changed at any
                time without notice. We have a policy of continuous improvement
                of products and reserve the right to make improvements or
                changes to products without notice. We do not warrant that your
                access will be uninterrupted or error-free, that defects will be
                corrected, or that this site or the server that makes it
                available are free of viruses or other harmful components. We do
                not warrant or make any representations regarding the use of or
                result of the use of the material in this site in terms of their
                correctness, accuracy, reliability or otherwise. You assume the
                entire cost of all necessary servicing, repair or correction. on
                account of your access to, use of, or browsing in the website
                (including accessing hyperlinks) or your downloading of any
                materials, data, text, images, video, or audio from the website.
                We are not responsible for the content of any website linked to
                or from the website. Your linking to any other website is
                entirely at your own risk and is only provided for your
                convenience and should not be interpreted as an endorsement of
                the owner/sponsor of the website or the content of the website.
                To the extent permitted by law, We disclaim all warranties,
                express and implied, as to the accuracy, validity, legality or
                otherwise of any materials or information contained on such
                sites.
              </p>

              <p
                className="paratitle bold  mt-4 mb-2"
                style={{ marginBottom: "10px" }}
              >
                9. Governing Law
              </p>
              <p className="paratitle">
                The information contained in this website is intended for use by
                persons resident in Australia and is governed by the laws of the
                State of New South Wales and the Commonwealth of Australia. This
                Website may be accessed throughout Australia and overseas. We
                make no representations or warranties that the content of this
                web site complies with the laws (including intellectual property
                laws) of any country outside Australia. If you access this web
                site from outside Australia, you are responsible for ensuring
                compliance with all laws in the place where you are located.{" "}
              </p>

              <p
                className="paratitle bold  mt-4 mb-2"
                style={{ marginBottom: "10px" }}
              >
                10. Specific Warnings
              </p>
              <p className="paratitle mb-2" style={{ marginBottom: "0px" }}>
                <img src={arrowul} />
                <span className="paratitle bold ">10.1 </span>
                You must ensure that your access to, or use of, the Website is
                not illegal or prohibited by laws which apply to you.
              </p>

              <p className="paratitle " style={{ marginBottom: "0px" }}>
                <img src={arrowul} />
                <span className="paratitle bold">10.2 </span>
                You must take your own precautions to ensure that the process
                which you employ for accessing the Website does not expose you
                to risk of viruses, malicious computer code or other forms of
                interference which may damage your computer system. Tobacco
                Store takes no responsibility for any such damage which may
                arise in connection with your use of the Website.
              </p>

              <p
                className="paratitle bold  mt-4 mb-2"
                style={{ marginBottom: "10px" }}
              >
                11. Goods
              </p>
              <p className="paratitle mb-2" style={{ marginBottom: "0px" }}>
                <img src={arrowul} />
                <span className="paratitle bold ">11.1 </span>
                Tobacco Store takes no responsibility for the content of any
                Goods purchased through the Website.
              </p>

              <p className="paratitle " style={{ marginBottom: "0px" }}>
                <img src={arrowul} />
                <span className="paratitle bold">11.2 </span>
                You warrant that, if your Order includes Restricted Material,
                you are eligible to purchase such Goods.{" "}
              </p>

              <p
                className="paratitle bold  mt-4 mb-2"
                style={{ marginBottom: "10px" }}
              >
                12. Pricing
              </p>
              <p className="paratitle mb-2" style={{ marginBottom: "0px" }}>
                <img src={arrowul} />
                <span className="paratitle bold ">12.1 </span>
                Prices displayed on the websites are subject to change without
                notice. Prices for items on an order are fixed once your Order
                has been accepted. Subsequent price changes either up or down
                will not be retrospectively applied to accepted Orders.
              </p>

              <p className="paratitle mb-2" style={{ marginBottom: "0px" }}>
                <img src={arrowul} />
                <span className="paratitle bold">12.2 </span>
                You agree to pay the delivery charges specified at the time you
                submit your Order.{" "}
              </p>

              <p className="paratitle mb-2" style={{ marginBottom: "0px" }}>
                <img src={arrowul} />
                <span className="paratitle bold">12.3 </span>
                All prices listed are in AUD{" "}
              </p>

              <p className="paratitle mb-2" style={{ marginBottom: "0px" }}>
                <img src={arrowul} />
                <span className="paratitle bold">12.4 </span>
                Online pricing is not indicative of in store pricing{" "}
              </p>

              <p className="paratitle mb-2" style={{ marginBottom: "0px" }}>
                <img src={arrowul} />
                <span className="paratitle bold">12.5 </span>
                Sales, Markdowns and Promotional Codes{" "}
              </p>

              <p className="paratitle mb-2" style={{ marginBottom: "0px" }}>
                <img src={arrowul} />
                <span className="paratitle bold">12.5.A</span>
                Sale and Markdown Items – sale prices are displayed on our
                website with the current sale price, no further discounts apply.{" "}
              </p>

              <p className="paratitle mb-2" style={{ marginBottom: "0px" }}>
                <img src={arrowul} />
                <span className="paratitle bold">12.5.B </span>
                “$10 Off your first order” promotional code, offer valid on full
                price GiftBox product only for order equal to or greater than
                $100, not valid in conjunction with any other offer or promo
                code offer. One voucher per transaction. Single use voucher.
                This code must be used at checkout and cannot be reimbursed post
                purchase.{" "}
              </p>

              <p className="paratitle mb-2 " style={{ marginBottom: "0px" }}>
                <img src={arrowul} />
                <span className="paratitle bold">12.5.C </span>
                “Buy One Get One 50% Off” promotional code, offer valid on
                selected full price product only, not valid in conjunction with
                any other offer or promo code offer. This code must be used at
                checkout and cannot be reimbursed post purchase.{" "}
              </p>

              <p className="paratitle mb-2 " style={{ marginBottom: "0px" }}>
                <img src={arrowul} />
                <span className="paratitle bold">12.5.D </span>
                Online promotional codes not previously mentioned, from time to
                time, we offer promo codes for free shipping or further
                discounts, these promotional codes are not valid in conjunction
                with any other offer or promo code.{" "}
              </p>

              <p
                className="paratitle bold  mt-4 mb-2"
                style={{ marginBottom: "10px" }}
              >
                13. Orders
              </p>

              <p className="paratitle mb-2" style={{ marginBottom: "0px" }}>
                <img src={arrowul} />
                <span className="paratitle bold "> 13.1 </span>
                You may place an Order by following the instructions on the
                Website. Your Order will be submitted by completing payment
                details and pressing the "Complete Order" button. Orders will be
                deemed to have been received by Tobacco Store the time Tobacco
                Store sends an Order confirmation to your nominated e-mail
                address.
              </p>

              <p className="paratitle mb-2" style={{ marginBottom: "0px" }}>
                <img src={arrowul} />
                <span className="paratitle bold "> 13.2 </span>
                Each Order (once accepted) represents a separate Agreement
                incorporating the terms of that Order.
              </p>

              <p className="paratitle mb-2" style={{ marginBottom: "0px" }}>
                <img src={arrowul} />
                <span className="paratitle bold "> 13.3 </span>
                Tobacco Store reserves the right to not accept Orders that
                request commercial quantities of Goods. If Tobacco Store is
                unable to supply your total Order this Agreement will apply to
                the supply of all or part of the Order.
              </p>

              <p className="paratitle mb-2" style={{ marginBottom: "0px" }}>
                <img src={arrowul} />
                <span className="paratitle bold "> 13.4 </span>
                Tobacco Store will use its best endeavours to supply Orders
                placed through the Website, however Tobacco Store reserves the
                right to cancel any Order without notice for any reason.
              </p>

              <p className="paratitle mb-2" style={{ marginBottom: "0px" }}>
                <img src={arrowul} />
                <span className="paratitle bold "> 13.5 </span>
                In event of a cancelled Order, any payments processed by Tobacco
                Store will be promptly refunded.
              </p>

              <p className="paratitle mb-2" style={{ marginBottom: "0px" }}>
                <img src={arrowul} />
                <span className="paratitle bold "> 13.6 </span>
                Once an order has been placed and payment has been confirmed,
                the order cannot be changed or cancelled. You may return an
                order once it has been delivered by following our Returns
                Process.{" "}
              </p>
              <p className="paratitle mb-2" style={{ marginBottom: "0px" }}>
                <img src={arrowul} />
                <span className="paratitle bold "> 13.7 </span>
                You acknowledge that the Internet can be an unstable and,
                sometimes, insecure marketplace. At times the Website may not be
                available, or Orders may not be processed or may not be accepted
                for reasons beyond our control. In these circumstances Tobacco
                Store accepts no responsibility.
              </p>

              <p
                className="paratitle bold  mt-4 mb-2"
                style={{ marginBottom: "10px" }}
              >
                14. Payment
              </p>
              <p className="paratitle mb-2" style={{ marginBottom: "0px" }}>
                <img src={arrowul} />
                <span className="paratitle bold "> 14.1 </span>
                You may provide your nominated credit card details during the
                purchase process described on the Website.{" "}
              </p>
              <p className="paratitle mb-2" style={{ marginBottom: "0px" }}>
                <img src={arrowul} />
                <span className="paratitle bold "> 14.2 </span>
                Once the Goods the subject of your Order are received from
                suppliers and are ready for dispatch, Tobacco Store will process
                payment for the Goods (plus any delivery charges) using your
                nominated credit card (Visa or Mastercard).
              </p>

              <p className="paratitle mb-2" style={{ marginBottom: "0px" }}>
                <img src={arrowul} />
                <span className="paratitle bold "> 14.3 </span>
                You authorise Tobacco Store to debit such amount from your
                nominated credit card.{" "}
              </p>

              <p className="paratitle mb-2" style={{ marginBottom: "0px" }}>
                <img src={arrowul} />
                <span className="paratitle bold "> 14.4 </span>
                If your nominated credit card is declined by your financial
                institution, Tobacco Store will not be able to guarantee the
                delivery of the Goods, and may contact you to make alternative
                payment and delivery arrangements.{" "}
              </p>
              <p className="paratitle mb-2" style={{ marginBottom: "0px" }}>
                <img src={arrowul} />
                <span className="paratitle bold ">14.5 </span>
                Tobacco Store may decide at any time not to accept payment from
                you by credit card for any reason. If we decline to accept
                payment, we will not process the Order and may not contact you
                to inform you that your Order will not be processed. We may
                process this Order or further Orders if you make a payment by
                another method that is acceptable to Tobacco Store.{" "}
              </p>

              <p
                className="paratitle bold  mt-4 mb-2"
                style={{ marginBottom: "10px" }}
              >
                15. Delivery, Risk and Title in the goods
              </p>
              <p className="paratitle mb-2" style={{ marginBottom: "0px" }}>
                <img src={arrowul} />
                <span className="paratitle bold "> 15.1 </span>
                Tobacco Store will use its best endeavours to deliver the Goods
                to your nominated delivery address within the time period
                specified at the time of placing your Order. However Tobacco
                Store will not be liable to you or anyone else for any losses
                suffered or incurred due to delay.{" "}
              </p>

              <p className="paratitle mb-2" style={{ marginBottom: "0px" }}>
                <img src={arrowul} />
                <span className="paratitle bold "> 15.2 </span>
                Subject to payment, property and risk in the Goods passes to you
                upon delivery of the Goods to your nominated delivery address.{" "}
              </p>

              <p className="paratitle mb-2" style={{ marginBottom: "0px" }}>
                <img src={arrowul} />
                <span className="paratitle bold "> 15.3 </span>
                All parcels are delivered using Couried internal teams. Internal
                Couriers reserves the right to request identification at the
                time of delivery. If the residence is unoccupied at time of
                delivery or identification can not be provided, Internal
                Couriers will attempt to re-deliver the following day. If the
                residence is again unoccupied the intended resident will be
                charged a return-to-sender fee and the goods will be returned to
                VAPE PARTNERS PTY LTD where a full refund will be issued upon
                receiving goods.{" "}
              </p>

              <p
                className="paratitle bold  mt-4 mb-2"
                style={{ marginBottom: "10px" }}
              >
                16. Faulty or damaged goods
              </p>
              <p className="paratitle mb-2" style={{ marginBottom: "0px" }}>
                <img src={arrowul} />
                <span className="paratitle bold "> 16.1 </span>
                Tobacco Store will use its best endeavours to replace any faulty
                Goods, or Goods damaged prior to delivery.{" "}
              </p>

              <p className="paratitle mb-2" style={{ marginBottom: "0px" }}>
                <img src={arrowul} />
                <span className="paratitle bold "> 16.2 </span>
                Damaged or faulty Goods will be replaced (at the discretion of
                Tobacco Store) or where replacement of damaged or faulty Goods
                is not possible (in the opinion of Tobacco Store) a full refund
                will be issued for the value of the faulty or damaged Goods.{" "}
              </p>

              <p className="paratitle mb-2" style={{ marginBottom: "0px" }}>
                <img src={arrowul} />
                <span className="paratitle bold "> 16.3 </span>
                All damaged and faulty goods must be returned to Tobacco Store.{" "}
              </p>

              <p
                className="paratitle bold  mt-4 mb-2"
                style={{ marginBottom: "10px" }}
              >
                17. Returns
              </p>
              <p className="paratitle mb-2" style={{ marginBottom: "0px" }}>
                If you have a return, please refer to our returns policy for
                more information.{" "}
              </p>

              <p
                className="paratitle bold  mt-4 mb-2"
                style={{ marginBottom: "10px" }}
              >
                18. Indemnity
              </p>

              <p className="paratitle mb-2" style={{ marginBottom: "0px" }}>
                <img src={arrowul} />
                <span className="paratitle bold "> 18.1 </span>
                You waive, release, discharge and relinquish any and all claims
                that you now have or may have against Tobacco Store which are
                connected with, arise out of, relate to or are incidental to the
                use of the Website.{" "}
              </p>
              <p className="paratitle mb-2" style={{ marginBottom: "0px" }}>
                <img src={arrowul} />
                <span className="paratitle bold "> 18.2 </span>
                You agree to indemnify Tobacco Store and keep us indemnified,
                and hold harmless from and against any and all claims, loss,
                damage, taxes, liability and/or expense that may be incurred by
                Tobacco Store arising out of or in connection with the Website
                by any other person as a result of your, or any person using
                your password's:{" "}
              </p>

              <p className="paratitle mb-2" style={{ marginBottom: "0px" }}>
                (a) act or omission;
              </p>
              <p className="paratitle mb-2" style={{ marginBottom: "0px" }}>
                (b) breach of these terms and conditions; or
              </p>
              <p className="paratitle mb-2" style={{ marginBottom: "0px" }}>
                (c) infringement of any Intellectual Property on the Website.
              </p>

              <p
                className="paratitle bold  mt-4 mb-2"
                style={{ marginBottom: "10px" }}
              >
                19. Privacy
              </p>
              <p className="paratitle mb-2" style={{ marginBottom: "0px" }}>
                Please refer to our website privacy policy for detailed
                information.
              </p>

              <p
                className="paratitle bold  mt-4 mb-2"
                style={{ marginBottom: "10px" }}
              >
                20. General
              </p>
              <p className="paratitle mb-2" style={{ marginBottom: "0px" }}>
                <img src={arrowul} />
                <span className="paratitle bold "> 20.1 </span> Any provision of
                these Terms and Conditions which is void or unenforceable may be
                severed from these Terms and Conditions without affecting the
                enforceability of other provisions.{" "}
              </p>

              <p className="paratitle mb-2" style={{ marginBottom: "0px" }}>
                <img src={arrowul} />
                <span className="paratitle bold "> 20.2 </span> A failure or
                delay by Tobacco Store to exercise a power or right under these
                Terms and Conditions does not operate as a waiver of that power
                or right, and the exercise of a power or right by Tobacco Store
                does not preclude its future exercise or the exercise of any
                other power or right.{" "}
              </p>

              <p
                className="paratitle bold  mt-4 mb-2"
                style={{ marginBottom: "10px" }}
              >
                21. International Online Customers
              </p>
              <p className="paratitle mb-2" style={{ marginBottom: "10px" }}>
                We do not accept any orders from international customers or
                international credit cards.
              </p>

              <p
                className="paratitle bold  mt-4 mb-2"
                style={{ marginBottom: "10px" }}
              >
                22. Tobacco Store
              </p>

              <p className="paratitle mb-2" style={{ marginBottom: "10px" }}>
                In these Terms of use, a reference to Tobacco Store is a
                reference to VAPE PARTNERS PTY LTD (ABN 21 200 896 551) trading
                as Tobacco Store.
              </p>

              <p
                className="paratitle bold  mt-4 mb-2"
                style={{ marginBottom: "10px" }}
              >
                23.
              </p>
              <p className="paratitle mb-5" style={{ marginBottom: "10px" }}>
                Both the customers and Tobacco Store shall have the benefit of
                any rights conferred and any warranties and conditions implied
                by law.
              </p>

              <p className="paratitle mb-2" style={{ marginBottom: "10px" }}>
                In these Terms of Use a reference to Tobacco Store is a
                reference to VAPE PARTNERS PTY LTD (ABN 21 200 896 551) trading
                as Tobacco Store.
              </p>
            </div>
          </div>
        </section>

        <Footer />
      </div>
    </div>
  );
}

export default TermsCondition;
