import { React, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import SideBar from "../../../Components/User/SideBar";
import ProductImg from "../../../assets/images/product1.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import NotificationUser from "../../../assets/images/notification-user.png";

import {
  faEye,
  faCancel,
  faListUl,
  faTrash,
  faCircle,
} from "@fortawesome/free-solid-svg-icons";
import { baseUrl } from "../../../assets/utils/IP";
import axios from "axios";

export default function MyProfile() {
  const [isInputVisible, setInputVisible] = useState(true);
  const [notifications, setNotifications] = useState();
  const [flag, setFlag] = useState();

  const toggleInputVisibility = () => {
    setInputVisible(!isInputVisible);
  };

  const userId = localStorage.getItem("userId")
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${baseUrl}/get-notification-by-user/${userId}`);
        setNotifications(response.data.history)
      } catch (error) {
      }
    };

    fetchData();
  }, [userId, flag]);





  function getTimeAgo(targetTime) {
    const originalTime = new Date(targetTime);
    const newTimeCopy = new Date(originalTime.getTime() + 10 * 60 * 60 * 1000);
    const newTime = newTimeCopy.toISOString()
    const targetDate = new Date(newTime); // Parse the target time string to a Date object
    const currentDate = new Date(); // Get the current time as a Date object
    const timeDifferenceMs = currentDate - targetDate; // Calculate the time difference in milliseconds
    const secondsAgo = Math.floor(timeDifferenceMs / 1000); // Convert milliseconds to seconds
    const minutesAgo = Math.floor(secondsAgo / 60); // Convert seconds to minutes
    const hoursAgo = Math.floor(minutesAgo / 60) + 5; // Convert minutes to hours
    const daysAgo = Math.floor(hoursAgo / 24); // Convert hours to days

    if (daysAgo > 0) {
      return daysAgo + (daysAgo === 1 ? ' day ago' : ' days ago');
    } else if (hoursAgo > 0) {
      return hoursAgo + (hoursAgo === 1 ? ' hour ago' : ' hours ago');
    } else if (minutesAgo > 0) {
      return minutesAgo + (minutesAgo === 1 ? ' minute ago' : ' minutes ago');
    } else {
      return 'Few minute ago';
    }
  }


  function handleNotificationSeen() {
    let data = JSON.stringify({
      "mark_status": "unmark"
    });

    let config = {
      method: 'patch',
      maxBodyLength: Infinity,
      url: `${baseUrl}/change-status-notification/${userId}`,
      headers: {
        'Content-Type': 'application/json'
      },
      data: data
    };

    axios.request(config)
      .then((response) => {
        setFlag(true)
      })
      .catch((error) => {
      });
  }

  return (
    <section className="bg-main">
      <SideBar />
      <div className="second-main-side">
        <div className="sec-main-side-parent p-5">
          <div className="blur-pages px-5">
            <div className="border-text">
              <div className="d-flex justify-content-between">
                <h1>Notification</h1>
              </div>

              <hr className="text-light opacity-100" />
            </div>
            {notifications?.map((notification) => (
              <div className="parent-status-box pt-3">
                <div key={notification.id} className="flex-img-txt d-flex border border-2 rounded-4">
                  <div className="status-box-img" style={{
                    width: "50px", height: "50px"
                  }}>
                    <img style={{
                      width: "50px", height: "50px"
                    }} src={`${baseUrl}/${notification?.user_image}`} alt="" />
                  </div>
                  <div className="status-box-txt">
                    <h3>Your Order is {notification?.status}</h3>
                    <p>
                      {notification.comment}
                      <br />
                    </p>
                    <span>{getTimeAgo(notification.created_at) == 0 ? "Few mintues ago" : getTimeAgo(notification.created_at)}</span>
                  </div>
                  {notification.mark_status == 0 ? <></> : <div style={{
                    position: "absolute",
                    right: "10px"
                  }}><FontAwesomeIcon color="green" icon={faCircle} /></div>}
                </div>

              </div>
            ))}


          </div>
          <Link className="btn-gradient p-2 px-5 rounded d-table text-dark font-bebas mt-5" onClick={handleNotificationSeen}>
            Mark All As Read
          </Link>

        </div>
      </div>
      {/* </div> */}
    </section>
  );
}
