import React, { useState } from "react";
import SideBar from "../../../Components/Admin/SideBar";
import { Link, useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircleChevronLeft,
  faPencil,
} from "@fortawesome/free-solid-svg-icons";
import { AuthorizationToken, baseUrl } from "../../../assets/utils/IP";
import { useEffect } from "react";
import axios from "axios";
import Swal from "sweetalert2";

export default function EditBrand() {
  const [brandData, setBrandData] = useState("");
  const [brandName, setName] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);
  const [displayedImage, setDisplayedImage] = useState(null);

  const { id } = useParams();
  const brandDetails = brandData.products && brandData.products[0] || "";

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${baseUrl}/get-brand/${id}`);
        setBrandData(response.data)

      } catch (error) {
      }
    };


    fetchData(); // Call the function when the component mounts
  }, [id]);


  const handleSave = async (e) => {
    e.preventDefault();
    const FormData = require("form-data");

    let data = new FormData();
    if (brandName) {
      data.append("brand_name", brandName.trim());
    } else {
      data.append("brand_name", brandDetails?.brand_name.trim());
    }

    if (selectedFile) {
      data.append("brand_logo", selectedFile);
    } else {
      if (brandDetails?.brand_logo) {
        data.append("brand_logo", brandDetails?.brand_logo);
      }
    }

    try {
      const res = await axios.put(`${baseUrl}/brand-update/${id}`, data, {
        headers: {
          'Authorization':
            `Bearer ${AuthorizationToken}`,
        }
      });
      Swal.fire({
        icon: "success",
        title: "Brand Updated Successfull",
        customClass: {
          container: "customstyle",
        }
      });
    }
    catch (error) {
      if (error?.response?.data.message === "Brand name is already in use") {
        Swal.fire({
          icon: "error",
          title: "Brand already exists",
          text: "Brand with same name can not be added",
          customClass: {
            container: "customstyle",
          }
        });
      }
    }
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setSelectedFile(file);

      const reader = new FileReader();
      reader.onloadend = () => {
        setDisplayedImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <section className="bg-main">
      <SideBar />
      <div className="second-main-side">
        <div className="sec-main-side-parent p-md-4 p-lg-5">
          <div className="blur-pages px-5">
            <div className="border-text">
              <div className="d-flex justify-content-between">
                <h1>EDIT BRAND</h1>
                <div className="boxs-flex">
                  <Link
                    className="p-3 justify-content-center bg-light w-25 h-25 d-flex align-items-center text-dark rounded-2"
                    to="/BrandCategories"
                  >
                    <FontAwesomeIcon icon={faCircleChevronLeft} />
                  </Link>
                </div>
              </div>
              <hr className="text-light opacity-100" />
            </div>
            <div className="add-categorie-list mt-5">
              <FontAwesomeIcon color="#fff" icon={faPencil} />
              <span className="mx-2">Edit Brand</span>
            </div>
            <form action="">
              <div className="grid-add d-grid align-items-center my-5">
                <label className="text-light d-block text-end" htmlFor="">
                  <span className="text-warning">*</span>Brand Name
                </label>
                <input
                  defaultValue={brandDetails?.brand_name}
                  onChange={(e) => setName(e.target.value)}
                  className="py-1 px-2"
                  type="text"
                  maxLength={18}
                  required
                />
              </div>
              <div className="grid-add d-grid my-5">
                <label className="text-light d-block text-end" htmlFor="">
                  <span className="text-warning">*</span>Upload Image
                </label>
                <div className="row">
                  <div className="col-lg-4">
                    <div className="row">
                      <div className="col-lg-4">
                        <input
                          type="file"
                          className="image-input"
                          id="fileInput"
                          accept="image/*"
                          onChange={handleFileChange}
                        />
                        <label
                          htmlFor="fileInput"
                          className="img-plus"
                          style={{ width: "200px" }}
                        >
                          {displayedImage ? (
                            <img
                              src={displayedImage}
                              alt=""
                              style={{ width: "100%" }}
                            />) :
                            brandDetails?.brand_logo ? (
                              <img
                                src={`${baseUrl}/${brandDetails?.brand_logo}`}
                                style={{ width: "100%" }}
                                alt="Image Alt Text"
                              />
                            ) : (
                              <span style={{ color: "yellow" }}>+ Click to upload image</span>
                            )}
                        </label>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
              <div className="grid-add d-grid align-items-center my-4">
                <label htmlFor=""></label>
                <input
                  className="fw-bold add-save-btn rounded-3 border-0"
                  type="submit"
                  value="Save"
                  onClick={handleSave}
                />
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
}
