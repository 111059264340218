import React, { useEffect, useState } from "react";
import SideBar from "../../../Components/Admin/SideBar";
import Graph from "../../../Components/Admin/Graph";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faChevronUp,
    faCircleChevronLeft,
    faListUl,
    faPencil,
    faPlus,
    faTrash,
} from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import { AuthorizationToken, baseUrl } from "../../../assets/utils/IP";

export default function SubCategories() {
    const [visibleProducts, setVisibleProducts] = useState(10);

    const [category, setCategory] = useState([]);
    const [restoreCategoryID, setRestoreCategoryId] = useState();
    const [selectedCatagory, setSelectedCatagory] = useState([]); // Step 1

    useEffect(() => {
        const getAllCategory = async () => {
            try {
                const res = await axios.get(`${baseUrl}/get-all-deleted-category`);
                setCategory(res.data);
            } catch (error) {
            }
        };
        getAllCategory();
    }, []);
    //
    const handleRestoreClick = (catId) => {
        setRestoreCategoryId(catId);
    };
    const handleRestore = async (id) => {
        try {
            const res = await axios.put(`${baseUrl}/restore-category/${id}`);
            setCategory((prevCategory) =>
                prevCategory.filter((subCat) => subCat.category_id !== id)
            );
        } catch (error) {
        }
    };
    const loadMoreProducts = () => {
        setVisibleProducts((prevVisibleProducts) => prevVisibleProducts + 10);
    };
    const displayedProducts = category?.slice(0, visibleProducts);

    const handleSelectAll = () => {
        setSelectedCatagory(category?.map((category) => category?.category_id)); // Step 2
    };

    const handleDeselectAll = () => {
        setSelectedCatagory([]);
    };

    const handleBrandSelect = (catagoryId) => {
        // Step 4
        if (selectedCatagory.includes(catagoryId)) {
            setSelectedCatagory(selectedCatagory.filter((id) => id !== catagoryId));
        } else {
            setSelectedCatagory([...selectedCatagory, catagoryId]);
        }
    };

    const deleteAll = () => {
        const categoryIdsToDelete = selectedCatagory;

        axios
            .delete(`${baseUrl}/delete-all-category`, {
                data: { categoryIds: categoryIdsToDelete },
                headers: {
                    'Authorization':
                        `Bearer ${AuthorizationToken}`,
                },
            })
            .then((response) => {

            })
            .catch((error) => {

            });
    };

    return (
        <section className="bg-main">
            <SideBar />
            <div className="second-main-side">
                <div className="sec-main-side-parent p-md-4 p-lg-5">
                    <div className="blur-pages px-5">
                        <div className="border-text">
                            <div className="d-flex justify-content-between">
                                <h1>Deleted Category</h1>
                                <div className="boxs-flex">
                                    <Link
                                        className="p-3 justify-content-center bg-light w-25 h-25 d-flex align-items-center text-dark rounded-2"
                                        to="/SubCategories"
                                    >
                                        <FontAwesomeIcon icon={faCircleChevronLeft} />
                                    </Link>
                                </div>
                            </div>
                            <hr className="text-light opacity-100" />
                        </div>
                        <div className="add-categorie-list">
                            <FontAwesomeIcon color="#fff" icon={faListUl} />
                            <span className="mx-2">Category List</span>
                        </div>
                        <div className="main-fi-order-box-master">
              <div className="main-fi-order-box">
                        <div className="categories-parent-table-box">
                            <div className="categorie-table-box w-100 align-items-center rounded-3 my-3">
                                <div className="categori-name d-flex align-items-center p-3">
                                    <input
                                        type="checkbox"
                                        onChange={() =>
                                            selectedCatagory?.length < category?.length
                                                ? handleSelectAll()
                                                : handleDeselectAll()
                                        }
                                        checked={selectedCatagory.length === category?.length}
                                    />                      <p className="mx-3">Category Name </p>
                                    <FontAwesomeIcon icon={faChevronUp} />
                                </div>
                                <div className="border-end p-3 text-end">
                                    <p>Number of Products</p>
                                </div>
                                <div className="p-3 text-end">
                                    <p>Action</p>
                                </div>
                            </div>
                            {displayedProducts?.map((category) => (
                                <div
                                    key={category.category_id
                                    }
                                    className="categorie-table-box-sec text-light w-100 align-items-center rounded-3 my-2"
                                >
                                    <div className="categori-name d-flex align-items-center p-3">
                                        <input
                                            type="checkbox"
                                            onChange={() => handleBrandSelect(category.category_id)}
                                            checked={selectedCatagory.includes(category.category_id)}
                                        />
                                        <p className="mx-3">{category.category_name}</p>
                                    </div>

                                    <div className="border-end text-end p-3">
                                        <p>{category.product_count
                                        }</p>
                                    </div>
                                    <div className="p-2 px-3 text-end">
                                        <button
                                            type="button"
                                            className="add-categorie-btn text-dark rounded-2 px-5 py-2 font-bebas"
                                            data-bs-toggle="modal"
                                            data-bs-target="#exampleModal"
                                            onClick={() => handleRestoreClick(category.category_id)}
                                        >
                                            Restore Category
                                        </button>

                                    </div>
                                </div>
                            ))}

                            <div className="text-center mt-3">
                                {visibleProducts < category?.length && (
                                    <button className="btn-gradient px-4 py-1 rounded border-0" onClick={loadMoreProducts}>Load More</button>
                                )}
                            </div>
                        </div>
                        </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* Modal */}
            <div
                className="modal fade"
                id="exampleModal"
                tabIndex={-1}
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog delet-popup d-flex align-items-center justify-content-center h-100">
                    <div className="modal-content p-3 rounded-5">
                        <div className="modal-header border-0">
                            <button
                                type="button"
                                className="btn-close text-light"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                            />
                        </div>
                        <div className="modal-body text-center delete-modal-responsive">
                            <FontAwesomeIcon
                                color="red"
                                fontSize="80px"
                                icon={faTrash}
                            />{" "}
                            <h1 className="text-light">
                                You are about to Restore this Category
                            </h1>
                        </div>
                        <div className="modal-footer justify-content-center border-0">
                            <button
                                type="button"
                                className="btn btn-gradient"
                                data-bs-dismiss="modal"
                            >
                                Go Back
                            </button>
                            <button
                                data-bs-dismiss="modal"
                                type="button"
                                className="btn btn-danger"
                                onClick={() => handleRestore(restoreCategoryID)}
                            >
                                Restore Category
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}
