import React, { useEffect, useState } from "react";
import SideBar from "../../../Components/Admin/SideBar";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faChevronUp,
    faCircleChevronLeft,
    faListUl,
    faPencil,
    faPlus,
    faTrash,
} from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import { baseUrl } from "../../../assets/utils/IP";

export default function DeletedBrands() {
    const [brands, setBrands] = useState()
    const [visibleProducts, setVisibleProducts] = useState(10);
    const [restoreBrandID, setRestoreBrandId] = useState();
    const [selectedBrands, setSelectedBrands] = useState([]);


    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`${baseUrl}/get-all-deleted-brand`);
                setBrands(response.data)
            } catch (error) {
                console.error(error);
            }
        };

        fetchData();
    }, []);


    const handleRestoreClick = (catId) => {
        setRestoreBrandId(catId);
    };
    const handleRestore = async (id) => {
        try {
            const res = await axios.put(`${baseUrl}/restore-brand/${id}`);
            setBrands((prevBrand) =>
                prevBrand.filter((brand) => brand.brand_id !== id)
            );
        } catch (error) {
        }
    };
    const loadMoreProducts = () => {
        setVisibleProducts((prevVisibleProducts) => prevVisibleProducts + 10);
    };

    const handleSelectAll = () => {
        setSelectedBrands(brands?.map((brand) => brand?.brand_id));
    };

    const handleDeselectAll = () => {
        setSelectedBrands([]);
    };

    const handleBrandSelect = (brandId) => {
        if (selectedBrands.includes(brandId)) {
            setSelectedBrands(selectedBrands.filter((id) => id !== brandId));
        } else {
            setSelectedBrands([...selectedBrands, brandId]);
        }
    };

    return (
        <section className="bg-main">
            <SideBar />
            <div className="second-main-side">
                <div className="sec-main-side-parent p-md-4 p-lg-5">
                    <div className="blur-pages px-5">
                        <div className="border-text">
                            <div className="d-flex justify-content-between">
                                <h1>Deleted Brands</h1>
                                <div className="boxs-flex">
                                    <Link
                                        className="p-3 justify-content-center bg-light w-25 h-25 d-flex align-items-center text-dark rounded-2"
                                        to="/BrandCategories"
                                    >
                                        <FontAwesomeIcon icon={faCircleChevronLeft} />
                                    </Link>
                                </div>
                            </div>
                            <hr className="text-light opacity-100" />
                        </div>


                        <div className="add-categorie-list">
                            <FontAwesomeIcon color="#fff" icon={faListUl} />
                            <span className="mx-2">Brands List</span>
                        </div>
                        <div className="main-fi-order-box-master">
              <div className="main-fi-order-box">
                        <div className="categories-parent-table-box">
                            <div className="brand-categorie-table-box w-100 align-items-center rounded-3 my-3">
                                <div className="categori-name d-flex align-items-center p-3">
                                    <input
                                        type="checkbox"
                                        onChange={() =>
                                            selectedBrands?.length < brands?.length
                                                ? handleSelectAll()
                                                : handleDeselectAll()
                                        }
                                        checked={selectedBrands.length === brands?.length}
                                    />                  <p className="mx-3">Brands</p>
                                    <FontAwesomeIcon icon={faChevronUp} />
                                </div>

                                <div className="border-end p-3 text-end">
                                    <p>Number of Products</p>
                                </div>
                                <div className="p-3 text-end">
                                    <p>Action</p>
                                </div>
                            </div>

                            {brands?.map((brand) => (
                                <div
                                    key={brand.brand_id}
                                    className="brand-categorie-table-box-sec text-light w-100 align-items-center rounded-3 my-2"
                                >
                                    <div className="categori-name d-flex align-items-center p-3">
                                        <input
                                            type="checkbox"
                                            onChange={() => handleBrandSelect(brand.brand_id)}
                                            checked={selectedBrands.includes(brand.brand_id)}
                                        />                    <p className="mx-3">{brand.brand_name}</p>
                                    </div>

                                    <div className="border-end text-end p-3">
                                        <p>{brand.product_count}</p>
                                    </div>
                                    <div className="p-2 px-3 text-end">
                                        <button
                                            type="button"
                                            className="add-categorie-btn text-dark rounded-2 px-5 py-2 font-bebas"
                                            data-bs-toggle="modal"
                                            data-bs-target="#exampleModal"
                                            onClick={() => handleRestoreClick(brand.brand_id)}
                                        >
                                            Restore Brand
                                        </button>
                                    </div>
                                </div>
                            ))}

                            <div className="text-center mt-3">
                                {visibleProducts < brands?.length && (
                                    <button className="btn-gradient px-4 py-1 rounded border-0" onClick={loadMoreProducts}>Load More</button>
                                )}
                            </div>
                        </div>
                        </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* Modal */}
            <div
                className="modal fade"
                id="exampleModal"
                tabIndex={-1}
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog delet-popup d-flex align-items-center justify-content-center h-100">
                    <div className="modal-content p-3 rounded-5">
                        <div className="modal-header border-0">
                            <button
                                type="button"
                                className="btn-close text-light"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                            />
                        </div>
                        <div className="modal-body text-center delete-modal-responsive">
                            <FontAwesomeIcon color="red" fontSize="80px" icon={faTrash} />{" "}
                            <h1 className="text-light">
                                You are about to Restore this Brand
                            </h1>
                        </div>
                        <div className="modal-footer justify-content-center border-0">
                            <button
                                type="button"
                                className="btn btn-gradient"
                                data-bs-dismiss="modal"
                            >
                                Go Back
                            </button>
                            <button
                                type="button"
                                className="btn btn-danger"
                                data-bs-dismiss="modal"
                                onClick={() => handleRestore(restoreBrandID)}
                            >
                                Restore
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </section >
    );
}
