import React, { useState } from "react";
import Footer from "../Components/Footer/Footer";
import Header from "../Components/Header/Header";
import Warning from "../Components/Warning";
import { Link, useNavigate } from "react-router-dom";
import buyrightspan from "../assets/images/buyrightspan.png";
import buyleftspan from "../assets/images/buyleftspan.png";
import Swal from "sweetalert2";
import axios from "axios";
import { baseUrl } from "../assets/utils/IP";
import { useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
function Checkout() {
  const navigate = useNavigate();
  const [singinloader, setsinginloader] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [validation, setValidation] = useState();
  const [user, setUser] = useState({
    email: "",
    password: "",
  });

  const togglePasswordVisibility2 = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  useEffect(() => {
    const token = localStorage.getItem("root");

    if (token) {
      const values = JSON.parse(localStorage.getItem("current"));

      if (values.value2) {
        navigate("/DashboardAdmin");
      } else {
        navigate("/shipping-cart");
      }
    }
  }, [navigate]);

  let handleSubmit = async (e) => {
    e.preventDefault();

    setsinginloader(true);

    try {
      const email = user.email;
      const password = user.password;

      if (!email || !password) {
        return; // Stop execution if fields are missing
      }

      const res = await axios.post(`${baseUrl}/login`, {
        email,
        password,
      });

      if (res && res.status === 200) {
        setValidation();
        const accessToken = res.data.token;
        localStorage.setItem("root", accessToken);
        const token = accessToken.split(".");

        const details = atob(token[1]);

        const isAdmin = JSON.parse(details);

        localStorage.setItem(
          "current",
          JSON.stringify({
            value: isAdmin.userId,
            value2: isAdmin.isAdmin,
          })
        );

        localStorage.setItem("userId", isAdmin.userId);
        Swal.fire({
          icon: "success",
          title: "Login Successful",
        });

        if (isAdmin.isAdmin) {
          navigate("/DashboardAdmin");
        } else {
          navigate("/shipping-cart");
        }
      }
    } catch (err) {
      if (err.response && err.response.status === 401) {
        setValidation("Incorrect email / password");
        Swal.fire({
          icon: "error",
          title: "Invalid Credentials",
          customClass: {
            container: "customstyle",
          }
        });
      } else {
        setTimeout(() => {
          Swal.fire({
            icon: "warning",
            title: "Server Error!",
            text: "We are facing some issue connecting to server. Please try again",
          });
        }, 3000);
      }
    }
  };

  return (
    <div className="font-bebas">
      <Warning />

      <Header />
      <div className="bef-aft">
        <div className="container-1640">
          <div className="toplinecon">
            <div className="hamicons">
              <ul style={{ opacity: "0" }}>
                <li>
                  <Link>Home</Link>
                </li>
                <li>
                  <Link>
                    {" "}
                    <i class="fa-solid fa-chevron-right"></i>{" "}
                  </Link>
                </li>
                <li>
                  <Link>Lighters</Link>
                </li>
                <li>
                  <Link>
                    {" "}
                    <i class="fa-solid fa-chevron-right"></i>{" "}
                  </Link>
                </li>
                <li>
                  <Link>Product Name</Link>
                </li>
                <li>
                  <Link>
                    {" "}
                    <i class="fa-solid fa-chevron-right"></i>{" "}
                  </Link>
                </li>
                <li>
                  <Link>Shopping Cart</Link>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <section className="checksection">
          <span className="buyleftspan">
            {" "}
            <img src={buyleftspan} alt="" />
          </span>
          <span className="buyrightspan">
            {" "}
            <img src={buyrightspan} alt="" />
          </span>
          <div className="container-1640">
            <h1 class="carttitle">Welcome, how would you like to continue?</h1>
            <div className="row checkoutrow">
              <div className="col-lg-5 dp mb-lg-0 mb-5">
                <div>
                  <h1 class="carttitle">Create an account</h1>
                  <ul className="mb-lg-0 mb-4">
                    <li>
                      <i class="fa-solid fa-check"></i>Keep track of previous
                      orders.
                    </li>
                    <li>
                      <i class="fa-solid fa-check"></i>Keep a Wish list.
                    </li>
                    <li>
                      <i class="fa-solid fa-check"></i>Write product reviews.
                    </li>
                  </ul>
                </div>
                <div className="signinbtn ">
                  <Link to="/signup">Sign Up</Link>
                </div>
              </div>
              <div className="col-lg-5">
                <div className="checkout-right-side-parent">
                  <h1 class="carttitle">Returning Customer</h1>
                  <label>Please log in below</label>
                  <input
                    type="email"
                    className="border poppins"
                    //required
                    name="email"
                    placeholder="Email Address"
                    value={user.email}
                    onChange={(e) => {
                      setUser({
                        ...user,
                        email: e.target.value.toLowerCase(),
                      });
                    }}
                    required
                  />
                  <span className="position-relative ">
                    <input
                      type={showConfirmPassword ? "text" : "password"}
                      name="password"
                      className="border mb-2 poppins"
                      //required
                      placeholder="Password"
                      value={user.password}
                      onChange={(e) => {
                        setUser({ ...user, password: e.target.value });
                      }}
                      required
                    />
                    <FontAwesomeIcon
                      className="eyeiconLogin checkOut"
                      onClick={togglePasswordVisibility2}
                      icon={showConfirmPassword ? faEyeSlash : faEye}
                    />
                  </span>

                  <Link to="/forgot-password" className="d-table my-3">
                    Password forgotten?
                  </Link>
                  <div className="signinbtn d-flex justify-content-between">
                    <button className="border-0" onClick={handleSubmit}>
                      Sign in
                    </button>
                    <Link to="/Guest">Guest Checkout</Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Footer />
      </div>
    </div>
  );
}

export default Checkout;
