import React, { useContext } from "react";
import Footer from "../Components/Footer/Footer";
import Header from "../Components/Header/Header";
import CartCancelImg from "../assets/images/cart-cancel.png"
import Warning from "../Components/Warning";
import { Link, useNavigate } from "react-router-dom";
import { useHistory } from 'react-router-dom';

import cohibalighter from "../assets/images/cohibalighter.png";
import buyleftspan from "../assets/images/buyleftspan.png";
import buyrightspan from "../assets/images/buyrightspan.png";
import { CartContext } from "../Components/Cart";
import { useState } from "react";
import { useEffect } from "react";
import { baseUrl } from "../assets/utils/IP";
import axios from "axios";
import Stripe from "./Stripe";
import Swal from "sweetalert2";
import { faTriangleExclamation } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";


function ShippingCart() {
  const { cartItems, getCartTotal, CancelProduct, clearCart } =
    useContext(CartContext);

  const [otherStreetAddress, setOtherStreetAddress] = useState();
  const [billingAdd, setBillingAdd] = useState();
  const [flag, setFlag] = useState(false);
  const [status, setStatus] = useState();
  const [payFlag, setPayFlag] = useState(false);
  const [isWorldwideShipping, setIsWorldwideShipping] = useState();
  const [isnationalwide, setIsNationalWide] = useState();
  const [ispickup, setIsPickUp] = useState();
  const [defaultStreetAddress, setDefaultStreetAddress] = useState();
  const [address, setAddress] = useState();
  console.log("address>>>", address)
  const [wAmount, setWAmount] = useState();
  const isGuest = localStorage.getItem('guestUserData') ? true : false



  const N_ShippingAmmount = 10
  const W_ShippingAmmount = 20
  const id = localStorage.getItem("userId")

  const productDataArray = [];

  const handleClick = (i) => {
    CancelProduct(i)
  }

  const handleCheck = (id) => {
    setIsNationalWide(false)
    setIsPickUp(false)
    setIsWorldwideShipping(false)

    if (id === 0) {
      setIsWorldwideShipping(true)
      setStatus(1)
    }
    else if (id === 1) {
      setIsNationalWide(true)
      setStatus(2)
    }
    else {
      setIsPickUp(true)
      setStatus(3)

    }
  }


  const handlePickUpAtStore = () => {
    setStatus(4)
  }

  const toggleShippingOption = (isWorldwide) => {
    setIsWorldwideShipping(isWorldwide);
  };


  useEffect(() => {
    if (isWorldwideShipping === true) {
      setWAmount(getCartTotal())
    }
    else {
      setWAmount(getCartTotal())
    }

  }, [isWorldwideShipping])

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (!isGuest) {
          const response = await axios.get(`${baseUrl}/get-street-address/${id}`);
          console.log("response>>>", response)
          setAddress(response.data)
        }
        else {
          const add = JSON.parse(localStorage.getItem('guestUserData')).streetaddress
          setAddress({ streetAddress: add })
        }
      }
      catch (error) {
      }
    };

    fetchData();
  }, [id]);

  useEffect(() => {
    setDefaultStreetAddress(address?.streetAddress)
  }, [isWorldwideShipping])


  const handleToggle = (e) => {
    setFlag(e.target.checked);
  };
  const handleToggle2 = (e) => {
    setPayFlag(e.target.checked);
  };



  const handleChange = (e) => {
    setOtherStreetAddress(e.target.value)
  }



  const dataToSend = {
    ShippingAddress: otherStreetAddress ? otherStreetAddress : defaultStreetAddress,
    defaultStreetAddress: defaultStreetAddress,
    shippingStatus: status,
    productDataArray: productDataArray,
    getCartTotal: getCartTotal(),
  };


  const navigate = useNavigate()
  const handleNavigate = () => {
    navigate("/ShippingPayNow", { state: { data: dataToSend } })
  }
  // const handleBillingChange = (e) => {
  //   setBillingAdd(e.target.value)
  // }


  const user_ID = localStorage.getItem("userId")

  const handlePayAtStoreOrder = async () => {
    try {
      let data = new FormData();
      let newData = {}
      if (isGuest) {
        newData = JSON.parse(localStorage.getItem('guestUserData'))
        // console.log('newData>>>', newData)
        Object.entries(newData).forEach(([key, value]) => {
          data.append(key, value);
        });
      }
      else {
        console.log('no guesData')
        data.append('user_id', user_ID);
      }

      data.append('shippingStatus', 4);
      const stringifiedOrderItems = JSON.stringify(productDataArray);
      data.append('order_items', stringifiedOrderItems);
      data.append('total_amount', getCartTotal());
      data.append('shipping_address', otherStreetAddress ? otherStreetAddress : defaultStreetAddress ? defaultStreetAddress : "NO ADDRESS")
      data.append('billingAddress', defaultStreetAddress ? defaultStreetAddress : "NO ADDRESS");


      let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: isGuest ? `${baseUrl}/create-guest-order` : `${baseUrl}/create-order`,
        headers: {
        },
        data: data,
      };

      const response = await axios.request(config);
      console.log("responsdew>>>", response?.data?.order)
      Swal.fire({
        icon: "success",
        title: "Order Successfully Placed",
        text: "Your order has been placed successfully",
        customClass: {
          container: "customstyle",
        },
      });
      clearCart()
      localStorage.removeItem("cartItems")
      localStorage.removeItem("guestUserData")
      navigate("/OrderComplete", { state: { ...response.data.order, ...newData } })

      // navigate("/OrderComplete", { state: response.data.order })
    }
    catch (error) {
      Swal.fire({
        icon: "error",
        title: "Order Failed",
        text: "Your order could not be submitted",
        customClass: {
          container: "customstyle",
        },
      });
    }
  };

  // console.log(isWorldwideShipping, "shipping status")
  // console.log(ispickup, "shipping status for ispickup")

  return (
    <div className="font-bebas">
      <Warning />

      <Header />
      <div className="bef-aft">
        <div className="container-1640">
          <div className="toplinecon shiphum">
            <div className="hamicons">
              <ul>
                <li>
                  <Link>Home</Link>
                </li>
                <li>
                  <Link>
                    {" "}
                    <i class="fa-solid fa-chevron-right"></i>{" "}
                  </Link>
                </li>
                <li>
                  <Link>Lighters</Link>
                </li>
                <li>
                  <Link>
                    {" "}
                    <i class="fa-solid fa-chevron-right"></i>{" "}
                  </Link>
                </li>
                <li>
                  <Link>Product Name</Link>
                </li>
                <li>
                  <Link>
                    {" "}
                    <i class="fa-solid fa-chevron-right"></i>{" "}
                  </Link>
                </li>
                <li>
                  <Link>Shopping Cart</Link>
                </li>
              </ul>
            </div>
          </div>

          <section className="shippingsection">
            <span className="buyleftspan">
              {" "}
              <img src={buyleftspan} />
            </span>
            <span className="buyrightspan">
              {" "}
              <img src={buyrightspan} />
            </span>
            <div className="row">
              <div className="col-lg-4">
                <h1 class="carttitle">
                  <i class="fa-solid fa-truck"></i>Your Shopping Cart
                </h1>

                <div className="categoriesitem frequent">
                  <div className="form-group">
                    <input
                      type="checkbox"
                      id="html101"
                      checked={isWorldwideShipping}
                      onChange={() => handleCheck(0)}
                    />
                    <label htmlFor="html101"></label>
                  </div>
                  <div className="catecon">
                    <p>Worldwide Shipping</p>
                  </div>
                </div>
                <div className="categoriesitem frequent">
                  <div className="form-group">
                    <input
                      type="checkbox"
                      id="html1011"
                      checked={isnationalwide}
                      onChange={() => handleCheck(1)}
                    />
                    <label htmlFor="html1011"></label>
                  </div>
                  <div className="catecon">
                    <p>Nationwide Shipping</p>
                  </div>
                </div>
                <div className="categoriesitem frequent">
                  <div className="form-group">
                    <input
                      type="checkbox"
                      id="2010"
                      checked={ispickup}
                      onChange={() => handleCheck(2)}
                    />
                    <label htmlFor="2010"></label>
                  </div>
                  <div className="catecon">
                    <p>Pickup from the store</p>
                  </div>
                </div>

                {(isnationalwide || isWorldwideShipping) && <div className="shipaddress">
                  <label>Billing Address</label>
                  <textarea value={defaultStreetAddress} onChange={handleChange} readOnly> </textarea>
                </div>}

                {(isnationalwide || isWorldwideShipping) && (
                  <>
                    <div className="togglebtn">
                      <input type="checkbox" id="switch" onChange={handleToggle} />
                      <label htmlFor="switch">
                        Toggle
                        Shipping Address
                        <textarea id="add" value={otherStreetAddress} onChange={handleChange}> </textarea>
                      </label>
                      <p className="ms-2">Add other Shipping Address</p>

                    </div><div className="subtotalbtn shipbtn">
                      <button onClick={handleNavigate}>Pay Now</button>
                    </div></>)}
                {ispickup &&
                  <div className="subtotalbtn shipbtn">
                    <button onClick={handleNavigate}>Pay Now</button >
                    <Link to="" onClick={handlePickUpAtStore} data-bs-toggle="modal" data-bs-target="#exampleModalPayAtStore">Pay at store</Link>
                  </div>
                }


                {/* <Stripe totalAmount={wAmount} productData={productDataArray} data={dataToSend} /> */}



                {/* <div class="subtotalbtn shipbtn d-flex justify-content-between">
                  {billingAdd && !payFlag ? (
                    <>
                      <Stripe totalAmount={wAmount} productData={productDataArray} data={dataToSend} />
                      <Link className="px-3 d-table" to="/sales">
                        <i class="fa-solid fa-chevron-left"></i> Continue Shopping
                      </Link>
                    </>
                  ) : billingAdd && payFlag ? (
                    <>
                      <button onClick={handleOrderComplete}> Complete Order </button>
                      <Link className="px-3 d-table" to="/sales" style={{ marginRight: "297px" }}>
                        <i class="fa-solid fa-chevron-left"></i> Continue Shopping
                      </Link>
                    </>
                  ) : (
                    <Link className="px-3 d-table" to="/sales" style={{ marginRight: "297px" }}>
                      <i class="fa-solid fa-chevron-left"></i> Continue Shopping
                    </Link>
                  )}
                </div> */}


              </div>
              <div className="col-lg-2"> </div>

              <div className="col-lg-6">
                <h1 class="carttitle">Order Summary</h1>

                {cartItems?.map((item, index) => {
                  const productData = {
                    product_id: item.product_id,
                    quantity: item.p_quantity,
                    price: item.sale_price,
                  };

                  productDataArray.push(productData);

                  return (<div key={item.product_id} className="cartdiscription mb-3">
                    <div className="cartProd">
                      <img src={`${baseUrl}/${item?.main_image}`} alt={`Product ${index}`} />
                    </div>

                    <div className="prodetailcart">
                      <h4>{item.product_name}</h4>
                      <p>${item.sale_price == 0 ? item.regular_price : item.sale_price} x {item.p_quantity}</p>
                    </div>
                    {/* <img src={CartCancelImg} style={{ position: "absolute", right: "20px" }} onClick={() => handleClick(item)} /> */}


                  </div>)
                })
                }
                <div className="subtotal">
                  <h6>Sub Total:</h6>
                  <p>${getCartTotal()}</p>
                </div>
                <div className="subtotal" style={{ color: "white" }}>

                  <h6>{isWorldwideShipping
                    ? `Worldwide Shipping Amount is $${W_ShippingAmmount}`
                    : ispickup ? "No Shipping"
                      : `Nationwide Shipping Amount is $${N_ShippingAmmount}`}</h6>
                  <h6>Grand Total:</h6>
                  <p>
                    ${isWorldwideShipping
                      ? W_ShippingAmmount + getCartTotal()
                      : ispickup ? getCartTotal()
                        : N_ShippingAmmount + getCartTotal()}
                  </p>
                </div>
              </div>
            </div>
          </section>
        </div>

        <Footer />
      </div>
      <div
        className="modal fade shippingcart-modal"
        id="exampleModalPayAtStore"
        tabIndex={-1}
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog delet-popup d-flex align-items-center justify-content-center h-100">
          <div className="modal-content p-3 rounded-5">
            <div className="modal-header border-0">
              <button
                type="button"
                className="btn-close text-light"
                data-bs-dismiss="modal"
                aria-label="Close"
              />
            </div>
            <div className="modal-body text-center delete-modal-responsive">
              <FontAwesomeIcon icon={faTriangleExclamation} fontSize="50px" style={{ color: "#e8cc11", }} />              <h1 className="text-light">
                Are you sure you want to make the payment at store
              </h1>
            </div>
            <div className="modal-footer justify-content-center border-0">
              <button
                type="button"
                className="btn btn-gradient"
                data-bs-dismiss="modal"
              >
                Go Back
              </button>
              <button
                type="button"
                className="btn btn-danger"
                data-bs-dismiss="modal"
                onClick={handlePayAtStoreOrder}
              >
                Yes Complete Order Anyway
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>




  );
}

export default ShippingCart;
