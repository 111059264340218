import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from "@fortawesome/free-solid-svg-icons";

export const RatingDisplay = ({ review_rating }) => {
  const stars = [];
  for (let i = 0; i < review_rating; i++) {
    stars.push(<FontAwesomeIcon key={i} icon={faStar} />);
  }

  return <div className="mange-star-parent">{stars}</div>;
};
