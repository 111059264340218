import React, { useEffect, useRef, useState } from "react";
import Warning from "../Components/Warning";
import Header from "../Components/Header/Header";
import "rc-slider/assets/index.css";
import { Link } from "react-router-dom";
import brandvideo from "../assets/video/brandvideo.mp4";
import smokepromotionspan from "../assets/images/smoke-promotion-span.png";
import buyleftspan from "../assets/images/buyleftspan.png";
import buyrightspan from "../assets/images/buyrightspan.png";
import smokeExquisite from "../assets/images/smokeExquisite.png";
import Footer from "../Components/Footer/Footer";
import axios from "axios";
import { baseUrl } from "../assets/utils/IP";
function Brand() {
  const [brandLogo, setBrndLogo] = useState()
  const [currentPage, setCurrentPage] = useState(1);


  useEffect(() => {
    const getBrands = async () => {
      try {
        const response = await axios.get(`${baseUrl}/get-all-brands`);
        if (response?.status == 200) {
          setBrndLogo(response?.data);
        }
      } catch (err) {
      }
    };
    getBrands();
  }, []);


  // pagination
  const itemsPerPage = 5;
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const ordersToDisplay = brandLogo?.reverse().slice(startIndex, endIndex);
  const totalPages = Math.ceil(brandLogo?.reverse().length / itemsPerPage);
  //



  return (
    <div className="av font-bebas">
      <Warning />
      <div className="brand-hover">
        <Header />
      </div>

      <section className="bannersec brandnamepages brand-page-resp-sec">
        <section className="secshop d-none">
          <span className="buyleftspan">
            {" "}
            <img src={buyleftspan} alt="" />
          </span>
          <span className="buyrightspan">
            {" "}
            <img src={buyrightspan} alt="" />
          </span>
          <div className="container-1640">
            <div className="toplinecon">
              <div className="hamicons">
                <ul>
                  <li>
                    <Link>Home</Link>
                  </li>
                  <li>
                    <Link>
                      {" "}
                      <i class="fa-solid fa-chevron-right"></i>{" "}
                    </Link>
                  </li>
                  <li>
                    <Link>Shop</Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>

        <div class="bg-video-wrap inner-bg-video">
          <video src={brandvideo} loop muted autoPlay playsInline></video>
          <div class="overlay"></div>
          <div className="row">
            <h1 class="title-mean">
              {" "}
              <span>All </span> Brands
              <img src={smokeExquisite} alt="" />
            </h1>
            <div className="col-lg-12">
              <div className="brandname">
                <div className="brandiamges d-flex justify-content-between align-items-center">
                  {ordersToDisplay?.map((brandImage, index) => (
                    <div className="mx-4">
                      <Link to={`/shop/${brandImage?.brand_id}`} key={index}>
                        <img src={`${baseUrl}/${brandImage?.brand_logo}`} alt={`Brand ${index}`} />
                        <img src={smokepromotionspan} alt="Promotion" />
                      </Link>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </div>
  );
}

export default Brand;
