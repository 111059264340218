import React from "react";
import { Link, useParams } from "react-router-dom";
import SideBar from "../../../Components/Admin/SideBar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircleChevronLeft,
  faInfoCircle,
} from "@fortawesome/free-solid-svg-icons";
import { AuthorizationToken, baseUrl } from "../../../assets/utils/IP";
import { useState } from "react";
import { useEffect } from "react";
import axios from "axios";
import Swal from "sweetalert2";

export default function AdminOrder() {
  const [orderById, setOrderById] = useState()
  const [orderMany, setOrderMany] = useState()
  const [userData, setUserData] = useState()
  const [selectedOrderId, setSelectedOrderId] = useState()
  const [billing, setBilling] = useState()
  const [Modal, setModalOpen] = useState(false);
  const [address, setAddress] = useState();
  const [address2, setAddress2] = useState();
  const [isChecked, setIsChecked] = useState(true);
  const [comment, setComment] = useState("");

  const [formData, setFormData] = useState({
    selectedStatus: '',
  });
  const [historyData, setHistoryData] = useState([]);

  const handleCheckboxChange = (e) => {
    setIsChecked(!isChecked); // Toggle the state

  }

  const handleComment = (e) => {
    setComment(e.target.value)
  }


  const { id } = useParams()





  useEffect(() => {
    const fetchData = async () => {
      try {
        let config = {
          method: 'get',
          maxBodyLength: Infinity,
          url: `${baseUrl}/get-order-by-id/${id}`,
          headers: {
            'Authorization':
              `Bearer ${AuthorizationToken}`,
          },
        };

        const response = await axios.request(config);
        setOrderById(response.data?.orderItems[0])
        setSelectedOrderId(response.data?.orderItems[0]?.order_id)
        setOrderMany(response.data?.orderItems)



      } catch (error) {
      }
    };

    fetchData();
  }, []);

  const userid = orderById?.user_id
  console.log(userid, "id")

  useEffect(() => {
    const fetchData = async () => {
      try {
        let config = {
          method: 'get',
          maxBodyLength: Infinity,
          url: `${baseUrl}/get-billing-address/${userid}`,
          headers: {},
        };

        const response = await axios.request(config);
        setBilling(response.data.user)
      } catch (error) {
      }
    };

    fetchData();
  }, [userid]);
  console.log(selectedOrderId, "selectedOrderId")
  useEffect(() => {
    // Inside the useEffect, place your Axios request
    const config = {
      method: 'get',
      maxBodyLength: Infinity,
      url: `${baseUrl}/get-user-order-id/${selectedOrderId}`,
      headers: {},
    };

    axios.request(config)
      .then((response) => {
        setUserData(response.data)


      })
      .catch((error) => {
      });
  }, [selectedOrderId]);


  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${baseUrl}/get-street-address/${userid}`);
        setAddress2(response?.data)
      } catch (error) {
      }
    };

    fetchData();
  }, [userid]);



  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${baseUrl}/get-shipping-address/${selectedOrderId}`);
        setAddress(response?.data?.order)
      } catch (error) {
      }
    };

    fetchData();
  }, [selectedOrderId]);

  console.log(address, "addresssss ")

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!comment || !formData.selectedStatus) {
      Swal.fire({
        icon: "error",
        title: "Comment and Status are required.",
      });
      return; // Don't proceed if comment or status is empty
    }

    let data = {
      user_id: billing?.user_id,
      order_id: id,
      comment: comment,
      status: formData.selectedStatus,
      notify: isChecked ? false : true,
    };

    let config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: `${baseUrl}/create-history`,
      headers: {
        'Authorization':
          `Bearer ${AuthorizationToken}`,
      },
      data: data
    };

    axios
      .request(config)
      .then((response) => {
        Swal.fire({
          icon: "success",
          title: "Product Added in History List",
          confirmButtonText: "Refresh",
        }).then((result) => {
          if (result.isConfirmed) {
            window.location.reload(); // Reload the window if the "Refresh" button is confirmed
          }
        });

      })
      .catch((error) => {
      });

  };
  const handleStatusChange = (e) => {
    setFormData({ ...formData, selectedStatus: e.target.value });
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const headers = {
          'Authorization':
            `Bearer ${AuthorizationToken}`,
        };

        const response = await axios.get(`${baseUrl}/get-history-by-user/${id}`, { headers });
        setHistoryData(response.data.history);
      } catch (error) {
        // Handle errors
      }
    };

    fetchData();
  }, [orderById?.order_id]);

  const downloadReceipt = () => {
    const imageUrl = `${baseUrl}/${userData?.orderItems[0].receipt}`;

    // Fetch the image as a blob
    fetch(imageUrl)
      .then(response => response.blob())
      .then(blob => {
        // Create a blob URL for the image blob
        const blobUrl = URL.createObjectURL(blob);

        // Create a temporary link element
        const link = document.createElement('a');

        // Set the href attribute to the blob URL
        link.href = blobUrl;

        // Specify the download attribute with the desired file name
        link.download = 'receipt_image.png';

        // Append the link element to the document
        document.body.appendChild(link);

        // Trigger a click event on the link element
        link.click();

        // Remove the link element from the document
        document.body.removeChild(link);

        // Revoke the blob URL to free up resources
        URL.revokeObjectURL(blobUrl);
      })
      .catch(error => {
      });
  };



  return (
    <section className="bg-main">
      <SideBar />
      <div className="second-main-side">
        <div className="sec-main-side-parent p-md-4 p-lg-5">
          <div className="blur-pages px-5">
            <div className="border-text">
              <div className="d-flex justify-content-between">
                <h1>Order</h1>
                <div className="boxs-flex">
                  {/* <Link
                    className="p-3 justify-content-center bg-yellow w-25 h-25 d-flex align-items-center text-dark rounded-2"
                    to=""
                  >
                    <FontAwesomeIcon color="red" icon={faTrash} />
                  </Link> */}
                  <Link
                    className="p-3 justify-content-center bg-light w-25 h-25 d-flex align-items-center text-dark rounded-2"
                    to="/AdminSales"
                  >
                    <FontAwesomeIcon icon={faCircleChevronLeft} />
                  </Link>
                </div>
              </div>
              <hr className="text-light opacity-100" />
            </div>
            <div className="pd-customer-row py-2">
              <div className="pd-customer-row ">
                <div className="add-categorie-list py-3">
                  <FontAwesomeIcon color="#fff" icon={faInfoCircle} />
                  <span className="mx-2">Order ({orderById?.order_id})</span>
                </div>
                <div className="inv-row-parent">
                  <div className="row py-4">
                    <div className="col-lg-4">
                      <div className="env-box position-relative text-dark bg-light">
                        <h5>Invoice</h5>
                        <p>{orderById?.order_id}</p>
                        <div className="setting-icn-box">
                          {/* <button
                            className="h-100 w-100 px-1 bg-transparent border-0"
                            data-bs-toggle="modal"
                            data-bs-target="#exampleModal"
                          >
                            <FontAwesomeIcon icon={faGear} />
                          </button> */}
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-4">
                      <div className="env-box bg-light text-dark">
                        <h5>Customer</h5>
                        <p>{orderById?.user_first_name} {orderById?.user_last_name}</p>
                      </div>
                    </div>
                    <div className="col-lg-4">
                      <div className="env-box bg-light text-dark">
                        <h5>Date Added</h5>
                        <p>{orderById?.created_at.substring(0, 10)}</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="main-fi-order-box-master">
              <div className="main-fi-order-box">
                <div className="sales-view-tble-parent">
                  <div className="sales-view-table">
                    <div className="sales-view-table-inner sale-indent text-center">
                      <p>Product</p>
                    </div>
                    <div className="sales-view-table-inner text-center">
                      <p>Brand</p>
                    </div>
                    <div className="sales-view-table-inner text-center">
                      <p>Quantity</p>
                    </div>
                    <div className="sales-view-table-inner text-center">
                      <p>Unit Price</p>
                    </div>
                    <div className="sales-view-table-inner text-center">
                      <p>Total</p>
                    </div>
                    {/* <div className="sales-view-table-inner text-center">
                      <p>Action</p>
                    </div> */}
                  </div>


                  {orderMany?.map((item, index) => (
                    <div key={index} className="sales-view-table sales-view-table-semi text-light">
                      <div className="sales-view-table-inner sale-indent brdr-right-sl-view text-center">
                        <p>{item.product_name}</p>
                      </div>
                      <div className="sales-view-table-inner text-center brdr-right-sl-view">
                        <p>{item.brand_name}</p>
                      </div>
                      <div className="sales-view-table-inner sale-indent brdr-right-sl-view text-center">
                        <p>{item.quantity}</p>
                      </div>
                      <div className="sales-view-table-inner sale-indent brdr-right-sl-view">
                        <p>{item.sale_price == 0 ? item.regular_price : item.sale_price}</p>
                      </div>
                      <div className="sales-view-table-inner sale-indent brdr-right-sl-view">
                        <p>{item.sale_price == 0 ? item.regular_price * item.quantity : item.sale_price * item.quantity}</p>
                      </div>
                    </div>
                  ))}


                </div>
                </div>
                </div>
                <div className="billing-row-parent my-4">
                  <div className="row">
                    <div className="col-lg-6">
                      <div className="billing-box rounded-3 position-relative">
                        <h5>Payment Address</h5>
                        <p>{billing?.user_first_name} {billing?.user_last_name} </p>
                        <p>{billing?.phone_number}</p>
                        <p>
                          {billing?.billing_address}
                        </p>
                        <p>{billing?.passport_number}</p>
                        <p>{billing?.city}</p>
                        <p>{billing?.country}</p>
                        {/* <button
                          className="setting-icn-box btn"
                          data-bs-toggle="modal"
                          data-bs-target="#exampleModal1"
                        >
                          <FontAwesomeIcon icon={faGear} />
                        </button> */}
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div style={{marginTop: "20px"}} className="billing-box rounded-3 position-relative">
                        <h5>Shipping Address</h5>
                        <p>{billing?.user_first_name} {billing?.user_last_name} </p>
                        <p>{billing?.phone_number}</p>
                        <p>
                          {address?.shipping_address ? address?.shipping_address : address2?.streetAddress}
                        </p>
                        <p>{billing?.passport_number}</p>
                        <p>{billing?.city}</p>
                        <p>{billing?.country}</p>
                        {/* <button
                          className="setting-icn-box btn"
                          data-bs-toggle="modal"
                          data-bs-target="#exampleModal2"
                        >
                          <FontAwesomeIcon icon={faGear} />
                        </button> */}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="method-payment-row-prnt">
                  <div className="row">
                    <div style={{marginBottom:"10px"}} className="col-lg-6">
                      <div className="py-mthd-box">
                        <h5>Shipping Method</h5>
                        <p>{userData?.orderItems[0].shipping_status == 1 ? "World Wide Shipping $20" :
                          userData?.orderItems[0].shipping_status == 2 ? "Nation Wide Shipping $10" :
                            "Pickup from Store"}</p>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="py-mthd-box">
                        <div className="py-mthd-box">
                          <h5>Payment Method</h5>
                          <div className="d-flex justify-content-between myclass-addsaleveiw">
                            <span>{userData?.orderItems[0].receipt != null ? "Bank Transfer" : "Pay at store"}</span>
                            <button className="btn-gradient btn" onClick={() => setModalOpen(true)} >View Reciept</button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="comment-row my-4">
                  <div className="row">
                    <div className="col-lg-12">

                    </div>
                  </div>
                </div>
                <div className="total-price-row ">
                  <div className="row">
                    <div className="col-md-11 col-10 pe-0 my-width-sales">
                      <div className="border  border-1 text-end bg-light">
                        <p className="border p-1 fw-bold">Flat Shipping Rate</p>
                        <p className="border p-1 fw-bold">Sub Total</p>
                        {/* <p className="border p-1 fw-bold">Flat Shipping Rate</p>
                        <p className="border p-1 fw-bold">Total</p> */}
                      </div>
                    </div>
                    <div className="col-md-1 col-2 ps-0">
                      <div className="border border-1 text-center bg-light my-width-sales2">
                        <p className="border p-1">{userData?.orderItems[0].shipping_status == 1 ? "$20" :
                          userData?.orderItems[0].shipping_status == 2 ? "$10" :
                            "$0"}</p>
                        <p className="border p-1">${orderById?.total_amount}</p>
                        {/* <p className="border p-1">${totalAmount + 100}</p> */}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="main-fi-order-box-master">
              <div className="main-fi-order-box">
                <div className="sales-history-tble-parent my-4">
                  <div className="sales-history-table bg-golden-gradeint d-grid p-3">
                    <div className="sales-view-table-inner sale-indent text-center">
                      <p>Date</p>
                    </div>
                    <div className="sales-view-table-inner text-center">
                      <p>Comment</p>
                    </div>
                    <div className="sales-view-table-inner text-center">
                      <p>Status</p>
                    </div>
                    <div className="sales-view-table-inner text-center">
                      <p>Notify User</p>
                    </div>
                  </div>
                  {historyData?.map((item, index) => (
                    <div key={index} className="sales-history-table d-grid text-light border">
                      <div className="sales-view-table-inner sale-indent brdr-right-sl-view p-2 text-center">
                        <p>{item.created_at.substring(0, 10)}</p>
                      </div>
                      <div className="sales-view-table-inner text-center brdr-right-sl-view p-2">
                        <p>{item.comment}</p>
                      </div>
                      <div className="sales-view-table-inner sale-indent brdr-right-sl-view p-2 text-center">
                        <p>{item.status}</p>
                      </div>
                      <div className="sales-view-table-inner sale-indent brdr-right-sl-view p-2 text-center">
                        <p>{item.notify === 0 ? "No" : "Yes"}</p>
                      </div>
                    </div>
                  ))}
                  {/* <div className="sales-history-table d-grid text-light border">
                    <div className="sales-view-table-inner sale-indent brdr-right-sl-view p-2">
                      <p>29/07/2023</p>
                    </div>
                    <div className="sales-view-table-inner text-center brdr-right-sl-view p-2">
                      <p>Product 2</p>
                    </div>
                    <div className="sales-view-table-inner sale-indent brdr-right-sl-view p-2 text-center">
                      <p>Pending</p>
                    </div>
                    <div className="sales-view-table-inner sale-indent brdr-right-sl-view p-2 text-center">
                      <p>No</p>
                    </div>
                  </div> */}
                </div>
                </div>
                </div>
                <form action="" onSubmit={handleSubmit}>
                  <div className="add-categorie-list py-3">
                    <FontAwesomeIcon color="#fff" icon={faInfoCircle} />
                    <span className="mx-2">Order (#{orderById?.order_id})</span>
                  </div>
                  <div className="grid-add d-grid align-items-center my-4">
                    <label className="text-light d-block text-end" htmlFor="">
                      Order Status
                    </label>
                    <select className="py-1 px-2" value={formData.selectedStatus} onChange={handleStatusChange}>
                      <option value="">Select</option>
                      <option value="In Progress">In Progress</option>
                      <option value="pending">Pending</option>
                      <option value="complete">Complete</option>
                      <option value="cancelled">Canceled</option>
                    </select>
                  </div>

                  <hr className="text-light my-4" />

                  <div className="grid-add d-grid my-4 form-check form-switch p-0">
                    <label className="text-light d-block text-end" htmlFor="">
                      Notify Customer
                    </label>
                    <input
                      type="checkbox"
                      className="form-check-input m-0"
                      id="flexSwitchCheckDefault"
                      checked={!isChecked}
                      onChange={handleCheckboxChange}
                    />
                    {/* <label htmlFor="flexSwitchCheckDefault">Toggle</label> */}
                  </div>
                  <hr className="text-light my-4" />
                  <div className="grid-add d-grid my-4">
                    <label className="text-light d-block text-end" htmlFor="">
                      Comment
                    </label>
                    <textarea
                      rows="5"
                      className="py-1 px-2"
                      type="text"
                      value={comment}
                      onChange={handleComment}
                    ></textarea>
                  </div>
                  <div className="grid-add d-grid my-4">
                    <label htmlFor=""></label>
                    <input
                      className="fw-bold add-save-btn w-25 rounded-3 border-0 ms-auto"
                      type="submit"
                      value="+ Add History "
                    />
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* MODAL  1*/}
      <div
        className="modal fade sales-view-invoice-modal"
        id="exampleModal"
        tabIndex={-1}
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header justify-content-center">
              <h1
                className="modal-title fs-5 text-light text-center"
                id="exampleModalLabel"
              >
                INV-2022-000
              </h1>
            </div>
            <div className="modal-body p-0">
              <div className="inv-modal-input-parent">
                <div className="grid-inpt-modal-parent">
                  <div className="grid-inpt-modal">
                    <label htmlFor="inv-mdl-one text-light">Order #</label>
                    <input type="number" placeholder={1446} id="inv-mdl-one" />
                  </div>
                  <div className="grid-inpt-modal">
                    <label htmlFor="inv-mdl-two">Date Added</label>
                    <input
                      type="text"
                      placeholder="25/07/2023"
                      id="inv-mdl-two"
                    />
                  </div>
                </div>
                <div className="grid-inpt-modal-parent">
                  <div className="grid-inpt-modal">
                    <label htmlFor="inv-mdl-three">Name</label>
                    <input
                      type="text"
                      placeholder="Mark Allen"
                      id="inv-mdl-three"
                    />
                  </div>
                  <div className="grid-inpt-modal">
                    <label htmlFor="inv-mdl-four">Email</label>
                    <input
                      type="email"
                      placeholder="mark_allen@gmail.com"
                      id="inv-mdl-four"
                    />
                  </div>
                  <div className="grid-inpt-modal">
                    <label htmlFor="inv-mdl-five">Contact #</label>
                    <input
                      type="number"
                      placeholder="1234 567 890"
                      id="inv-mdl-five"
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-6">
                    <div className="grid-inpt-modal">
                      <label htmlFor="inv-mdl-six">Shipping Address</label>
                      <textarea
                        id="inv-mdl-six"
                        className="fs-7 text-dark"
                        placeholder="Lorem ipsum dolor sit amet, consect etur adipiscing elit, sed do eiusmod 
tempor incididunt magna aliqua."
                        defaultValue={""}
                      />
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="grid-inpt-modal">
                      <label htmlFor="inv-mdl-six">Billing Address</label>
                      <textarea
                        id="inv-mdl-six"
                        className="fs-7 text-dark"
                        placeholder="Lorem ipsum dolor sit amet, consect etur adipiscing elit, sed do eiusmod 
tempor incididunt magna aliqua."
                        defaultValue={""}
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-12">
                    <div className="grid-inpt-modal">
                      <label htmlFor="inv-mdl-seven">Payment Method</label>
                      <input
                        type="text"
                        id="inv-mdl-six"
                        placeholder="Via Bank Transfer"
                      />
                    </div>
                  </div>
                </div>
                <div className="table-modal-parent">
                  <div className="table-modal-inner-parent">
                    <div className="table-modal-inner text-center mdl-right-border">
                      <p>Product</p>
                    </div>
                    <div className="table-modal-inner text-center mdl-right-border">
                      <p>Brand</p>
                    </div>
                    <div className="table-modal-inner text-center mdl-right-border">
                      <p>Quantity</p>
                    </div>
                    <div className="table-modal-inner text-center mdl-right-border">
                      <p>Unit Price</p>
                    </div>
                    <div className="table-modal-inner text-start pd-left-mdl-ttl">
                      <p>Total</p>
                    </div>
                  </div>
                  <div className="table-modal-inner-parent mdl-top-border">
                    <div className="table-modal-inner text-center mdl-right-border">
                      <p>Product Name</p>
                    </div>
                    <div className="table-modal-inner text-center mdl-right-border">
                      <p>Dunhill</p>
                    </div>
                    <div className="table-modal-inner text-center mdl-right-border">
                      <p>2</p>
                    </div>
                    <div className="table-modal-inner text-center mdl-right-border">
                      <p>$122.00</p>
                    </div>
                    <div className="table-modal-inner text-start pd-left-mdl-ttl">
                      <p>$244.00</p>
                    </div>
                  </div>
                  <div className="total-price-grid ">
                    <div>
                      <div className="text-end text-light">
                        <p className="border p-1">Sub Total</p>
                        <p className="border p-1">Flat Shipping Rate</p>
                        <p className="border p-1">Total</p>
                      </div>
                    </div>
                    <div className=" border border-1 text-center bg-light">
                      <p className="border p-1">$200.00</p>
                      <p className="border p-1">$50.00</p>
                      <p className="border p-1">$250.00</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer border-0">
              <button
                type="button"
                className="btn btn-gradient"
                data-bs-dismiss="modal"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* MODAL  1*/}
      {/* MODAL  2*/}
      <div
        class="modal fade sales-view-invoice-modal"
        id="exampleModal1"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog">
          <div class="modal-content p-3">
            <div class="modal-header  border-0">
              <h5 class="modal-title text-light" id="exampleModalLabel">
                Payment Address
              </h5>
            </div>
            <div class="modal-body">
              <form action="">
                <div className="my-1 ">
                  <label className="text-light fs-7" htmlFor="">
                    Full Name
                  </label>
                  <input type="text" className="w-100 my-1 p-1 fs-7" />
                </div>
                <div className="my-1 ">
                  <label className="text-light fs-7" htmlFor="">
                    Contact Number
                  </label>
                  <input type="number" className="w-100 my-1 p-1 fs-7" />
                </div>
                <div className="my-1 ">
                  <label className="text-light fs-7" htmlFor="">
                    Email Address
                  </label>
                  <input type="email" className="w-100 my-1 p-1 fs-7" />
                </div>
                <div className="my-1 ">
                  <label className="text-light fs-7" htmlFor="">
                    Billing Address
                  </label>
                  <input type="text" className="w-100 my-1 p-1 fs-7" />
                </div>
                <div className="my-1 ">
                  <label className="text-light fs-7" htmlFor="">
                    Shipping Address
                  </label>
                  <input type="text" className="w-100 my-1 p-1 fs-7" />
                </div>
                <div className="my-1 ">
                  <label className="text-light fs-7" htmlFor="">
                    Country
                  </label>
                  <input type="text" className="w-100 my-1 p-1 fs-7" />
                </div>
                <div className="my-1 ">
                  <label className="text-light fs-7" htmlFor="">
                    City
                  </label>
                  <input type="text" className="w-100 my-1 p-1 fs-7" />
                </div>
                <div className="my-1 ">
                  <label className="text-light fs-7" htmlFor="">
                    Post Code
                  </label>
                  <input type="text" className="w-100 my-1 p-1 fs-7" />
                </div>
                <div className="my-1 ">
                  <label className="text-light fs-7" htmlFor="">
                    Special Instruction
                  </label>
                  <textarea type="text" className="w-100 my-2 fs-7"></textarea>
                </div>
                <div className="my-2 text-end">
                  <button
                    type="button"
                    className="btn-secondary border-0 rounded p-1 px-3 me-4"
                    data-bs-dismiss="modal"
                  >
                    Close
                  </button>
                  <input
                    type="submit"
                    value="Save"
                    className="btn-gradient border-0 rounded p-1 px-3"
                  />
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      {/* MODAL  2*/}
      {/* MODAL  3*/}
      <div
        class="modal fade sales-view-invoice-modal"
        id="exampleModal2"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog">
          <div class="modal-content p-3">
            <div class="modal-header  border-0">
              <h5 class="modal-title text-light" id="exampleModalLabel">
                Shipping Address
              </h5>
            </div>
            <div class="modal-body">
              <form action="">
                <div className="my-1 ">
                  <label className="text-light fs-7" htmlFor="">
                    Full Name
                  </label>
                  <input type="text" className="w-100 my-1 p-1 fs-7" />
                </div>
                <div className="my-1 ">
                  <label className="text-light fs-7" htmlFor="">
                    Contact Number
                  </label>
                  <input type="number" className="w-100 my-1 p-1 fs-7" />
                </div>
                <div className="my-1 ">
                  <label className="text-light fs-7" htmlFor="">
                    Email Address
                  </label>
                  <input type="email" className="w-100 my-1 p-1 fs-7" />
                </div>
                <div className="my-1 ">
                  <label className="text-light fs-7" htmlFor="">
                    Billing Address
                  </label>
                  <input type="text" className="w-100 my-1 p-1 fs-7" />
                </div>
                <div className="my-1 ">
                  <label className="text-light fs-7" htmlFor="">
                    Shipping Address
                  </label>
                  <input type="text" className="w-100 my-1 p-1 fs-7" />
                </div>
                <div className="my-1 ">
                  <label className="text-light fs-7" htmlFor="">
                    Country
                  </label>
                  <input type="text" className="w-100 my-1 p-1 fs-7" />
                </div>
                <div className="my-1 ">
                  <label className="text-light fs-7" htmlFor="">
                    City
                  </label>
                  <input type="text" className="w-100 my-1 p-1 fs-7" />
                </div>
                <div className="my-1 ">
                  <label className="text-light fs-7" htmlFor="">
                    Post Code
                  </label>
                  <input type="text" className="w-100 my-1 p-1 fs-7" />
                </div>
                <div className="my-1 ">
                  <label className="text-light fs-7" htmlFor="">
                    Special Instruction
                  </label>
                  <textarea type="text" className="w-100 my-2 fs-7"></textarea>
                </div>
                <div className="my-2 text-end">
                  <button
                    type="button"
                    className="btn-secondary border-0 rounded p-1 px-3 me-4"
                    data-bs-dismiss="modal"
                  >
                    Close
                  </button>
                  <input
                    type="submit"
                    value="Save"
                    className="btn-gradient border-0 rounded p-1 px-3"
                  />
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      {/* MODAL  3*/}
      {/* MODAL  3*/}
      <div
        class="modal fade sales-view-invoice-modal"
        id="exampleModal3"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog d-flex align-items-center justify-content-center h-75">
          <div class="modal-content p-3">
            <div class="modal-header  border-0">
              <h5 class="modal-title text-light" id="exampleModalLabel">
                Comment
              </h5>
            </div>
            <div class="modal-body">
              <form action="">
                <div>
                  <label className="d-block text-light" htmlFor="">
                    Comment
                  </label>
                  <textarea
                    type="text"
                    rows="7"
                    className="w-100 my-2 fs-7"
                  ></textarea>
                </div>

                <div className="my-2 text-end">
                  <button
                    type="button"
                    className="btn-secondary border-0 rounded p-1 px-3 me-4"
                    data-bs-dismiss="modal"
                  >
                    Close
                  </button>
                  <input
                    type="submit"
                    value="Save"
                    className="btn-gradient border-0 rounded p-1 px-3"
                  />
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      {/* MODAL  3*/}
      {Modal ?
        <div className="align-items-center d-flex h-100 justify-content-center myclass-addsale position-fixed top-0 w-100 z-1560">
          <div className="w-50">
            <div className="pd-responsive-modal set-the-modal-box-order-one delet-popup justify-content-center">
              <div className="modal-content modal-border-set p-5">

                <div className="modal-flex-status text-center">
                  <img className="w-50 m-auto" src={`${baseUrl}/${userData?.orderItems[0].receipt}`} alt="There is no payment recipt" />
                </div>
                <div className="d-flex justify-content-center">
                  <button onClick={() => setModalOpen(false)} className="btn btn-secondary me-4">Close</button>
                  <button onClick={downloadReceipt} className="btn btn-gradient ">Download Receipt</button>
                </div>
              </div>
            </div>
          </div>
        </div> : ""

      }
    </section>
  );
}
