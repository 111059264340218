import React, { useContext } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import Footer from "../Components/Footer/Footer";
import Header from "../Components/Header/Header";
import Warning from "../Components/Warning";
import UploadImg from "../assets/images/upload image shop.png";
import DownloadImgShop from "../assets/images/download_image.png";
import PayShopImage from "../assets/images/pay_image.png";
import { useState } from 'react';
import axios from 'axios';
import { useLocation } from 'react-router-dom';
import { baseUrl } from '../assets/utils/IP';
import Swal from 'sweetalert2';
import { CartContext } from '../Components/Cart';
function ShippingPayNow(props) {
  const [selectedImage, setSelectedImage] = useState(null);
  const [imageInApi, setImageInApi] = useState(null);
  const [data, setData] = useState(null);
  const isGuest = localStorage.getItem('guestUserData') ? true : false


  const { clearCart } = useContext(CartContext);

  const location = useLocation();
  const receivedData = location.state?.data;

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file && file.type.startsWith('image/')) {
      const reader = new FileReader();
      setImageInApi(file)
      reader.onload = () => {
        setSelectedImage(reader.result);
      };
      reader.readAsDataURL(file);
    } else {
      alert('Please select a valid image file.');
    }
  };

  const user_ID = localStorage.getItem("userId")
  const navigate = useNavigate()

  const handleCompleteOrder = async () => {

    try {
      let data = new FormData();
      data.append('receipt_image', imageInApi);
      data.append('total_amount', receivedData.getCartTotal);
      data.append('shipping_address', receivedData.ShippingAddress ? receivedData.ShippingAddress : "NO ADDRESS");
      data.append('billingAddress', receivedData.defaultStreetAddress ? receivedData.defaultStreetAddress : "NO ADDRESS");
      data.append('shippingStatus', receivedData.shippingStatus);
      const stringifiedOrderItems = JSON.stringify(receivedData?.productDataArray);
      data.append('order_items', stringifiedOrderItems);

      let newData = {}
      if (isGuest) {
        newData = JSON.parse(localStorage.getItem('guestUserData'))
        // console.log('newData>>>', newData)
        Object.entries(newData).forEach(([key, value]) => {
          data.append(key, value);
        });
      }
      else {
        console.log('no guesData')
        data.append('user_id', user_ID);

      }

      let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: isGuest ? `${baseUrl}/create-guest-order` : `${baseUrl}/create-order`,
        // url: `${baseUrl}/create-order`,
        headers: {
        },
        data: data,
      };

      const response = await axios.request(config);
      console.log("RES>>>", response)


      clearCart()
      localStorage.removeItem("cartItems")
      localStorage.removeItem("guestUserData")
      Swal.fire({
        icon: "success",
        title: "Order Successfully Placed",
        text: "Your order has been placed successfully",
        customClass: {
          container: "customstyle",
        },
      });
      // let orderCompleteData = {
      //   ...response.data.order,
      //   ...newData
      // }
      // console.log("orderCompleteData>>", orderCompleteData)
      navigate("/OrderComplete", { state: { ...response.data.order, ...newData } })

    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Order Failed",
        text: "Your order could not be submitted",
        customClass: {
          container: "customstyle",
        },
      });
    }
  };

  return (
    <div className="font-bebas">
      <Warning />

      <Header />
      <div className="bef-aft">
        <div className="container-1640">
          <div className="toplinecon shiphum">
            <div className="hamicons">
              <ul>
                <li>
                  <Link>Home</Link>
                </li>
                <li>
                  <Link>
                    {" "}
                    <i class="fa-solid fa-chevron-right"></i>{" "}
                  </Link>
                </li>
                <li>
                  <Link>Lighters</Link>
                </li>
                <li>
                  <Link>
                    {" "}
                    <i class="fa-solid fa-chevron-right"></i>{" "}
                  </Link>
                </li>
                <li>
                  <Link>Product Name</Link>
                </li>
                <li>
                  <Link>
                    {" "}
                    <i class="fa-solid fa-chevron-right"></i>{" "}
                  </Link>
                </li>
                <li>
                  <Link>Shopping Cart</Link>
                </li>
              </ul>
            </div>
          </div>
          {/* pr.muslim.82@gmail.com
TestA@gmail.com

Karachi123+ */}
          <section className="shippingsection">
            <div className="row">
              <div className="col-lg-5">
                <div className='prnt-txt pb-4 d-flex gap-3 align-items-center text-white'>
                  <img src={PayShopImage} alt='' />
                  <h3>Pay Now</h3>
                </div>
                <div className='prnt-shop-msg-box p-5'>
                  <div className="prnt-shop-box-txt pb-4">
                    <h3>Bank Name</h3>
                    <p>Lorem Ipsum dolor sit</p>
                  </div>
                  <div className="prnt-shop-box-txt pb-4">
                    <h3>Account Title</h3>
                    <p>Lorem Ipsum</p>
                  </div>
                  <div className="prnt-shop-box-txt pb-4">
                    <h3>IBAN Number</h3>
                    <p>1234567890</p>
                  </div>
                  <div className="prnt-shop-box-txt pb-4">
                    <h3>Swift Code</h3>
                    <p>1234</p>
                  </div>
                </div>
                <div className="subtotalbtn pt-4">
                  <button onClick={handleCompleteOrder}>Complete Order</button>
                  <Link to="/shipping-cart"><i class="fa-solid fa-angle-left"></i>Back to shipping details</Link>
                </div>
              </div>
              <div className="col-lg-7 text-start">
                <div className='prnt-txt pb-4 ps-5 d-flex gap-3 align-items-center text-white'>
                  <img src={DownloadImgShop} alt='' />
                  <h3>Upload Reciept</h3>
                </div>
                <div className='ms-5'>
                  <input id='upload-img' type="file" accept="image/*" onChange={handleImageChange} />
                  <label htmlFor='upload-img' style={{ cursor: "pointer" }}>
                    <img src={UploadImg} alt='' />
                  </label>
                  {selectedImage && (
                    <div className='RecieptWidthImg mt-3'>
                      <img src={selectedImage} alt="Selected" style={{ maxWidth: '100%' }} />
                    </div>
                  )}
                </div>
                <label htmlFor='upload-img' className='upload-shop-btn ms-5 mt-5'>Upload</label>
              </div>
            </div>
          </section>
        </div>

        <Footer />
      </div>
    </div>
  )
}

export default ShippingPayNow