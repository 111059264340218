import React from 'react';
import { Outlet, Navigate } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';

const isAuthenticated = () => {

  const accessToken = localStorage.getItem('root');


  if (accessToken) {

    const decodedToken = jwtDecode(accessToken);

    const currentTime = Date.now() / 1000;
    if (decodedToken.exp && decodedToken.exp < currentTime) {
      return false;
    }
    return true;
  }

  return false;
};

export const ProtectedRoute = () => {
  const isAuthenticatedUser = isAuthenticated();

  return isAuthenticatedUser ? <Outlet /> : <Navigate to="/Login" />;
};
