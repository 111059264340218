import React from "react";
import Slider from "react-slick";
import smokeExquisite from "../assets/images/smokeExquisite.png";
import Apperance1 from "../assets/images/Apperance1.png";
import Apperance2 from "../assets/images/Apperance2.png";
import Apperance3 from "../assets/images/Apperance3.png";
import span1 from "../assets/images/span1.png";
import span2 from "../assets/images/span2.png";
import smoke1 from "../assets/images/smoke1.png";
import { Link } from "react-router-dom";

function Exquisite() {
  var settings = {
    arrows: false,
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <section className="EXQUISITE bg-black">
      <div className="container-1640 position-relative z-2">
        <h1 className="title-mean mb-5">
          <span>EXQUISITE APPEARaNCE,</span> INGENUITY, EXCELLENCE
          <img src={smokeExquisite} alt="" />
        </h1>

        <div className="row show-pro d-lg-flex d-md-flex d-none">
          <div className="col-4">
            <div className="show-pro-in">
              <Link>
                <img width="100%" src={Apperance3} alt="" />
              </Link>
            </div>
          </div>
          <div className="col-4">
            <div className="show-pro-in">
              <Link>
                <img width="100%" src={Apperance1} alt="" />
              </Link>
            </div>
          </div>
          <div className="col-4">
            <div className="show-pro-in">
              <Link>
                <img width="100%" src={Apperance2} alt="" />
              </Link>
            </div>
          </div>
        </div>
        <Slider className="d-lg-none d-md-none d-block" {...settings}>
          <div className="show-pro-in">
            <Link>
              {" "}
              <img width="100%" src={Apperance3} alt="" />
            </Link>
          </div>
          <div className="show-pro-in">
            <Link>
              {" "}
              <img width="100%" src={Apperance1} alt="" />
            </Link>
          </div>
          <div className="show-pro-in">
            <Link>
              {" "}
              <img width="100%" src={Apperance2} alt="" />
            </Link>
          </div>
        </Slider>
      </div>
      <span className="EXQUISITE-span1">
        <img src={span1} alt="" className="h-100" />
      </span>
      <span className="EXQUISITE-span2">
        <img src={span2} alt="" className="h-100" />
      </span>
    </section>
  );
}

export default Exquisite;
