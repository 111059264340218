import React, { useEffect, useState } from "react";
import SideBar from "../../../Components/User/SideBar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEye,
  faListUl,
} from "@fortawesome/free-solid-svg-icons";
import { baseUrl } from "../../../assets/utils/IP";
import axios from "axios";

export default function UserOrder() {
  const [Modal, setModalOpen] = useState(false);
  const [selectedOrderId, setSelectedOrderId] = useState(null);
  const [userOrders, setUserOrders] = useState()
  const [address, setAddress] = useState();
  const [address2, setAddress2] = useState();
  const [billing, setBilling] = useState()
  const [userData, setUserData] = useState()

  const id = localStorage.getItem("userId")



  useEffect(() => {
    // Inside the useEffect, place your Axios request
    const config = {
      method: 'get',
      maxBodyLength: Infinity,
      url: `${baseUrl}/get-user-order-id/${selectedOrderId}`,
      headers: {},
    };

    axios.request(config)
      .then((response) => {
        setUserData(response.data)

        // If you need to update state with the response data, you can do it here
        // setData(response.data);
      })
      .catch((error) => {
        // Handle errors if needed
      });
  }, [selectedOrderId]);


  console.log(userData, "user dataa")

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${baseUrl}/get-order-by-user/${id}`);
        setUserOrders(response.data.data)
      } catch (error) {
      }
    };

    fetchData();
  }, [id]);


  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${baseUrl}/get-street-address/${id}`);
        setAddress2(response.data)
      } catch (error) {
      }
    };

    fetchData();
  }, [id]);




  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${baseUrl}/get-shipping-address/${userData?.orderItems[0].order_id}`);
        setAddress(response?.data.order)
      } catch (error) {
      }
    };

    fetchData();
  }, [userData?.orderItems[0].order_id]);




  useEffect(() => {
    const fetchData = async () => {
      try {
        let config = {
          method: 'get',
          maxBodyLength: Infinity,
          url: `${baseUrl}/get-billing-address/${id}`,
          headers: {},
        };

        const response = await axios.request(config);
        setBilling(response.data.user)
      } catch (error) {
      }
    };

    fetchData();
  }, [id]);

  const openModal = (order_id) => {
    setSelectedOrderId(order_id); // Set the selected order_id to open the modal
  };

  const downloadReceipt = () => {
    const imageUrl = `${baseUrl}/${userData?.orderItems[0].receipt}`;

    // Fetch the image as a blob
    fetch(imageUrl)
      .then(response => response.blob())
      .then(blob => {
        // Create a blob URL for the image blob
        const blobUrl = URL.createObjectURL(blob);

        // Create a temporary link element
        const link = document.createElement('a');

        // Set the href attribute to the blob URL
        link.href = blobUrl;

        // Specify the download attribute with the desired file name
        link.download = 'receipt_image.png';

        // Append the link element to the document
        document.body.appendChild(link);

        // Trigger a click event on the link element
        link.click();

        // Remove the link element from the document
        document.body.removeChild(link);

        // Revoke the blob URL to free up resources
        URL.revokeObjectURL(blobUrl);
      })
      .catch(error => {
      });
  };






  return (
    <section className="bg-main">
      <SideBar />
      <div className="second-main-side">
        <div className="sec-main-side-parent p-5">
          <div className="blur-pages px-5">
            <div className="border-text">
              <div className="d-flex justify-content-between">
                <h1>ORDER</h1>
              </div>
              <hr className="text-light opacity-100" />
            </div>
            <div className="add-categorie-list">
              <FontAwesomeIcon color="#fff" icon={faListUl} />
              <span className="mx-2">Order List</span>
            </div>
            <div className="main-fi-order-box-master">
              <div className="main-fi-order-box">
            <div className="order-table">
              <table className="table table-bordered">
                <thead>
                  <tr className="bg-golden-gradeint text-center">
                    <th scope="col">Order ID</th>
                    <th scope="col">Product</th>
                    <th scope="col">Brand</th>
                    <th scope="col">Status</th>
                    <th scope="col">Total</th>
                    <th scope="col">Date</th>
                    <th scope="col">Action</th>
                  </tr>
                </thead>
                <tbody className="data-orders-body">
                  {userOrders?.map((item, index) => (
                    <tr className="text-center" key={index}>
                      <td className="text-light">{item.order_id}</td>
                      <td className="text-light">{item.products_count}</td>
                      <td className="text-light">{item.brand_count}</td>
                      <td className="text-light">{item.STATUS}</td>
                      <td className="text-light">{item.total_amount}</td>
                      <td className="text-light">{item.created_at.substring(0, 10)}</td>
                      <td>
                        <button className="bg-transparent border-0 me-2" data-bs-toggle="modal" data-bs-target="#exampleModal1" onClick={() => openModal(item.order_id)}>
                          {/* You may need to import FontAwesomeIcon and faEye */}
                          <FontAwesomeIcon icon={faEye} />
                        </button>
                        {/* <button className="bg-transparent border-0" data-bs-toggle="modal" data-bs-target="#exampleModal" onClick={() => openModal(item.order_id)}>
                          <FontAwesomeIcon color="red" icon={faXmark} />
                        </button> */}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            </div>
            </div>
          </div>
        </div>
      </div>
      {/* Modal */}
      {/* <div
        className="modal fade"
        id="exampleModal"
        tabIndex={-1}
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog delet-popup  d-flex align-items-center justify-content-center h-100">
          <div className="modal-content p-3 rounded-5">
            <div className="modal-header border-0">
              <button
                type="button"
                className="btn-close text-light"
                data-bs-dismiss="modal"
                aria-label="Close"
              />
            </div>
            <div className="modal-body text-center delete-modal-responsive">
              <FontAwesomeIcon color="red" fontSize="80px" icon={faTrash} />{" "}
              <h1 className="text-light">You are about to delete this order</h1>
            </div>
            <div className="modal-footer justify-content-center border-0">
              <button
                type="button"
                className="btn btn-gradient"
                data-bs-dismiss="modal"
              >
                Go Back
              </button>
              <button type="button" className="btn btn-danger">
                Delete Anyway
              </button>
            </div>
          </div>
        </div>
      </div> */}
      {/* Modal */}
      <div
        className="modal fade"
        id="exampleModal1"
        tabIndex={-1}
      // aria-labelledby="exampleModalLabel"
      // aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered pd-responsive-modal set-the-sec-modal-box set-the-modal-box-order-one delet-popup justify-content-center">
          <div className="modal-content modal-border-set p-5">
            <div className="modal-flex-status">
              {/* <h1>
                Status: <span>{PENDING}</span>
              </h1> */}
            </div>
            <div className="billing-row-parent my-4">
              <div className="row">
                <div className="col-lg-6">
                  <div className="billing-box rounded-3 position-relative">
                    <div className="w-75">
                      <h5>Payment Address</h5>
                      <p>{billing?.user_first_name} {billing?.user_last_name} </p>
                      <p>{billing?.phone_number}</p>
                      <p>
                        {billing?.billing_address}
                      </p>
                      <p>{billing?.passport_number}</p>
                      <p>{billing?.city}</p>
                      <p>{billing?.country}</p>
                    </div>
                    {/* <button
                      className="setting-icn-box btn"
                      data-bs-toggle="modal"
                      data-bs-target="#exampleModal1"
                    >
                      <FontAwesomeIcon icon={faGear} />
                    </button> */}
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="billing-box rounded-3 position-relative">
                    <div className="w-75">
                      <h5>Shipping Address</h5>
                      <p>{billing?.user_first_name} {billing?.user_last_name} </p>
                      <p>{billing?.phone_number}</p>
                      <p>
                        {address?.shipping_address ? address?.shipping_address : address2?.streetAddress}
                      </p>
                      <p>{billing?.passport_number}</p>
                      <p>{billing?.city}</p>
                      <p>{billing?.country}</p>
                    </div>
                    {/* <button
                      className="setting-icn-box btn"
                      data-bs-toggle="modal"
                      data-bs-target="#exampleModal2"
                    >
                      <FontAwesomeIcon icon={faGear} />
                    </button> */}
                  </div>
                </div>
              </div>
            </div>
            <div className="method-payment-row-prnt">
              <div className="row user-order-gap">
                <div className="col-lg-6">
                  <div className="py-mthd-box">
                    <h5>Shipping Method</h5>
                    <p>{userData?.orderItems[0].shipping_status == 1 ? "World Wide" :
                      userData?.orderItems[0].shipping_status == 2 ? "Nation Wide" :
                        "Pickup from store"}</p>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="py-mthd-box">
                    <h5>Payment Method</h5>
                    <div className="d-flex justify-content-between">
                      <span>{userData?.orderItems[0].receipt != null ? "Bank Transfer" : "Pay at store"}</span>
                      <button className="btn-gradient btn" onClick={() => setModalOpen(true)} >View Reciept</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="comment-row my-4">
              <div className="row">
                <div className="col-lg-12">
                  <div className="env-box position-relative text-dark bg-light">
                    <textarea
                      name=""
                      id=""
                      className="border-0 p-2"
                      placeholder="Comment"
                    ></textarea>
                  </div>
                </div>
              </div>
            </div> */}
            <p></p>
            <div class="modal-order-summer-txt text-light text-center mt-5">
              <h1>ORDER SUMMARY</h1>
            </div>
            <div className="parent-grids-modal ">
              <div className="product-detail-grid font-bebas">
                <div className="prdct-detail-txt">
                  <h2>Product</h2>
                </div>
                <div className="prdct-detail-txt">
                  <h2>QTY</h2>
                </div>
                <div className="prdct-detail-txt text-end">
                  <h2>UNIT PRICE</h2>
                </div>
              </div>
              <div className="prdct-dtl-line">
                <hr />
              </div>
              <div>
                {userData?.orderItems.map((item, index) => (
                  <div key={index} className="product-detail-grid set-grid">
                    <div className="prdct-detail-img">
                      <div className="prdct-txt-nxt-grid ">
                        <img width="20%" className="me-4" src={`${baseUrl}/${item.main_image}`} alt="" />
                        <div>
                          <h2>{item.product_name}</h2>
                        </div>
                      </div>
                    </div>
                    <div className="prdct-detail-txt set-detail-txt font-bebas">
                      <h2>{item.quantity}</h2>
                    </div>
                    <div className="prdct-detail-txt set-detail-price font-bebas">
                      <h2>${item.sale_price === 0 ? item.regular_price : item.sale_price}</h2>
                    </div>
                  </div>
                ))}
              </div>

              <div className="prdct-dtl-line font-bebas">
                <hr />
              </div>
              <div className="prdct-sub-total-flex font-bebas">
                <h2>Subtotal :</h2>
                <h3>${userData?.orderItems[0]?.shipping_status === 1 ? userData?.orderItems[0]?.total_amount - 20 :
                  userData?.orderItems[0]?.shipping_status === 2 ? userData?.orderItems[0]?.total_amount - 10 :
                    userData?.orderItems[0]?.shipping_status === 3 ? userData?.orderItems[0]?.total_amount :
                      userData?.orderItems[0]?.total_amount
                }</h3>
              </div>
              <div className="prdct-sub-total-flex set-sec-flex font-bebas">
                <h2>SHIPPING :</h2>
                <h3>${userData?.orderItems[0]?.shipping_status === 1 ? 20 :
                  userData?.orderItems[0]?.shipping_status === 2 ? 10 :
                    userData?.orderItems[0]?.shipping_status === 3 ? 0 :
                      0
                }</h3>
              </div>
              <div className="prdct-sub-total-line font-bebas">
                <hr />
              </div>
              <div className="prdct-sub-total-flex set-th-flex font-bebas">
                <h2>total :</h2>
                <h3>${userData?.orderItems[0]?.total_amount}</h3>
              </div>
            </div>
          </div>
        </div>
      </div>


      {Modal ?
        <div className="align-items-center d-flex h-100 justify-content-center position-fixed top-0 w-100 z-1560">
          <div className="w-50 user-receipt-downlaod">
            <div className="pd-responsive-modal set-the-modal-box-order-one myclass-setmodal delet-popup justify-content-center">
              <div className="modal-content modal-border-set p-5">

                <div className="modal-flex-status text-center">
                  <img className="w-50 m-auto" src={`${baseUrl}/${userData?.orderItems[0].receipt}`} alt="There is no payment recipt" />
                </div>
                <div className="d-flex justify-content-center">
                  <button onClick={() => setModalOpen(false)} className="btn btn-secondary me-4">Close</button>
                  <button onClick={downloadReceipt} className="btn btn-gradient user-receipt-btn">Download Receipt</button>
                </div>
              </div>
            </div>
          </div>
        </div> : ""

      }
    </section >
  );
}
