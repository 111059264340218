import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "./assets/css/dashboard-css/style.css";
import "./assets/css/dashboard-css/responsive.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.js";
import { CartProvider } from "./Components/Cart.js";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  // <React.StrictMode>
    <CartProvider >
      <App />
    </CartProvider>
  // </React.StrictMode>
);

reportWebVitals();
