import React, { useState, useEffect } from "react";

import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import brand1 from "../../assets/images/brand1.png";
import brand2 from "../../assets/images/brand2.png";
import brand3 from "../../assets/images/brand3.png";
import brand4 from "../../assets/images/brand4.png";
import brand5 from "../../assets/images/brand5.png";
import brandhover from "../../assets/images/brandhover.png";
import headerlogo from "../../assets/images/headerlogo.png";
import { Link, useNavigate } from "react-router-dom";
import headermenushover from "../../assets/images/headermenushover.png";
import smallheaderhover from "../../assets/images/smallheaderhover.png";

import pluseight from "../../assets/images/18+.png";
import { baseUrl } from "../../assets/utils/IP";
import axios from "axios";
import Swal from "sweetalert2";
import { useContext } from "react";
import { CartContext } from "../Cart";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCartShopping,
  faChevronDown,
  faHeart,
  faSearch,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import Headerlogotab from "../../assets/images/headerlogo.png";

function Header() {
  // modale 2
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  // modale 1

  const [show1, setShow1] = useState(false);

  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);

  // modale 2

  const [show2, setShow2] = useState(false);

  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);

  const [isSticky, setIsSticky] = useState(false);
  const [brandLogo, setBrndLogo] = useState();
  const [feedback, setFeedback] = useState("");
  const [contact, setContact] = useState({
    name: "",
    email: "",
    message: "",
  });

  const { cartItems, getCartTotal, CancelProduct } = useContext(CartContext);
  useEffect(() => {
    window.scrollTo(0, 0);
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    const getBrands = async () => {
      try {
        const response = await axios.get(`${baseUrl}/get-all-brands`);
        if (response?.status == 200) {
          setBrndLogo(response?.data);
        }
      } catch (err) {}
    };
    getBrands();
  }, []);
  // console.log(brandLogo, "brand logo");

  const handleSubmit = async (e) => {
    e.preventDefault(); // Prevent the default form submission behavior

    try {
      const data = JSON.stringify({
        message: feedback,
      });

      const config = {
        method: "post",
        maxBodyLength: Infinity,
        url: `${baseUrl}/feedback`,
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };
      if (feedback === "") {
        Swal.fire({
          icon: "error",
          title: "FeedBack",
          text: "Please write any message",
        });
      } else {
        const response = await axios.request(config);
        if (response.status === 200) {
          setFeedback("");
        }
        Swal.fire({
          icon: "success",
          title: "FeedBack",
          text: "Your Feed Back is added",
        });
      }
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "FeedBack",
        text: "Your Feed Back is not added",
      });
      // Handle errors here.
    }
  };

  const handleContactSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post(`${baseUrl}/contact`, {
        name: contact.name,
        email: contact.email,
        message: contact.message,
      });

      if (response.status === 200) {
        setContact({
          ...contact,
          name: "",
          email: "",
          message: "",
        });
        Swal.fire({
          icon: "Sucess",
          title: "Contact",
          text: "Your Contact is submitted",
        });
      }
    } catch (err) {
      Swal.fire({
        icon: "error",
        title: "Contact",
        text: "Your Message is not submitted",
      });
    }
  };

  const page = localStorage.getItem('root') ? '/UserDashboard' : '/Login'

  const [isChecked, setIsChecked] = useState(false);

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };

  return (
    <div className="main-header">
      <div className="feedcont">
        <Link
          onClick={handleShow}
          className="align-items-center d-flex justify-content-center"
        >
          FEEDBACK
        </Link>
        <Link
          onClick={handleShow1}
          className="align-items-center d-flex justify-content-center"
        >
          Contact us
        </Link>
      </div>
      <header>
        <div className="container-1640">
          <div className="flhed tablet-hidden">
            <div className="logo">
              <Link to="/">
                <img src={headerlogo} alt="" />
              </Link>
            </div>
            <div className="menusheader">
              <ul>
                {/* <li className="brandhover">
                  <Link to="">
                    Brands <i class="fa-solid fa-chevron-down"></i>
                    <img src={smallheaderhover} />
                  </Link>

                  <div className="hoverul">
                    {brandLogo?.slice(0, 4).map((logo, index) => (
                      <span>
                        {console.log(logo.brand_id, "brandid")}
                        <img src={brandhover} />
                        <img src={`${baseUrl}/${logo?.brand_logo}`}
                        />
                      </span>

                    ))}

                  </div>
                </li> */}

                <li className="shop-active">
                  <Link to="/Brand">
                    Brands <img src={smallheaderhover} alt="" />
                  </Link>
                </li>

                <li className="Sales-active">
                  <Link to="/sales">
                    Shop <img src={smallheaderhover} alt="" />
                  </Link>
                </li>

                <li className="Promotion-active">
                  <Link to="/Promotions">
                    This week's Promotion
                    <img src={headermenushover} alt="" />
                  </Link>
                </li>

                {/* <li className='Order'>
                  <Link to="/Promotions">
                    Order Status <img src={smallheaderhover} />
                  </Link>
                </li> */}

                <li className="about-active">
                  <Link to="/about">
                    ABOUT US <img src={smallheaderhover} alt="" />
                  </Link>
                </li>

                <li className="Contact-active">
                  <Link to="/contact">
                    Contact <img src={smallheaderhover} alt="" />
                  </Link>
                </li>
              </ul>
            </div>
            <div className="iconsheader">
              <div className="Account-my">
                <ul>
                  <li>
                    <Link to={page}>
                      <i class="fa-solid fa-user"></i>
                    </Link>
                  </li>
                </ul>
              </div>
              <Link to="/WishList">
                <i class="fa-solid fa-heart"></i>
              </Link>
              <Link className="position-relative" to="/cart">
                <i class="fa-solid fa-cart-shopping"></i>
                {cartItems != 0 ? (
                  <span className="counting-span">{cartItems.length}</span>
                ) : (
                  ""
                )}
              </Link>
            </div>
          </div>
          <div className="tab-grid-header-parent d-lg-none d-grid">
            <div className="hamburger">
              <div className="header-left d-lg-none d-block">
                <label className="hamburger-icon">
                  <input type="checkbox" className="hamburger-checkbox" />
                  <span />
                  <span />
                  <span />
                  <div className="drop-down-menu-parent">
                    <div className="links-drop-down-menu">
                    <ul className="d-flex flex-column gap-4">
                        <li className="shop-active">
                          <Link to="/Brand">
                            Brands
                          </Link>
                        </li>

                        <li className="Sales-active">
                          <Link to="/sales">
                            Shop
                          </Link>
                        </li>

                        <li className="Promotion-active">
                          <Link to="/Promotions">
                            This week's Promotion
                          </Link>
                        </li>
                        <li className="sales-act">
                          <Link to="/sales">
                            Sales
                          </Link>
                        </li>
                        <li className="order-status-act">
                          <Link to="/Track-Order">
                            Order Status
                          </Link>
                        </li>

                        <li className="about-active">
                          <Link to="/about">
                            ABOUT US
                          </Link>
                        </li>

                        <li className="Contact-active">
                          <Link to="/contact">
                            Contact
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </label>
              </div>
            </div>
            <div className="tab-logo text-center">
              <Link to="/">
                <img src={Headerlogotab} alt="" />
              </Link>
            </div>
            <div className="right-drp-down-header-tab position-relative d-md-none d-block w-max mx-auto">
              <label>
                <input
                  className="d-none"
                  type="checkbox"
                  checked={isChecked}
                  onChange={handleCheckboxChange}
                />
                <FontAwesomeIcon
                  className="text-white chevron-header"
                  icon={faChevronDown}
                  style={{
                    transform: isChecked ? "rotate(180deg)" : "rotate(0deg)",
                  }}
                />
              </label>

              {isChecked && (
                <div className="position-absolute d-flex flex-column align-items-center tab-header-righ-arrow-open">
                  <Link to="/WishList">
                    <FontAwesomeIcon
                      className="text-white chevron-header"
                      icon={faHeart}
                    />
                  </Link>
                  <Link to="/Login">
                    <FontAwesomeIcon
                      className="text-white chevron-header"
                      icon={faUser}
                    />
                  </Link>
                  <Link className="position-relative" to="/cart">
                    <FontAwesomeIcon
                      className="text-white chevron-header"
                      icon={faCartShopping}
                    />
                  </Link>
                  <Link>
                    <FontAwesomeIcon
                      className="text-white chevron-header"
                      icon={faSearch}
                    />
                  </Link>
                </div>
              )}
            </div>
            <div className="iconsheader d-md-flex d-none justify-content-end">
              <div className="Account-my">
                <ul>
                  <li>
                    <Link to="/Login">
                      <i class="fa-solid fa-user"></i>
                    </Link>
                  </li>
                </ul>
              </div>
              <Link to="/WishList">
                <i class="fa-solid fa-heart"></i>
              </Link>
              <Link className="position-relative" to="/cart">
                <i class="fa-solid fa-cart-shopping"></i>
                {cartItems != 0 ? (
                  <span className="counting-span">{cartItems.length}</span>
                ) : (
                  ""
                )}
              </Link>
            </div>
          </div>
        </div>
      </header>

      <Modal
        className="Feedbackmodal font-bebas"
        show={show}
        onHide={handleClose}
      >
        <Modal.Header>
          <i onClick={handleClose} class="fa-solid fa-circle-xmark"></i>

          <Modal.Title>
            {" "}
            <h1>Can't Find what you are looking for ? </h1>
            <p>Please tell us below what you would like to see on our site</p>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <textarea
            cols="30"
            rows="10"
            value={feedback}
            onChange={(e) => setFeedback(e.target.value)}
          >
            {" "}
          </textarea>
          <div className="opentiming">
            <p style={{ color: "#7998EA" }}>
              Open 09AM - 05PM <br />
              Monday - Friday
            </p>
            <Link onClick={handleSubmit}>SUBMIT</Link>
            <p style={{ color: "#F2EA9A" }}>
              Feedback will be given within 30 mins
            </p>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        className="Feedbackmodal Contactmodal font-bebas"
        show={show1}
        onHide={handleClose1}
      >
        <Modal.Header>
          <i onClick={handleClose1} class="fa-solid fa-circle-xmark"></i>

          <Modal.Title>
            {" "}
            <h1>CONTACT US </h1>
            <p>Do you have a question, let us know</p>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <label>Name</label>
          <input
            type="text"
            value={contact.name}
            onChange={(e) => {
              setContact({ ...contact, name: e.target.value });
            }}
          />

          <label>EMAIL</label>
          <input
            type="email"
            value={contact.email}
            onChange={(e) => {
              setContact({ ...contact, email: e.target.value });
            }}
          />

          <label>MESSAGE</label>
          <textarea
            cols="30"
            rows="10"
            value={contact.message}
            onChange={(e) => {
              setContact({ ...contact, message: e.target.value });
            }}
          >
            {" "}
          </textarea>
          <div className="opentiming">
            <Link onClick={handleContactSubmit}>SUBMIT</Link>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        className="Feedbackmodal Contactmodal font-bebas"
        show={show2}
        onHide={handleClose2}
      >
        <Modal.Header>
          {/* <i onClick={handleClose2} class="fa-solid fa-circle-xmark"></i> */}

          <Modal.Title>
            {" "}
            <img style={{ paddingTop: "30px" }} src={pluseight} alt="" />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="restriction">
            <h1>Age Restricted Content</h1>
            <p>You must be 18+ </p>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default Header;
