import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import SideBar from "../../../Components/Admin/SideBar";
import ProductImg from "../../../assets/images/product1.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CustomerFilter from "../../404Error/CustomFilter/CustomerFilter";

import {
  faChevronUp,
  faCircleChevronLeft,
  faListUl,
  faFilter,
  faPencil,
  faPlus,
  faTrash,
  faEye,
  faCalendar,
} from "@fortawesome/free-solid-svg-icons";
import { faDotCircle } from "@fortawesome/free-regular-svg-icons";
import { baseUrl } from "../../../assets/utils/IP";
import axios from "axios";

export default function AdminSales() {
  const [orders, setOrders] = useState()
  const [ordersCopy, setOrdersCopy] = useState()
  const [visibleCustomers, setVisibleCustomers] = useState(10); // Number of customers to display



  const applyFilters = (orderId, name, Lname, brand, total, dateFrom, dateTo) => {
    let filteredCustomers = [...ordersCopy];
    if (name) {
      filteredCustomers = filteredCustomers.filter(
        (order) => order?.users?.toLowerCase().includes(name?.toLowerCase())
      );
    }
    if (brand) {
      filteredCustomers = filteredCustomers.filter(
        (order) => order?.brand_name.toLowerCase().includes(brand?.toLowerCase())
      );
    }
    if (orderId) {
      filteredCustomers = filteredCustomers.filter(
        (order) => order.order_id == orderId
      );
    }

    // date filter 
    if (dateFrom || dateTo) {
      // Parse dateFrom and dateTo strings to Date objects
      const fromDate = dateFrom ? new Date(dateFrom) : null;
      const toDate = dateTo ? new Date(dateTo) : null;

      filteredCustomers = filteredCustomers.filter((order) => {
        const orderDate = new Date(order.created_at);


        const inputDate = new Date(orderDate);
        const year = inputDate.getFullYear();
        const month = (inputDate.getMonth() + 1).toString().padStart(2, '0'); // Month is 0-based
        const day = inputDate.getDate().toString().padStart(2, '0');

        const formattedDate = `${year}-${month}-${day}`;



        if (dateFrom && dateTo) {
          // Filter orders within the date range
          return formattedDate >= dateFrom && formattedDate <= dateTo;
        } else if (dateFrom) {
          // Filter orders from the fromDate
          return formattedDate >= dateFrom;
        } else if (dateTo) {
          // Filter orders up to the toDate
          return formattedDate <= dateTo;
        }

        return true; // If no date range specified, include all orders
      });
    }
    if (total) {
      filteredCustomers = filteredCustomers.filter(
        (order) => order?.total_amount.includes(total)
      );
    }
    setOrders(filteredCustomers);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const config = {
          method: 'get',
          maxBodyLength: Infinity,
          url: `${baseUrl}/get-all-orders`,
          headers: {},
        };

        const response = await axios.request(config);
        setOrders(response.data.orders)
        setOrdersCopy(response.data.orders)
      } catch (error) {
      }
    };
    fetchData();
  }, []);




  const loadMoreSales = () => {
    setVisibleCustomers((prevVisibleCustomers) => prevVisibleCustomers + 10);
  };

  const displayedCustomers = orders?.slice(0, visibleCustomers);





  return (
    <section className="bg-main">
      <SideBar />
      <div className="second-main-side">
        <div className="sec-main-side-parent p-md-4 p-lg-5">
          <div className="blur-pages px-5">
            <div className="border-text">
              <div className="d-flex justify-content-between">
                <h1>Sales</h1>
                <div className="boxs-flex"></div>
              </div>
              <hr className="text-light opacity-100" />
            </div>
            <div className="row pd-customer-row">
              <div className="row pd-customer-row">
                <div className="col-lg-8 border border-2 rounded-5 px-5 py-3">
                  <div className="add-categorie-list">
                    <FontAwesomeIcon color="#fff" icon={faListUl} />
                    <span className="mx-2 ">Sales List</span>
                  </div>
                  <div className="main-fi-order-box-master">
              <div className="main-fi-order-box">
                  <div className="grid-sales-parent mt-3 sales-grid-parent ">
                    <div className="inner-sale-grid  px-2">
                      <div className="inner-grid-sales">
                        <input type="checkbox" name="" id="" />
                      </div>
                      <div className="inner-grid-sales">
                        <p>Order ID</p>
                      </div>
                      <div className="inner-grid-sales">
                        <p>Product</p>
                      </div>
                      <div className="inner-grid-sales">
                        <p>Brand</p>
                      </div>
                      <div className="inner-grid-sales">
                        <p>Customer</p>
                      </div>
                      <div className="inner-grid-sales">
                        <p>Status</p>
                      </div>
                      <div className="inner-grid-sales">
                        <p>Total</p>
                      </div>
                      <div className="inner-grid-sales">
                        <p>Date Added</p>
                      </div>
                      <div className="inner-grid-sales">
                        <p>Action</p>
                      </div>
                    </div>


                    {displayedCustomers?.map((order, index) => (
                      <div className="inner-sale-grid grid-sales-two border border-2 text-light px-2">
                        <div key={index} className="inner-grid-sales">
                          <input type="checkbox" name="" id="" />
                        </div>
                        <div key={index} className="inner-grid-sales">
                          <p>#{order.order_id} </p>
                        </div>
                        <div key={index} className="inner-grid-sales">
                          <p>{order.product_count}</p>
                        </div>
                        <div key={index} className="inner-grid-sales">
                          <p>{order.brand_quantity}</p>
                        </div>
                        <div key={index} className="inner-grid-sales">
                          <p>{order.users}</p>
                        </div>
                        <div key={index} className="inner-grid-sales">
                          <p>
                            {/* icon={faDotCircle} */}
                            <FontAwesomeIcon className="text-success" /> {order.order_status}
                          </p>
                        </div>
                        <div key={index} className="inner-grid-sales">
                          <p>${order.total_amount}</p>
                        </div>
                        <div key={index} className="inner-grid-sales">
                          <p>{order.created_at.substring(0, 10)}</p>
                        </div>
                        <div key={index} className="inner-grid-sales">
                          <p>
                            <Link to={`/SalesView/${order.order_id}`}>
                              <FontAwesomeIcon icon={faEye} />
                            </Link>
                          </p>
                        </div>
                      </div>
                    ))}
                  </div>
                  </div>
                  </div>
                  <div className="text-center mt-3">
                      {visibleCustomers < orders?.length && (
                        <button className="btn-gradient px-4 py-1 rounded border-0" onClick={loadMoreSales}>Load More</button>
                      )}
                    </div>
                </div>
                <CustomerFilter onFilter={applyFilters} brand="brand" reset="none" filter="none" />

              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
