import React, { useState } from 'react'
import FilterIcon from "../../../assets/images/filtericon.png";


function CustomerFilter(props) {
  const [totalFilter, setTotalFilter] = useState('');
  const [orderIDFilter, setOrderIDFilter] = useState()
  const [customerNameFilter, setCustomerNameFilter] = useState()
  const [LastNameFilter, setLastNameFilter] = useState()
  const [customerCityFilter, setCustomerCityFilter] = useState()
  const [totalAmountFilter, setTotalAmountFilter] = useState()
  const [dateFromFilter, setdateFromFilter] = useState()
  const [dateToFilter, setdateToFilter] = useState()



  const handleFilter = () => {
    setCustomerNameFilter("")
    setLastNameFilter("")
    setCustomerCityFilter("")
    setTotalAmountFilter("")
    setdateFromFilter("")
    setdateToFilter("")
    setOrderIDFilter("")
    setTotalFilter("")
    props.onFilter(orderIDFilter, customerNameFilter, LastNameFilter, customerCityFilter, totalAmountFilter, dateFromFilter, dateToFilter)

  };
  const handleReset = () => {
    setCustomerNameFilter("")
    setCustomerCityFilter("")
    setTotalAmountFilter("")
    setdateFromFilter("")
    setdateToFilter("")
    handleFilter("")
  };

 /////////////Filter/////////
 const [isActive, setIsActive] = useState(false);

 const handleHamburgerClick = () => {
   setIsActive(!isActive);
 };
  return (
    <>
      <div className="col-lg-4">
        <div className={`filter-box-parent ${isActive ? "active" : ""}`}>
        {/* <div className="filter-box-parent"> */}
          <div className="filter-box-heading" onClick={handleHamburgerClick} >
            <h3>
              {/* <img src={FilterIcon} alt="" /> */}
              Filter
            </h3>
          </div>
          <div className="filter-box-body">
            {props.RemoveOrderId ? "" :
              (<div className="filter-lb-inp-parent">
                <label htmlFor="filtone">Order ID</label>
                <input
                  type="text"
                  id="filtone"
                  placeholder="Order ID"
                  value={orderIDFilter}
                  onChange={(e) => setOrderIDFilter(e.target.value)}
                />
              </div>)}

            <div className="filter-lb-inp-parent">
              <label htmlFor="filttwo">{props.customer ? "First Name" : "Customer"}</label>
              <input
                type="text"
                id="filttwo"
                placeholder="Customer"
                value={customerNameFilter}
                onChange={(e) => setCustomerNameFilter(e.target.value)}
                required
              />
            </div>
            {props.lastName ? (
              <div className="filter-lb-inp-parent">
                <label htmlFor="filttwo">{props.customer ? "Last Name" : "Customer"}</label>
                <input
                  type="text"
                  id="filttwo"
                  placeholder="Customer"
                  value={LastNameFilter}
                  onChange={(e) => setLastNameFilter(e.target.value)}
                  required
                />
              </div>
            ) :
              <></>
            }
            {props.RemoveOrderId ?
              (<div className="filter-lb-inp-parent">
                <label htmlFor="filtone">Email</label>
                <input
                  type="text"
                  id="filtone"
                  placeholder="username@domain.com"
                  value={orderIDFilter}
                  onChange={(e) => setOrderIDFilter(e.target.value)}
                />
              </div>) : ""}
            {props.brand ? (<div className="filter-lb-inp-parent">
              <label htmlFor="filtthree">Brand</label>
              <input
                type="text"
                id="filtthree"
                placeholder="Dunhill"
                value={customerCityFilter}
                onChange={(e) => setCustomerCityFilter(e.target.value)}
              />
            </div>) : <></>}
            {props.RemoveTotal1 ? "" :
              (<div className="filter-lb-inp-parent">
                <label htmlFor="filtfour">Total</label>
                <input type="text" id="filtfour" placeholder="Total"
                  value={totalAmountFilter}
                  onChange={(e) => setTotalAmountFilter(e.target.value)} />
              </div>)}
            {props.RemoveTotal ? "" : (<div className="filter-lb-inp-parent">
              <label htmlFor="filtfive">Date From</label>
              <input
                className="date-inpt"
                type="date"
                id="filtfive"
                value={dateFromFilter}
                onChange={(e) => setdateFromFilter(e.target.value)}
              />
            </div>)}
            {props.RemoveTotal ? "" : (<div className="filter-lb-inp-parent">
              <label htmlFor="filtsix">Date To</label>
              <input className="date-inpt" type="date" id="filtsix" value={dateToFilter} onChange={(e) => setdateToFilter(e.target.value)} />
            </div>)}
            <div className="reset-filt-parent">
              <button onClick={handleReset}>
                {/* <img src={FilterIcon} alt="" /> */}
                Reset
              </button>
              <button onClick={handleFilter}>
                {/* <img src={FilterIcon} alt="" /> */}
                Filter
              </button>
            </div>
          </div>
        </div>
      </div>

    </>
  )
}

export default CustomerFilter





// ----------------------------------------------------
// import React from 'react'
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import {
//     faChevronUp,
//     faCircleChevronLeft,
//     faListUl,
//     faFilter,
//     faPencil,
//     faPlus,
//     faTrash,
//     faEye,
//     faCalendar,
//   } from "@fortawesome/free-solid-svg-icons";
//   import { faDotCircle } from "@fortawesome/free-regular-svg-icons";

// function CustomerFilter() {
//   return (
// <div className="col-lg-4">
//     <div className="filter-box-parent">
//         <div className="filter-box-heading">
//             <h3>
//                 <FontAwesomeIcon icon={faFilter} />
//                 Filter
//             </h3>
//         </div>
//         <div className="filter-box-body">
//             <div className="filter-lb-inp-parent">
//                 <label htmlFor="filtone">Order ID</label>
//                 <input
//                     type="text"
//                     id="filtone"
//                     placeholder="Order ID"
//                 />
//             </div>
//             <div className="filter-lb-inp-parent">
//                 <label htmlFor="filttwo">Customer</label>
//                 <input
//                     type="text"
//                     id="filttwo"
//                     placeholder="Customer"
//                 />
//             </div>
//             <div className="filter-lb-inp-parent">
//                 <label htmlFor="filtthree">Brand</label>
//                 <select className="w-100 p-2 fs-7" type="text">
//                     <option value="">Dunhill</option>
//                     <option value="">Winfield</option>
//                     <option value="">Benson & Hedges</option>
//                     <option value="">Longbeach</option>
//                     <option value="">Port Royale</option>
//                 </select>
//             </div>
//             <div className="filter-lb-inp-parent">
//                 <label htmlFor="filtfour">Order Status</label>
//                 <select className="w-100 p-2 fs-7" type="text">
//                     <option value="">Pending</option>
//                     <option value="">Cancelled</option>
//                     <option value="">Complete</option>
//                 </select>
//             </div>
//             <div className="filter-lb-inp-parent">
//                 <label htmlFor="filttwo">Total</label>
//                 <input type="text" placeholder="Total" />
//             </div>
//             <div className="filter-lb-inp-parent">
//                 <label htmlFor="filtfive">Date From</label>
//                 <div className="position-relative">
//                     <input
//                         className="date-inpt position-relative"
//                         type="date"
//                         id="filtfive"
//                     />
//                     <FontAwesomeIcon
//                         className="h-75 me-1 mt-1 position-absolute end-0"
//                         icon={faCalendar}
//                         color="SaddleBrown"
//                         fontSize="14px"
//                     />
//                 </div>
//             </div>
//             <div className="filter-lb-inp-parent">
//                 <label htmlFor="filtsix">Date To</label>
//                 <div className="position-relative">
//                     <input
//                         className="date-inpt position-relative"
//                         type="date"
//                         id="filtsix"
//                     />
//                     <FontAwesomeIcon
//                         className="h-75 me-1 mt-1 position-absolute end-0"
//                         icon={faCalendar}
//                         color="SaddleBrown"
//                         fontSize="14px"
//                     />
//                 </div>
//             </div>
//             <div className="reset-filt-parent">
//                 <input type="reset" value="Reset" />
//                 <button>
//                     <FontAwesomeIcon icon={faFilter} />
//                     Filter
//                 </button>
//             </div>
//         </div>
//     </div>
// </div>  )
// }

// export default CustomerFilter


