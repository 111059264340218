import {
  faCircleChevronLeft,
  faPencil,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useRef, useState } from "react";
import SideBar from "../../../Components/Admin/SideBar";
import { Link, useNavigate } from "react-router-dom";
import Editor from "../../../Components/Admin/CKeditor";
import axios from "axios";
import { AuthorizationToken, baseUrl } from "../../../assets/utils/IP";
import ImageUploader from "./ImageUploader";
import Swal from "sweetalert2";

export default function AddProduct() {
  const [editorLoaded, setEditorLoaded] = useState(false);
  const [data, setData] = useState("");
  const [showStock, setShowStock] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [imageUrl, setImageUrl] = useState(null);
  const imageRef = useRef()
  const [category, setCategory] = useState([]);
  const [brand, setBrand] = useState([]);
  const [selectedImages, setSelectedImages] = useState([]);
  const [product, setProduct] = useState({
    product_name: "",
    brand_id: "",
    category_id: "",
    description_1: "",
    description_2: "",
    regular_price: "",
    sale_price: "",
    quantity: "",
    gallery_images: [],
    promotion_status: 0,
  });

  //calculating cost states
  const [costOfProduct, setCostOfProduct] = useState();
  const [originalPrice, setOriginalPrice] = useState();
  const [discountedPrice, setDiscountedPrice] = useState();
  const [marketingCost, setMarketingCost] = useState();
  const [estimatedProfit, setEstimatedProfit] = useState(0);
  const [suggestedPrice, setSuggestedPrice] = useState(0);
  const [showfield, setShowField] = useState();



  const calculateProfit = () => {
    const profit = (costOfProduct + marketingCost);
    return profit;
  };

  const calculateSuggestedPrice = () => {
    const profit = calculateProfit();
    const suggestedPrice = profit - discountedPrice;
    // const suggestedPrice = costOfProduct + marketingCost + profit;
    setShowField(!showfield);
    return setSuggestedPrice(suggestedPrice);
  };


  // const calculateSuggestedPrice = () => {
  //   const profit = calculateProfit();
  //   const suggestedPrice = profit - discountedPrice;
  //   // const suggestedPrice = costOfProduct + marketingCost + profit;
  //   setShowField(!showfield);
  //   return setSuggestedPrice(suggestedPrice);
  // };

  //calculatin cost when the value cchanges
  useEffect(() => {
    const costOfProductValue = parseFloat(costOfProduct);
    const originalPriceValue = parseFloat(originalPrice);
    const discountedPriceValue = parseFloat(discountedPrice);
    const marketingCostValue = parseFloat(marketingCost);

    const estimatedProfitValue =
      originalPriceValue -
      discountedPriceValue -
      costOfProductValue -
      marketingCostValue;

    setEstimatedProfit(estimatedProfitValue);
  }, [costOfProduct, originalPrice, discountedPrice, marketingCost]);



  useEffect(() => {
    setEditorLoaded(true);
  }, []);

  useEffect(() => {
    if (selectedImage) {
      setImageUrl(URL.createObjectURL(selectedImage));
    }
  }, [selectedImage]);


  const handleImageChange = (index, images) => {
    const updatedSelectedImages = [...selectedImages];
    updatedSelectedImages[index] = images;
    setSelectedImages(updatedSelectedImages);
  };

  useEffect(() => {
    const getAllCategory = async () => {
      try {
        const res = await axios.get(`${baseUrl}/get-all-category`);
        setCategory(res.data);
      } catch (error) {
      }
    };
    getAllCategory();
  }, []);

  // get all Brands category
  useEffect(() => {
    const getBrands = async () => {
      try {
        const response = await axios.get(`${baseUrl}/get-all-brands`);
        if (response?.status == 200) {
          setBrand(response?.data);
        }
      } catch (err) {
      }
    };
    getBrands();
  }, []);


  useEffect(() => {
    setEditorLoaded(true);
  }, []);

  const navigate = useNavigate()

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const formData = new FormData();
      // Append product data to the FormData object
      formData.append("product_name", product.product_name.trim());
      formData.append("brand_id", product.brand_id);
      formData.append("category_id", product.category_id);
      formData.append("description_1", product.description_1);
      formData.append("description_2", data);
      formData.append("regular_price", product.regular_price);
      formData.append("sale_price", product.sale_price ? product.sale_price : '');
      formData.append("quantity", product.quantity);
      formData.append("promotion_status", product.promotion_status);

      product.gallery_images.forEach((elem, ind) => {
        formData.append(`gallery_images`, product.gallery_images[ind]);
      })

      formData.append("product_image", selectedImage);

      if (parseInt(product.sale_price) >= parseInt(product.regular_price)) {
        Swal.fire({
          icon: "error", // Use "success" for the icon
          title: "Price not set properly",
          text: "Sale price should be less tha regular price",
          customClass: {
            container: "customstyle",
          }
        });
      }
      else {
        const res = await axios.post(`${baseUrl}/create-product`, formData, {
          headers: {
            headers: {
              'Authorization':
                `Bearer ${AuthorizationToken}`,
            },
          }
        });
        Swal.fire({
          icon: "success",
          title: "Product Add",
          text: "Your Product Added successfully",
          customClass: {
            container: "customstyle",
          }
        });
        navigate("/AdminProduct")
      }
    } catch (error) {
      if (error?.response?.data.message == "main Image are required") {
        Swal.fire({
          icon: "error",
          title: "Required Feilds",
          text: "main Image are required",
          customClass: {
            container: "customstyle",
          }
        });
      }
      else if (error?.response?.data.message == "File is too large. Max file size is 5 MB.") {
        Swal.fire({
          icon: "error",
          title: "Image issue",
          text: "File is too large. Max file size is 5 MB.",
          customClass: {
            container: "customstyle",
          }
        });
      }
      else {
        Swal.fire({
          icon: "error",
          title: "Product not added succesfully",
          text: "Please Add brand/Category",
          customClass: {
            container: "customstyle",
          }
        });
      }
    }
  };

  const handleImageUpload = (images) => {
    setProduct({ ...product, gallery_images: images })
  };





  return (
    <section className="bg-main">
      <SideBar />
      <div className="second-main-side">
        <div className="sec-main-side-parent p-md-4 p-lg-5">
          <div className="blur-pages px-59">
            <div className="border-text">
              <div className="d-flex justify-content-between">
                <h1>Add Products</h1>
                <div className="boxs-flex">
                  <Link
                    className="p-3 justify-content-center bg-light w-25 h-25 d-flex align-items-center text-dark rounded-2"
                    to="/AdminProduct"
                  >
                    <FontAwesomeIcon icon={faCircleChevronLeft} />
                  </Link>
                </div>
              </div>
              <hr className="text-light opacity-100" />
            </div>
            <div className="add-categorie-list mt-5">
              <FontAwesomeIcon color="#fff" icon={faPencil} />
              <span className="mx-2">Add Product</span>
            </div>
            <form action="" onSubmit={handleSubmit}>
              <div className="row">
                <div className="col-lg-9">
                  <div className="grid-add d-grid align-items-center my-4">
                    <label className="text-light d-block text-end" htmlFor="">
                      <span className="text-warning">*</span>Product Name
                    </label>
                    <input className="py-1 px-2" type="text"
                      name="product_name"
                      value={product.product_name}
                      required
                      maxLength={18}
                      onChange={(e) => setProduct({ ...product, product_name: e.target.value.trim() })} />
                  </div>
                  <hr className="text-light my-4" />
                  <div className="grid-add d-grid align-items-center my-4">
                    <label className="text-light d-block text-end" htmlFor="brand">
                      Select Brand
                    </label>
                    <select className="py-1 px-2 border" name="brand" id="brand" value={product.brand_id}
                      onChange={(e) => setProduct({ ...product, brand_id: e.target.value })}
                    >
                      <option value="">Select Brand</option>
                      {brand.map((brand) => (
                        <option key={brand.brand_id} value={brand.brand_id}>
                          {brand.brand_name}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="grid-add d-grid align-items-center my-4">
                    <label className="text-light d-block text-end" htmlFor="category">
                      Select Category
                    </label>
                    <select
                      className="py-1 px-2 border"
                      name="category"
                      id="category"
                      value={product.category_id}
                      onChange={(e) =>
                        setProduct({ ...product, category_id: e.target.value })
                      }
                    >
                      <option value="">Select Category</option>
                      {category.map((category) => (
                        <option key={category.category_id} value={category.category_id}>
                          {category.category_name}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="grid-add d-grid my-4">
                    <label
                      className="text-light d-block text-end p-"
                      htmlFor=""
                    >
                      Short Description
                    </label>
                    {/* <input type="text" /> */}
                    <textarea name="description_1" className="p-1" rows="3"
                      value={product.description_1}
                      onChange={(e) => setProduct({ ...product, description_1: e.target.value })}></textarea>
                  </div>
                  <div className="grid-add d-grid my-4">
                    <label className="text-light d-block text-end" htmlFor="">
                      Long Description
                    </label>
                    <Editor
                      name="description"
                      onChange={(data) => {
                        setData(data);
                      }}
                      editorLoaded={editorLoaded}
                    />
                  </div>
                  <hr className="text-light my-4" />
                  <div className="grid-add d-grid my-4">
                    <label className="text-light d-block text-end" htmlFor="">
                      Regular Price
                    </label>
                    <input type="number" name="regular_price"
                      value={product.regular_price}
                      onChange={(e) => {
                        const sanitizedValue3 = e.target.value.replace(/[^0-9.]/g, '');

                        if (sanitizedValue3 >= 0) {
                          const ind = sanitizedValue3.indexOf('.')
                          if (ind !== -1) {
                            const limitedValue = sanitizedValue3.slice(0, ind + 3);
                            setProduct({
                              ...product, regular_price: limitedValue
                            })
                          }
                          else {
                            setProduct({
                              ...product, regular_price: sanitizedValue3
                            })
                          }
                        }
                      }
                      } />
                  </div>
                  <div className="grid-add d-grid my-4">
                    <label className="text-light d-block text-end" htmlFor="">
                      Sale Price
                    </label>
                    <input type="number" name="sale_price"
                      value={product.sale_price}
                      onChange={(e) => {
                        const sanitizedValue2 = e.target.value.replace(/[^0-9.]/g, '');

                        if (sanitizedValue2 >= 0) {
                          const ind = sanitizedValue2.indexOf('.')
                          if (ind !== -1) {
                            const limitedValue = sanitizedValue2.slice(0, ind + 3);
                            setProduct({
                              ...product, sale_price: limitedValue
                            })
                          }
                          else {
                            setProduct({
                              ...product, sale_price: sanitizedValue2
                            })
                          }
                        }
                      }} />
                  </div>
                  <hr className="text-light my-4" />
                  <div className="grid-add d-grid my-4 alignset">
                    <label className="text-light d-block text-end" htmlFor="">
                      Manage Stock
                    </label>
                    <div className="text-start">
                      <input
                        type="checkbox"
                        checked={showStock}
                        onChange={(e) => setShowStock(e.target.checked)}
                      />
                    </div>
                  </div>
                  <div className="grid-add d-grid my-4 alignset">
                    <label className="text-light d-block text-end" htmlFor="">
                      Promotions Product
                    </label>
                    <div className="text-start">
                      <input type="checkbox" checked={product.promotion_status === 1}
                        onChange={(e) => setProduct({ ...product, promotion_status: product.promotion_status === 1 ? 0 : 1 })} />
                    </div>
                  </div>

                  {showStock === true ? (<div className="grid-add d-grid my-4">
                    <label className="text-light d-block text-end" htmlFor="">
                      Quantity
                    </label>
                    <input
                      type="number"
                      className="py-1 px-2"
                      name="quantity"
                      value={product.quantity}
                      onChange={(e) => {
                        const sanitizedValue = e.target.value.replace(/[^0-9]/g, '');
                        const limitedValue = sanitizedValue.slice(0, 5);
                        if (sanitizedValue >= 0) {
                          setProduct({ ...product, quantity: limitedValue });
                        }
                      }}
                    />
                  </div>) : ""}

                  <div className="grid-add d-grid my-4">
                    <label htmlFor=""></label>
                    <input
                      className="fw-bold add-save-btn rounded-3 border-0 ms-auto"
                      type="submit"
                      value="Save"
                    />
                  </div>
                </div>
                <div className="col-lg-3">
                  <div className="row">
                    <div className="border-bottom border-secondary col-lg-12 mb-3 pb-2">
                      {imageUrl && selectedImage && (
                        <div className="mb-3" textAlign="center">
                          <img ref={imageRef} src={imageUrl} alt={selectedImage.name} width="100%" className="border border-secondary p-1 rounded-3" />
                        </div>
                      )}
                      <input
                        accept="image/*"
                        type="file"
                        id="select-image"
                        onChange={(e) => { setSelectedImage(e.target.files[0]); }}
                      />
                      <label htmlFor="select-image" className="bg-gradient cursor-pointer mb-2 px-3 py-3 rounded-4 text-white upl-img--resp">
                        {!imageUrl && !selectedImage ? "Upload Featured Image" : "Change Featured Image"}
                      </label>

                    </div>
                    <div className="col-lg-12">
                      <ImageUploader onImagesChange={handleImageUpload} title="Upload Multiple Images" />
                    </div>

                  </div>
                  <div className="px-1">
                    <div className="row my-3 bg-light cost-val-parent-resp">
                      <div className="col-7 p-0">
                        <div className="bg-cross-img cost-and-val-resp p-3 fw-bold">
                          COST CALCULATOR
                        </div>
                      </div>
                      <div className="col-5">
                        <div className="p-3 fw-bold cost-and-val-resp">VALUES</div>
                      </div>
                    </div>
                    <div className="row border-bottom border-2 cost-sub-text-parent">
                      <div className="col-lg-8 bg-light p-3">
                        <label className="p-2 fw-bold" htmlFor="">
                          Cost of Product
                        </label>
                      </div>
                      <div className="col-lg-4 bg-light p-3 bg-400-grey">
                        <input
                          className="w-100 p-1"
                          type="number"
                          value={costOfProduct}
                          onChange={(e) =>
                            setCostOfProduct(Number(e.target.value))
                          }
                        />
                      </div>
                    </div>
                    <div className="row border-bottom border-2 cost-sub-text-parent">
                      <div className="col-lg-8 bg-light p-3">
                        <label className="p-2 fw-bold" htmlFor="">
                          Original Selling Price
                        </label>
                      </div>
                      <div className="col-lg-4 bg-light p-3 bg-400-grey">
                        <input
                          className="w-100 p-1"
                          type="number"
                          value={originalPrice}
                          onChange={(e) =>
                            setOriginalPrice(Number(e.target.value))
                          }
                        />
                      </div>
                    </div>
                    <div className="row border-bottom border-2 cost-sub-text-parent">
                      <div className="col-lg-8 bg-light p-3">
                        <label className="p-2 fw-bold" htmlFor="">
                          Discounted Price
                        </label>
                      </div>
                      <div className="col-lg-4 bg-light p-3 bg-400-grey">
                        <input
                          className="w-100 p-1"
                          type="number"
                          value={discountedPrice}
                          onChange={(e) =>
                            setDiscountedPrice(Number(e.target.value))
                          }
                        />
                      </div>
                    </div>
                    <div className="row border-bottom border-2 cost-sub-text-parent">
                      <div className="col-lg-8 bg-light p-3">
                        <label className="p-2 fw-bold" htmlFor="">
                          Marketing Cost
                        </label>
                      </div>
                      <div className="col-lg-4 bg-light p-3 bg-400-grey">
                        <input
                          className="w-100 p-1"
                          type="number"
                          value={marketingCost}
                          onChange={(e) =>
                            setMarketingCost(Number(e.target.value))
                          }
                        />
                      </div>
                    </div>
                    <div className="row border-bottom border-2 cost-sub-text-parent">
                      <div className="col-lg-8 bg-light p-3">
                        <label className="p-2 fw-bold" htmlFor="">
                          Estimated Profit
                        </label>
                      </div>
                      <div className="col-lg-4 bg-light p-3 bg-400-grey d-flex align-items-center">
                        {isNaN(estimatedProfit) ? 0 : estimatedProfit}
                      </div>
                    </div>
                  </div>
                  <div className="text-start mt-4">
                    <button
                      className="btn-gradient p-2 border-0 rounded"
                      onClick={calculateSuggestedPrice}
                    >
                      Suggest Price
                    </button>

                    {showfield && (
                      <div className="col-lg-18 mt-10 bg-light mt-3 p-3 justify-content-between bg-400-grey d-flex align-items-center">
                        <span className="fw-bold">{`$${suggestedPrice}`}</span>
                        <span>
                          At <b>20%</b> Off
                        </span>
                      </div>
                    )}
                  </div>
                </div>


              </div>
            </form>
          </div>
        </div>
      </div >
    </section >
  );
}
