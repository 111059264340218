import React, { useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import SideBar from "../../../Components/Admin/SideBar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircleChevronLeft,
} from "@fortawesome/free-solid-svg-icons";
import { AuthorizationToken, baseUrl } from "../../../assets/utils/IP";
import axios from "axios";
import { useState } from "react";
import { RatingDisplay } from "../../../Components/RatingDisplay";
import Swal from "sweetalert2";

export default function ManageReviews() {
  const [reviewById, setReviewById] = useState()
  const { id } = useParams()


  useEffect(() => {
    const fetchData = async () => {
      try {
        let config = {
          method: 'get',
          maxBodyLength: Infinity,
          url: `${baseUrl}/get-reviewById/${id}`,
        };

        const response = await axios.request(config);
        setReviewById(response.data)
      } catch (error) {
      }
    };

    fetchData();
  }, [id]);

  const handleApproved = () => {
    let data = {
      "review_status": "Approved"
    };

    let config = {
      method: 'put',
      maxBodyLength: Infinity,
      url: `${baseUrl}/update-review/${id}`,
      headers: {
        'Authorization':
          `Bearer ${AuthorizationToken}`,
      },
      data: data
    };

    axios
      .request(config)
      .then((response) => {
        Swal.fire({
          icon: "success",
          title: "Review Approved Successfully",
        });
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Something went wrong",
        });
      });
  };


  const handleDeclined = () => {
    let data = {
      "review_status": "Declined"
    };

    let config = {
      method: 'put',
      maxBodyLength: Infinity,
      url: `${baseUrl}/update-review/${id}`,
      headers: {
        'Authorization':
          `Bearer ${AuthorizationToken}`,
      },
      data: data
    };

    axios
      .request(config)
      .then((response) => {
        Swal.fire({
          icon: "Danger",
          title: "Review Declined Successfully",
        });
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Something went wrong",
        });
      });
  };

  return (
    <section className="bg-main">
      <SideBar />
      <div className="second-main-side">
        <div className="sec-main-side-parent p-md-4 p-lg-5">
          <div className="blur-pages px-5">
            <div className="border-text">
              <div className="d-flex justify-content-between">
                <h1>Edit Review</h1>
                <div className="boxs-flex">
                  <Link
                    className="p-3 justify-content-center bg-light w-25 h-25 d-flex align-items-center text-dark rounded-2"
                    to="/ManageReviews"
                  >
                    <FontAwesomeIcon icon={faCircleChevronLeft} />
                  </Link>
                </div>
              </div>
              <hr className="text-light opacity-100" />
            </div>

            <div className="edit-mange-cent position-relative">
              <div className="edit-mnge-heading">
                <h3>{reviewById?.product_name}</h3>
              </div>
              <div className="edit-mnge-star">
                <RatingDisplay review_rating={reviewById?.review_rating} />
              </div>
            </div>
            <hr className="hr-edit-cust" />
            <div className="edit-cust-grid">
              <div className="edit-cust-grid-inner text-end">
                <p>Name</p>
              </div>
              <div className="edit-cust-grid-inner">
                <input type="text" placeholder={reviewById?.user_first_name} readOnly />
              </div>
            </div>
            <hr className="hr-edit-cust" />
            <div className="edit-cust-grid">
              <div className="edit-cust-grid-inner text-end">
                <p>Email</p>
              </div>
              <div className="edit-cust-grid-inner">
                <input type="email" placeholder={reviewById?.user_email} readOnly />
              </div>
            </div>
            <hr className="hr-edit-cust" />
            <div className="edit-cust-grid">
              <div className="edit-cust-grid-inner text-end">
                <p>Review Title</p>
              </div>
              <div className="edit-cust-grid-inner">
                <input type="text" placeholder={reviewById?.review_title} readOnly />
              </div>
            </div>
            <hr className="hr-edit-cust" />
            <div className="edit-cust-grid">
              <div className="edit-cust-grid-inner text-end h-100">
                <p>Review Message</p>
              </div>
              <div className="edit-cust-grid-inner">
                <textarea placeholder={reviewById?.review_message} readOnly></textarea>
              </div>
            </div>
            <div className="d-flex justify-content-end mt-4">
              <Link
                className="py-2 px-4 rounded  bg-success mx-2 text-light "

                onClick={handleApproved}
              >
                Approved
              </Link>
              <Link
                className="py-2 px-4 rounded  bg-danger mx-2 text-light "

                onClick={handleDeclined}
              >
                Declined
              </Link>
            </div>
            <div className="d-flex justify-content-between">
              <div className="pagi mnge-rev-pagi">
              </div>
            </div>
          </div>
        </div>
      </div>

    </section>
  );
}
