import Warning from "../Components/Warning";
import Header from "../Components/Header/Header";
import Footer from "../Components/Footer/Footer";
import Slider from "react-slick";
import Rating from "../Components/Rating";
import RatingBy from "../Components/RatingByReview";

import { Link, Navigate, useNavigate, useParams } from "react-router-dom";
import React, { useState } from "react";
import flower from "../assets/images/flower.png";
import smokepromotionspan from "../assets/images/smoke-promotion-span.png";
import buyleftspan from "../assets/images/buyleftspan.png";
import buyrightspan from "../assets/images/buyrightspan.png";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import modallogo from "../assets/images/modallogo.png";
import axios from "axios";
import { useEffect } from "react";
import { baseUrl } from "../assets/utils/IP";
import Swal from "sweetalert2";
import { useContext } from "react";
import { CartContext } from "../Components/Cart";
import ReviewComponent from "./ReviewComponent"
import AddToCartModal from "../Components/Modals/AddToCartModal";

import TrunctedText from "../Components/TrunctedText";

function Buynow() {
  const [products, setProducts] = useState();
  const [selectedProducts, setSelectedProducts] = useState([])
  console.log("selectedProducts>>>", selectedProducts)
  const [productsQty, setProductsQty] = useState([])
  // console.log("productsQty>>>", productsQty)

  const [reviewPercentage, setReviewPercentage] = useState()
  const [likeProducts, setLikeProducts] = useState()
  const [recentViewdProduct, setRecentViewdProduct] = useState()
  const [toggle, setToggle] = useState(0);
  const [isStarBoxVisible, setIsStarBoxVisible] = useState(true);
  const [p_quantity, setQuantity] = useState()
  const [state1, setState1] = useState("");
  const [state2, setState2] = useState("");
  const [state3, setState3] = useState("");
  const [state4, setState4] = useState("");
  const [state5, setState5] = useState("");
  const [checkWl, setCheckWl] = useState("");
  const [parentStars, setParentStars] = useState(null);
  const [reviewName, setReviewName] = useState(null);
  const [reviewMessage, setReviewMessage] = useState(null);
  const [reviewTitle, setReviewTitle] = useState(null);
  const [currentRating, setCurrentRating] = useState(null);
  const [reviewCount, setReviewCount] = useState();
  const [reviewCount3, setReviewCount3] = useState();
  const [reviewCount5, setReviewCount5] = useState();
  const [reviewCount1, setReviewCount1] = useState();
  const [reviewCount4, setReviewCount4] = useState();
  const [reviewCount2, setReviewCount2] = useState();
  const [stateObject, setStateObject] = useState({})
  const [flag, setFlag] = useState(true);
  const [frequentlyProducts, setFrequentlyProducts] = useState("");
  // console.log("frequentlyProducts>>>", frequentlyProducts)
  const [contact, setContact] = useState({
    name: "",
    email: "",
    message: "",
  });
  const [addProdValue, setAddProdValue] = useState(0);
  
  const [show, setShow] = useState(false);
  const [cartvalue, setcartvalue] = useState(1);
  const [cartProduct, setCartProduct] = useState();

  const { updateQuantity, updateQuantityArr, getProductById, cartItems } =
    useContext(CartContext);
  const UserID = localStorage.getItem("userId")
  const { id } = useParams()

  const settings = {
    dots: false,
    infinite: true,
    speed: 1000,
    slidesToShow: 3,
    slidesToScroll: 2,
    autoplay: true,
    autoplaySpeed: 4000,
  };

  const handleToggleClick = () => {
    setIsStarBoxVisible(!isStarBoxVisible);
  };

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }
  const AddToCartFunction = (prod) => {
    updateQuantity(prod, p_quantity)
    Swal.fire({
      icon: "success",
      title: "Add to Cart",
      text: "Your Product is Added to Cart",
      showCancelButton: true,
      confirmButtonText: "Proceed to Checkout",
      cancelButtonText: "Back",
      customClass: {
        container: "customstyle",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        navigate("/cart");
        window.scrollTo(0, 0)
        // console.log("Ok button clicked");
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        // Handle Back button click
      }
    });
  }

  const handleButtonClick = (prod) => {
    scrollToTop(); // Call the first function
    handleShow(prod); // Call the second function
  };


  useEffect(() => {
    const sendData = async () => {
      try {
        const data = JSON.stringify({
          "product_id": id,
          "user_id": UserID,
        });

        const config = {
          method: 'post',
          maxBodyLength: Infinity,
          url: `${baseUrl}/create-most-view`,
          headers: {
            'Content-Type': 'application/json'
          },
          data: data
        };
        const response = await axios.request(config);
      } catch (error) {
      }
    };

    sendData();
  }, [id, UserID]);


  useEffect(() => {
    let config = {
      method: 'get',
      maxBodyLength: Infinity,
      url: `${baseUrl}/review-count-by-product/${id} `,
      headers: {}
    };
    axios.request(config)
      .then((response) => {
        setReviewCount(response.data)
        setReviewCount3(response.data[0])
        setReviewCount5(response.data[1])
        setReviewCount2(response.data[2])
        setReviewCount4(response.data[3])
        setReviewCount1(response.data[4])
        setStateObject(response?.data)

      })
      .catch((error) => {
      });

  }, [id]);

  const progressBar1 = `${stateObject ? stateObject?.values?.one * 100 / 5 : 0}% `
  const progressBar2 = `${stateObject ? stateObject?.values?.two * 100 / 5 : 0}% `
  const progressBar3 = `${stateObject ? stateObject?.values?.three * 100 / 5 : 0}% `
  const progressBar4 = `${stateObject ? stateObject?.values?.four * 100 / 5 : 0}% `
  const progressBar5 = `${stateObject ? stateObject?.values?.five * 100 / 5 : 0}% `


  const handleCartValue = (event) => {
    setcartvalue(event.target.value);
  };

  useEffect(() => {
    // Define the Axios configuration
    let config = {
      method: 'get',
      maxBodyLength: Infinity,
      url: `${baseUrl}/review-final-rating/${id}`,
      headers: {}
    };

    // Make the Axios request within the useEffect
    axios.request(config)
      .then((response) => {
        setReviewPercentage(response.data)
      })
      .catch((error) => {
      });

  }, [id]);

  useEffect(() => {
    let config = {
      method: 'get',
      maxBodyLength: Infinity,
      url: `${baseUrl}/get-productById/${id}`,
      headers: {}
    };

    axios.request(config)
      .then((response) => {
        setProducts(response.data);
      })
      .catch((error) => {
      });

  }, [id]);

  useEffect(() => {
    let config = {
      method: 'get',
      maxBodyLength: Infinity,
      url: `${baseUrl}/you-may-also-like/${id}`,
      headers: {}
    };
    axios.request(config)
      .then((response) => {
        setLikeProducts(response.data)
      })
      .catch((error) => {
      });
  }, [id]);

  useEffect(() => {
    let config = {
      method: 'get',
      maxBodyLength: Infinity,
      url: `${baseUrl}/get-most-view`,
      headers: {}
    };
    axios.request(config)
      .then((response) => {
        setRecentViewdProduct(response.data)
      })
      .catch((error) => {
      });

  }, []);


  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${baseUrl}/popular-products`);
        setFrequentlyProducts(response.data?.slice(0, 3))

        let prds = { ...productsQty }
        for (let i = 0; i < response.data?.length; i++) {
          if (i < 3) {
            prds[i] = '1'
          }
        }
        setProductsQty({ ...prds })

      } catch (error) {
      }
    };

    fetchData();
  }, []);



  const ProductID = useParams()
  const navigate = useNavigate()
  const imgs = products?.gallery_images ? products?.gallery_images?.split(`,`) : []
  const checkidfromurl = Object.values(checkWl);
  const isMatching = checkidfromurl.some(product => product.product_id === ProductID.id);
  if (isMatching) {
  }
  else {
  }
  const value = getProductById(products?.product_id)
  let count = value?.p_quantity
  const minValue = 1;
  const maxValue = 50;

  useEffect(() => {
    setQuantity(count || 1)
  }, [count])

  const addToWishlist = () => {
    // Define the data to send
    let data = JSON.stringify({
      "user_id": UserID,
      "product_id": ProductID.id,
    });

    // Define the Axios configuration
    let config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: `${baseUrl}/add-wishlist`,
      headers: {
        'Content-Type': 'application/json'
      },
      data: data
    };
    axios.request(config)
      .then((response) => {
      })
      .catch((error) => {
      });
  };

  const handleAddToWL = () => {
    if (UserID) {
      addToWishlist();
      navigate("/WishList")
    }
    else {
      Swal.fire({
        icon: "Opps",
        title: "Please Login or Register",
        text: "To Add product in WishList you need to be login",
      });
    }
  };

  const handleClose = () => setShow(false);
  const handleShow = (product) => {
    updateQuantity(product, p_quantity)
    setCartProduct(product)
    setShow(true)
  };

  const incrementQuantity = () => {
    if (p_quantity < products?.quantity) {
      setQuantity(p_quantity + 1);
    }
  };
  const decrementQuantity = () => {
    setQuantity(p_quantity - 1)
  };

  const handleAdd = () => {
    let items = []
    selectedProducts.forEach(elem => {
      const qty = Number(productsQty[elem])
      items.push({
        ...frequentlyProducts[elem],
        p_quantity: qty
      })
    })

    updateQuantityArr(items)
  }

  const handleRatingChange = (newRating) => {
    setCurrentRating(newRating);
  };

  const handleStarsSelected = (selectedStars) => {
    setParentStars(selectedStars + 1);
  };

  const handleReviewSubmit = () => {
    let data = JSON.stringify({
      "user_id": UserID,
      "product_id": id,
      "review_title": reviewTitle,
      "review_rating": parentStars,
      "review_message": reviewMessage
    });

    let config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: `${baseUrl}/create-review`,
      headers: {
        'Content-Type': 'application/json'
      },
      data: data
    };

    axios.request(config)
      .then((response) => {
        Swal.fire({
          icon: "sucess",
          title: "Review",
          text: "Your Review is added",
        });
      })
      .catch((error) => {
      });
  }


  const handleQuestionSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post(`${baseUrl}/contact`, {
        name: contact.name,
        email: contact.email,
        message: contact.message,
      });

      if (response.status === 200) {
        setContact({
          ...contact,
          name: "",
          email: "",
          message: "",
        });
        Swal.fire({
          icon: "success",
          title: "Contact",
          text: "Your contact is submitted  Successful",
          customClass: {
            container: "customstyle",
          }
        });
      }
    } catch (err) {
      Swal.fire({
        icon: "error",
        title: "Contact",
        text: "Your contact is not submitted",
        customClass: {
          container: "customstyle",
        }
      });
    }
  };

  const handleAddProdValue = (prods, qtys) => {
    console.log("working", prods, qtys)
    let updatedPrice = 0;
    console.log("check>>>", prods.length)
    if (prods.length > 0) {
      prods.forEach(prod => {
        let price = frequentlyProducts[prod].sale_price == 0 ? frequentlyProducts[prod]?.regular_price : frequentlyProducts[prod]?.sale_price
        let qty = qtys[prod]
        console.log("Proce>>", price, "qty>>>", qty)
        updatedPrice += price * qty
      })
      setAddProdValue(updatedPrice)
    }
    else {
      setAddProdValue(0)
    }
  }


  return (
    <div className="font-bebas">
      <Warning />

      <Header />

      <div className="bglinear">
        <section className="buynowsec tab-ovf-x-y-hide">
          <span className="buyleftspan">
            <img src={buyleftspan} alt="" />
          </span>
          <span className="buyrightspan">
            <img src={buyrightspan} alt="" />
          </span>
          <div className="container-1640">
            <div className="toplinecon">
              <div className="hamicons">
                <ul>
                  <li>
                    <Link>Home</Link>
                  </li>
                  <li>
                    <Link>
                      <i class="fa-solid fa-chevron-right"></i>
                    </Link>
                  </li>
                  <li>
                    <Link>Shop</Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="row buyrowone">
              <div className="col-lg-4 col-md-6 col-12 p-0">
                <div className="buyimg">
                  <img src={baseUrl + `/` + imgs[toggle]} alt="" />


                  <div className="upcomingpro">
                    <Slider {...settings}>
                      {
                        imgs?.map((images, ind) => (
                          <img
                            src={baseUrl + `/` + images}
                            alt=""
                            onClick={() => setToggle(ind)}
                          />
                        ))
                      }
                    </Slider>
                  </div>
                </div>
              </div>

              <div className="col-lg-8 col-md-6 col-12 mt-md-0 mt-4">
                <div className="d-flex flex-column h-100 justify-content-between productdetail">
                  <div className="protitle-name">
                    <h2>{products?.product_name}</h2>
                    <div className="rating-whishlist">
                      {/* <Rating rating={4} /> */}
                      <RatingBy rating={reviewPercentage?.final_rating ? reviewPercentage?.final_rating.slice(0, 1) : 0} />

                      <div className="cohib-add">

                        {flag === true ? (
                          <div style={{ color: "green" }} onClick={handleAddToWL}>
                            <p style={{ cursor: 'pointer', padding: "7px" }}>
                              <i class="fa-solid fa-heart fa-beat"></i><span style={{ border: "none" }}>   </span>Add to Wishlist
                            </p>
                          </div>
                        ) : (
                          <div style={{ color: "red" }}>
                            <p style={{ cursor: 'pointer', padding: "7px" }}>
                              <i class="fa-solid fa-heart fa-beat"></i> <span style={{ border: "none" }}>  </span>Remove from Wishlist
                            </p>
                          </div>
                        )}
                        <span></span>
                        {UserID ? (<Link>
                          <p
                            data-bs-toggle="modal"
                            data-bs-target="#exampleModal"
                          >
                            <i class="fa-sharp fa-solid fa-star"></i>Write
                            Review
                          </p>
                        </Link>) : (<Link to="/Login">
                          <p>
                            <i class="fa-sharp fa-solid fa-star"></i>Write
                            Review
                          </p>
                        </Link>)}
                      </div>
                    </div>
                  </div>
                  <div className="prodetail-price">
                    <p className="pricesku mb-lg-5 mb-md-4">
                      ${products?.sale_price == 0 ? products?.regular_price : products?.sale_price}<span>incl. GST</span>
                    </p>
                    <div className="sku">
                      <p>Availability</p>
                      <p>
                        {products?.quantity != null && products?.quantity > 0 ? (<span>
                          <i class="fa-solid fa-check"></i> In Stock
                        </span>)
                          : (<span style={{ color: "red" }}>
                            <i class="fa-solid fa-xmark" style={{ color: "red" }}></i> Out of Stock
                          </span>)}
                      </p>
                    </div>

                    <p className="lh-lg mt-3 poppins text-white">
                      {products?.description_1}
                    </p>
                  </div>

                  {products?.quantity != null && products?.quantity > 0 ? (
                    <div>
                      <div className="addtocart">
                        <Link onClick={() => handleShow(products)}>Add to cart</Link>
                      </div>


                      <div className="quantity-parent-prdct brdr-left-prdct">
                        <div className="quantity-heading-prdt">
                          <h5 className="text-light pt-3">Quantity</h5>
                        </div>
                        <div id="field1" className="productCounter">
                          <button
                            type="button"
                            id="add"
                            className="plus add-minus-btns"
                            onClick={incrementQuantity}>
                            +
                          </button>
                          <input
                            type="number"
                            value={p_quantity ? p_quantity : 1}
                            min={1}
                            max={products?.quantity}
                            className="quantity input-add-prdct"
                            readOnly
                            style={{ color: "black" }}
                          />
                          {p_quantity > 1 &&
                            <button
                              type="button"
                              id="sub"
                              className="minus add-minus-btns"
                              onClick={decrementQuantity}

                            >
                              -
                            </button>}
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="addtocart">
                      <Link>Add to cart</Link>
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div className="row Description ">
              <span className="buyleftspan">
                <img src={buyleftspan} alt="" />
              </span>
              <span className="buyrightspan">
                <img src={buyrightspan} alt="" />
              </span>
              <div className="col-lg-12 ">
                <div className="discript">
                  <div className="abdesttitle">
                    <h2>Description</h2>
                  </div>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: products?.description_2,
                    }}
                  ></div>
                  {/* <p>{products?.description_2}</p> */}
                </div>
              </div>
            </div>

            <div className="row FrequentlyBought">
              <span className="buyleftspan">
                <img src={buyleftspan} alt="" />
              </span>
              <span className="buyrightspan">
                <img src={buyrightspan} alt="" />
              </span>
              <div className="col-lg-12">
                <div className=" frequently">
                  <h2>Frequently Bought Together</h2>
                  <div className="dflx">
                    {frequentlyProducts && frequentlyProducts?.map((item) => (
                      <div className="leftpro img-width-set">
                        {item.main_image == null ? <></> : (<Link className="position-relative" to={`/Buynow/${item.product_id}`} onClick={scrollToTop}>
                          <img className="imgProduct" src={`${baseUrl}/${item.main_image}`} style={{
                            width: "100%",
                          }} alt="" />
                          <span className="PlusIconFrequently">
                            +
                          </span>
                        </Link>)}
                      </div>
                    ))}
                    <div className="righttpro">
                      <p>Total Price: ${addProdValue}.00</p>
                      <a onClick={handleAdd}>Add Selected To Cart</a>
                    </div>
                  </div>


                  {frequentlyProducts && frequentlyProducts?.map((item, index) => (
                    item.main_image === null ? <></> :
                      <div key={index}>
                        <div class="categoriesitem frequent">
                          <div class="form-group">
                            <input
                              onChange={(e) => {
                                let prds = [...selectedProducts]
                                const ind = prds.findIndex(elem => elem === index)
                                console.log("ind>>>", ind)
                                // if (ind === -1) {
                                //   prds.push(index)
                                // }
                                // else if (ind === 0) {
                                //   prds.shift()
                                // }
                                // else {
                                //   prds.splice(ind, 1)
                                // }

                                if (ind !== -1) {
                                  prds.splice(ind, 1)
                                }
                                else {
                                  prds.push(index)
                                }

                                setSelectedProducts(prds)
                                handleAddProdValue(prds, productsQty)
                              }}
                              type="checkbox"
                              value={index}
                              id={index + ''}
                            />
                            <label for={index + ''}></label>
                          </div>
                          <div class="catecon">
                            <p>{item.product_name}</p>
                            <input
                              onChange={(e) => {
                                let updatedProdsQty = { ...productsQty, [index]: e.target.value }
                                setProductsQty(updatedProdsQty)
                                handleAddProdValue(selectedProducts, updatedProdsQty)
                              }}
                              type="number"
                              min={1}
                              defaultValue={1}
                            />
                            <p>${item.sale_price}</p>
                          </div>
                        </div>
                      </div>
                  ))}

                </div>
              </div>
            </div>
            <div class="content-descrip-sec-parent">
              <span className="buyleftspan">
                <img src={buyleftspan} alt="" />
              </span>
              <span className="buyrightspan">
                <img src={buyrightspan} alt="" />
              </span>
              <div class="descrip-box descrip-box-gap">
                <div class="rating-box-pr-top-parent">
                  <div class="rating-pr gapp-tab-ratin" style={{ display: "flex" }}>
                    <span class="rate-pr-head-one">{reviewPercentage?.final_rating ? reviewPercentage?.final_rating.slice(0, 3) : 0}</span>
                    <RatingBy rating={reviewPercentage?.final_rating ? reviewPercentage?.final_rating.slice(0, 1) : 0} />
                    <span class="rate-pr-head-two d-lg-inline-block d-flex align-items-center">Based on Reviews</span>
                  </div>
                  <div class="rating-pr gapp-tab-ratin">
                    <span class="rate-pr-head-one">{reviewPercentage?.final_rating ? (reviewPercentage?.final_rating * 100) / 5 : 0}%</span>
                    <span class="rate-pr-head-two">
                      would recommend this product
                    </span>
                  </div>
                </div>
                <div class="progess-bar-parensupper">
                  <div class="progess-bar-parent">
                    <div class="progres-num-and-star">
                      <span class="rate-pr-head-two">1</span>

                      <span class="sm-starr">
                        <i class="fa-solid fa-star"></i>
                      </span>
                    </div>
                    <div class="progress2 progress-moved">
                      <div class="progress-bar2" style={{ width: `${progressBar1}` }}></div>
                    </div>
                    <div class="progres-num-and-star progres-num-and-star-pd">
                    </div>
                  </div>
                  <div class="progess-bar-parent">
                    <div class="progres-num-and-star">
                      <span class="rate-pr-head-two">2</span>
                      {/* Rating */}

                      <span class="sm-starr">
                        <i class="fa-solid fa-star"></i>
                      </span>
                    </div>
                    <div class="progress2 progress-moved">
                      <div class="progress-bar2" style={{ width: `${progressBar2}` }}></div>
                    </div>
                    <div class="progres-num-and-star progres-num-and-star-pd">
                      <span class="rate-pr-head-two"></span>
                    </div>
                  </div>
                  <div class="progess-bar-parent">
                    <div class="progres-num-and-star">
                      <span class="rate-pr-head-two">3</span>
                      {/* Rating */}

                      <span class="sm-starr">
                        <i class="fa-solid fa-star"></i>
                      </span>
                    </div>
                    <div class="progress2 progress-moved">
                      <div class="progress-bar2" style={{ width: `${progressBar3}` }}></div>
                    </div>
                    <div class="progres-num-and-star progres-num-and-star-pd">
                      <span class="rate-pr-head-two"></span>
                    </div>
                  </div>
                  <div class="progess-bar-parent">
                    <div class="progres-num-and-star">
                      <span class="rate-pr-head-two">4</span>
                      {/* Rating */}
                      <span class="sm-starr">
                        <i class="fa-solid fa-star"></i>
                      </span>
                    </div>
                    <div class="progress2 progress-moved">
                      <div class="progress-bar2" style={{ width: `${progressBar4}` }}></div>
                    </div>
                    <div class="progres-num-and-star progres-num-and-star-pd">
                      <span class="rate-pr-head-two"></span>
                    </div>
                  </div>
                  <div class="progess-bar-parent">
                    <div class="progres-num-and-star">
                      <span class="rate-pr-head-two">5</span>
                      {/* Rating */}
                      <span class="sm-starr">
                        <i class="fa-solid fa-star"></i>
                      </span>
                    </div>
                    <div class="progress2 progress-moved">
                      <div class="progress-bar2" style={{ width: `${progressBar5}` }}></div>
                    </div>
                    <div class="progres-num-and-star progres-num-and-star-pd">
                      <span class="rate-pr-head-two"></span>
                    </div>
                  </div>
                </div>
                <ReviewComponent />

              </div>
            </div>
          </div>
        </section>

        <div className="buyproduct tab-ovf-x-y-hide">
          <span className="buyleftspan">
            <img src={buyleftspan} alt="" />
          </span>
          <span className="buyrightspan">
            <img src={buyrightspan} alt="" />
          </span>
          <div className="container-1640 position-relative z-10">
            <div className="d-flex flex-wrap justify-content-around youmealso filter-mean">
              <h4 className="d-flex justify-content-center w-100">
                <span className="blue-color-thme">You May</span> <span className="ms-2 beige-color"> Also Like</span>
              </h4>
              {likeProducts?.slice(0, 4).map((product, index) => (
                <div key={index} className="d-flex position-relative product-detail-also-like">
                  {/* // <div key={index} className="pro-content"> */}
                  <div className="align-items-center d-flex mx-2 pro2-content">
                    <div className="position-relative product-img z-1">
                      <Link to={`/Buynow/${product.product_id}`}>
                        <img src={`${baseUrl}/${product.main_image}`} alt="" className="w-100" />
                      </Link>
                    </div>
                    <div className="position-relative procontent justify-content-center">
                      <Link to={`/Buynow/${product.product_id}`}>
                        <img src={flower} alt="" />
                        <h1>{product.product_name}</h1>
                        <TrunctedText text={product.description_1} maxLength={53} />
                        {/* <p>{product.description_1}</p> */}
                        <h6>${parseInt(product.sale_price)}</h6>
                        <span>incl. GST</span>
                      </Link>
                      <button onClick={() => handleButtonClick(product)} className="border-0 probtn position-relative z-1560">
                        Add to Cart
                      </button>
                    </div>
                    <div className="per-off per-off-single-pr">
                      {parseInt(product.discount_percentage)}%<sub> &nbsp;Off</sub>
                    </div>
                  </div>
                  <span className="position-absolute smokehover bottom-0">
                    <img src={smokepromotionspan} alt="" className="w-100" />
                  </span>
                </div>

              ))}

            </div>

            <div className="d-flex flex-wrap justify-content-around youmealso filter-mean">
              <h4 className="d-flex justify-content-center w-100">
              <span className="blue-color-thme">Recently</span> <span className="ms-2 beige-color">Viewed</span>
              </h4>
              {recentViewdProduct?.slice(0, 4).map((product) => (
                <div key={product.id} className="d-flex position-relative product-detail-also-like">
                  <div className="align-items-center d-flex mx-2 pro2-content">
                    <div className="position-relative product-img z-1">
                      <Link to={`/Buynow/${product.product_id}`}>
                        <img src={`${baseUrl}/${product.main_image}`} alt="" className="w-100" />
                      </Link>
                    </div>
                    <div className="position-relative procontent justify-content-center">
                      <Link to={`/Buynow/${product.product_id}`}>
                        <img src={flower} alt="" />
                        <h1>{product.product_name}</h1>
                        <TrunctedText text={product.description_1} maxLength={53} />
                        {/* <p>{product.description_1}</p> */}
                        <h6>{parseInt(product.sale_price)}</h6>
                        <span>incl. GST</span>
                      </Link>
                      <button onClick={() => handleButtonClick(product)} className="probtn border-0 position-relative z-1560" >
                        Add to Cart
                      </button>
                    </div>

                  </div>
                  <span className="position-absolute smokehover bottom-0">
                    <img src={smokepromotionspan} alt="" className="w-100" />
                  </span>
                </div>
              ))}
            </div>
          </div>
        </div>

        
      </div>

      <Footer />
      <div
          class="modal cohibeproduct buy-now-review-modal-tab-resp fade"
          id="exampleModal"
          tabindex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
              <div class="modal-header">
                <span className="modallogo">
                  <img src={modallogo} alt="" />
                </span>
                <button
                  type="button"
                  class="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  <i class="fa-solid fa-circle-xmark"></i>
                </button>
              </div>
              <div class="modal-body">
                <div className="cohibamodalcontetn">
                  <div>
                    <img src={`${baseUrl}/${products?.main_image}`} />
                  </div>
                  <div className="managerating">
                    <h2>{products?.product_name}</h2>
                    <Rating onStarsSelected={handleStarsSelected} rating={currentRating} onRatingChange={handleRatingChange} />
                  </div>
                </div>

                <div className="cohibinput">
                  <input type="text" placeholder="Review Title" value={reviewTitle} onChange={(e) => setReviewTitle(e.target.value)} />
                  <textarea
                    cols="30"
                    rows="10"
                    placeholder="What did you think about this Product?"
                    value={reviewMessage} onChange={(e) => setReviewMessage(e.target.value)}
                  ></textarea>

                  <input type="text" placeholder="Name" value={reviewName} onChange={(e) => setReviewName(e.target.value)} />

                </div>
                <div className="btnaggrement pb-2">
                  <p>
                    By continuing you agree to our
                    <span>
                      <Link> Terms and Conditions </Link>
                    </span>
                    and
                    <span>
                      <Link to="/Privacy-Policy"> Privacy Policy </Link>
                    </span>
                  </p>
                  <Link class="btn btn-secondary mt-3" data-bs-dismiss="modal"
                    onClick={handleReviewSubmit}
                  >
                    Agree & Submit
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* modale 2 */}

        <div
          class="modal cohibeproduct fade"
          id="exampleModal1"
          tabindex="-1"
          aria-labelledby="exampleModalLabel1"
          aria-hidden="true"
        >
          <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
              <div class="modal-header">
                <span className="modallogo">
                  <img src={modallogo} alt="" />
                </span>
                <button
                  type="button"
                  class="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  <i class="fa-solid fa-circle-xmark"></i>
                </button>
              </div>
              <div class="modal-body">
                <div className="cohibamodalcontetn">
                  <div>
                    <img src={`${baseUrl}/${products?.main_image}`} />
                  </div>
                  <div className="managerating">
                    <h2>{products?.product_name}</h2>
                  </div>
                </div>

                <div className="cohibinput">
                  <textarea
                    cols="30"
                    rows="10"
                    placeholder="What would you know about Cohiba Lighter ?"
                    value={contact.message}
                    onChange={(e) => {
                      setContact({ ...contact, message: e.target.value });
                    }}

                  ></textarea>

                  <input type="text" placeholder="Name" value={contact.name}
                    onChange={(e) => {
                      setContact({ ...contact, name: e.target.value });
                    }} />
                  <input type="email" placeholder="Email Address" value={contact.email}
                    onChange={(e) => {
                      setContact({ ...contact, email: e.target.value });
                    }} />

                </div>
                <div className="btnaggrement">
                  <p>
                    By continuing you agree to our
                    <span>
                      <Link>Terms and Conditions</Link>
                    </span>
                    and
                    <span>
                      <Link> Privacy Policy </Link>
                    </span>
                  </p>
                  <Link
                    to="/buynow"
                    class="btn btn-secondary"
                    data-bs-dismiss="modal"
                    onClick={handleQuestionSubmit}
                  >
                    Agree & Submit
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>

      {/* <Modal
        className="accountcreated continuemodl"
        show={show}
        onHide={handleClose}
      >
        <Modal.Header className="pb-3">
          <h2 className="mb-0">{products?.product_name}</h2>
          <Button onClick={handleClose} className="p-0">
            <i class="fa-solid fa-circle-xmark"></i>
          </Button>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-lg-5 p-0">
              <div className="ProductModalImg">
                <img src={`${baseUrl}/${products?.main_image}`} alt="Smoke" />
              </div>
            </div>
            <div className="col-lg-7">
              <div className="mx-2 my-4">
                <h2>
                  <i class="fa-solid fa-check"></i> {products?.product_name} was added to{" "}
                  <br />
                  your shopping cart. !
                </h2>

                <p class="pricesku">
                  ${cartvalue * products?.sale_price == 0 ? products?.regular_price : products?.sale_price} <span>incl. GST</span>
                </p>
              </div>
              <div class="signinbtn d-flex">
                <Link to="/sales" className="me-4 px-4 py-2">
                  <i class="fa-solid fa-chevron-left"></i> Continue Shopping
                </Link>
                <Link to={'/cart'} className="px-4 py-2">
                  Proceed to checkout
                </Link>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal> */}

      {show && <AddToCartModal show={show} handleClose={handleClose} products={cartProduct} cartvalue={cartvalue}/>}
    </div >
  );
}

export default Buynow;

