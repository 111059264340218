import React from "react";
import promotionimg from "../assets/images/promotionimg.png";
import promotionpro1 from "../assets/images/promotionpro1.png";
import flower from "../assets/images/flower.png";
import smokepromotionspan from "../assets/images/smoke-promotion-span.png";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import popularpro1 from "../assets/images/popularpro1.png";
import popularpro3 from "../assets/images/popularpro3.png";
import smoke1 from "../assets/images/smoke1.png";
import axios from "axios";
import { useEffect } from "react";
import { useState } from "react";
import { baseUrl } from "../assets/utils/IP";
import { Link } from "react-router-dom";

import TrunctedText from "./TrunctedText";

function PopularProduct() {
  const [popularProducts, setPopularProducts] = useState();
  console.log("popularProducts", popularProducts)

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${baseUrl}/popular-products`);
        setPopularProducts(response.data);
      } catch (error) {}
    };

    fetchData();
  }, []);

  const settings = {
    dots: true,
    infinite: true,
    speed: 1500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 999,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
    arrows: false,
    autoplay: true, // Enable auto sliding
    autoplaySpeed: 5000, // Set the time interval in milliseconds (e.g., 2000ms = 2 seconds)
  };
  return (
    <section className="sec-Promotions popularproduct bg-black tab-ovf-x-y-hide">
      <span className="seccoverline popular-pro"></span>
      <div className="container-1640 z-10">
        <h1 className="title-mean">
          <span>POPULAR</span> Products
        </h1>

        <div className="row">
          <div className="col-lg-12">
            {popularProducts?.length > 4 ? (
              <Slider {...settings}>
                {popularProducts?.map((product) => (
                  <div>
                    <div key={product.id} className="proslider">
                      <span>
                        {" "}
                        <img src={smokepromotionspan} alt="" />
                      </span>
                      <img
                        src={`${baseUrl}/${product.main_image}`}
                        className="pro-slider-img"
                        alt=""
                      />
                      <div className="procontent justify-content-center">
                        <h1>{product.product_name}</h1>
                        <TrunctedText text={product.description_1} maxLength={53} />
                        {/* <p>{product.description_1}</p> */}
                        {/* <div className="align-items-center d-flex flex-column pe-md-5 pe-0 pro-text promotion-text-resp-mob w-100"> */}
                          <h6 className="mb-0 mt-4">${product.sale_price ? product.sale_price : product.regular_price}</h6>
                          <span>incl. GST</span>
                          <Link
                            to={`/Buynow/${product.product_id}`}
                            className="probtn px-3 py-1"
                          >
                            View Product
                          </Link>
                        {/* </div> */}
                      </div>
                    </div>
                  </div>
                ))}
              </Slider>
            ) : (
              <div className="d-flex justify-content-evenly">
                {popularProducts?.map((product) => (
                  <div>
                    <div key={product.id} className="proslider">
                      <span>
                        {" "}
                        <img src={smokepromotionspan} alt="" />
                      </span>
                      <img
                        src={`${baseUrl}/${product.main_image}`}
                        className="pro-slider-img"
                        alt=""
                      />
                      <div className="procontent">
                        <h1>{product.product_name}</h1>
                        <p>{product.description_1}</p>
                        <div className="align-items-center d-flex flex-column pe-5 pro-text w-100">
                          <h6 className="mb-0 mt-4">${product.sale_price}</h6>
                          <span>incl. GST</span>
                          <Link
                            to={`/Buynow/${product.product_id}`}
                            className="probtn px-3 py-1"
                          >
                            Buy Now
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      </div>
      <div class="smoke-section-parent">
        <div class="ag-smoke-block">
          <div>
            <img class="ag-smoke-1" src={smoke1} alt="Smoke" />
            <img class="ag-smoke-2" src={smoke1} alt="Smoke" />
            <img class="ag-smoke-3" src={smoke1} alt="Smoke" />
            <img class="ag-smoke-4" src={smoke1} alt="Smoke" />
          </div>
        </div>
      </div>
    </section>
  );
}

export default PopularProduct;
