import React, { useState, useEffect } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import { MdCloudUpload, MdDelete } from "react-icons";
import { AiFillFileImage } from "react-icons/ai";
import PlusCirc from "../../../assets/images/plus-circ.png";
import "./imageUploader.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCancel, faTimes } from "@fortawesome/free-solid-svg-icons";
import { baseUrl } from "../../../assets/utils/IP";

export default function ImageUploader({ title, onImagesChange, getGalleryImages }) {

  const location = useLocation()

  const [editorLoaded, setEditorLoaded] = useState(false);
  const [selectedImages, setSelectedImages] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);

  const path = location.pathname
  const { id } = useParams()

  useEffect(() => {
    setEditorLoaded(true);
  }, []);
  useEffect(() => {
    if (getGalleryImages?.length && typeof getGalleryImages === 'string') {
      const imgs = []
      getGalleryImages.split(",").map(elem => elem.length > 4 && imgs.push(elem))

      setSelectedImages(imgs)
    }

  }, [getGalleryImages]);

  const handleImageChange = async (e) => {
    const files = e.target.files;
    onImagesChange([...files, ...selectedImages]);
    if (files.length > 0) {
      for (const file of files) {
        const reader = new FileReader();

        reader.onloadend = async () => {
          const base64Image = reader.result; // Base64-encoded image
          setSelectedImages((prevImages) => [...prevImages, base64Image]);
        };

        reader.readAsDataURL(file);
      }
    }
  };
  const handleRemoveImage = (index) => {
    const imgs = selectedImages.filter((_, i) => i !== index)
    setSelectedImages(imgs);
    onImagesChange(imgs);
  }


  return (
    <div class="right-parent">
      <div class="image-box-parent">
        <div class="image-box-heading"></div>
        <div class="image-box" onclick="selectImage(this)">
          <input
            type="file"
            class="image-input"
            id="fileInput"
            accept="image/*"
            multiple
            onChange={handleImageChange}
          />
          <p className="text-center">
            <span>
              <img
                class="img-plus"
                src={PlusCirc}
                alt=""
                onClick={() => {
                  document.getElementById("fileInput").click();
                }}
              />
            </span>
            {title}
          </p>




          {/* {selectedImage && (
            <div>
              <img src={selectedImage} alt="Selected" width="200" />
            </div>
          )}
          {selectedImage ? (
            ""
          ) : (
            <p className="text-center">
              <span>
                <img
                  class="img-plus"
                  src={PlusCirc}
                  alt=""
                  onClick={() => {
                    document.getElementById("fileInput").click();
                  }}
                />
              </span>
              Upload Product Image
            </p>
          )} */}
        </div>

        {selectedImages.length > 0 ? (
          <div className="d-flex justify-content-around mt-3 uploaded-images">
            {selectedImages.map((image, index) => {
              return (
                <div key={index} className="position-relative align-items-center bg-white border d-flex mx-2 my-3 px-2 rounded-3 image-uploader-width">
                  <img src={(path === "/AddProduct" || image.includes(`base64`)) ? image : baseUrl + "/" + image} alt={`Selected ${index}`} className="w-100" />
                  <span onClick={() => handleRemoveImage(index)} className="bg-white border position-absolute rounded-5 shadow text-danger cross-btn" ><FontAwesomeIcon icon={faTimes} /></span>
                </div>
              );
            })}
          </div>
        ) : (
          <div className="alert alert-warning mt-2">Currently No images uploaded</div>
        )




        }



      </div>
    </div>
  );
}
