import React, { useEffect, useState } from "react";
import SideBar from "../../../Components/Admin/SideBar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChartColumn,
  faDownload,
} from "@fortawesome/free-solid-svg-icons";
import { AuthorizationToken, baseUrl } from "../../../assets/utils/IP";
import axios from "axios";

export default function Reports() {
  const [pendingOrders, setPendingOrders] = useState([]);
  const [totalOrders, setTotalOrders] = useState([]);
  const [inProgressOrders, setInProgressOrders] = useState([]);
  const [cancelOrders, setCancelOrders] = useState([]);
  const [selectedOption, setSelectedOption] = useState("Total Sales"); // Default selection

  useEffect(() => {
    const fetchData = async () => {
      let config = {
        method: 'get',
        maxBodyLength: Infinity,
        url: `${baseUrl}/get-all-pending-order`,
        headers: {
          'Authorization':
            `Bearer ${AuthorizationToken}`,
        },
      };

      try {
        const response = await axios.request(config);
        setPendingOrders(response.data);
      } catch (error) {
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      let config = {
        method: 'get',
        maxBodyLength: Infinity,
        url: `${baseUrl}/get-total-sales`,
        headers: {
          'Authorization':
            `Bearer ${AuthorizationToken}`,
        },
      };

      try {
        const response = await axios.request(config);
        setTotalOrders(response.data);
      } catch (error) {
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      let config = {
        method: 'get',
        maxBodyLength: Infinity,
        url: `${baseUrl}/get-all-in-progress`,
        headers: {
          'Authorization':
            `Bearer ${AuthorizationToken}`,
        },
      };

      try {
        const response = await axios.request(config);
        setInProgressOrders(response.data);
      } catch (error) {
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      let config = {
        method: 'get',
        maxBodyLength: Infinity,
        url: `${baseUrl}/get-all-cancled-order`,
        headers: {
          'Authorization':
            `Bearer ${AuthorizationToken}`,
        },
      };

      try {
        const response = await axios.request(config);
        setCancelOrders(response.data);
      } catch (error) {
      }
    };

    fetchData();
  }, []);

  return (
    <section className="bg-main">
      <SideBar />
      <div className="second-main-side">
        <div className="sec-main-side-parent p-5">
          <div className="blur-pages px-5">
            <div className="border-text">
              <div className="d-flex justify-content-between">
                <h1>REPORTS</h1>
              </div>
              <hr className="text-light opacity-100" />
            </div>
            <div className="mt-5">
              <h5 className="bg-golden-gradeint p-2 m-0">
                <FontAwesomeIcon icon={faChartColumn} /> Choose the report type
              </h5>
              <div className="p-4 blur-white">
                <select
                  className="w-100 p-2"
                  onChange={(e) => setSelectedOption(e.target.value)}
                >
                  <option value="Total Sales">Total Sales</option>
                  <option value="In progress Orders">In progress Orders</option>
                  <option value="Cancelled Orders">Cancelled Orders</option>
                  <option value="Pending Orders">Pending Orders</option>
                </select>
              </div>
            </div>
            {/* Render orders based on selectedOption */}
            {selectedOption === "Total Sales" && (
              <div>
                <div className="main-fi-order-box-master">
              <div className="main-fi-order-box">
                <div className="mt-5">
                  <div>
                    <h5 className="bg-golden-gradeint p-2 m-0 d-flex justify-content-between">
                      <p>
                        <FontAwesomeIcon icon={faChartColumn} /> Total sales
                      </p>
                      <div className="reports-table-reports-sec text-end d-flex">
                        {/* <p className="me-4">Date Range</p> */}
                        {/* <span className="cursor-pointer rel-two-span position-relative">
                          <FontAwesomeIcon
                            className="cursor-pointer position-absolute end-0 bg-light p-1 px-3 rounded"
                            icon={faCalendar}
                          />
                          <input className="date-inp" type="date" />
                        </span>
                        <span className="cursor-pointer rel-two-span position-relative ms-2">
                          <FontAwesomeIcon
                            className="cursor-pointer position-absolute end-0 bg-light p-1 px-3 rounded"
                            icon={faCalendar}
                          />
                          <input className="date-inp" type="date" />
                        </span> */}
                      </div>
                    </h5>
                    <div className="reports-blur">
                      <div className="grid-report border">
                        <div className="border p-2 text-center">
                          <input type="checkbox" />
                        </div>
                        <div className="border p-2 text-center">
                          <p>Customer Name</p>
                        </div>
                        <div className="border p-2 text-center">
                          <p>No. of Products</p>
                        </div>
                        <div className="border p-2 text-center">
                          <p>Total Price</p>
                        </div>
                        {/* <div className="border p-2">
                          <p>Product</p>
                        </div> */}
                        <div className="border p-2 text-center">
                          <p>Quantity</p>
                        </div>
                      </div>
                      {/* --- remove class grid-no-result --- */}
                      {totalOrders?.map((report, index) => (
                        <div className="d-grid pt-2 text-center text-light">
                          <div className="grid-report border">
                            <div className="border p-2 text-center">
                              <input type="checkbox" />
                            </div>
                            <div className="border p-2 text-center">
                              <p>{report.user_first_name}<span></span> {report.user_last_name}</p>
                            </div>
                            <div className="border p-2 text-center">
                              <p>{report.product_count}</p>
                            </div>
                            <div className="border p-2 text-center">
                              <p>{report.total_amount}</p>
                            </div>
                            <div className="border p-2 text-center">
                              <p>{report.quantity}</p>
                            </div>
                            {/* <p>
                              <FontAwesomeIcon className="fs-4" icon={faDownload} />
                            </p> */}
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
                </div>
                </div>
              </div>
            )}
            {selectedOption === "In progress Orders" && (
              <div>
<div className="main-fi-order-box-master">
              <div className="main-fi-order-box">
                <div className="mt-5">
                  <div>
                    <h5 className="bg-golden-gradeint p-2 m-0 d-flex justify-content-between">
                      <p>
                        <FontAwesomeIcon icon={faChartColumn} /> Total sales
                      </p>
                      <div className="reports-table-reports-sec text-end d-flex">
                        {/* <p className="me-4">Date Range</p> */}
                        {/* <span className="cursor-pointer rel-two-span position-relative">
                          <FontAwesomeIcon
                            className="cursor-pointer position-absolute end-0 bg-light p-1 px-3 rounded"
                            icon={faCalendar}
                          />
                          <input className="date-inp" type="date" />
                        </span>
                        <span className="cursor-pointer rel-two-span position-relative ms-2">
                          <FontAwesomeIcon
                            className="cursor-pointer position-absolute end-0 bg-light p-1 px-3 rounded"
                            icon={faCalendar}
                          />
                          <input className="date-inp" type="date" />
                        </span> */}
                      </div>
                    </h5>
                    <div className="reports-blur">
                      <div className="grid-report border">
                        <div className="border p-2 text-center">
                          <input type="checkbox" />
                        </div>
                        <div className="border p-2 text-center">
                          <p>Customer Name</p>
                        </div>
                        <div className="border p-2 text-center">
                          <p>No. of Products</p>
                        </div>
                        <div className="border p-2 text-center">
                          <p>Total Price</p>
                        </div>
                        <div className="border p-2 text-center">
                          <p>Quantity</p>
                        </div>
                        {/* <div className="border p-2">
                          <p>Action</p>
                        </div> */}
                      </div>
                      {inProgressOrders?.map((report, index) => (
                        <div className="d-grid pt-2 text-center text-light">
                          <div className="grid-report border">
                            <div className="border p-2 text-center">
                              <input type="checkbox" />
                            </div>
                            <div className="border p-2 text-center">
                              <p>{report.user_first_name}<span> </span>{report.user_last_name}</p>
                            </div>
                            <div className="border p-2 text-center">
                              <p>{report.product_count}</p>
                            </div>
                            <div className="border p-2 text-center">
                              <p>{report.total_amount}</p>
                            </div>
                            <div className="border p-2 text-center">
                              <p>{report.quantity}</p>
                            </div>
                          </div>
                          {/* <p>
                            <FontAwesomeIcon className="fs-4" icon={faDownload} />
                          </p> */}
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
                </div>
                </div>
              </div>
            )}
            {selectedOption === "Cancelled Orders" && (
              <div>
<div className="main-fi-order-box-master">
              <div className="main-fi-order-box">
                <div className="mt-5">
                  <div>
                    <h5 className="bg-golden-gradeint p-2 m-0 d-flex justify-content-between">
                      <p>
                        <FontAwesomeIcon icon={faChartColumn} /> Total sales
                      </p>
                      <div className="reports-table-reports-sec text-end d-flex">
                        {/* <p className="me-4">Date Range</p> */}
                        {/* <span className="cursor-pointer rel-two-span position-relative">
                          <FontAwesomeIcon
                            className="cursor-pointer position-absolute end-0 bg-light p-1 px-3 rounded"
                            icon={faCalendar}
                          />
                          <input className="date-inp" type="date" />
                        </span>
                        <span className="cursor-pointer rel-two-span position-relative ms-2">
                          <FontAwesomeIcon
                            className="cursor-pointer position-absolute end-0 bg-light p-1 px-3 rounded"
                            icon={faCalendar}
                          />
                          <input className="date-inp" type="date" />
                        </span> */}
                      </div>
                    </h5>
                    <div className="reports-blur">
                      <div className="grid-report border">
                        <div className="border p-2 text-center">
                          <input type="checkbox" />
                        </div>
                        <div className="border p-2 text-center">
                          <p>Customer Name</p>
                        </div>
                        <div className="border p-2 text-center">
                          <p>No. of Products</p>
                        </div>
                        <div className="border p-2 text-center">
                          <p>Total Price</p>
                        </div>
                        <div className="border p-2 text-center">
                          <p>Quantity</p>
                        </div>
                        {/* <div className="border p-2">
                          <p>Action</p>
                        </div> */}
                      </div>
                      {cancelOrders?.map((report, index) => (
                        <div className="d-grid pt-2 text-center text-light">
                          <div className="grid-report border">
                            <div className="border p-2 text-center">
                              <input type="checkbox" />
                            </div>
                            <div className="border p-2 text-center">
                              <p>{report.user_first_name}<span> </span>{report.user_last_name}</p>
                            </div>
                            <div className="border p-2 text-center">
                              <p>{report.product_count}</p>
                            </div>
                            <div className="border p-2 text-center">
                              <p>{report.total_amount}</p>
                            </div>
                            <div className="border p-2 text-center">
                              <p>{report.quantity}</p>
                            </div>
                          </div>
                          {/* <p>
                            <FontAwesomeIcon className="fs-4" icon={faDownload} />
                          </p> */}
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
                </div>
                </div>
              </div>
            )}
            {selectedOption === "Pending Orders" && (
              <div>
                <div className="main-fi-order-box-master">
              <div className="main-fi-order-box">
                <div className="mt-5">
                  <div>
                    <h5 className="bg-golden-gradeint p-2 m-0 d-flex justify-content-between">
                      <p>
                        <FontAwesomeIcon icon={faChartColumn} /> Total sales
                      </p>
                      <div className="reports-table-reports-sec text-end d-flex">
                        {/* <p className="me-4">Date Range</p> */}
                        {/* <span className="cursor-pointer rel-two-span position-relative">
                          <FontAwesomeIcon
                            className="cursor-pointer position-absolute end-0 bg-light p-1 px-3 rounded"
                            icon={faCalendar}
                          />
                          <input className="date-inp" type="date" />
                        </span>
                        <span className="cursor-pointer rel-two-span position-relative ms-2">
                          <FontAwesomeIcon
                            className="cursor-pointer position-absolute end-0 bg-light p-1 px-3 rounded"
                            icon={faCalendar}
                          />
                          <input className="date-inp" type="date" />
                        </span> */}
                      </div>
                    </h5>
                    <div className="reports-blur">
                      <div className="grid-report border">
                        <div className="border p-2 text-center">
                          <input type="checkbox" />
                        </div>
                        <div className="border p-2 text-center">
                          <p>Customer Name</p>
                        </div>
                        <div className="border p-2 text-center">
                          <p>No. of Products</p>
                        </div>
                        <div className="border p-2 text-center">
                          <p>Total Price</p>
                        </div>
                        <div className="border p-2 text-center">
                          <p>Quantity</p>
                        </div>
                        {/* <div className="border p-2">
                          <p>Action</p>
                        </div> */}
                      </div>
                      {pendingOrders?.map((report, index) => (
                        <div className="d-grid pt-2 text-center text-light">
                          <div className="grid-report border">
                            <div className="border p-2 text-center">
                              <input type="checkbox" />
                            </div>
                            <div className="border p-2 text-center">
                              <p>{report.user_first_name}<span> </span>{report.user_last_name}</p>
                            </div>
                            <div className="border p-2 text-center">
                              <p>{report.product_count}</p>
                            </div>
                            <div className="border p-2 text-center">
                              <p>{report.total_amount}</p>
                            </div>
                            <div className="border p-2 text-center">
                              <p>{report.quantity}</p>
                            </div>
                          </div>
                          {/* <p>
                            <FontAwesomeIcon className="fs-4" icon={faDownload} />
                          </p> */}
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
                </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </section>
  );
}