import React, { useContext, useEffect, useRef, useState } from "react";
import Warning from "../Components/Warning";
import Banner from "../Components/Banner";
import Header from "../Components/Header/Header";
import brandhover from "../assets/images/brandhover.png";
import brand1 from "../assets/images/brand1.png";
import shoptria from "../assets/images/shoptria.png";
import shoptria2 from "../assets/images/shoptria2.png";
import line from "../assets/images/pathline.png";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";
import { Link, useNavigate } from "react-router-dom";

import promotionimg from "../assets/images/promotionimg.png";
import promotionpro1 from "../assets/images/promotionpro1.png";
import flower from "../assets/images/flower.png";
import smokepromotionspan from "../assets/images/smoke-promotion-span.png";

import popularpro1 from "../assets/images/popularpro1.png";
import popularpro3 from "../assets/images/popularpro3.png";
import smoke1 from "../assets/images/smoke1.png";
import sectioncoverline from "../assets/images/sectioncoverline.png";
import filterproduct2 from "../assets/images/filterproduct2.png";
import filterproduct1 from "../assets/images/filterproduct1.png";
import filterproduct3 from "../assets/images/filterproduct3.png";

import filterproduct4 from "../assets/images/filterproduct4.png";
import filterproduct5 from "../assets/images/filterproduct5.png";
import filterproduct6 from "../assets/images/filterproduct6.png";

import filterproduct7 from "../assets/images/filterproduct7.png";
import filterproduct8 from "../assets/images/filterproduct8.png";
import filterproduct9 from "../assets/images/filterproduct9.png";

import filterproduct10 from "../assets/images/filterproduct10.png";
import filterproduct11 from "../assets/images/filterproduct11.png";
import filterproduct12 from "../assets/images/filterproduct12.png";

import span1 from "../assets/images/span1.png";
import span2 from "../assets/images/span2.png";

import brandsales1 from "../assets/images/brandsales1.png";
import brandsales2 from "../assets/images/brandsales2.png";

import brandsales3 from "../assets/images/brandsales3.png";
import brandsales4 from "../assets/images/brandsales4.png";

import brandsales5 from "../assets/images/brandsales5.png";

import Footer from "../Components/Footer/Footer";
import axios from "axios";
import { baseUrl } from "../assets/utils/IP";
import Pagination from "../Components/Pagination";
import { CartContext } from "../Components/Cart";
import Swal from "sweetalert2";

import AddToCartModal from "../Components/Modals/AddToCartModal";
import TrunctedText from "../Components/TrunctedText";

function Sales() {
  const [minPrice, setMinPrice] = useState(2);
  const [maxPrice, setMaxPrice] = useState(2000);
  const [products, setProducts] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [brandLogo, setBrndLogo] = useState();
  const [showMore, setShowMore] = useState(false);
  const [showMoreCat, setShowMoreCat] = useState(false);
  const [category, setCategory] = useState();
  const [options, setOptions] = useState([]);
  const [selectedItem, setSelectedItem] = useState(9); // Default value is "1" or any other default value
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [selectedBrands, setSelectedBrands] = useState([]);
  const [selectedBrandId, setSelectedBrandId] = useState([]);
  const [selectedProductId, setSelectedProductId] = useState([]);
  const handleSliderChange = (values) => {
    setMinPrice(values[0]);
    setMaxPrice(values[1]);
  };

  const [show, setShow] = useState(false);
  const [cartvalue, setcartvalue] = useState(1);
  const [cartProduct, setCartProduct] = useState();

  const { updateQuantity } = useContext(CartContext);

  useEffect(() => {
    const getBrands = async () => {
      try {
        const response = await axios.get(`${baseUrl}/get-all-brands`);
        if (response?.status == 200) {
          setBrndLogo(response?.data);
        }
      } catch (err) {}
    };
    getBrands();
  }, []);

  useEffect(() => {
    const getProducts = async () => {
      try {
        const response = await axios.get(
          `${baseUrl}/get-all-discounted-products`
        );
        if (response?.status == 200) {
          setProducts(response?.data?.results);
        }
      } catch (err) {}
    };
    getProducts();
  }, []);

  useEffect(() => {
    const getCategory = async () => {
      try {
        const response = await axios.get(`${baseUrl}/get-all-shop-category`);
        if (response?.status == 200) {
          setCategory(response?.data);
        }
      } catch (err) {}
    };
    getCategory();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${baseUrl}/get-most-view`);
        const data = response.data;
        let addedOptions = 0;
        const filteredOptions = []; 
        data.forEach((option) => {
          if (option.views_count > 1 && addedOptions < 5) {
            addedOptions++
            filteredOptions.push(option)
          }
          
        });
        
        // console.log("options>>>",response )
        // console.log("second>>>",filteredOptions )
        setOptions(filteredOptions);
      } catch (error) {
        console.log("error>>>",error )
      }
    };

    fetchData();
  }, []);

  const filterProducts = () => {
    if (!products || !products.length) {
      return [];
    }

    const filteredProducts = products.filter((product) => {
      const isInSelectedCategories =
        selectedCategories.length === 0 ||
        selectedCategories.includes(product.category_id);

      // Check if the product's brand is among the selected brands
      const isInSelectedBrands =
        selectedBrands.length == 0 || selectedBrands.includes(product.brand_id);

      const isMostViewed =
        selectedProductId.length == 0 ||
        selectedProductId.includes(product.product_id);

      const isSelectedBrand =
        selectedBrandId.length == 0 ||
        selectedBrandId.includes(product.brand_id);

      const isWithinPriceRange =
        product?.regular_price >= minPrice && product?.regular_price <= maxPrice;

        // Return true if both category and brand filters match
      return (
        isInSelectedCategories &&
        isInSelectedBrands &&
        isMostViewed &&
        isSelectedBrand &&
        isWithinPriceRange
      );
    });

    return filteredProducts;
  };

  const handleSelectChange = (event) => {
    const selectedValue = event.target.value;
    const selectedValueAsInt = parseInt(selectedValue, 10);
    const updatedProductIds = [selectedValueAsInt];
    setSelectedProductId(updatedProductIds);
    setCurrentPage(1)

    // You can now use updatedProductIds as an array of selected product IDs
  };
 
  const handleSelectBrand = (event) => {
    const selectedBrandId = event.target.value;
    const selectedBrandIdAsInt = parseInt(selectedBrandId, 10);
    const updatedBrandIds = [selectedBrandIdAsInt];
    setSelectedBrandId(updatedBrandIds);
    setCurrentPage(1)

    // You can now use updatedProductIds as an array of selected product IDs
  };

  const handleCategoryChange = (category_id) => {
    setSelectedCategories((prevSelected) => {
      if (prevSelected.includes(category_id)) {
        // Remove the category from the selected list
        return prevSelected.filter((id) => id !== category_id);
      } else {
        // Add the category to the selected list
        return [...prevSelected, category_id];
      }
    });
    setCurrentPage(1)
  };

  const handleBrandChange = (brand_id) => {
    setSelectedBrands((prevSelected) => {
      if (prevSelected.includes(brand_id)) {
        // Remove the brand from the selected list
        return prevSelected.filter((id) => id !== brand_id);
      } else {
        // Add the brand to the selected list
        return [...prevSelected, brand_id];
      }
    });
    
    setCurrentPage(1)
  };

  const filteredProducts = filterProducts();

  const clearSelectedProductId = () => {
    setSelectedProductId([]);
    setSelectedBrandId([]);
  };

  // ----

  // pagination
  const itemsPerPage = selectedItem ? selectedItem : 9;
  // console.log("itemsPerPage>>>", itemsPerPage);
  // console.log("selectedItem>>>", selectedItem);

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + +itemsPerPage;
  const ordersToDisplay = filteredProducts.slice(startIndex, endIndex);
  // console.log("ordersToDisplay>>>", ordersToDisplay);
  // console.log(startIndex, endIndex);
  const totalPages = Math.ceil(
    filteredProducts.reverse().length / itemsPerPage
  );
  //

  const handlePageItemChange = (event) => {
    const selectedValue = event.target.value;
    setSelectedItem(selectedValue);
  };

  const p_quantity = 1;
  const navigate = useNavigate();

  const handleSales = (product) => {
    updateQuantity(product, p_quantity);

    Swal.fire({
      icon: "success",
      title: "Add to Cart",
      text: "Your Product is Added to Cart",
      showCancelButton: true,
      confirmButtonText: "Proceed to Checkout",
      cancelButtonText: "Back",
      customClass: {
        container: "customstyle",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        navigate("/cart");
        window.scrollTo(0, 0);
        // console.log("Ok button clicked");
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        // Handle Back button click
      }
    });
  };

  // const limitProdDesc = (desc) => {
  //   let limitLength = 53

  //   const updatedDesc = desc.length > limitLength
  //     ? desc.slice(0, limitLength) + "..."
  //     : desc
  //   return updatedDesc
  // }

  // AddToCartModal Functionality

  const handleShow = (product) => {
    updateQuantity(product, p_quantity);
    setCartProduct(product);
    setShow(true);
  };
  const handleClose = () => setShow(false);

  // mobile filter usestate
  const [showFilters, setShowFilters] = useState(false);

  const handleFilterHeadingClick = () => {
    setShowFilters(!showFilters);
  };

  // mobile filter usestate

  return (
    <div className="font-bebas">
      <Warning />

      <div className="sales-hover">
        <Header />
      </div>

      <section className="secshop ">
        <div class="smoke-section-parent">
          <div class="ag-smoke-block">
            <div>
              <img class="ag-smoke-1" src={smoke1} alt="Smoke" />
              <img class="ag-smoke-2" src={smoke1} alt="Smoke" />

              <img class="ag-smoke-4" src={smoke1} alt="Smoke" />
            </div>
          </div>
        </div>
        <span className="EXQUISITE-span1">
          <img src={span1} alt="" />
        </span>
        <span className="EXQUISITE-span2">
          <img src={span2} alt="" />
        </span>

        <div className="container-1640 position-relative z-10">
          <div className="toplinecon">
            <div className="hamicons">
              <ul>
                <li>
                  <Link>Home</Link>
                </li>
                <li>
                  <Link>
                    <i class="fa-solid fa-chevron-right"></i>
                  </Link>
                </li>
                <li>
                  <Link>Shop</Link>
                </li>
              </ul>
            </div>
            <div className="pagin">
              {/* <div className="bx">
                <img src={shoptria} alt="" />
                <img src={line} alt="" />
                <img src={shoptria2} alt="" />
              </div> */}
              <div>
                <select
                  class="form-select"
                  aria-label="Default select example"
                  value={selectedItem} // Set the value of the select element to the state variable
                  onChange={handlePageItemChange}
                >
                  <option selected value="9">
                    Items
                  </option>
                  <option value="5">5</option>
                  <option value="10">10</option>
                  <option value="15">15</option>
                </select>
              </div>
            </div>
          </div>

          <div className="filter-mean filter-mean-mob position-relative">
            <div className="filters d-md-block d-none">
              <div className="fitlertitel">
                <h2>Filters</h2>
              </div>

              <div className="range" style={{ marginTop: "20px" }}>
                <Slider
                  range
                  min={2}
                  max={2000}
                  defaultValue={[minPrice, maxPrice]}
                  onChange={handleSliderChange}
                />
                <p>
                  <span> Min:$ {minPrice}</span>
                  <span> Max:$ {maxPrice}</span>
                </p>
              </div>
              <div className="select-apn">
                <select
                  className="form-select"
                  aria-label="Default select example"
                  onChange={handleSelectChange}
                  value={selectedProductId}
                >
                  <option style={{}}>Most viewed</option>
                  {options?.map((option, index) => (
                    <option key={index} value={option.product_id} style={{}}>
                      {option.product_name}{" "}
                      {/* Change 'label' to the actual property you want to display */}
                    </option>
                  ))}
                </select>
                {/* <select
                  class="form-select"
                  aria-label="Default select example"
                  onChange={handleSelectBrand}
                  value={selectedBrandId}
                >
                  <option style={{}}>All Brands</option>
                  {brandLogo?.map((brands) => (
                    <option value={brands.brand_id} style={{}}>
                      {brands.brand_name}
                    </option>
                  ))}
                </select> */}
              </div>
              <div className="p-3">
                <button
                  className="btn btn-gradient w-100 rounded"
                  onClick={clearSelectedProductId}
                >
                  Reset
                </button>
              </div>
              <div className="categories">
                <h2>Category</h2>

                {category
                  ?.slice(0, showMoreCat ? category?.length : 5)
                  .map((category) => (
                    <div>
                      <div
                        key={category.category_id}
                        className="categoriesitem mb-3"
                      >
                        <div className="form-group">
                          <input
                            type="checkbox"
                            id={category.category_id}
                            checked={selectedCategories.includes(
                              category.category_id
                            )}
                            onChange={() =>
                              handleCategoryChange(category.category_id)
                            }
                          />
                          <label htmlFor={category.category_id}></label>
                        </div>
                        <div className="catecon">
                          <p>{category?.category_name}</p>
                          <p>{category?.product_count}</p>
                        </div>
                      </div>
                    </div>
                  ))}
                {category?.length > 5 && !showMoreCat && (
                  <button onClick={() => setShowMoreCat(true)}>
                    Show More
                  </button>
                )}
              </div>

              <div className="categories salespage">
                <div className="brandtitle">
                  <h2>Brands</h2>
                </div>
                {brandLogo
                  ?.slice(0, showMore ? brandLogo?.length : 5)
                  .map((brand) => (
                    <div className="categoriesitem BrandItems">
                      <div key={brand.brand_id} className="form-group">
                        <input
                          type="checkbox"
                          id={brand.brand_id}
                          checked={selectedBrands.includes(brand.brand_id)}
                          onChange={() => handleBrandChange(brand.brand_id)}
                        />
                        <label htmlFor={brand.brand_id}></label>
                      </div>
                      <div className="catecon">
                        <img src={`${baseUrl}/${brand.brand_logo}`} alt="" />
                      </div>
                    </div>
                  ))}
                {brandLogo?.length > 5 && !showMore && (
                  <button onClick={() => setShowMore(true)}>Show More</button>
                )}
              </div>
            </div>
            <div className="filters filter-mobleft position-absolute d-md-none d-block">
              <div className="fitlertitel" onClick={handleFilterHeadingClick}>
                <h2>Filters</h2>
              </div>

              {showFilters && (
                <>
                  <div className="range" style={{ marginTop: "20px" }}>
                    <Slider
                      range
                      min={2}
                      max={2000}
                      defaultValue={[minPrice, maxPrice]}
                      onChange={handleSliderChange}
                    />
                    <p>
                      <span> Min:$ {minPrice}</span>
                      <span> Max:$ {maxPrice}</span>
                    </p>
                  </div>
                  <div className="select-apn">
                    <select
                      className="form-select"
                      aria-label="Default select example"
                      onChange={handleSelectChange}
                      value={selectedProductId}
                    >
                      <option style={{}}>Most viewed</option>
                      {options?.map((option, index) => (
                        <option
                          key={index}
                          value={option.product_id}
                          style={{}}
                        >
                          {option.product_name}{" "}
                          {/* Change 'label' to the actual property you want to display */}
                        </option>
                      ))}
                    </select>
                    {/* <select
                  class="form-select"
                  aria-label="Default select example"
                  onChange={handleSelectBrand}
                  value={selectedBrandId}
                >
                  <option style={{}}>All Brands</option>
                  {brandLogo?.map((brands) => (
                    <option value={brands.brand_id} style={{}}>
                      {brands.brand_name}
                    </option>
                  ))}
                </select> */}
                  </div>
                  <div className="p-3">
                    <button
                      className="btn btn-gradient w-100 rounded"
                      onClick={clearSelectedProductId}
                    >
                      Reset
                    </button>
                  </div>
                  <div className="categories">
                    <h2>Category</h2>

                    {category
                      ?.slice(0, showMoreCat ? category?.length : 5)
                      .map((category) => (
                        <div>
                          <div
                            key={category.category_id}
                            className="categoriesitem mb-3"
                          >
                            <div className="form-group">
                              <input
                                type="checkbox"
                                id={category.category_id}
                                checked={selectedCategories.includes(
                                  category.category_id
                                )}
                                onChange={() =>
                                  handleCategoryChange(category.category_id)
                                }
                              />
                              <label htmlFor={category.category_id}></label>
                            </div>
                            <div className="catecon">
                              <p>{category?.category_name}</p>
                              <p>{category?.product_count}</p>
                            </div>
                          </div>
                        </div>
                      ))}
                    {category?.length > 5 && !showMoreCat && (
                      <button onClick={() => setShowMoreCat(true)}>
                        Show More
                      </button>
                    )}
                  </div>

                  <div className="categories salespage">
                    <div className="brandtitle">
                      <h2>Brands</h2>
                    </div>
                    {brandLogo
                      ?.slice(0, showMore ? brandLogo?.length : 5)
                      .map((brand) => (
                        <div className="categoriesitem BrandItems">
                          <div key={brand.brand_id} className="form-group">
                            <input
                              type="checkbox"
                              id={brand.brand_id}
                              checked={selectedBrands.includes(brand.brand_id)}
                              onChange={() => handleBrandChange(brand.brand_id)}
                            />
                            <label htmlFor={brand.brand_id}></label>
                          </div>
                          <div className="catecon">
                            <img
                              src={`${baseUrl}/${brand.brand_logo}`}
                              alt=""
                            />
                          </div>
                        </div>
                      ))}
                    {brandLogo?.length > 5 && !showMore && (
                      <button onClick={() => setShowMore(true)}>
                        Show More
                      </button>
                    )}
                  </div>
                </>
              )}
            </div>

            <div className="filterright filter-product w-100">
              <div className="d-flex flex-wrap ">
                {ordersToDisplay?.map((product) => (
                  <div
                    key={product.id}
                    className="d-flex position-relative product-main"
                  >
                    <div className="align-items-center d-flex mx-2 pro2-content">
                      <div className="position-relative product-img z-1 w-25">
                        {/* <img src={product?.main_image ? `${baseUrl}/${product.main_image}` : filterproduct2} alt={product.product_name} className="w-100" />
                        <img src={filterproduct2} alt="" className="w-100" /> */}
                        {product.main_image != null ? (
                          <Link to={`/Buynow/${product.product_id}`}>
                            <img
                              src={`${baseUrl}/${product.main_image}`}
                              alt={product.name}
                              className="w-100"
                            />{" "}
                          </Link>
                        ) : (
                          <img
                            src={filterproduct1}
                            alt={product}
                            className="w-100"
                          />
                        )}
                      </div>
                      <div className="position-relative procontent justify-content-center justify-content-center">
                        <Link to={`/Buynow/${product.product_id}`}>
                          <img src={flower} alt="" />
                          <h1>{product.product_name}</h1>
                          <TrunctedText text={product.description_1} maxLength={53} />
                          {/* <p>{limitProdDesc(product.description_1)}</p> */}
                          <h6>$
                            {product.sale_price > 0 ? product.sale_price : product.regular_price}
                          </h6>
                          <span>incl. GST</span>
                        </Link>
                        <button
                          onClick={() => handleShow(product)}
                          className="probtn border-0 linkbtn"
                        >
                          Add to Cart
                        </button>
                      </div>
                      {
                        product.discount_percentage > 0 && <div className="per-off">
                          {
                            Math.round(product.discount_percentage) + '%'
                          }
                          {/* {product.sale_price && product.regular_price
                            ? `${(
                              ((product.regular_price - product.sale_price) /
                                product.regular_price) *
                              100
                            )
                              .toFixed(2)
                              .slice(0, 2)}% Off`
                            : "No discount"} */}
                            <sub>&nbsp;  Off</sub>
                        </div>
                      }

                    </div>
                    <span className="position-absolute smokehover">
                      <img src={smokepromotionspan} alt="" />
                    </span>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
        <div
          class="d-flex justify-content-between mx-5 pagiban ms-auto"
          style={{ width: "10%" }}
        >
          {/* <p>Page 1 of 12</p> */}

          <Pagination
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={(page) => setCurrentPage(page)}
          />
        </div>
      </section>

      <Footer />

      {show && (
        <AddToCartModal
          show={show}
          handleClose={handleClose}
          products={cartProduct}
          cartvalue={cartvalue}
        />
      )}
    </div>
  );
}

export default Sales;
